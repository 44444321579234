import React from "react";
import { Dropdown } from "antd";
import {
  DropdownName,
  DropdownContainer,
  DropdownContent,
} from "./styledComponents";
import { DownOutlined } from "@ant-design/icons";
const CustomDropdown = ({ dropdownName, key, menu, visible, className, page }) => {
  return (
    <>
      <DropdownContainer key={key} page={page}>
        <Dropdown
          overlay={menu}
          placement="bottomCenter"
          // visible={visible}
          overlayClassName={className}
        >
          <DropdownContent>
            <DropdownName>{dropdownName}</DropdownName>
            <DownOutlined />
          </DropdownContent>
        </Dropdown>
      </DropdownContainer>
    </>
  );
};

export default CustomDropdown;
