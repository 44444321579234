import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { message } from 'antd';
import ApiCalls from '../../../apis/ApiCalls';
import { SearchInputContainer, SearchIconContainer } from '../PatientDashboard/styledComponents';
import SearchIcon from '../../../assets/icons/DashBoardIcons/SearchIcon';
import NotFound from '../../../components/NotFound';
import { LoadingOutlined } from '@ant-design/icons';
import {
  DoctorSelectionContainer,
  Heading,
  Header,
  SearchBar
} from '../CorporateDashboard/styledComponent';
import { StyledAutoComplete } from './styledComponents';
import storage from '../../../storage';
import ImageNotAvailable from '../../../assets/images/CorporateModule/Image_not_available.png';

const CompanyDoctorList = () => {
  const history = useHistory();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [doctorSearchList, setDoctorSearchList] = useState([]);

  const getDoctorList = () => {
    let userId = storage.user.getItem()?._id;
    ApiCalls.getCompanyDoctors(userId)
      .then((response) => {
        if (response.data.status) {
          if (response?.data?.data?.length > 0) {
            setData(response.data.data);
            setDoctorSearchList(response.data.data);
          }
        } else {
          message.error("Can't fetch therapist list");
        }
        setLoading(false);
      })
      .catch((err) => {
        setData([]);
        setDoctorSearchList([]);
        setLoading(false);
      });
  };

  useEffect(() => {
    getDoctorList();
  }, []);

  // Doctor search
  const onSearch = (value) => {
    if(value?.trim('')?.length === 0) {
      setDoctorSearchList(data);
    } else {
      let temp = [];
      data.forEach(item => {
        if(item.fullName?.toLowerCase()?.includes(value?.toLowerCase())) {
          temp.push(item);
        }
      });
      setDoctorSearchList(temp);
    }
  }

  // Go to doctor profile
  const goToDoctorProfile = (item) => {
    history.push(`/user/base/home/doctor-profile/${item._id}`);
  }

  return loading ? (
    <LoadingOutlined />
  ) : (
    <DoctorSelectionContainer>
      <Header>
        <Heading>Selected Therapists</Heading>
      </Header>
      {data?.length > 0 ? (
        <>

          {/* Search */}
          <SearchBar>
            <SearchInputContainer style={{ position: 'relative' }}>
              <SearchIconContainer style={{ position: 'absolute', zIndex: 20 }}>
                <SearchIcon />
              </SearchIconContainer>
              <StyledAutoComplete
                placeholder='Search'
                options={doctorSearchList?.map(item => ({ value: JSON.stringify(item), label: item?.fullName }))}
                onSearch={onSearch}
                onSelect={value => goToDoctorProfile(JSON.parse(value))}
              />
            </SearchInputContainer>
          </SearchBar>

          {/* Doctor list */}
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label='simple table'>
              <TableHead>
                <TableRow>
                  <TableCell />
                  <TableCell>Name</TableCell>
                  <TableCell align='right'>Expertise</TableCell>
                  <TableCell align='right'>Rating</TableCell>
                  <TableCell align='right'>Consultation Fees</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data.map((item) => (
                  <TableRow
                    key={item._id}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    style={{ cursor: 'pointer' }}
                    onClick={() => goToDoctorProfile(item)}
                  >
                    <TableCell>
                      <img
                        alt='No img found'
                        src={item.profilePic}
                        height='25px'
                        width='25px'
                        style={{ borderRadius: '50%' }}
                        onError={(e) => {
                          if (e.target.src !== ImageNotAvailable) {
                            e.target.src = ImageNotAvailable;
                          }
                        }}
                      />
                    </TableCell>
                    <TableCell component='th' scope='row'>
                      {item.fullName}
                    </TableCell>
                    <TableCell align='right'>{item.consultationType}</TableCell>
                    <TableCell align='right'>{item.rating}</TableCell>
                    <TableCell align='right'>₹{item.consultationFee}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>

        </>
      ) : (
        <NotFound text='No Therapists Found' />
      )}
    </DoctorSelectionContainer>
  );
};

export default CompanyDoctorList;