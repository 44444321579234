import React, { useEffect, useState } from "react";
import { SectionHeading, Description, ListItem, Wrappers } from "./styledComponents";
import ApiCalls from "../../../apis/ApiCalls";
import { message } from "antd";

const PrivacyPolicy = () => {
  const [privacyPolicy, setPrivacyPolicy] = useState([]);

  const getPrivacyPolicy = () => {
    ApiCalls.fetchPrivacyPolicy()
      .then((response) => {
        if (response.data.status) {
          setPrivacyPolicy(response.data.data);
        }
      })
      .catch((error) => {
        message.error(
          error.response
            ? error?.response?.data?.data?.message
            : "No Response From Server",
          5
        );
      });
  };

  useEffect(() => {
    getPrivacyPolicy();
  }, []);

  return (
    <Wrappers>
      {privacyPolicy.map((item) => (
        <>
          {item.title ? <SectionHeading>{item.title}</SectionHeading> : null}
          {item.description ? (
            <Description>{item.description}</Description>
          ) : null}
          {item.ul ? (
            <ul>
              {item.ul.map((item) => {
                return <ListItem>{item.li}</ListItem>;
              })}
            </ul>
          ) : null}
        </>
      )
      )}
    </Wrappers>
  );
};

export default PrivacyPolicy;
