import React from "react";
import styled from "styled-components";
import { Row, Col, message, Form, Input } from "antd";
import ApiCalls from "../../apis/ApiCalls";
import { useState } from "react";
import { useHistory } from "react-router-dom";
import { Main } from "../UpdateProfilePatient/styledComponents";
import {
  FormWrapper,
  StyledFormItem,
  LogoContainer,
  FormHeading,
  WelcomeText,
} from "../signup/styledComponents";
import storage from "../../storage/index";
import reduxActions from "../../redux-store/actions/index";
import PrimaryButton from "../../components/PrimaryButton/index";
import Timer from "./Timer";
import { useDispatch } from "react-redux";
import TextLogo from "../../assets/icons/Logo/TextLogo";

const CustomCol = styled(Col)``;

const FormStyle = styled(Form)`
  width: 100%;
`;

const OtpVerification = ({ forgotPassword }) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [minutes, setMinutes] = useState(5);
  const [seconds, setSeconds] = useState(0);
  const history = useHistory();
  const dispatch = useDispatch();

  const onFinish = (values) => {
    setLoading(true);
    ApiCalls.verifyOtp(values)
      .then((response) => {
        if (response.data.status) {
          message.success(response.data.data.message);
          if (forgotPassword) {
            history.push(
              `/user/patient/reset-password/${response.data.data.authToken}`
            );
          } else {
            storage.authToken.setItem(response.data.data.authToken);
            dispatch(
              reduxActions.authActions.setAuthToken(
                response.data.data.authToken
              )
            );
            history.push("/user/patient/update-profile");
          }
        } else {
          message.error(response.data.data.message);
        }
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        message.error(
          error.response
            ? error?.response?.data?.data?.message
            : "No Response From Server",
          5
        );
      });
  };

  const getNewOtp = () => {
    ApiCalls.resendOtp()
      .then((response) => {
        if (response.data.status) {
          setMinutes(15);
          setSeconds(0);
          message.success(response.data.data.message);
        } else message.error(response.data.data.message);
      })
      .catch((error) => {
        message.error(
          error.response
            ? error?.response?.data?.data?.message
            : "No Response From Server",
          5
        );
      });
  };

  return (
    <Main login={true}>
      <Row>
        <LogoContainer>
          <TextLogo />
        </LogoContainer>
      </Row>
      <Row style={{ marginBottom: "114px" }}>
        <Col span={24}>
          <FormWrapper>
            <FormHeading>OTP Verification</FormHeading>
            <WelcomeText>
              Please enter the OTP send to your phone number. Confirm the OTP to
              complete signup
            </WelcomeText>
            <FormStyle
              layout="vertical"
              form={form}
              initialValues={{
                layout: "vertical",
              }}
              onFinish={onFinish}
            >
              <Row>
                <CustomCol span={24}>
                  <StyledFormItem
                    name="otp"
                    rules={[
                      {
                        required: true,
                        message: "OTP is required",
                      },
                    ]}
                    label="OTP"
                  >
                    <Input placeholder="Enter OTP" />
                  </StyledFormItem>
                </CustomCol>
              </Row>

              <Row>
                <Col span={24}>
                  <StyledFormItem>
                    <PrimaryButton
                      htmlType="submit"
                      loading={loading}
                      label="Verify"
                    />
                  </StyledFormItem>
                </Col>
              </Row>
              <Row>
                <Timer
                  seconds={seconds}
                  setSeconds={setSeconds}
                  minutes={minutes}
                  setMinutes={setMinutes}
                  getNewOtp={getNewOtp}
                />
              </Row>
            </FormStyle>
          </FormWrapper>
        </Col>
      </Row>
    </Main>
  );
};

export default OtpVerification;
