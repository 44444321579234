import React, { useState } from "react";
import ApiCalls from "../../apis/ApiCalls";
import CustomSearchSelect from "../../components/CustomSearchSelect";

const LanguageSearch = ({ selectedLanguage, setSelectedLanguage }) => {
  const [searchValue, setSearchValue] = useState("");
  const [scrollLoading, setScrollLoading] = useState(false);
  const [hasMore, setHasMore] = useState(false);
  const [languageList, setLanguageList] = useState([]);

  const searchLanguages = (page) => {
    if (searchValue.length > 0) {
      setScrollLoading(true);
      let params = {
        page: page,
        limit: 20,
        searchKey: searchValue,
      };
      if (page === 0) setLanguageList([]);

      ApiCalls.getAllLanguages(params)
        .then((response) => {
          if (response.data.status) {
            let languages = response.data.data.languages;
            if (page === 0) {
              setLanguageList(languages);
            } else {
              setLanguageList(languageList.concat(languages));
            }
            setScrollLoading(false);
            setHasMore(languages.length > 19);
          }
        })
        .catch((error) => {
          setScrollLoading(false);
        });
    }
  };

  return (
    <CustomSearchSelect
      valueKeyName="language"
      title="Search by language"
      placeholder="Search by Language"
      emptyMessage="Search to find languages"
      searchValue={searchValue}
      setSearchValue={setSearchValue}
      selectedValue={selectedLanguage}
      setSelectedValue={setSelectedLanguage}
      searchFunction={searchLanguages}
      scrollLoading={scrollLoading}
      hasMore={hasMore}
      data={languageList}
    />
  );
};

export default LanguageSearch;
