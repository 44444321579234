import React, { useState, useEffect } from "react";
import { UpdateAreaContainer } from "../styledComponents";
import { message } from "antd";
import Section from "./Section";
import ApiCalls from "../../../apis/ApiCalls";

const EducationExperience = ({
  educationalDetails,
  experienceDetails,
  fetchWorkProfile,
  trigger,
  setTrigger,
  setCurrentPage,
  setLastCompletedPage,
}) => {
  const [addNewEducation, setAddNewEducation] = useState(true);
  const [addNewExperience, setAddNewExperinece] = useState(true);
  const [editEducationItem, setEditEducationItem] = useState(null);
  const [editExperienceItem, setEditExperienceItem] = useState(null);
  // eslint-disable-next-line
  const [loading, setLoading] = useState(false);
  // eslint-disable-next-line
  const [temp, setTemp] = useState(false);

  const checkUpdated = () => {
    if (educationalDetails.length < 1)
      message.error("Education information is mandatory");
    else {
      setTrigger(0);
      setCurrentPage((prev) => prev + 1);
      setLastCompletedPage((prev) => prev + 1);
    }
  };

  const deleteItem = (value, isEducation) => {
    let data = {};
    if (isEducation) data.educationalDetails = { ...value, status: "delete" };
    else data.experienceDetails = { ...value, status: "delete" };
    ApiCalls.updateDoctorExtraInfo(data)
      .then((response) => {
        if (response.data.status) {
          message.success("Succesfully deleted item");
          fetchWorkProfile(setLoading, setTemp);
        } else message.error("Failed to delete");
      })
      .catch((error) =>
        message.error(
          error.response
            ? error?.response?.data?.data?.message
            : "No Response From Server",
          5
        )
      );
  };

  useEffect(() => {
    trigger && checkUpdated();
    // eslint-disable-next-line
  }, [trigger]);

  return (
    <>
      <UpdateAreaContainer>
        <Section
          itemList={educationalDetails}
          editItem={editEducationItem}
          setEditItem={setEditEducationItem}
          fetchWorkProfile={fetchWorkProfile}
          addNew={addNewEducation}
          setAddNew={setAddNewEducation}
          education={true}
          deleteItem={deleteItem}
        />
        <Section
          itemList={experienceDetails}
          editItem={editExperienceItem}
          setEditItem={setEditExperienceItem}
          fetchWorkProfile={fetchWorkProfile}
          addNew={addNewExperience}
          setAddNew={setAddNewExperinece}
          education={false}
          deleteItem={deleteItem}
        />
      </UpdateAreaContainer>
    </>
  );
};

export default EducationExperience;
