import React, { useState, useEffect } from 'react';
import {
  CardContainer,
  ProfileContainer,
  Header,
  Heading,
  Discription,
  Details,
  EmployeeProfileContainer,
  EmployeeInfo,
  Profile,
  Name,
  EmailId,
  EmployeeDetails,
  HeaderWrapper,
  EditButton,
  DividerLine,
  EmployeeBasic,
  Text,
  Text2,
  LogoutConfirmationText,
  ItemWrapper,
} from './styledComponent';
import { useHistory } from 'react-router-dom';
import { Divider, message } from 'antd';
import { useDispatch } from 'react-redux';
import Navigation from '../../../assets/images/CorporateModule/assistant_navigation.png';
import Group from '../../../assets/images/CorporateModule/groups.png';
import Store from '../../../assets/images/CorporateModule/store.png';
import Privacy from '../../../assets/images/CorporateModule/admin_panel_settings.png';
import PaymentHistoryImage from '../../../assets/images/CorporateModule/history.png';
import Power from '../../../assets/images/CorporateModule/power_rounded.png';
import ApiCalls from '../../../apis/ApiCalls';
import storage from '../../../storage';
import reduxActions from '../../../redux-store/actions';
import CustomModal from '../../../components/CustomModal';
import ImageNotAvailable from '../../../assets/images/CorporateModule/Image_not_available.png';
import { RightOutlined } from "@ant-design/icons";

const ProfileCorporate = () => {
  let history = useHistory();
  // eslint-disable-next-line
  const [companyProfiles, setCompanyProfiles] = useState({});
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();
  let companyProfile = storage.user.getItem();

  const getCompanyprofile = () => {
    ApiCalls.getCompanyProfile()
      .then((response) => {
        if (response.data.status) {
          setCompanyProfiles(response.data.data);
          storage.user.setItem(response.data.data);
        }
      })
      .catch((error) => {
        message.error(
          error.response ? error?.response?.data?.data?.message : 'No Response From Server',
          5
        );
      });
  };

  useEffect(() => {
    getCompanyprofile();
  }, []);

  const handleOpen = () => {
    setOpen(true);
  };

  const CorporateLogout = () => {
    ApiCalls.corporateLogout()
      .then((response) => {
        if (response.data.status) {
          history.push('/user/corporate/login');
          setOpen(false);
          storage.authToken.clear();
          storage.user.clear();
          dispatch(reduxActions.authActions.removeAuthToken());
          dispatch(reduxActions.authActions.removeUserData());
          message.success('Logout Successfully');
        } else {
          message.error('Logout is pending');
        }
      })
      .catch((err) => {
        
      });
  };

  return (
    <>
      <CardContainer>
        <ProfileContainer>
          <HeaderWrapper>
            <Header>
              <Heading>Profile</Heading>
              <Discription>Update and manage your account</Discription>
            </Header>
            <EditButton
              onClick={() => history.push('/user/base/home/corporate/profile/edit-profile')}
            >
              Edit Profile
            </EditButton>
          </HeaderWrapper>

          <EmployeeProfileContainer>
            <EmployeeInfo>
              <Profile tele={true}>
                <img
                  alt='No img found'
                  src={companyProfile.companyLogo}
                  onError={(e) => {
                    if (e.target.src !== ImageNotAvailable) {
                      e.target.src = ImageNotAvailable;
                    }
                  }}
                />
              </Profile>
              <Name userName={true}>Telehealings</Name>
              <Details
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  marginTop: '15px'
                }}
              >
                <EmailId>{companyProfile.companyAddress}</EmailId>
              </Details>
            </EmployeeInfo>
            <DividerLine></DividerLine>
            <EmployeeDetails>
              <EmployeeBasic>
                <img
                  src={Navigation}
                  alt='No img found'
                  height='25px'
                  width='25px'
                />
                <Text>Address</Text>
              </EmployeeBasic>
              <Text2>{companyProfile.companyAddress}</Text2>
              <Divider />
              <EmployeeBasic>
                <img
                  src={Group}
                  alt='No img found'
                  height='25px'
                  width='25px'
                />
                <Text>Company Size</Text>
              </EmployeeBasic>
              <Text2>{companyProfile.companySize}</Text2>
              <Divider />
              <EmployeeBasic>
                <img
                  src={Store}
                  alt='No img found'
                  height='25px'
                  width='25px'
                />
                <Text>Billing Address</Text>
              </EmployeeBasic>
              <Text2>{companyProfile.companyAddress}</Text2>
              <Divider />
              <EmployeeBasic
                historyPayment={true}
                style={{ cursor: 'pointer' }}
                onClick={() => history.push('/user/base/home/corporate/profile/my-payment-history')}
              >
                <ItemWrapper>
                <img
                  src={PaymentHistoryImage}
                  height='25px'
                  width='25px'
                  alt=''
                />
                <Text style={{color:"#000000", fontWeight:"500", marginTop:"0px", marginBottom:"0px"}}>My Payment History</Text>
                </ItemWrapper>
                <ItemWrapper>
                <RightOutlined style={{textAlign:"right"}} />
                </ItemWrapper> 
              </EmployeeBasic>
              <Divider />
              <EmployeeBasic
                style={{ cursor: 'pointer' }}
                onClick={() => history.push('/user/base/home/corporate/profile/privacy-policy')}
              >
                <img
                  src={Privacy}
                  height='25px'
                  width='25px'
                  alt=''
                />
                <Text>Privacy Policy</Text>
              </EmployeeBasic>
              <Divider />
              <EmployeeBasic onClick={handleOpen}>
                <img
                  alt='No img found'
                  src={Power}
                  height='25px'
                  width='25px'
                  style={{ color: '#FF5353', cursor: 'pointer' }}
                />
                <Text style={{ color: '#FF5353', cursor: 'pointer' }}>Logout</Text>
              </EmployeeBasic>
              {open && (
                <CustomModal
                  visible={open}
                  cancelAppointment={true}
                  title={'Logout'}
                  okLabel={'Confirm'}
                  onCancel={() => setOpen(false)}
                  onOk={() => CorporateLogout()}
                >
                  <LogoutConfirmationText>Are you sure you want to logout?</LogoutConfirmationText>
                </CustomModal>
              )}
              <Divider />
            </EmployeeDetails>
          </EmployeeProfileContainer>
        </ProfileContainer>
      </CardContainer>
    </>
  );
};

export default ProfileCorporate;
