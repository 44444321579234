import * as React from "react";

function MailSentIcon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={152.943}
      height={155.423}
      viewBox="0 0 152.943 155.423"
      {...props}
    >
      <defs>
        <style>{".prefix__b{fill:#2F4CBF}.prefix__d{fill:#fff}"}</style>
      </defs>
      <path
        d="M146 58.767L86.427 5.461a19.236 19.236 0 00-26.853 0L0 58.767V152h146z"
        fill="#4385C6"
      />
      <path
        className="prefix__b"
        d="M63 103.294h-.43L0 60v92s39.512-25.1 60.592-39.337c.776-.522 1.589-8.955 2.408-9.369zM83.29 103.294H82c.853.433 1.7 8.893 2.5 9.442L146 152V60z"
      />
      <path
        d="M146 152l-60.979-47.145a22.039 22.039 0 00-24.8-.073C39.269 118.982 0 152 0 152z"
        fill="#233faf"
      />
      <path
        className="prefix__d"
        d="M94.212 53.633a2.666 2.666 0 01-3.8 0L76.187 39.267v45.019A2.7 2.7 0 0173.5 87a2.7 2.7 0 01-2.687-2.714V39.267L56.588 53.633a2.666 2.666 0 01-3.8 0 2.73 2.73 0 010-3.838L71.6 30.8a2.659 2.659 0 01.876-.589A2.641 2.641 0 0173.5 30a2.641 2.641 0 011.027.209 2.659 2.659 0 01.876.589l18.809 19a2.73 2.73 0 010 3.835z"
      />
      <g transform="translate(105 108)">
        <ellipse
          cx={23.971}
          cy={23.711}
          rx={23.971}
          ry={23.711}
          fill="#ffa41b"
        />
        <path
          className="prefix__d"
          d="M20.223 36.86a3.127 3.127 0 01-1.818-1.124l-5.68-7.3a2.9 2.9 0 01.564-4.162 2.974 2.974 0 014.2.562l3.181 4.046 10.914-15.734a2.952 2.952 0 014.089-.788 2.9 2.9 0 01.8 4.046L23.289 35.624a2.974 2.974 0 01-2.385 1.236.731.731 0 01-.681 0z"
        />
      </g>
    </svg>
  );
}

export default MailSentIcon;
