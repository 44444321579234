import React, { useState, useEffect } from "react";
import {
  UpdateAreaContainer,
  PageHeading,
  SubHeading,
} from "../styledComponents";
import { days } from "./DayHelper";
import moment from "moment";
import { tz } from "moment-timezone";
import GeneralAvailabity from "./GeneralAvailabity";
import SpecialSlots from "./SpecialSlots";
import { SectionHeading } from "../ServicesSpecialities/styledComponents";
import { message } from "antd";
import ApiCalls from "../../../apis/ApiCalls";

const Availability = ({
  trigger,
  setTrigger,
  setCurrentPage,
  setLastCompletedPage,
  fromMyAccount,
  timeZone,
  selectedValues,
}) => {
  const [daysList, updateDaysList] = useState(null);
  const [specialTime, setSpecialTime] = useState({
    open: false,
    slot: [{ date: null, timeArray: [] }],
  });
  const [offDays, setOffDays] = useState({
    open: false,
    slot: [{ date: null, timeArray: [] }],
  });
  const dayMap = new Map([
    ["Sunday", 0],
    ["Monday", 1],
    ["Tuesday", 2],
    ["Wednesday", 3],
    ["Thursday", 4],
    ["Friday", 5],
    ["Saturday", 6],
  ]);

  const onToggle = (state, item) => {
    daysList.forEach((day) => {
      if (day.day === item.day) {
        day.open = state;
        if (state === false) {
          day.startTime = null;
          day.endTime = null;
          day.addBreak = [];
        }
        updateDaysList([...daysList]);
      }
    });
  };

  const showTimePicker = (item, workingHours, position) => {
    daysList.forEach((day) => {
      if (day.day === item.day) {
        if (workingHours) {
          day.startTime = moment("00:00", "hh:mm A");
          day.endTime = moment("00:00", "hh:mm A");
        } else {
          if (position === 0 || position) {
            day.addBreak.splice(position, 1);
          } else {
            let breakTime = {
              startTime: moment("00:00", "hh:mm A"),
              endTime: moment("00:00", "hh:mm A"),
            };
            day.addBreak = [...day.addBreak, breakTime];
          }
        }
        updateDaysList([...daysList]);
      }
    });
  };

  const setTime = (workingHours, item, time, start, position) => {
    daysList.forEach((day) => {
      if (day.day === item.day) {
        if (workingHours) {
          start ? (day.startTime = time) : (day.endTime = time);
        } else {
          start
            ? (day.addBreak[position].startTime = time)
            : (day.addBreak[position].endTime = time);
        }
        updateDaysList([...daysList]);
      }
    });
  };

  const onToggleSpecialSlot = (state, special) => {
    if (special) {
      specialTime.open = state;
      if (!state) {
        specialTime.slot = [{ date: null, timeArray: [] }];
      }
      setSpecialTime({ ...specialTime });
    } else {
      offDays.open = state;
      if (!state) {
        offDays.slot = [{ date: null, timeArray: [] }];
      }
      setOffDays({ ...offDays });
    }
  };

  const showPickerSpecial = (date, special, position, day, pickTime) => {
    if (date && !pickTime) {
      if (special) specialTime.slot[day].date = moment().add(1, "day");
      else offDays.slot[day].date = moment();
    } else {
      if (special) {
        let newSlot = null;
        if (position === 0 || position) {
          specialTime.slot[day].timeArray.splice(position, 1);
        } else {
          newSlot = {
            startTime: moment("00:00", "hh:mm A"),
            endTime: moment("00:00", "hh:mm A"),
          };
          specialTime.slot[day].timeArray.push(newSlot);
        }
      } else {
        let newSlot = null;
        if (position === 0 || position) {
          offDays.slot[day].timeArray.splice(position, 1);
        } else {
          newSlot = {
            startTime: moment("00:00", "hh:mm A"),
            endTime: moment("00:00", "hh:mm A"),
          };
          offDays.slot[day].timeArray.push(newSlot);
        }
      }
    }

    special ? setSpecialTime({ ...specialTime }) : setOffDays({ ...offDays });
  };

  const addNew = (special, remove) => {
    let item = { date: null, timeArray: [] };

    if (special) {
      specialTime.slot.push(item);
      setSpecialTime({ ...specialTime });
    } else {
      offDays.slot.push(item);
      setOffDays({ ...offDays });
    }
  };

  const setDateTime = (special, item, time, start, position, date, day) => {
    if (date) {
      special
        ? (specialTime.slot[day].date = date)
        : (offDays.slot[day].date = date);
    } else {
      if (special)
        start
          ? (specialTime.slot[day].timeArray[position].startTime = time)
          : (specialTime.slot[day].timeArray[position].endTime = time);
      else
        start
          ? (offDays.slot[day].timeArray[position].startTime = time)
          : (offDays.slot[day].timeArray[position].endTime = time);
    }
    special ? setSpecialTime({ ...specialTime }) : setOffDays({ ...offDays });
  };

  const convertTime = (time, date) => {
    let newTime = moment(time).local().format("HH:mm:ss");
    let newDate = tz(date, timeZone).format("YYYY-MM-DD");
    return tz(
      `${date ? newDate : "2021-01-01"} ${newTime}`,
      "YYYY-MM-DD hh:mm:ss",
      timeZone
    ).toISOString();
  };

  const saveWorkTime = () => {
    let currentSpecialTime = JSON.parse(JSON.stringify(specialTime));
    let currentOffDays = JSON.parse(JSON.stringify(offDays));
    let currentDaysList = JSON.parse(JSON.stringify(daysList));
    let updatedSpecialTime = [];
    let updatedOffDays = [];
    let updatedDaysList = [];
    let error = false;

    if (currentSpecialTime.open) {
      currentSpecialTime.slot.forEach((item, day) => {
        if (item.date === null) {
          message.error(`Select date for ${day + 1}th item in special time`);
          error = true;
        } else if (item.timeArray.length === 0) {
          message.error(
            `Select a time slot for ${day + 1}th item in special time`
          );
          error = true;
        } else {
          currentSpecialTime.slot[day].timeArray.forEach((time) => {
            time.startTime = convertTime(
              time.startTime,
              moment(item.date).toISOString()
            );
            time.endTime = convertTime(
              time.endTime,
              moment(item.date).toISOString()
            );
          });
          item.date = tz(moment(item.date).toISOString(), timeZone).format(
            "YYYY-MM-DD"
          );
          item.specialTimeArray = item.timeArray;
          delete item.timeArray;
          delete item.open;
          updatedSpecialTime.push(item);
        }
      });
    }

    if (currentOffDays.open) {
      currentOffDays.slot.forEach((item, day) => {
        if (item.date === null) {
          message.error(`Select date for ${day + 1}th item in off days`);
          error = true;
        } else if (item.timeArray.length === 0) {
          message.error(`Select a time slot for ${day + 1}th item in off days`);
          error = true;
        } else {
          currentOffDays.slot[day].timeArray.forEach((time) => {
            time.startTime = convertTime(
              time.startTime,
              moment(item.date).toISOString()
            );
            time.endTime = convertTime(
              time.endTime,
              moment(item.date).toISOString()
            );
          });
          item.date = tz(moment(item.date).toISOString(), timeZone).format(
            "YYYY-MM-DD"
          );
          item.offDayArray = item.timeArray;
          delete item.timeArray;
          delete item.open;
          updatedOffDays.push(item);
        }
      });
    }

    currentDaysList &&
      currentDaysList.forEach((item) => {
        if (item.open) {
          delete item.open;
          item.addBreak.forEach((breakTime) => {
            breakTime.startTime = convertTime(breakTime.startTime);
            breakTime.endTime = convertTime(breakTime.endTime);
          });
          item.startTime = convertTime(item.startTime);
          item.endTime = convertTime(item.endTime);
          updatedDaysList.push(item);
        }
      });

    updatedSpecialTime = [...updatedSpecialTime];
    updatedOffDays = [...updatedOffDays];
    updatedDaysList = [...updatedDaysList];

    if (updatedDaysList.length < 1)
      message.error("Set working hours to proceed");
    else if (!error) {
      const data = {
        timeSlot: [...updatedDaysList],
        specialTime: [...updatedSpecialTime],
        offDays: [...updatedOffDays],
      };
      ApiCalls.updateDoctorWorkProfileInfo(data)
        .then((response) => {
          if (response.data.status) {
            setTrigger(0);
            message.success("Updated working hours successfully");
            if (!fromMyAccount) {
              setCurrentPage((prev) => prev + 1);
              setLastCompletedPage((prev) => prev + 1);
            }
          } else {
            message.error(response.data.data.message);
          }
        })
        .catch((error) =>
          message.error(
            error.response
              ? error?.response?.data?.data?.message
              : "No Response From Server",
            5
          )
        );
    }
  };

  const createSelectedDayValues = (timeSlot) => {
    timeSlot.forEach((item, index) => {
      let day = days[dayMap.get(item.day)];
      item.addBreak.forEach((breakItem) => {
        breakItem.startTime = moment(breakItem.startTime);
        breakItem.endTime = moment(breakItem.endTime);
        delete breakItem._id;
      });
      if (item.day === day.day) {
        day.open = true;
        day.startTime = moment(item.startTime);
        day.endTime = moment(item.endTime);
        day.addBreak = item.addBreak;
      }
    });
    updateDaysList([...days]);
  };

  const createSelectedSpecialTimeValues = (specialTime, isSpecialTime) => {
    let slot = specialTime.map((item) => {
      let obj = {};
      obj.date = item.date;
      obj.timeArray = (
        isSpecialTime ? item.specialTimeArray : item.offDayArray
      ).map((time) => {
        time.startTime = moment(time.startTime);
        time.endTime = moment(time.endTime);
        delete time._id;
        return time;
      });
      return obj;
    });
    isSpecialTime
      ? setSpecialTime({ open: true, slot: [...slot] })
      : setOffDays({ open: true, slot: [...slot] });
  };

  useEffect(() => {
    trigger && saveWorkTime();
    // eslint-disable-next-line
  }, [trigger]);

  useEffect(() => {
    if (selectedValues?.timeSlot?.length > 0)
      createSelectedDayValues(selectedValues.timeSlot);
    else updateDaysList([...days]);
    if (selectedValues?.specialTime?.length > 0) {
      createSelectedSpecialTimeValues(selectedValues.specialTime, true);
    }
    if (selectedValues?.offDays?.length > 0) {
      createSelectedSpecialTimeValues(selectedValues.offDays, false);
    }
    // eslint-disable-next-line
  }, [selectedValues]);

  return (
    <>
      <UpdateAreaContainer>
        <PageHeading>Set Your Availibility</PageHeading>
        <SubHeading>
          Let your customer know when you're typically available to accept
          bookings
        </SubHeading>

        {fromMyAccount && (
          <>
            <SectionHeading>Add time off for patients visits</SectionHeading>
            <SpecialSlots
              item={offDays}
              special={false}
              onToggle={onToggleSpecialSlot}
              showTimePicker={showPickerSpecial}
              setDateTime={setDateTime}
              addNew={addNew}
            />

            <SectionHeading>Add more slots for patient visits</SectionHeading>
            <SpecialSlots
              item={specialTime}
              special={true}
              onToggle={onToggleSpecialSlot}
              showTimePicker={showPickerSpecial}
              setDateTime={setDateTime}
              addNew={addNew}
            />
          </>
        )}

        <SectionHeading>Set your working hours</SectionHeading>
        {daysList && (
          <GeneralAvailabity
            daysList={daysList}
            setTime={setTime}
            showTimePicker={showTimePicker}
            onToggle={onToggle}
          />
        )}
      </UpdateAreaContainer>
    </>
  );
};

export default Availability;
