const setPatientRecord = (record = {}) => ({
    type: 'SET_PATIENT_RECORD',
    record
})
const removePatientRecord = () => ({
    type: 'REMOVE_PATIENT_RECORD',
})

const setPatientProfile = (profile = {}) => ({
    type: 'SET_PATIENT_PROFILE',
    profile
})
const removePatientProfile = () => ({
    type: 'REMOVE_PATIENT_PROFILE',
})
const updateSoap = (soap = {}) => ({
    type: 'UPDATE_SOAP',
    soap
})
const removeSoap  = ()=>({
    type: 'REMOVE_SOAP',
})



export default {
    setPatientRecord,
    removePatientRecord,
    setPatientProfile,
    removePatientProfile,
    updateSoap,
    removeSoap,
}



