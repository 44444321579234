import React from "react";
import WhatsAppIcon from "../../../assets/icons/WhatsAppIcon";
const SendViaWhatsapp = ({ sendVerification }) => {
  return (
    <button
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        padding: "14px 10px",
        border: "1px solid #2C509F",
        borderRadius: "8px",
        gap: "16px",
        backgroundColor: "#075E54",
      }}
      onMouseOver={(e) => (e.target.style.cursor = "pointer")}
      onClick={sendVerification}
    >
      <WhatsAppIcon />
      <span
        style={{
          fontSize: "16px",
          fontWeight: "700",
          color: "#FFFFFF",
        }}
      >
        Send Codes to Whatsapp
      </span>
    </button>
  );
};

export default SendViaWhatsapp;
