import { message } from "antd";
import React, { useState } from "react";
import ApiCalls from "../../../apis/ApiCalls";
import CustomSearchSelect from "../../../components/CustomSearchSelect";

const CitySearch = ({ selectedCity, setSelectedCity }) => {
  const [searchValue, setSearchValue] = useState("");
  const [scrollLoading, setScrollLoading] = useState(false);
  const [hasMore, setHasMore] = useState(false);
  const [cityList, setCityList] = useState([]);

  const searchCities = (page) => {
    if (searchValue.length > 0) {
      setScrollLoading(true);
      let params = {
        page: page,
        limit: 20,
        searchKey: searchValue,
      };
      if (page === 0) setCityList([]);

      ApiCalls.getAllCities(params)
        .then((response) => {
          if (response.data.status) {
            let cities = response.data.data.cities;
            if (page === 0) {
              setCityList(cities);
            } else {
              setCityList(cityList.concat(cities));
            }
            setScrollLoading(false);
            setHasMore(cities.length > 19);
          }
        })
        .catch((error) => {
          setScrollLoading(false);
          message.error(
            error.response
              ? error?.response?.data?.data?.message
              : "No Response From Server",
            5
          );
        });
    }
  };

  return (
    <CustomSearchSelect
      valueKeyName="name"
      title="Search by city"
      placeholder="Search by city"
      emptyMessage="Search to find cities"
      searchValue={searchValue}
      setSearchValue={setSearchValue}
      selectedValue={selectedCity}
      setSelectedValue={setSelectedCity}
      searchFunction={searchCities}
      scrollLoading={scrollLoading}
      hasMore={hasMore}
      data={cityList}
    />
  );
};

export default CitySearch;
