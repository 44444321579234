import * as React from "react";

function CameraIcon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={50}
      height={50}
      viewBox="0 0 34 34"
      {...props}
    >
      <defs>
        <style>
          {
            ".prefix__b{fill:none;stroke:#35364F;stroke-linecap:round;stroke-linejoin:round}"
          }
        </style>
      </defs>
      <g fill="none" stroke="#f6f6f6" strokeWidth={3}>
        <rect width={50} height={50} rx={25} stroke="none" />
      </g>
      <path
        className="prefix__b"
        d="M24.459 21.747a1.356 1.356 0 01-1.356 1.351H10.898a1.356 1.356 0 01-1.356-1.351v-7.459a1.356 1.356 0 011.356-1.356h2.712l1.356-2.034h4.069l1.356 2.034h2.712a1.356 1.356 0 011.356 1.356z"
      />
      <path
        className="prefix__b"
        d="M19.712 17.678A2.712 2.712 0 1117 14.966a2.712 2.712 0 012.712 2.712z"
      />
    </svg>
  );
}

export default CameraIcon;
