import HomeIcon from "../../assets/images/patientModuleIcons/dashboard.svg";
import AppointmentsIcon from "../../assets/images/patientModuleIcons/calendarMonth.svg";
import MessagesIcon from "../../assets/images/patientModuleIcons/sms.svg";
import EarningsIcon from "../../assets/images/DoctorModulesIcon/account_balance_wallet.svg";
import PatientsIcon from "../../assets/images/DoctorModulesIcon/volunteer_activism.svg";

const doctorLinks = [
  {
    key: "1",
    name: "Dashboard",
    path: "/user/base/home/dashboard",
    icon: HomeIcon,
  },
  {
    key: "2",
    name: "Appointments",
    path: "/user/base/home/appointments",
    icon: AppointmentsIcon,
  },
  {
    key: "3",
    name: "Messages",
    path: "/user/base/home/messages",
    icon: MessagesIcon,
  },
  {
    key: "4",
    name: "Patients",
    path: "/user/base/home/patients",
    icon: PatientsIcon,
  },
  {
    key: "5",
    name: "Earnings",
    path: "/user/base/home/earnings",
    icon: EarningsIcon,
  },
];

export default doctorLinks;
