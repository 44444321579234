import React from "react";

const CustomButton = ({
  buttonName,
  onClick,
  icon,
  customStyles = {},
  textStyles = {},
}) => {
  return (
    <button
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        padding: "14px 10px",
        border: "none",
        borderRadius: "8px",
        gap: "16px",
        backgroundColor: "#2C509F",
        ...customStyles,
      }}
      onMouseOver={(e) => (e.target.style.cursor = "pointer")}
      onClick={onClick}
    >
      {icon ? icon : <></>}
      <span
        style={{
          fontSize: "16px",
          fontWeight: "700",
          color: "#FFFFFF",
          ...textStyles,
        }}
      >
        {buttonName}
      </span>
    </button>
  );
};

export default CustomButton;
