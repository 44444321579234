import SocketClient from "./SocketClient";
const socket = new SocketClient();

export default class SocketActions {
  static connect(endPoint) {
    return socket.connect(endPoint);
  }
  static disconnect() {
    return socket.disconnect();
  }
  static createRoom(data) {
    return socket.emit("joinCall", data);
  }
  static getRoomId(fun) {
    return socket.on("agora_token", fun);
  }
  static offGetRoomId(fun) {
    return socket.off("agora_token", fun);
  }
  static callTriggered(fun) {
    return socket.on("calltriggered", fun);
  }
  static offCallTriggered(fun) {
    return socket.off("calltriggered", fun);
  }
  static joinRoom(data) {
    return socket.emit("joinRoom", data);
  }
  static sendMessage(data) {
    return socket.emit("send-message", data);
  }
  static receiveMessage(fun) {
    return socket.on("message", fun);
  }
  static offReceiveMessage(fun) {
    return socket.off("message", fun);
  }
  static messageReceivedClientSide(fun) {
    return socket.on("message-received-client-side", fun);
  }
  static offMessageReceivedClientSide(fun) {
    return socket.off("message-received-client-side", fun);
  }
  static messageReceived(fun) {
    return socket.on("message-received", fun);
  }
  static offMessageReceived(fun) {
    return socket.off("message-received", fun);
  }
  static messageAcknowledgement(data) {
    return socket.emit("message-acknowledgement", data);
  }
  static messageSeen(data) {
    return socket.emit("message-seen", data);
  }
  static messageSeenClientSide(fun) {
    return socket.on("message-seen-client-side", fun);
  }
  static offMessageSeenClientSide(fun) {
    return socket.off("message-seen-client-side", fun);
  }
  static changeAppointmentStatus(data) {
    return socket.emit("startVideoConsultation", data);
  }
  static heartBeatFunction(data) {
    return socket.emit("heartBeat", data);
  }
  static sendNotification(data) {
    return socket.emit("sendNotification", data);
  }
  static receiveNotification(fun) {
    return socket.on("notification", fun);
  }
  static offReceiveNotification(fun) {
    return socket.off("notification", fun);
  }
  static uploadPercentage(fun) {
    return socket.on("uploadPercentage", fun);
  }
  static offUploadPercentage(fun) {
    return socket.off("uploadPercentage", fun);
  }
  static onlineStatus(fun) {
    return socket.on("onlineStatus", fun);
  }
  static offOnlineStatus(fun) {
    return socket.on("onlineStatus", fun);
  }
  static typing(data) {
    return socket.emit("typing", data);
  }
  static onTyping(fun) {
    return socket.on("typing-message", fun);
  }
  static offOnTyping(fun) {
    return socket.off("typing-message", fun);
  }
  static stopTyping(data) {
    return socket.emit("stop-typing", data);
  }
  static onStopTyping(fun) {
    return socket.on("stop-typing-message", fun);
  }
  static offOnStopTyping(fun) {
    return socket.off("stop-typing-message", fun);
  }
  static receiveDeclineMsg(fun) {
    return socket.on("decline-call-message", fun);
  }
  static offReceiveDeclineMsg(fun) {
    return socket.off("decline-call-message", fun);
  }
  static appointmentExtended(data) {
    return socket.emit("appointment-extended", data);
  }

  static appointmentExtendOn(fun) {
    return socket.on("appointment-extended", fun);
  }
}
