import React, { useState } from "react";
import { Form, Input, Row, Col, message } from "antd";
import styled from "styled-components";
import { useParams, withRouter, useHistory } from "react-router-dom";
import ApiCalls from "../../apis/ApiCalls";
import { StyledFormItem } from "../signup/styledComponents";
import PrimaryButton from "../../components/PrimaryButton/index";
import { FormContainer, LoginSignupContainer } from "../login/styledComponents";
import Banner from "../login/Banner";
import CustomButton from "../Utils/CustomButton";
import PasswordForm from "../Utils/PasswordForm";
import Heading from "../Utils/Heading";
import SecondaryInfo from "../Utils/SecondaryInfo";

const ResetPasswordScreen = ({ accountVerification }) => {
  const [form] = Form.useForm();
  const { userType } = useParams();
  const history = useHistory();
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const FormStyle = styled(Form)`
    width: 100%;
  `;

  const onFinish = (values) => {
    if (password !== confirmPassword) {
      message.error("Passwords do not match");
    } else if (password.length < 8) {
      message.error("Password should be at least 8 characters long");
    } else if (
      !/[A-Z]/.test(password) ||
      !/[a-z]/.test(password) ||
      !/[0-9]/.test(password)
    ) {
      message.error(
        "Password must contain at least one uppercase letter, one lowercase letter, and one number"
      );
    } else {
      const data = {
        password: password,
      };

      ApiCalls.resetPassword(data)
        .then((response) => {
          if (response.data.status) {
            message.success("Please Login to continue");
            history.push(`/user/${userType}/login`);
          }
        })
        .catch((error) => {
          console.log(error);
          message.error(
            error.response
              ? error?.response?.data?.data?.message
              : "No Response From Server",
            5
          );
        });
    }
  };

  return (
    <LoginSignupContainer>
      <Banner />
      <FormContainer>
        <div style={{ maxWidth: "398px", minWidth: "310px" }}>
          <Heading title={"Reset Password"} />
          <SecondaryInfo
            info={
              "Enter your new password below. Make sure it’s strong and secure."
            }
          />
        </div>
        <PasswordForm
          placeholder={"Enter New Password"}
          setPassword={setPassword}
        />

        <PasswordForm
          placeholder={"Confirm Password"}
          setPassword={setConfirmPassword}
        />

        <CustomButton
          customStyles={{ width: "100%" }}
          buttonName={"Continue"}
          onClick={onFinish}
        />
      </FormContainer>
    </LoginSignupContainer>
  );
};
export default withRouter(ResetPasswordScreen);
