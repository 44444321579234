import React, { useEffect, useState } from "react";
import {
  UpdateAreaContainer,
  PageHeading,
  SubHeading,
} from "../styledComponents";
import { StyledRow, SectionHeading } from "./styledComponents";
import { Form, Input, message } from "antd";
import { FormContainer } from "../EducationExperience/styledComponents";
import { StyledFormItem } from "../../signup/styledComponents";
import ApiCalls from "../../../apis/ApiCalls";
import Section from "./Section";

const ServicesSpecialities = ({
  trigger,
  setTrigger,
  setCurrentPage,
  setLastCompletedPage,
  selectedValues,
  fromMyAccount,
}) => {
  const [specialities, setSpecialities] = useState([]);
  const [selectedSpecialities, setSelectedSpecialities] = useState(new Set());
  const [services, setServices] = useState([]);
  const [selectedServices, setSelectedServices] = useState(new Set());
  const [languages, setLanguages] = useState([]);
  const [selectedLanguages, setSelectedLanguages] = useState(new Set());
  const [moreServiceValue, setMoreServiceValue] = useState("");
  const [doctorAddedServices, setDoctorAddedServices] = useState(new Set());

  const [form] = Form.useForm();

  const onFinish = (val) => {
    if (
      // selectedServices.size + doctorAddedServices.size < 1 ||
      selectedSpecialities.size < 1 ||
      selectedLanguages.size < 1
    )
      message.error(
        "Please select atleast one service, speciality and language"
      );
    else {
      let data = {};
      data.tagLine = val.tagline;
      // data.services = [...selectedServices];
      data.specialization = [...selectedSpecialities];
      data.languagesKnown = [...selectedLanguages];
      // data.doctorAddedService = [...doctorAddedServices];
      ApiCalls.updateDoctorWorkProfileInfo(data)
        .then((response) => {
          if (response.data.status) {
            setTrigger(0);
            message.success("Updated services and specialities successfully");
            if (!fromMyAccount) {
              setCurrentPage((prev) => prev + 1);
              setLastCompletedPage((prev) => prev + 1);
            }
          } else {
            message.error(response.data.data.message);
          }
        })
        .catch((error) =>
          message.error(
            error.response
              ? error?.response?.data?.data?.message
              : "No Response From Server",
            5
          )
        );
    }
  };

  const handleSelectChange = (val, isServices, isLanguages) => {
    isLanguages
      ? selectedLanguages.add(val.key)
      : isServices
      ? selectedServices.add(val.key)
      : selectedSpecialities.add(val.key);
    let updatedSet = new Set(
      isLanguages
        ? selectedLanguages
        : isServices
        ? selectedServices
        : selectedSpecialities
    );
    isLanguages
      ? setSelectedLanguages(updatedSet)
      : isServices
      ? setSelectedServices(updatedSet)
      : setSelectedSpecialities(updatedSet);
  };

  const removeItem = (selected, id, isServices, isLanguages) => {
    selected.delete(id);
    let updatedSet = new Set(selected);
    isLanguages
      ? setSelectedLanguages(updatedSet)
      : isServices
      ? setSelectedServices(updatedSet)
      : setSelectedSpecialities(updatedSet);
  };

  // const addMoreService = () => {
  //   if (moreServiceValue.trim().length === 0) {
  //     message.error("Type in value to add services");
  //   } else {
  //     setDoctorAddedServices(
  //       new Set([...doctorAddedServices, moreServiceValue])
  //     );
  //     setMoreServiceValue("");
  //   }
  // };

  // const removeAddMoreItem = (item) => {
  //   doctorAddedServices.delete(item);
  //   setDoctorAddedServices(new Set(doctorAddedServices));
  // };

  useEffect(() => {
    ApiCalls.getSpecialisationList()
      .then((response) => {
        if (response.data.status) {
          setSpecialities(response.data.data);
        } else {
          message.error("Can't fetch specialites");
        }
      })
      .catch((err) => { /* Error */ });

    // ApiCalls.getServicesList()
    //   .then((response) => {
    //     if (response.data.status) {
    //       setServices(response.data.data);
    //     } else {
    //       message.error("Can't fetch services");
    //     }
    //   })
    //   .catch((err) => { /* Error */ });

    ApiCalls.getLanguages()
      .then((response) => {
        if (response.data.status) {
          setLanguages(response.data.data);
        } else {
          message.error("Can't fetch services");
        }
      })
      .catch((err) => { /* Error */ });
  }, []);

  useEffect(() => {
    if (trigger) {
      form.submit();
    }
    // eslint-disable-next-line
  }, [trigger]);

  useEffect(() => {
    if (selectedValues?.specialization?.length > 0) {
      let specialiationIds = selectedValues.specialization.map((item) => {
        return item._id;
      });
      setSelectedSpecialities(new Set(specialiationIds));
    }

    // if (selectedValues?.services?.length > 0) {
    //   let servicesIds = selectedValues.services.map((item) => {
    //     return item._id;
    //   });
    //   setSelectedServices(new Set(servicesIds));
    // }

    // if (selectedValues?.doctorAddedService) {
    //   setDoctorAddedServices(new Set(selectedValues.doctorAddedService));
    // }

    if (selectedValues?.languagesKnown?.length > 0) {
      setSelectedLanguages(new Set(selectedValues.languagesKnown));
    }
    form.setFieldsValue({ tagline: selectedValues.tagLine });
    // eslint-disable-next-line
  }, [selectedValues]);

  return (
    <>
      <UpdateAreaContainer>
        <PageHeading>Specialitizatons</PageHeading>
        {/* <SubHeading>
          Please add your services you would like to provide on Telehealings.com
        </SubHeading>
        <Section
          services={true}
          handleSelectChange={handleSelectChange}
          removeItem={removeItem}
          itemList={services}
          selectedItems={selectedServices}
          showAddMore
          addMoreValue={moreServiceValue}
          setAddMoreValue={setMoreServiceValue}
          addMoreFunction={addMoreService}
          selectedAddMoreItems={doctorAddedServices}
          removeAddMoreItem={removeAddMoreItem}
        /> */}

        <SectionHeading>Specialities</SectionHeading>
        <Section
          services={false}
          handleSelectChange={handleSelectChange}
          removeItem={removeItem}
          itemList={specialities}
          selectedItems={selectedSpecialities}
        />

        <SectionHeading>Languages</SectionHeading>
        <Section
          languages={true}
          handleSelectChange={handleSelectChange}
          removeItem={removeItem}
          itemList={languages}
          selectedItems={selectedLanguages}
        />

        <SectionHeading>Tagline</SectionHeading>
        <FormContainer style={{ paddingTop: "0" }}>
          <Form form={form} onFinish={onFinish}>
            <StyledRow>
              <StyledFormItem
                name="tagline"
                label="Tagline"
                type2={true}
              >
                <Input placeholder="Enter tagline" />
              </StyledFormItem>
            </StyledRow>
          </Form>
        </FormContainer>
      </UpdateAreaContainer>
    </>
  );
};

export default ServicesSpecialities;
