import styled from "styled-components";

export const DropdownName = styled.div`
  font-size: 16px;
  max-width: 100px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

export const DropdownContainer = styled.div`
  background-color: white;
  height: 100%;
  width: ${(props) => (props.page ? '20%' : '100%')};
  display: flex;
  align-items: center;
  border-radius: 10px;
  padding: 0px 20px;
  border: ${(props) => (props.page ? '1px solid #2C509F' : 'none')};
  color: ${(props) => (props.page && '#2C509F')};
  margin-left: ${(props) => (props.page && '600px')};
`;

export const DropdownContent = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
