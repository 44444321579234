import styled from "styled-components";

export const ListItemContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  height: 200px;
  width: 100%;
  overflow: scroll;
  & > span {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-family: 'Urbanist', sans-serif;
    font-weight: 500;
    color: #35364f;
    text-align: center;
  }
  & > .anticon-loading {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`;

export const Item = styled.p`
  display: ${(props) => (props.loading ? "flex" : null)};
  justify-content: center;
  align-items: center;
  min-height: 40px;
  max-height: 80px;
  width: 100%;
  padding: 5px 10px;
  line-height: 30px;
  vertical-align: middle;
  margin-bottom: 0;
  font-family: ${(props) =>
    props.selected ? "Gilroy SemiBold" : "'Urbanist', sans-serif"};
  font-weight: 500;
  font-size: 14px;
  color: #262c3d;
  cursor: pointer;
  background: ${(props) => (props.selected ? "aliceblue" : null)};
  &:hover {
    background: #e2e5e7;
  }
`;
