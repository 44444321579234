import React from "react";
import { StyledButton } from "./styledComponents";

const PrimaryButton = ({
  onClick,
  htmlType,
  loading,
  disabled,
  icon,
  cancel,
  label,
  secondary,
  onMouseDown,
  height,
  cancelAppointment,
  width,
  buttonType,
  followUp,
  buttonName
}) => {
  return (
    <StyledButton
      onClick={onClick}
      onMouseDown={onMouseDown}
      htmlType={htmlType}
      loading={loading}
      disabled={disabled}
      size="large"
      type="primary"
      icon={icon}
      cancel={cancel}
      secondary={secondary}
      height={height}
      cancelAppointment={cancelAppointment}
      width={width}
      buttonType={buttonType}
      buttonName={buttonName}
    >
      {label}
    </StyledButton>
  );
};
export default PrimaryButton;
