import React, { useState, useEffect, useRef, useCallback } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Checkbox from "@mui/material/Checkbox";
import { LeftCircleFilled, LoadingOutlined } from "@ant-design/icons";
import ApiCalls from "../../../apis/ApiCalls";
import { useHistory } from "react-router-dom";
import CitySearch from "../../Dashboard/PatientDashboard/CitySearch";
import LanguageSearch from "../../../../src/doc-patient-modules/ViewDoctors/LanguageSearch";
import {
  ProceedButton,
  Header,
  Heading,
  SearchBar,
  DoctorSelectionContainer,
  Text,
} from "./styledComponent";
import {
  SearchInputContainer,
  SearchIconContainer,
} from "../PatientDashboard/styledComponents";
import SearchIcon from "../../../assets/icons/DashBoardIcons/SearchIcon";
import { message, Input } from "antd";
import { SubHeading } from "../../DoctorProfile/styledComponents";
import ImageNotAvailable from "../../../assets/images/CorporateModule/Image_not_available.png";
import storage from "../../../storage";
import { useLocation } from "react-router-dom";
import {
  FilterTabContainer,
  DropdownContainer,
  ApplyButton,
  StyledMenu,
} from "../../../doc-patient-modules/ViewDoctors/styledComponents";
import NotFound from "../../../components/NotFound";
import CustomDropdown from "../../../components/CustomDropdown";

function DoctorAllList() {
  const location = useLocation();
  const [data, setData] = useState([]);
  const [checkedList, setCheckedList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchValue, setSearchValue] = useState("");
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [isSearch, setIsSearch] = useState(false);
  const [selectedGender, setSelectedGender] = useState(null);
  const [selectedSort, setSelectedSort] = useState("RatingHigh");
  const [selectedCity, setSelectedCity] = useState(
    location.state?.selectedCity || null
  );
  const [selectedSpecialization, setSelectedSpecialization] = useState(
    location.state?.specialization ? location.state?.specialization : null
  );
  const [selectedLanguage, setSelectedLanguage] = useState(null);
  const [specializations, setSpecializations] = useState([]);
  const [selectedDoctors, setSelectedDoctors] = useState([]);
  const [selectedDoctorNumber, setSelectedDoctorNumber] = useState(0);
  let history = useHistory();

  let companyProfile = storage.user.getItem();
  const observer = useRef();
  let companyId = localStorage.getItem("companyId");

  // Fetch selected doctor list
  const fetchSelectedDoctorList = () => {
    setLoading(true);
    ApiCalls.getCompanySelectedProviders(companyId)
      .then((response) => {
        if (response.data.status) {
          if (response?.data?.data?.length > 0) {
            setSelectedDoctorNumber(response.data.data?.length);
            let temp = [];
            response.data.data.forEach((item) => {
              temp.push(item._id);
            });
            setSelectedDoctors(temp);
          } else {
            setSelectedDoctors([]);
            setSelectedDoctorNumber(0);
          }
        } else {
          setSelectedDoctors([]);
          setSelectedDoctorNumber(0);
        }
        setLoading(false);
      })
      .catch((err) => {
        setSelectedDoctors([]);
        setSelectedDoctorNumber(0);
        setLoading(false);
      });
  };

  // On clicking checkbox
  const onCheckboxSelectionChange = (e, item) => {
    if (
      Number(selectedDoctorNumber) + checkedList.length <
        Number(companyProfile.subscriptionDetails.doctorCount) ||
      item.selected
    ) {
      let temp = [...data];
      let tempCheckList = [];
      for (let i = 0; i < temp.length; i++) {
        if (temp[i]._id === item._id) {
          if (temp[i].selected === false) {
            tempCheckList.push(temp[i]._id);
          }
          temp[i].selected = !temp[i].selected;
        } else if (temp[i].selected) {
          tempCheckList.push(temp[i]._id);
        }
      }
      setData(temp);
      setCheckedList(tempCheckList);
    } else {
      // message.error(`You can add only Upto${" "}
      // ${
      //   companyProfile.subscriptionDetails.doctorCount - selectedDoctorNumber
      // }${" "}
      // doctors.`);
      message.error(`Maximum therapist selection reached`);
    }
  };

  // Adding doctor to company
  const addDoctor = () => {
    let temp = [...data];
    temp.forEach((item, index) => {
      if (item.selected) {
        ApiCalls.addSelectedDoctors(companyId, item._id)
          .then((response) => {
            if (response.data.status) {
              message.success("Succesfully added");
            }
          })
          .catch((error) => {
            message.error(
              error.response
                ? error?.response?.data?.data?.message
                : "No Response From Server",
              5
            );
          });
      }
      if (index === temp.length - 1) {
        setTimeout(() => {
          history.goBack();
        }, 500);
      }
    });
  };

  // Fetching doctor list
  const getDoctor = (pageNo) => {
    if (pageNo === 0 && data?.length === 0) {
      setLoading(true);
    }
    let params = {
      page: pageNo,
      limit: 10,
    };

    // Applying search and filters
    let filterSelected = false;
    if (selectedSpecialization) {
      params.specialization = [selectedSpecialization._id];
      filterSelected = true;
    }
    if (selectedGender) {
      params.gender = selectedGender;
      filterSelected = true;
    }
    if (selectedSort) {
      params.sort = selectedSort;
      filterSelected = true;
    }
    if (selectedCity) {
      params.city = selectedCity;
      filterSelected = true;
    }
    if (selectedLanguage) {
      params.languagesKnown = [selectedLanguage];
      filterSelected = true;
    }
    if ((filterSelected && searchValue?.trim("")?.length > 0) || isSearch) {
      params.text = searchValue;
    }

    ApiCalls.getDoctorList(params)
      .then((response) => {
        if (response.data.status) {
          if (response?.data?.data?.doctors?.length > 0) {
            if (Math.ceil(response.data.data.count / 10) !== totalPages) {
              setTotalPages(Math.ceil(response.data.data.count / 10));
            }
            let temp = [];
            response.data.data.doctors.forEach((dat) => {
              temp.push({
                ...dat,
                selected: false,
              });
            });
            if (pageNo === 0) {
              setData(temp);
            } else {
              setData((data) => [...data, ...temp]);
            }
          } else if (pageNo === 0) {
            setData([]);
            setTotalPages(0);
          }
        } else {
          message.error("Can't fetch therapist list");
          setData([]);
        }
        setLoading(false);
      })
      .catch((err) => {
        setData([]);
        setLoading(false);
      });
  };

  // On called when reaching the last element
  const lastElementRef = useCallback(
    (node) => {
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting) {
          if (currentPage < totalPages) {
            setCurrentPage((currentPage) => currentPage + 1);
          }
        }
      });
      if (node) observer.current.observe(node);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    [totalPages, currentPage]
  );

  useEffect(() => {
    fetchSelectedDoctorList();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (searchValue?.trim("")?.length === 0) {
      setIsSearch(false);
    }
  }, [searchValue]);

  useEffect(() => {
    if (isSearch) {
      setCheckedList([]);
      setCurrentPage(0);
      getDoctor(0);
    } else {
      setCheckedList([]);
      setCurrentPage(0);
      getDoctor(0);
    }
    // eslint-disable-next-line
  }, [isSearch]);

  const search = () => {
    if (searchValue?.trim("")?.length > 0) {
      if (isSearch) {
        setCheckedList([]);
        setCurrentPage(0);
        getDoctor(0);
      } else {
        setIsSearch(true);
      }
    }
  };

  // Apply filters
  const applyFilters = () => {
    setCheckedList([]);
    setCurrentPage(0);
    getDoctor(0);
  };

  const menu = (
    <StyledMenu>
      {selectedSpecialization && (
        <StyledMenu.Item onClick={() => setSelectedSpecialization(null)}>
          <a href={() => false}>All</a>
        </StyledMenu.Item>
      )}
      {specializations.length > 0
        ? specializations.map((item) => (
            <StyledMenu.Item onClick={() => setSelectedSpecialization(item)}>
              <a href={() => false}>{item.name}</a>
            </StyledMenu.Item>
          ))
        : null}
    </StyledMenu>
  );

  const menu2 = (
    <StyledMenu>
      <StyledMenu.Item onClick={() => setSelectedGender(null)}>
        <a href={() => false}>All</a>
      </StyledMenu.Item>
      <StyledMenu.Item onClick={() => setSelectedGender("Male")}>
        <a href={() => false}>Male</a>
      </StyledMenu.Item>
      <StyledMenu.Item onClick={() => setSelectedGender("Female")}>
        <a href={() => false}>Female</a>
      </StyledMenu.Item>
    </StyledMenu>
  );

  const menu3 = (
    <StyledMenu>
      <StyledMenu.Item onClick={() => setSelectedSort(null)}>
        <a href={() => false}>None</a>
      </StyledMenu.Item>
      <StyledMenu.Item onClick={() => setSelectedSort("RatingLow")}>
        <a href={() => false}>Rating - Low to High</a>
      </StyledMenu.Item>
      <StyledMenu.Item onClick={() => setSelectedSort("RatingHigh")}>
        <a href={() => false}>Rating - High to Low</a>
      </StyledMenu.Item>
      <StyledMenu.Item onClick={() => setSelectedSort("FeeLow")}>
        <a href={() => false}>Fee - Low to High</a>
      </StyledMenu.Item>
      <StyledMenu.Item onClick={() => setSelectedSort("FeeHigh")}>
        <a href={() => false}>Fee - High to Low</a>
      </StyledMenu.Item>
      <StyledMenu.Item onClick={() => setSelectedSort("Oldest")}>
        <a href={() => false}>Oldest</a>
      </StyledMenu.Item>
      <StyledMenu.Item onClick={() => setSelectedSort("Newest")}>
        <a href={() => false}>Newest</a>
      </StyledMenu.Item>
    </StyledMenu>
  );

  useEffect(() => {
    ApiCalls.getSpecialisationList()
      .then((response) => {
        if (response.data.status) {
          setSpecializations(response.data.data);
        } else {
          message.error("Can't fetch specialites");
        }
      })
      .catch((err) => {});
  }, []);

  useEffect(() => {
    if (currentPage !== 0) {
      getDoctor(currentPage);
    }
    // eslint-disable-next-line
  }, [currentPage]);

  const cancelOpreation = () => {
    let temp = [...data];
    for (let i = 0; i < temp.length; i++) {
      temp[i].selected = false;
    }
    setData(temp);
    setCheckedList([]);
  };

  return loading ? (
    <LoadingOutlined />
  ) : (
    <DoctorSelectionContainer>
      <div style={{ display: "flex", flexDirection: "row" }}>
        <LeftCircleFilled
          onClick={() => history.goBack()}
          style={{ color: "#4385C6", fontSize: "22px" }}
        />
        <Text style={{ color: "#4385C6" }}>Go Back</Text>
      </div>

      <Header>
        <Heading>Therapist selection</Heading>
        <SubHeading></SubHeading>
      </Header>
      <Text welsub={true}>
        You can add only upto {companyProfile.subscriptionDetails.doctorCount}{" "}
        therapists
      </Text>
      <SearchBar>
        <SearchInputContainer>
          <SearchIconContainer>
            <SearchIcon />
          </SearchIconContainer>
          <Input
            placeholder="Search"
            value={searchValue}
            onChange={(e) => setSearchValue(e.target.value)}
          />
        </SearchInputContainer>

        <ProceedButton
          proceed={searchValue?.trim("")?.length === 0}
          onClick={search}
        >
          Search
        </ProceedButton>
      </SearchBar>
      <div style={{ margin: "20px" }}>
        <FilterTabContainer style={{ justifyContent: "center" }}>
          <DropdownContainer>
            <LanguageSearch
              selectedLanguage={selectedLanguage}
              setSelectedLanguage={setSelectedLanguage}
            />
            <CitySearch
              selectedCity={selectedCity}
              setSelectedCity={setSelectedCity}
            />
            <CustomDropdown
              dropdownName={
                selectedSpecialization
                  ? selectedSpecialization.name
                  : "Category"
              }
              key={1}
              menu={menu}
              visible={true}
            />
            <CustomDropdown
              dropdownName={selectedGender ? selectedGender : "Gender"}
              key={2}
              menu={menu2}
            />
            <CustomDropdown
              dropdownName={
                selectedSort
                  ? selectedSort.split(/(?=[A-Z])/).join(" ")
                  : "Filter"
              }
              key={3}
              menu={menu3}
            />
          </DropdownContainer>
          <ApplyButton type="button" onClick={applyFilters}>
            Apply
          </ApplyButton>
        </FilterTabContainer>
      </div>
      {data?.length > 0 ? (
        <TableContainer
          component={Paper}
          sx={checkedList?.length > 0 ? { paddingBottom: "70px" } : {}}
        >
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell />
                <TableCell>Name</TableCell>
                <TableCell align="right">Expertise</TableCell>
                <TableCell align="right">Rating</TableCell>
                <TableCell align="right">Consultation Fees</TableCell>
                <TableCell align="right">Selection</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data.map((item, index) => (
                <TableRow
                  key={item._id}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  style={item.selected ? { backgroundColor: "#ECECEC" } : {}}
                >
                  <TableCell
                    ref={index === data.length - 1 ? lastElementRef : undefined}
                  >
                    <img
                      alt="No img"
                      src={item.profilePic}
                      height="25px"
                      width="25px"
                      style={{ borderRadius: "50%" }}
                      onError={(e) => {
                        if (e.target.src !== ImageNotAvailable) {
                          e.target.src = ImageNotAvailable;
                        }
                      }}
                    />
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {item.name}
                  </TableCell>
                  <TableCell align="right">
                    {item?.specializations[0]?.name}
                  </TableCell>
                  <TableCell align="right">{item.rating}</TableCell>
                  <TableCell align="right">₹{item.consultationFee}</TableCell>
                  <TableCell align="right">
                    <Checkbox
                      checked={
                        selectedDoctors?.includes(item._id)
                          ? true
                          : item.selected
                      }
                      disabled={selectedDoctors?.includes(item._id)}
                      onChange={(e) => onCheckboxSelectionChange(e, item)}
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <NotFound text="No therapists found" />
      )}
      {checkedList.length > 0 ? (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-end",
            backgroundColor: "white",
            width: "100%",
            padding: "20px 50px",
            position: "absolute",
            bottom: 0,
            left: 0,
            right: 0,
          }}
        >
          <ProceedButton cancel={true} onClick={cancelOpreation}>
            Cancel
          </ProceedButton>
          <ProceedButton
            proceed={checkedList.length === 0}
            onClick={() => addDoctor()}
          >
            Proceed
          </ProceedButton>
        </div>
      ) : null}
    </DoctorSelectionContainer>
  );
}

export default DoctorAllList;
