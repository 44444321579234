import { Button } from 'antd';
import styled from 'styled-components';

export const StyledButton = styled(Button)`
  height: ${(props) => (props.height ? `${props.height}px` : props.buttonName == "familyMember" ? "40px" : '55px')};
  width: ${(props) =>
    props.width
      ? `${props.width}px`
      : props.cancel
      ? '100%'
      : props.cancelAppointment
      ? '100%'
      : props.buttonTypeDoc2
      ? '100%'
      : '100%'};
  font-size: 14px;
  border-radius: 7px;
  margin-left: ${(props) => (props.buttonType === 'cancel' ? '10px' : '')};
  background-color: ${(props) =>
    props.cancel && props.secondary
      ? '#F5F6FA'
      : props.cancelAppointment
      ? '#F94848'
      : props.cancel
      ? '#fff'
      : props.secondary
      ? '#4385C6'
      : props.buttonType === 'Edit'
      ? '#fff'
      : ' #2C509F'};
  color: ${(props) =>
    props.cancel && props.secondary
      ? '#35364F'
      : props.cancel
      ? '#FF5353'
      : props.buttonType === 'Edit'
      ? '#4385C6'
      : '#fff'};
  border: ${(props) => (props.buttonType === 'Edit' ? '1px solid #4385C6' : props.cancel? '1px solid #FF5353': 'none')};
  text-align: center;
  right: ${(props) => (props.buttonType === 'addButton' ? '50px' : '0')};
  border-color: ${(props) =>
    props.cancel && props.secondary
      ? '#F5F6FA'
      : props.cancel
      ? '#FF5353'
      : props.cancelAppointment
      ? '#F94848'
      : props.buttonType === 'Edit'
      ? '#4385C6'
      : 'none !important'};
  font-family: Metropolis Regular;
  font-weight: 500;

  &:hover,
  :active,
  :focus {
    background-color: ${(props) =>
      props.cancel && props.secondary
        ? '#1C295D12'
        : props.cancel
        ? '#fff'
        : props.secondary
        ? 'orange'
        : props.followUp
        ? '#fff'
        : props.cancelAppointment
        ? '#F9485C'
        : props.buttonType === 'editProfile'
        ? '#fff'
        : ''};
    border-color: ${(props) =>
      props.cancel && props.secondary
        ? '#1C295D12'
        : props.cancel
        ? '#FF5353'
        : props.cancelAppointment
        ? '#F9485C'
        : ''};
    color: ${(props) =>
      props.secondary || props.buttonType === 'editProfile' ? '#4385C6' : props.followUp ? '#4385C6' : props.cancel? "#FF5353" : '#fff'};
    opacity: 0.7;
  }

  & span {
    padding: 0;
  }

  @media only screen and (max-width: 1200px) {
    width: ${(props) => (props.buttonType === 'Edit' ? '100px' : '')};
  }

  @media only screen and (max-width: 900px) {
    width: ${(props) => (props.buttonType === 'Edit' ? '100px' : '')};
    margin-top: ${(props) => (props.buttonType === 'cancel' ? '10px' : '')};
    margin-left: ${(props) => (props.buttonType === 'cancel' ? '0' : 'none')};
    font-size: 12px;
    height: ${(props) => (props.height ? `${props.height}px` : '45px')};
  }

  @media only screen and (max-width: 600px) {
    width: ${({ buttonType }) => buttonType === 'Confirm' ? '200px' : ''};
  }
`;
