import React from "react";
import { useStripe, useElements } from "@stripe/react-stripe-js";
import { PaymentElement } from "@stripe/react-stripe-js";
import { CustomButton } from "../../styledComponent";
import { Form } from "antd";
import styled from 'styled-components';

const StyledForm = styled(Form)`
  background-color: white;
  width: 500px;
  display: flex;
  flex-direction: column;
  margin: auto;
  margin-top: 100px;
  padding: 30px;
  border-radius: 10px;
`;

const StripePaymentGateway = ({ transactionId, verifyPayment, onCancel }) => {
  const stripe = useStripe();
  const elements = useElements();

  const handlePayment = async(event)=>{
    event.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    const result = await stripe.confirmPayment({
      elements,
      redirect: 'if_required'
    });

    if (result.error) {
      // Error
    } else {
      verifyPayment(transactionId)
    }
  }

  return (
    <StyledForm>
      <PaymentElement />
      <div style={{ display: 'flex', marginTop: '20px', alignItems: 'center', justifyContent: 'flex-end' }}>
        <CustomButton onClick={onCancel} cancel={true} style={{ margin: 0, marginRight: '20px' }}>
          Cancel
        </CustomButton>
        <CustomButton onClick={handlePayment}>Submit</CustomButton>
      </div>
    </StyledForm>
  );
};
export default StripePaymentGateway;
