import React from "react";
import { Main } from "../UpdateProfilePatient/styledComponents";
import { Row } from "antd";
import TextLogo from "../../assets/icons/Logo/TextLogo";
import MailSentIcon from "../../assets/icons/MailSentIcon";
import { LogoContainer, FormWrapper } from "../signup/styledComponents";
import {
  MailIconContainer,
  CompletedMessageContainer,
  CompletedMessage,
  RedirectButtonContainer,
} from "./styledComponents";
import PrimaryButton from "../../components/PrimaryButton/index";
import { useHistory } from "react-router-dom";

const UpdateProfileCompleted = () => {
  const history = useHistory();
  return (
    <>
      <Main login={true}>
        <Row>
          <LogoContainer>
            <TextLogo />
          </LogoContainer>
        </Row>
        <FormWrapper>
          <CompletedMessageContainer>
            <MailIconContainer>
              <MailSentIcon />
            </MailIconContainer>
            <CompletedMessage>You are ready to start!</CompletedMessage>
            <CompletedMessage sub={true}>
              Our Medical Expert will check and confirm about your profile
              within 2-3 business day.
            </CompletedMessage>
            <RedirectButtonContainer>
              <PrimaryButton
                label={"Check later"}
                onClick={() => {
                  history.push("/user/doctor/login");
                  localStorage.clear();
                }}
              />
            </RedirectButtonContainer>
          </CompletedMessageContainer>
        </FormWrapper>
      </Main>
    </>
  );
};

export default UpdateProfileCompleted;
