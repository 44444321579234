import React from "react";
import { PageHeading, SubHeading } from "../styledComponents";
import {
  ListCardContainer,
  StyledCard,
  CollegeName,
  Qualification,
  Address,
  AddEducation,
  EditOption,
} from "./styledComponents";
import AddEducationForm from "./AddEducation";
import AddExperienceForm from "./AddExperience";
import { EditOutlined, DeleteOutlined } from "@ant-design/icons";
import ImageNotAvailable from "../../../assets/images/CorporateModule/Image_not_available.png";

const Section = ({
  itemList,
  editItem,
  setEditItem,
  fetchWorkProfile,
  addNew,
  setAddNew,
  education,
  deleteItem,
}) => {
  return (
    <>
      <PageHeading mandatory={education ? true : false}>
        {education ? "Education" : "Experience"}
      </PageHeading>
      <SubHeading>
        {education
          ? "Please add your education related information"
          : "Please add your experience related information"}
      </SubHeading>
      <ListCardContainer>
        {itemList.length > 0
          ? itemList.map((item) => (
              <>
                {editItem === item._id ? (
                  <>
                    {education ? (
                      <AddEducationForm
                        setAddNew={setAddNew}
                        fetchWorkProfile={fetchWorkProfile}
                        setEditItem={setEditItem}
                        selectedItem={item}
                      />
                    ) : (
                      <AddExperienceForm
                        setAddNew={setAddNew}
                        fetchWorkProfile={fetchWorkProfile}
                        setEditItem={setEditItem}
                        selectedItem={item}
                      />
                    )}
                  </>
                ) : (
                  <StyledCard
                    style={{
                      display: "flex",
                      padding: "12px",
                    }}
                  >
                    <div style={{ height: "60px", display: "flex" }}>
                      <img
                        src={item?.url}
                        onError={(e) => {
                          if (e.target.src !== ImageNotAvailable) {
                            e.target.src = ImageNotAvailable;
                          }
                        }}
                      />
                    </div>
                    <div>
                      <CollegeName>
                        {education ? item.instituteName : item.hospitalName}
                      </CollegeName>
                      {!education && <Address>{item.hospitalAddress}</Address>}
                      <Qualification>
                        {education ? item.qualifiction : item.referencePerson}
                      </Qualification>
                      <Address>
                        {education ? item.instituteAddress : item.contactNumber}
                      </Address>
                      <EditOption onClick={() => setEditItem(item._id)}>
                        <EditOutlined />
                        Edit
                      </EditOption>
                      <EditOption
                        type="button"
                        delete={true}
                        onClick={() => deleteItem(item, education)}
                      >
                        <DeleteOutlined />
                        Delete
                      </EditOption>
                    </div>
                  </StyledCard>
                )}
              </>
            ))
          : null}
      </ListCardContainer>
      {addNew &&
        (education ? (
          <AddEducationForm
            setAddNew={setAddNew}
            fetchWorkProfile={fetchWorkProfile}
          />
        ) : (
          <AddExperienceForm
            setAddNew={setAddNew}
            fetchWorkProfile={fetchWorkProfile}
          />
        ))}
      {!addNew && (
        <AddEducation onClick={() => setAddNew(true)}>
          {education ? "+ Add New Education" : "+ Add New Experience"}
        </AddEducation>
      )}
    </>
  );
};

export default Section;
