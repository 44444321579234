import styled from "styled-components";

export const PatientDashboardContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: auto;
  width: 100%;
  background-color: white;
`;

export const BannerContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  height: auto;
  width: 100%;
  background-color: #ffffff;
  padding: 40px 50px 20px 50px;
  flex-wrap: wrap;

  @media only screen and (max-width: 600px) {
    font-size: 20px;
  }
`;

export const WelcomeTextContainer = styled.div``;

export const WelcomeText = styled.h6`
  margin-top: ${(props) => (props.sub ? "0px" : "5px")};
  font-family: "Urbanist", sans-serif;
  font-weight: ${(props) => (props.sub ? 500 : 600)};
  font-size: ${(props) => (props.sub ? "15px" : "30px")};
  color: #fff;
  & span {
    text-transform: capitalize;
  }
`;

export const CardsContainer = styled.div`
  display: flex;
  height: auto;
  width: 100%;
  margin: 30px 0px 30px 15px;
  flex-wrap: wrap;

  @media only screen and (max-width: 600px) {
    margin: 15px 0px 15px 10px;
  }
`;

export const Cards = styled.div`
  margin: 25px;
  width: 45%;
  height: auto;
  display: flex;
  flex-direction: row;
  align-items: center;

  @media only screen and (max-width: 1400px) {
    width: 40%;
  }

  @media only screen and (max-width: 600px) {
    flex-direction: column;
    width: 90%;
  }
`;

export const SectionHeading = styled.h6`
  margin-top: 25px;
  margin-left: ${(props) => (props.contain ? "" : "50px")};
  margin-bottom: 20px;
  font-family: "Urbanist", sans-serif;
  font-weight: 500;
  font-size: 24px;
  color: #03183c;
`;

export const MainHeading = styled.h6`
  font-family: "Urbanist", sans-serif;
  font-weight: 500;
  font-size: 24px;
  font-weight: 500;
  color: #03183c;
  margin: ${(props) => (props.sub ? "20px 0px 30px 50px" : "0px")};
`;

export const CardContent = styled.div`
  flex-direction: column;
  margin-left: 20px;

  @media only screen and (max-width: 600px) {
    margin-top: 20px;
  }
`;

export const CardText = styled.p`
  color: #6f6f6f;
  font-family: "Urbanist", sans-serif;
  font-size: 16px;
  font-weight: 500;
  margin-top: 10px;
  margin-bottom: 5px;
  overflow-y: hidden;
  max-height: 180px;
  text-overflow: ellipsis;

  @media only screen and (max-width: 1200px) {
    font-size: 14px;
  }
`;

export const ViewMoreButton = styled.p`
  color: #4385c6;
  font-family: "Urbanist", sans-serif;
  font-size: 16px;
  font-weight: 500;
  border: none;
  outline: none;
  padding: 0px;
  margin: 0px;

  &:hover {
    cursor: pointer;
    opacity: 0.7;
  }
`;

export const ReminderContainer = styled.div`
  margin-top: 37px;
  width: 100%;
`;

export const ReminderHeader = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  & span {
    font-family: "Gilroy Bold";
    font-size: 16px;
    color: #35364f;
  }
`;

export const ClearButton = styled.button`
  border: none;
  background: none;
  font-family: "Gilroy Medium";
  font-family: 14px;
  color: #2f4cbf;
  cursor: pointer;
`;

export const ReminderCardsContainer = styled.div`
  margin-top: 22px;
  width: 100%;
  & > :not(:first-of-type) {
    margin-top: 8px;
  }
`;

export const ReminderCard = styled.div`
  display: flex;
  height: 68px;
  width: 100%;
  background: #fff;
  border-radius: 12px;
`;

export const NotificationIconContainer = styled.div`
  height: 100%;
  width: 50px;
  padding-top: 20px;
  padding-left: 15px;
`;

export const ReminderInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 20px;
`;

export const ReminderText = styled.span`
  font-family: "Gilroy Medium";
  font-size: ${(props) => (props.sub ? "12px" : "16px")};
  color: ${(props) => (props.sub ? "#35364F" : "#262c3d")};
  text-transform: capitalize;
`;

export const Specialization = styled.div`
  position: relative;
  padding: 10px 50px;
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const Outlined = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  transition: 0.5s;
  color: gray;
  position: absolute;
  top: 155px;
  background-color: white;
  height: 50px;
  width: 50px;
  border-radius: 50%;
  border: 1px solid gray;
  justify-content: center;
  z-index: 20;
  left: ${(props) => (props.direct === "left" ? "30px" : "")};
  right: ${(props) => (props.direct === "right" ? "30px" : "")};
`;

export const CategoryTile = styled.div`
  display: flex;
  flex-direction: column;
  gap: 19px;
  align-items: center;
  height: 179px;
  width: 168px;
  border-radius: 10px;
  background: #fff;

  &:hover {
    cursor: pointer;
    transform: scale(1.05);
    transition: all 0.25s linear;
  }
`;

export const CategoryTileContainer = styled.div`
  display: grid;
  grid-gap: 20px;
  height: auto;
  scroll-behavior: smooth;
  overflow-x: scroll;
  grid-auto-flow: column;
  padding: 18px 5px;

  ::-webkit-scrollbar {
    display: none;
  }
`;

export const CategoryImage = styled.div`
  height: 128px;
  width: 128px;
  & img {
    height: 100%;
    width: 100%;
    border-radius: 100px;
  }
`;

export const CategoryName = styled.span`
  max-width: 160px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-family: "Urbanist", sans-serif;
  font-weight: 500;
  font-size: 16px;
  color: #03183c;
  text-transform: capitalize;
  text-align: center;
`;

export const HelperContainersWrapper = styled.div`
  width: 100%;
  background-color: #4385c6;
  background-image: linear-gradient(90deg, #4385c6, #4385c6 40%);
  backdrop-filter: blur(100px);
  margin-top: 60px;
  margin-bottom: 50px;
`;

export const HelperHeading = styled.h1`
  font-family: "Urbanist", sans-serif;
  font-weight: 500;
  font-size: 24px;
  margin: 30px 0px 30px 50px;
  color: #fff;

  @media only screen and (max-width: 1200px) {
    font-size: 20px;
  }
`;

export const HelperContainerContain = styled.div`
  display: flex;
  flex-direction: row;
  margin: 50px 50px 20px 50px;
  flex-wrap: wrap;
`;

export const HelperContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: auto;
  width: 331px;
  padding: 20px;
  border-radius: 7px;
  background: #fff;
  margin-right: 30px;
  margin-bottom: 40px;

  @media only screen and (max-width: 1200px) {
    margin-right: 10px;
  }
`;

export const HelperImage = styled.img`
  margin-top: -38px;
`;

export const HelperText = styled.p`
  margin: 0px;
  line-height: 36px;
  margin-top: ${(props) => (props.sub ? "1px" : "20px")};
  font-family: "Urbanist", sans-serif;
  font-weight: ${(props) => (props.sub ? 400 : 500)};
  font-size: ${(props) => (props.sub ? "16px" : "24px")};
  color: ${(props) => (props.sub ? "#6F6F6F" : "#03183C")};
  margin-bottom: ${(props) => (props.sub ? "10px" : "0px")};
`;

export const HelperButton = styled.div`
  height: auto;
  width: 200px;
`;

export const SearchContainer = styled.div`
  position: relative;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  color: #65667d;
  & .ant-select {
    height: 80%;
    width: 100%;
    font-size: 16px;
    border-color: #fff;
    border: none !important;

    & .ant-select-selector {
      height: 80%;
      width: 100%;
      border: none !important;
      outline: None;
      box-shadow: none !important;
      & .ant-select-selection-search-input {
        height: 80%;
        border: none !important;
      }
      & .ant-select-selection-placeholder,
      & .ant-select-selection-item {
        vertical-align: middle;
        line-height: 58px;
      }
    }
  }

  & input {
    height: 80%;
    font-size: 16px;
    border: none;
    &:focus {
      box-shadow: none;
    }
  }
`;

export const SearchInputContainer = styled.div`
  display: flex;
  flex-direction: row;
  background: #ffffff;
  height: 50px;
  width: 50%;
  align-items: center;
  border-radius: 7px;
  overflow-x: hidden;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.04), 0px 4px 8px rgba(0, 0, 0, 0.06);
  border-radius: 7px;
  @media only screen and (max-width: 1200px) {
    width: 100%;
  }
  @media only screen and (max-width: 900px) {
    height: 40px;
  }
  & .ant-select {
    height: 100%;
    width: 100%;
    font-size: 16px;
    border: none !important;

    @media only screen and (max-width: 900px) {
      font-size: 14px;
    }

    & .ant-select-selector {
      height: 100%;
      width: 100%;
      border: none !important;
      box-shadow: none !important;

      & .ant-select-selection-search-input {
        height: 100%;
        border: none !important;
      }
      & .ant-select-selection-placeholder,
      & .ant-select-selection-item {
        vertical-align: middle;
        line-height: 50px;
      }
    }
  }

  & input {
    height: 100%;
    font-size: 16px;
    border: none;
    &:focus {
      box-shadow: none;
    }
  }
  & .ant-select-selection-item {
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`;

export const SearchIconContainer = styled.div`
  display: flex;
  flex-direction: row;
  height: 31px;
  width: 50px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  background: #fff;

  & svg {
    @media only screen and (max-width: 900px) {
      height: 20px;
      width: 20px;
    }
  }

  @media only screen and (max-width: 500px) {
    width: 30px;
    height: 20px;
  }
`;

export const ReminderEmptyMessage = styled.p`
  font-family: "Gilroy Medium";
  font-size: 14px;
  color: #c4c4c4;
  text-align: center;
`;
export const SymptomCheckerInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 254px;
  width: 100%;
  padding: 35px 20px 17px 20px;
  background: #4385c6;
  border-radius: 10px;
`;

export const SymptomCheckerText = styled.p`
  margin-bottom: 5px;
  font-family: "Urbanist", sans-serif;
  font-size: ${(props) => (props.sub ? "18px" : "22px")};
  color: #fff;
  line-height: ${(props) => (props.sub ? "1.35" : null)};
`;

export const SymptomButton = styled.button`
  height: 48px;
  width: 135px;
  border-radius: 10px;
  background: #2f4cbf;
  border: none;
  font-family: "Gilroy SemiBold";
  font-size: 14px;
  color: #fff;
  cursor: pointer;
`;

export const CardImage = styled.img`
  width: 180px;
  height: 180px;

  @media only screen and (max-width: 1200px) {
    width: 120px;
    height: 120px;
  }
`;

//NEW

export const ArticlesGridContainer = styled.div`
  display: grid;
  max-height: 240px;
  grid-template-columns: auto auto;
  gap: 20px;
  padding: 20px;

  @media only screen and (max-width: 1200px) {
    grid-template-columns: auto;
  }

  @media only screen and (max-width: 700px) {
    display: none;
  }
`;
