import React from "react";
import {
  SubscriptionCard,
  SubsDetails,
  Heading,
  Text,
  Text2,
  DividerLine,
  SubsDetailsWrapper,
  MainsHeading,
  Days,
} from "./styledComponent";
import { useHistory } from "react-router-dom";
import Pana from "../../../assets/images/CorporateModule/pana.png";
import { Divider, message } from "antd";
import { useState } from "react";
import ApiCalls from "../../../apis/ApiCalls";
import { useEffect } from "react";
import storage from "../../../storage";
import { RightOutlined } from "@ant-design/icons";

const WelcomeSubscription = () => {
  const [subscriptionDetails, setSubscriptionDetails] = useState([]);
  let history = useHistory();
  const SubscriptionDetails = () => {
    let companyId = storage.user.getItem()?.companyDetails?.companyId;
    ApiCalls.getUserSubscriptionDetails(companyId)
      .then((response) => {
        if (response.data.status) {
          setSubscriptionDetails(response.data.data);
        }
      })
      .catch((error) => {
        message.error(
          error.response
            ? error?.response?.data?.data?.message
            : "No Response From Server",
          5
        );
      });
  };

  useEffect(() => {
    SubscriptionDetails();
  }, []);

  return (
    <SubscriptionCard welsub={true}>
      <SubsDetails welsub={true}>
        <Heading>My subscriptions</Heading>
        <MainsHeading>
          {subscriptionDetails?.subscriptionDetails?.planName}
        </MainsHeading>
        <SubsDetailsWrapper>
          <div>
            <Text welsub={true}>Credits left :</Text>
            <Days>{subscriptionDetails?.creditDetail?.creaditsLeft}</Days>
          </div>
          <div>
            <Text welsub={true}>Validity time left :</Text>
            <Days>
              {subscriptionDetails?.subscriptionDetails?.planDuration} Days
            </Days>
          </div>
        </SubsDetailsWrapper>
        <Divider />
        <Text2>Looking for more benefits ? upgrade to enterprise !</Text2>
      </SubsDetails>
      <DividerLine welsub={true} />
      <SubsDetails second={true}>
        <img src={Pana} alt="No img found" width="234px" height="148px" />
        <SubsDetailsWrapper second={true}>
          <div style={{ width: "auto" }}>
            <Text welsub={true}>Selected Therapists</Text>
            <Days>
              {/* {companyDetails.providersSelected} */}
              <span
                onClick={() =>
                  history.push(
                    "/user/base/home/corporate/view-selected-doctors"
                  )
                }
              >
                {" "}
                View More{" "}
                <RightOutlined style={{ fontSize: "15px", color: "#4385C6" }} />
              </span>
            </Days>
          </div>
          {/* <div>
            <Text welsub={true}>Doctors yet to be added</Text>
            <Days>{Number(companyDetails.subscriptionDetails)-companyDetails.providersSelected}</Days>
          </div> */}
        </SubsDetailsWrapper>
      </SubsDetails>
    </SubscriptionCard>
  );
};

export default WelcomeSubscription;
