import storageBase from "./storage";

const storage = {
  user: storageBase("user"),
  authToken: storageBase("authToken"),
  accountStatus: storageBase("accountStatus"),
  pageInfo: storageBase("pageInfo"),
  oauthData: storageBase("oauthData"),
};

export default storage;
