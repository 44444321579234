import React, { useState } from "react";
import LockIcon from "../../assets/icons/LockIcon";
import NotVisibleIcon from "../../assets/icons/NotVisibleIcon";

const PasswordForm = ({ setPassword, placeholder }) => {
  const [passwordVisibe, setPasswordVisibe] = useState(false);

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        backgroundColor: "#FBFBFB",
        borderRadius: "4px",
        height: "55px",
        padding: "0px 16px",
      }}
    >
      <LockIcon />
      <input
        type={passwordVisibe ? "text" : "password"}
        style={{
          width: "100%",
          fontSize: "18px",
          fontWeight: "500",
          border: "none",
          backgroundColor: "#FBFBFB",
          padding: "0px 16px",
          outline: "none",
        }}
        placeholder={placeholder}
        onChange={(e) => {
          setPassword && setPassword(e.target.value);
        }}
      ></input>
      <button
        style={{
          border: "none",
          backgroundColor: "#FBFBFB",
        }}
        onMouseOver={(e) => (e.target.style.cursor = "pointer")}
        onClick={() => setPasswordVisibe(!passwordVisibe)}
      >
        <NotVisibleIcon />
      </button>
    </div>
  );
};

export default PasswordForm;
