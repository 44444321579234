const addFile = (file = {}) => ({
  type: "ADD_FILE",
  file,
});
const clearFile = () => ({
  type: "CLEAR_FILES",
});
const removeFile = (id = "") => ({
  type: "REMOVE_FILE",
  id,
});

const fileActions = {
  addFile,
  clearFile,
  removeFile,
};
export default fileActions;
