import React from "react";

const EyeOffIcon = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_283_11807)">
        <path
          d="M14.95 14.9499C13.5255 16.0358 11.7909 16.6373 10 16.6666C4.16667 16.6666 0.833336 9.99994 0.833336 9.99994C1.86991 8.06819 3.30762 6.38045 5.05 5.04994M8.25 3.53327C8.82361 3.39901 9.41089 3.33189 10 3.33327C15.8333 3.33327 19.1667 9.99994 19.1667 9.99994C18.6608 10.9463 18.0575 11.8372 17.3667 12.6583M11.7667 11.7666C11.5378 12.0122 11.2618 12.2092 10.9551 12.3459C10.6485 12.4825 10.3174 12.556 9.98174 12.5619C9.64607 12.5678 9.31264 12.5061 9.00134 12.3803C8.69005 12.2546 8.40727 12.0675 8.16987 11.8301C7.93248 11.5927 7.74533 11.3099 7.61959 10.9986C7.49386 10.6873 7.43211 10.3539 7.43803 10.0182C7.44395 9.68252 7.51743 9.35148 7.65407 9.04481C7.79071 8.73815 7.98771 8.46215 8.23334 8.23327"
          stroke="#848484"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M0.833336 0.833252L19.1667 19.1666"
          stroke="#848484"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_283_11807">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default EyeOffIcon;
