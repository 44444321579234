import React from "react";

const Gender = ({ gender, setGender }) => {
  const genderInfo = [
    {
      name: "Male",
      value: "male",
      icon: (
        <>
          <svg
            width="25"
            height="24"
            viewBox="0 0 25 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M21.2187 2.25H16.9999C16.7264 2.25 16.4641 2.35865 16.2707 2.55205C16.0773 2.74544 15.9687 3.00775 15.9687 3.28125C15.9687 3.55475 16.0773 3.81706 16.2707 4.01045C16.4641 4.20385 16.7264 4.3125 16.9999 4.3125H18.7291L15.8984 7.14328C12.702 4.66547 8.07023 4.89328 5.13867 7.82625C1.95867 11.0067 1.95867 16.1808 5.14055 19.3594C6.67128 20.8855 8.74464 21.7425 10.9062 21.7425C13.0677 21.7425 15.1411 20.8855 16.6718 19.3594C19.6048 16.4259 19.8326 11.7961 17.3548 8.59969L20.1874 5.77078V7.5C20.1874 7.7735 20.2961 8.03581 20.4895 8.2292C20.6829 8.4226 20.9452 8.53125 21.2187 8.53125C21.4922 8.53125 21.7545 8.4226 21.9479 8.2292C22.1413 8.03581 22.2499 7.7735 22.2499 7.5V3.28125C22.2499 3.14582 22.2232 3.01172 22.1714 2.88661C22.1196 2.76149 22.0436 2.64781 21.9479 2.55205C21.8521 2.45629 21.7384 2.38032 21.6133 2.3285C21.4882 2.27667 21.3541 2.25 21.2187 2.25ZM15.2149 17.9025C14.0713 19.0437 12.5218 19.6846 10.9062 19.6846C9.29059 19.6846 7.741 19.0437 6.59742 17.9025C4.22133 15.5269 4.22133 11.6606 6.59742 9.285C8.97352 6.90938 12.8393 6.90891 15.2149 9.285C17.5905 11.6611 17.591 15.5269 15.2149 17.9025Z"
              fill="#252525"
            />
          </svg>
        </>
      ),
    },
    {
      name: "Female",
      value: "female",
      icon: (
        <>
          <svg
            width="18"
            height="24"
            viewBox="0 0 18 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M17.1562 8.90625C17.1562 4.40906 13.4972 0.75 9 0.75C4.50281 0.75 0.84375 4.40906 0.84375 8.90625C0.84375 13.0542 3.95625 16.4892 7.96875 16.9973V18.4688H5.25V20.5312H7.96875V23.25H10.0312V20.5312H12.75V18.4688H10.0312V16.9973C14.0437 16.4892 17.1562 13.0542 17.1562 8.90625ZM2.90625 8.90625C2.90625 5.54625 5.64 2.8125 9 2.8125C12.36 2.8125 15.0938 5.54625 15.0938 8.90625C15.0938 12.2662 12.36 15 9 15C5.64 15 2.90625 12.2662 2.90625 8.90625Z"
              fill="#252525"
            />
          </svg>
        </>
      ),
    },
    { name: "Prefer not to say", value: "other", icon: <></> },
  ];

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        maxWidth: "815px",
        width: "100%",
        padding: "5% 0",
        gap: "clamp(24px, 4vw, 36px)",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "12px",
        }}
      >
        <span
          style={{
            fontSize: "clamp(24px, 5vw, 32px)",
            fontWeight: "700",
            color: "#262626",
            fontFamily: "Metropolis Bold, sans-serif",
          }}
        >
          What is your Gender?
        </span>
        <span
          style={{
            fontSize: "clamp(16px, 3vw, 20px)",
            fontWeight: "400",
            color: "#262626",
            fontFamily: "Metropolis Regular, sans-serif",
          }}
        >
          It helps us understand you better
        </span>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "clamp(24px, 4vw, 36px)",
        }}
      >
        {genderInfo.map((item, index) => {
          return (
            <div
              key={index}
              style={{
                width: "100%",
                maxWidth: "386px",
                minHeight: "60px",
                borderRadius: "8px",
                border: "1px solid #ABABAB",
                padding: "16px",
                backgroundColor: gender === item.value ? "#C7D7FE" : "#FBFBFB",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                gap: "16px",
              }}
              onMouseOver={(e) => (e.target.style.cursor = "pointer")}
              onClick={() => setGender(item.value)}
            >
              {item?.icon}
              <span
                style={{
                  fontSize: "clamp(20px, 4vw, 28px)",
                  fontWeight: "700",
                  color: "#000000",
                  fontFamily: "Metropolis Bold, sans-serif",
                }}
              >
                {item.name}
              </span>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Gender;
