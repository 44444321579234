import React, { useRef, useState, useEffect } from "react";
import {
  CardContainer,
  ProfileContainer,
  Header,
  DividerLine,
  Heading,
  EmployeeProfileContainer,
  EmployeeInfo,
  Profile,
  AddPicture,
  SaveButton,
  Text,
} from "./styledComponent";
import { useHistory } from "react-router-dom";
import PhotoCamera from "../../../assets/images/CorporateModule/photo_camera.png";
import { Form, Input, Row, Col, message, Select } from "antd";
import {
  StyledFormItem,
  FormEditWrapper,
} from "../../../doc-patient-modules/signup/styledComponents";
import styled from "styled-components";
import storage from "../../../storage";
import ApiCalls from "../../../apis/ApiCalls";
import { LeftCircleFilled } from "@ant-design/icons";
import ImageNotAvailable from "../../../assets/images/CorporateModule/Image_not_available.png";

const EditProfile = () => {
  let history = useHistory();
  let companyProfile = storage.user.getItem();

  const [form] = Form.useForm();
  const [companyRole, setCompanyRole] = useState([]);
  const [companySize, setCompanySize] = useState([]);
  const [category, setCategory] = useState([]);
  const [selectedCompanyCategory, setSelectedCompanyCategory] = useState(
    JSON.stringify(companyProfile?.companyCategory) ?? {}
  );
  const fileInputRef = useRef(null);

  const fileChange = (e) => {
    if (e.target.files.length > 0) {
      let formData = new FormData();
      formData.append("files", e.target.files[0]);
      let companyId = localStorage.getItem("companyId");
      ApiCalls.editProfileLogo(companyId, formData)
        .then((response) => {
          if (response.data.status) {
            message.success("Successfully Logo Changed");
          }
        })
        .catch((error) => {
          message.error(
            error?.response
              ? String(error?.response?.data?.data?.message)
              : "No Response From Server",
            5
          );
        });
    }
  };

  const AllCategories = () => {
    ApiCalls.getAllCategories()
      .then((response) => {
        if (response.data.status === 1) {
          let tempRole = [];
          response.data.data.forEach((item) => {
            tempRole.push({
              label: item.categoryName,
              value: JSON.stringify(item),
            });
          });
          setCategory(tempRole);
        }
      })
      .catch((error) => {
        if (error.response) {
          message.error(
            error.response
              ? error?.response?.data?.data?.message
              : "No Response From Server",
            5
          );
        }
      });
  };

  const CompanyRole = () => {
    ApiCalls.CompanySignupDetails()
      .then((response) => {
        if (response.data.status === 1) {
          let tempRole = [];
          let tempSize = [];
          response.data.data.companySignupRoles.forEach((item) => {
            tempRole.push({
              label: item,
              value: item,
            });
          });
          response.data.data.companySize.forEach((item) => {
            tempSize.push({
              label: item,
              value: item,
            });
          });
          setCompanyRole(tempRole);
          setCompanySize(tempSize);
        }
      })
      .catch((error) => {
        if (error.response) {
          message.error(
            error.response
              ? error?.response?.data?.data?.message
              : "No Response From Server",
            5
          );
        }
      });
  };

  const onChange = (value) => {
    console.log(`selected ${value}`);
  };

  const onSearch = (value) => {
    console.log("search:", value);
  };

  useEffect(() => {
    AllCategories();
    CompanyRole();
  }, []);

  const imageChange = () => {
    fileInputRef.current.click();
  };

  const CustomCol = styled(Col)`
    & > div {
      margin-bottom: 0px;
    }
    .success:hover {
      border: 2px solid red;
    }
  `;

  const FormStyle = styled(Form)`
    width: 100%;
  `;

  const editprofile = (values) => {
    let companyCategoryTemp = JSON.parse(selectedCompanyCategory);
    let req = {
      companyCategory: { categoryName: companyCategoryTemp?.categoryName },
      companyAddress: values?.companyAddress,
      companyName: values?.companyName,
      companyWebsite: values?.companyWebsite,
      role: values?.role,
      email: values?.email,
      companySize: values?.employeeCount,
      // currency: values?.currency,
    };

    ApiCalls.editProfileDetails(req)
      .then((response) => {
        if (response.data.status) {
          history.goBack();
          message.success("Successfully Saved Changes");
        }
      })
      .catch((error) => {
        message.error(
          error?.response
            ? error?.response?.data?.data?.message
            : "No Response From Server",
          5
        );
      });
  };

  return (
    <>
      <CardContainer>
        <div style={{ display: "flex", flexDirection: "row" , marginTop:"25px"}}>
          <LeftCircleFilled
            onClick={() => history.goBack()}
            style={{ color: "#4385C6", fontSize: "22px" }}
          />
          <Text style={{ color: "#4385C6" }}>Go Back</Text>
        </div>
        <ProfileContainer>
          <Header>
            <Heading>Profile</Heading>
          </Header>
          <EmployeeProfileContainer>
            <EmployeeInfo>
              <Profile tele={true}>
                <img
                  src={companyProfile.companyLogo}
                  onError={(e) => {
                    if (e.target.src !== ImageNotAvailable) {
                      e.target.src = ImageNotAvailable;
                    }
                  }}
                  alt=''
                />
                <AddPicture onClick={imageChange}>
                  <img
                    src={PhotoCamera}
                    alt="No img found"
                    height="35px"
                    width="35px"
                  />
                  <input
                    type="file"
                    style={{ display: "none" }}
                    ref={fileInputRef}
                    onChange={fileChange}
                  />
                </AddPicture>
              </Profile>
              <SaveButton>Save Changes</SaveButton>
            </EmployeeInfo>
            <DividerLine editProfile={true}></DividerLine>
            <FormEditWrapper>
              <Col>
                <Row>
                  <FormStyle
                    layout="vertical"
                    form={form}
                    initialValues={{
                      layout: "vertical",
                    }}
                    onFinish={editprofile}
                  >
                    <Row
                      style={{
                        justifyContent: "space-between",
                        width: "100%",
                        marginBottom: "23px",
                      }}
                    >
                      <CustomCol span={24}>
                        <StyledFormItem
                          name="role"
                          rules={[
                            {
                              required: true,
                              message: "Role is required",
                            },
                          ]}
                          initialValue={companyProfile?.profileCreatorRole}
                        >
                          <Select
                            style={{ cursor: "pointer" }}
                            showSearch
                            placeholder="Role"
                            optionFilterProp="children"
                            onChange={onChange}
                            onSearch={onSearch}
                            filterOption={(input, option) =>
                              (option?.label ?? "")
                                .toLowerCase()
                                .includes(input.toLowerCase())
                            }
                            options={companyRole}
                          />
                        </StyledFormItem>
                      </CustomCol>
                    </Row>

                    <Row style={{ marginBottom: "23px" }}>
                      <CustomCol span={24}>
                        <StyledFormItem
                          name="email"
                          rules={[
                            {
                              required: true,
                              message: "Email is required",
                            },
                          ]}
                          initialValue={companyProfile.companyEmail}
                        >
                          <Input placeholder="Your Email" autoFocus={true} />
                        </StyledFormItem>
                      </CustomCol>
                    </Row>

                    <Row style={{ marginBottom: "23px" }}>
                      <CustomCol span={24}>
                        <StyledFormItem
                          name="companyName"
                          rules={[
                            {
                              required: true,
                              message: "Company Name is required",
                            },
                          ]}
                          initialValue={companyProfile.companyName}
                        >
                          <Input placeholder="Company Name" autoFocus={true} />
                        </StyledFormItem>
                      </CustomCol>
                    </Row>

                    <Row style={{ marginBottom: "23px" }}>
                      <CustomCol span={24}>
                        <StyledFormItem
                          name="companyAddress"
                          rules={[
                            {
                              required: true,
                              message: "Location/Area is required",
                            },
                          ]}
                          initialValue={companyProfile.companyAddress}
                        >
                          <Input
                            type="Local"
                            placeholder="Local/Area"
                            size="large"
                          />
                        </StyledFormItem>
                      </CustomCol>
                    </Row>

                    <Row style={{ marginBottom: "23px" }}>
                      <CustomCol span={24}>
                        <StyledFormItem
                          name="companyCategory"
                          rules={[
                            {
                              required: true,
                              message: "Company Category is required",
                            },
                          ]}
                          initialValue={
                            companyProfile?.companyCategory?.categoryName
                          }
                        >
                          <Select
                            style={{ cursor: "pointer" }}
                            showSearch
                            placeholder="Category of company"
                            optionFilterProp="children"
                            onChange={onChange}
                            onSearch={onSearch}
                            filterOption={(input, option) =>
                              (option?.label ?? "")
                                .toLowerCase()
                                .includes(input.toLowerCase())
                            }
                            options={category}
                            onSelect={(value) =>
                              setSelectedCompanyCategory(value)
                            }
                          />
                        </StyledFormItem>
                      </CustomCol>
                    </Row>

                    <Row style={{ marginBottom: "23px" }}>
                      <CustomCol span={24}>
                        <StyledFormItem
                          name="employeeCount"
                          rules={[
                            {
                              required: true,
                              message: "No of employee is required",
                            },
                          ]}
                          initialValue={companyProfile?.companySize}
                        >
                          <Select
                            style={{ cursor: "pointer" }}
                            showSearch
                            placeholder="Number of employees"
                            optionFilterProp="children"
                            onChange={onChange}
                            onSearch={onSearch}
                            filterOption={(input, option) =>
                              (option?.label ?? "")
                                .toLowerCase()
                                .includes(input.toLowerCase())
                            }
                            options={companySize}
                            // disabled
                          />
                        </StyledFormItem>
                      </CustomCol>
                    </Row>

                    <Row style={{ marginBottom: "23px" }}>
                      <CustomCol span={24}>
                        <StyledFormItem
                          name="companyWebsite"
                          rules={[
                            {
                              required: true,
                              message: "Company Website is required",
                            },
                          ]}
                          initialValue={companyProfile?.companyWebsite}
                        >
                          <Input
                            type="Website"
                            placeholder="Company Website"
                            size="large"
                          />
                        </StyledFormItem>
                      </CustomCol>
                    </Row>

                    <Row style={{ marginBottom: "23px" }}>
                      <CustomCol span={24}>
                        <StyledFormItem
                          name="currency"
                          initialValue={companyProfile?.currency}
                        >
                          <Select
                            style={{ cursor: "pointer" }}
                            showSearch
                            placeholder="Currency"
                            optionFilterProp="children"
                            onChange={onChange}
                            onSearch={onSearch}
                            filterOption={(input, option) =>
                              (option?.label ?? "")
                                .toLowerCase()
                                .includes(input.toLowerCase())
                            }
                            options={[
                              {
                                label: "INR",
                                value: "INR",
                              },
                            ]}
                            disabled
                          />
                        </StyledFormItem>
                      </CustomCol>
                    </Row>

                    <Row justify="center" style={{ marginTop: "10px" }}>
                      <Col span={24}>
                        <StyledFormItem>
                          <SaveButton type="submit">
                            Save Changes
                          </SaveButton>
                        </StyledFormItem>
                      </Col>
                    </Row>
                  </FormStyle>
                </Row>
              </Col>
            </FormEditWrapper>
          </EmployeeProfileContainer>
        </ProfileContainer>
      </CardContainer>
    </>
  );
};

export default EditProfile;
