import React, { useState, useEffect } from "react";
import {
  FormListContainer,
  HeadingText,
  FormListWrapper,
  SubHeadingText,
  CustomButton,
} from "./styledComponent";
import { LoadingOutlined } from "@ant-design/icons";
import { Divider } from "../../MyDoctors/styledComponents";

const FormList =()=>{
  const [loading, setLoading] = useState(false);

    const onCreate=()=>{

    }

    const onViewDetails=()=>{

    }

    return  loading ? (
        <LoadingOutlined />
      ) : (
        <FormListContainer>
            <FormList>
                <HeadingText>Form List</HeadingText>
                <CustomButton
                onClick={onCreate}
                style={{ marginRight: "10px" }}
              >
                Create Form
              </CustomButton>
            </FormList>
            <FormListWrapper>
                <SubHeadingText>Lorem ipsum oi</SubHeadingText>
                <CustomButton onClick={onViewDetails}>View Details</CustomButton>
                <Divider/>
            </FormListWrapper>
        </FormListContainer>
      )
}
export default FormList;
