import React from "react";
import {
  CardContainer,
  SubscriptionCard,
  SubsImage,
  SubsDetails,
  Heading,
  Discription,
  Details,
  Discount,
  AddDoctors,
  Validity,
  ExplorePlans,
  CustomButton,
  Text,
  Text2,
  DividerLine,
  SubsDetailsWrapper,
  MainsHeading,
  Days,
  ButtonWrapper,
} from "./styledComponent";
import { useHistory } from "react-router-dom";
import SubscriptionCorporate from "../../../assets/images/CorporateModule/SubscriptionCorporate.png";
import Myemployees from "../../../assets/images/CorporateModule/Myemployees.png";
import { Divider } from "antd";
import RechargeCredits from "../../../assets/images/CorporateModule/account_balance_wallet.png";
import Pana from "../../../assets/images/CorporateModule/pana.png";
import useWindowDimensions from "../../../utils/useWindowDimensions";

const DashboardContent = ({ companyProfile }) => {
  let history = useHistory();
  const { width } = useWindowDimensions();

  return (
    <>
      <CardContainer style={{ overflow: "hidden", height: "auto" }}>
        {companyProfile?.subscriptionDetails?._id ? (
          <SubscriptionCard mysub={true}>
            <SubsDetails mysub={true}>
              <Heading>My subscriptions</Heading>
              <MainsHeading>
                {companyProfile.subscriptionDetails.planName}
              </MainsHeading>
              <SubsDetailsWrapper>
                <div>
                  <Text mysub={true}>Credits left :</Text>
                  <Days>
                    {companyProfile.subscriptionCreditDetails.creaditsLeft}
                  </Days>
                </div>
                <div>
                  <Text mysub={true}>Validity time left :</Text>
                  <Days>
                    {companyProfile.subscriptionDetails.planDuration} Days
                  </Days>
                </div>
              </SubsDetailsWrapper>
              <Divider />
              <Text2>Looking for more benefits ? upgrade to enterprise !</Text2>

              <ButtonWrapper>
                <CustomButton
                  onClick={() =>
                    history.push("/user/base/home/corporate/Subscription-plans")
                  }
                >
                  Upgrade Plan
                </CustomButton>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    marginTop: "10px",
                  }}
                >
                  <img src={RechargeCredits} alt="No img found" height="34px" width="34px" />
                  <CustomButton
                    RechargeCredits={true}
                    onClick={() =>
                      history.push(
                        "/user/base/home/corporate/dashboard/wallet-credit"
                      )
                    }
                  >
                    Recharge credits
                  </CustomButton>
                </div>
              </ButtonWrapper>
            </SubsDetails>
            {width > 1150 ? <DividerLine mysub={true} /> : null}
            <SubsDetails second={true}>
              <img src={Pana} alt="No img found" width="234px" height="148px" />
              <SubsDetailsWrapper second={true}>
                <div>
                  <Text mysub={true}>Therapists added</Text>
                  <Days>{companyProfile.providersSelected.length}</Days>
                </div>
                <div>
                  <Text mysub={true}>Therapists yet to be added</Text>
                  <Days>
                    {Number(companyProfile.subscriptionDetails.doctorCount) -
                      companyProfile.providersSelected.length}
                  </Days>
                </div>
              </SubsDetailsWrapper>
              <div>
                <CustomButton
                  second={true}
                  onClick={() =>
                    history.push(
                      "/user/base/home/corporate/dashboard/view-doctors"
                    )
                  }
                >
                  View Therapists
                </CustomButton>
              </div>
            </SubsDetails>
          </SubscriptionCard>
        ) : (
          <SubscriptionCard>
            <SubsImage>
              <img src={SubscriptionCorporate} alt="No img found" first={true} />
            </SubsImage>
            <SubsDetails>
              <Heading>Subscriptions</Heading>
              <Discription>First step for better health !</Discription>
              <Details>
                <Discount>Upto 20% discount</Discount>
                <AddDoctors>Add upto 5 therapists</AddDoctors>
                <Validity>Upto 12 months validity</Validity>
              </Details>
              <ExplorePlans
                explore={true}
                onClick={() =>
                  history.push("/user/base/home/corporate/Subscription-plans")
                }
              >
                Explore Plans
              </ExplorePlans>
            </SubsDetails>
          </SubscriptionCard>
        )}
        <SubscriptionCard>
          <SubsImage employee={true}>
            <img employee={true} src={Myemployees} alt="No img found" />
          </SubsImage>
          <SubsDetails>
            <Heading>My employees</Heading>
            <Discription>
            Your employees are perfectly healthy. Click below to access your employee data and check how they're doing.
            </Discription>
            <CustomButton
              view={true}
              onClick={() =>
                history.push("/user/base/home/corporate/employees-list")
              }
            >
              View Employee
            </CustomButton>
          </SubsDetails>
        </SubscriptionCard>
      </CardContainer>
    </>
  );
};

export default DashboardContent;
