const addMessage = (message = {}) => ({
    type: 'ADD_MESSAGE',
    message
})
const clearMessages = () => ({
    type:'CLEAR_MESSAGES'
})

const messageActions = {
    addMessage,
    clearMessages
}
export default messageActions