const authReducerDefaultState = {
  accessToken: "",
  userData: {},
};

export default (state = authReducerDefaultState, action) => {
  switch (action.type) {
    case "SET_AUTH_TOKEN":
      return {
        ...state,
        accessToken: action.authToken,
      };
    case "REMOVE_AUTH_TOKEN":
      return {
        ...state,
        accessToken: "",
      };
    case "SET_USER_DATA":
      return {
        ...state,
        userData: action.userData,
      };
    case "REMOVE_USER_DATA":
      return {
        ...state,
        userData: {},
      };
    default:
      return state;
  }
};
