import { DatePicker } from "antd";
import moment from "moment";
import React from "react";

const Age = ({ dob, setDob }) => {
  const onChange = (date, dateString) => {
    if (date) {
      setDob(date);
    }
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        maxWidth: "815px",
        width: "100%",
        padding: "5% 0",
        gap: "clamp(24px, 4vw, 36px)",
        alignItems: "center",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "12px",
          width: "100%",
          textAlign: "center",
        }}
      >
        <span
          style={{
            fontSize: "clamp(24px, 5vw, 32px)",
            fontWeight: "700",
            color: "#262626",
            fontFamily: "Metropolis Bold, sans-serif",
          }}
        >
          How old are you?
        </span>
        <span
          style={{
            fontSize: "clamp(16px, 3vw, 20px)",
            fontWeight: "400",
            color: "#262626",
            fontFamily: "Metropolis Regular, sans-serif",
          }}
        >
          Helps us better tailor recommendations for you
        </span>
      </div>
      <div
        style={{
          height: "clamp(60px, 10vw, 90px)",
          width: "100%",
          maxWidth: "386px",
          borderRadius: "8px",
          border: "1px solid #ABABAB",
          padding: "16px",
          backgroundColor: "#FBFBFB",
          display: "flex",
          // gap: "4px",
          alignItems: "center",
        }}
      >
        {/* <input
          style={{
            fontSize: "clamp(20px, 4vw, 28px)",
            fontWeight: "700",
            color: "#000000",
            fontFamily: "Metropolis Bold, sans-serif",
            textAlign: "end",
            border: "none",
            outline: "none",
            backgroundColor: "#FBFBFB",
            width: "50%",
          }}
          type="number"
          value={age}
          onChange={(e) => {
            setAge(e.target.value);
          }}
        /> */}
        <DatePicker
          style={{
            fontSize: "clamp(20px, 4vw, 28px)",
            fontWeight: "700",
            color: "#000000",
            fontFamily: "Metropolis Bold, sans-serif",
            textAlign: "end",
            border: "none",
            outline: "none",
            backgroundColor: "#FBFBFB",
            width: "160%",
          }}
          format="DD-MM-YYYY"
          placeholder="Select Birth Date"
          disabledDate={function disabledDate(current) {
            return (
              (current && current.valueOf() > moment().endOf("day")) ||
              current.valueOf() < moment().subtract(120, "years")
            );
          }}
          onChange={onChange}
        />
      </div>
    </div>
  );
};

export default Age;
