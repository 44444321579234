import React, { useEffect, useState } from "react";
import {
  UpdateAreaContainer,
  PageHeading,
  SubHeading,
} from "../styledComponents";
import UploadAvatar from "./UploadFile";
import DoctorDetails from "./DoctorDetails";
import CustomModal from "../../../components/CustomModal";
import MapComponent from "./MapComponent";

const BasicProfile = ({
  trigger,
  setTrigger,
  fetchWorkProfile,
  profilePic,
  setCurrentPage,
  setLastCompletedPage,
  setTimeZone,
}) => {
  const [avatar, setAvatar] = useState("");
  const [viewMap, setViewMap] = useState(false);
  const [address, setAddress] = useState("");
  const [location, setLocation] = useState({ lat: 23.4241, lng: 53.8478 });
  const [oauthData, setOauthData] = useState(null);

  const onCancel = () => {
    setAddress("");
    setViewMap(false);
  };

  const onOk = () => {
    setViewMap(false);
  };

  useEffect(() => {
    let oaData = localStorage.getItem("oauthData");
    if (oaData) {
      let parsedData = JSON.parse(oaData);
      setOauthData(parsedData);
    }
  }, []);

  return (
    <>
      <UpdateAreaContainer>
        <PageHeading>Basic Information</PageHeading>
        <SubHeading>Basic Information about yourself</SubHeading>
        <UploadAvatar
          file={avatar}
          setSelectedItem={setAvatar}
          fetchWorkProfile={fetchWorkProfile}
          image={profilePic}
          title="Upload Image"
          info="Your avatar should be a friendly and inviting headshot. Clearly identifiable as you"
          type={"profile_pic"}
          triggerUpload={true}
        />
        <DoctorDetails
          trigger={trigger}
          setTrigger={setTrigger}
          setCurrentPage={setCurrentPage}
          setLastCompletedPage={setLastCompletedPage}
          address={address}
          location={location}
          setViewMap={setViewMap}
          setTimeZone={setTimeZone}
          oauthData={oauthData}
        />
        <CustomModal
          visible={viewMap}
          onCancel={onCancel}
          onOk={onOk}
          map={true}
          title={"Select Location"}
        >
          <MapComponent
            setAddress={setAddress}
            location={location}
            setLocation={setLocation}
          />
        </CustomModal>
      </UpdateAreaContainer>
    </>
  );
};

export default BasicProfile;
