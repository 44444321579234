import * as React from "react";

function SearchIcon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={20.575}
      height={21.033}
      {...props}
    >
      <g
        transform="translate(-2.028 -2.028)"
        fill="none"
        stroke="#35364f"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
      >
        <circle
          cx={8.989}
          cy={8.989}
          r={8.989}
          transform="translate(2.778 2.778)"
        />
        <path d="M18.018 18.485L21.542 22" opacity={0.4} />
      </g>
    </svg>
  );
}

export default SearchIcon;
