import { AutoComplete } from 'antd';
import styled from 'styled-components';

export const StyledAutoComplete = styled(AutoComplete)`
  & .ant-select-selector {
    padding-left: 60px !important;
  }

  input {
    padding-left: 50px !important;
  }
`;