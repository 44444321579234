import styled from "styled-components";
import { Upload, Spin } from "antd";
import AvatarBg from "../../assets/backgrounds/avatar.svg";

export const Main = styled.div`
  height: 100vh;
  overflow-y: auto;
  width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const PhotoWrapper = styled.div`
  height: 130px;
  width: 100%;
  margin: 10px 0;
`;

export const PhotoContainer = styled.div`
  height: 100%;
  width: 130px;
  border-radius: 50%;
  margin: 0 auto;
`;

export const StyledUpload = styled(Upload)`
  height: 100%;
  width: 100%;

  & .ant-upload-select-picture-card {
    height: 100%;
    width: 100%;
    border: none;
    background: none;
  }

  & .ant-upload {
    padding: 0 !important;
    & img {
      height: 130px;
      width: 130px;
      border-radius: 50%;
      object-fit: cover;
    }
  }
`;

export const UploadDiv = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
  background-image: url(${AvatarBg});
  background-size: contain;
`;

export const IconContainer = styled.div`
  position: absolute;
  right: 3%;
  top: 73%;
  height: 30px;
  width: 30px;

  & svg {
    height: 100%;
    width: 100%;
  }
`;

export const StyledSpin = styled(Spin)`
  position: absolute;
  top: 45%;
  left: 45%;
`;

export const UploadDivWrapper = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
`;
