export default (state = [], action) => {
  switch (action.type) {
    case "ADD_FILE":
      return state.concat(action.file);
    case "REMOVE_FILE":
      const newState = state.filter((item) => item._id !== action.id);
      return newState;
    case "CLEAR_FILES":
      return [];
    default:
      return state;
  }
};
