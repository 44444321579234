import React from "react";
import {
  StyledSelect,
  StyledInput,
  StyledOption,
  Label,
  StyledRow,
  SelectedItemsContainer,
  SelectedItem,
  ItemValue,
  IconContainer,
  AddMoreContainer,
} from "./styledComponents";
import { CloseOutlined } from "@ant-design/icons";
import PrimaryButton from "../../../components/PrimaryButton/index";

const Section = ({
  services,
  handleSelectChange,
  itemList,
  selectedItems,
  removeItem,
  languages,
  showAddMore,
  addMoreFunction,
  addMoreValue,
  setAddMoreValue,
  selectedAddMoreItems,
  removeAddMoreItem,
}) => {
  const onAddMoreChange = (e) => {
    let value = e.target.value;
    if (value.match(/^[a-zA-Z ]+$/i) || value === "") setAddMoreValue(value);
  };
  return (
    <>
      <StyledRow style={{ position: "relative" }}>
        <Label>
          {languages
            ? "Select languages"
            : "Choose specialities"}
        </Label>
        <StyledSelect
          mode="multiple"
          placeholder={
            languages
              ? "Select your languages"
              : "Select your specialities"
          }
          onSelect={(_, val) => handleSelectChange(val, services, languages)}
          onDeselect={(_, val) =>
            removeItem(selectedItems, val.key, services, languages)
          }
          style={{ width: "100%" }}
          value={[...selectedItems]}
        >
          {itemList.map((item) => (
            <StyledOption
              key={item._id || item.language}
              value={item.name || item.language}
            >
              {item.name || item.language}
            </StyledOption>
          ))}
        </StyledSelect>
        <SelectedItemsContainer>
          {itemList.map((item) => (
            <>
              {selectedItems.has(item._id || item.language) ? (
                <SelectedItem key={item._id || item.language}>
                  <ItemValue title={item.name || item.language}>
                    {item.name || item.language}
                  </ItemValue>
                  <IconContainer
                    onClick={() =>
                      languages
                        ? removeItem(
                            selectedItems,
                            item.language,
                            services,
                            languages
                          )
                        : removeItem(selectedItems, item._id, services)
                    }
                  >
                    <CloseOutlined />
                  </IconContainer>
                </SelectedItem>
              ) : null}
            </>
          ))}
          {selectedAddMoreItems &&
            [...selectedAddMoreItems].map((item) => {
              return (
                <SelectedItem key={item}>
                  <ItemValue title={item}>{item}</ItemValue>
                  <IconContainer onClick={() => removeAddMoreItem(item)}>
                    <CloseOutlined />
                  </IconContainer>
                </SelectedItem>
              );
            })}
        </SelectedItemsContainer>
      </StyledRow>
    </>
  );
};

export default Section;
