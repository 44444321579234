// Email validation
export function emailValidation(email) {
    // eslint-disable-next-line
    let emailRegex = /^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,20})$/;
    if(email.length === 0) {
      return 'Email is required';
    } else if(email.match(emailRegex)) {
      return null;
    } else {
      return 'Invalid email';
    }
  }