import React, { useState, useEffect } from "react";
import {
  SubscriptionCardPlansContainer,
  HeadingPlans,
  CardPlansWrapper,
  Card,
  CardHeading,
  CardDiscription,
  Amount,
  CorporateCustomButton,
  Popular,
  CustomButton,
  StripeText
} from "./styledComponent";
import { message } from "antd";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import ApiCalls from "../../../apis/ApiCalls";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import StripePaymentGateway from './Payment/Stripe';
import rafiki2 from "../../../assets/images/CorporateModule/rafiki.png";
import rising from "../../../assets/images/CorporateModule/rising.png";
import { LoadingOutlined } from "@ant-design/icons";
import ConfirmationModal from "./Payment";
import storage from "../../../storage";
import { Heading, Text } from "../../signup/styledComponents";
import { useHistory } from "react-router-dom";

const SubscriptionPlan = () => {
  const [isSubscription, setSubscription] = useState([]);
  const [loading, setLoading] = useState(true);
  const [open, setOpen] = useState(false);
  const [subscriptionId, setSubscriptionId] = useState(null);
  const [paymentSatus, setPaymentStatus] = useState({});
  const [paymentStatusModel, setPaymentStatusModel] = useState(false);
  const [companyProfile, setCompanyProfile] = useState([]);
  const history = useHistory();
  const [selectedGateway, setSelectedGateway] = useState(null);
  const stripePromise = loadStripe(
    process.env.REACT_APP_STRIPE_KEY
  );
  const [stripeModal, setStripeModal] = useState({
    show: false,
    clientsecret: null,
    transactionId: null
  });

  // Get subscription details
  const getSubscription = (profile, showLoader = true) => {
    if (showLoader) {
      setLoading(true);
    }
    ApiCalls.getSubscriptionPlans()
      .then((response) => {
        if (response.data.status) {
          let dummy = [];
          let planseen = false;

          response.data.data.forEach((item) => {
            if (item._id === profile.subscriptionDetails?._id) {
              planseen = true;
            }
            dummy.push({
              ...item,
              selected:
                item._id === profile.subscriptionDetails?._id ? true : false,
              upgradable:
                !profile.subscriptionDetails?._id || planseen ? true : false
            });
          });
          setSubscription(dummy);
          setLoading(false);
        }
      })
      .catch((error) => {
        setLoading(false);
        message.error(
          error.response
            ? error?.response?.data?.data?.message
            : "No Response From Server",
          5
        );
      });
  };

  const getCompanyprofile = (showLoader = true) => {
    if (showLoader) {
      setLoading(true);
    }
    ApiCalls.getCompanyProfile()
      .then((response) => {
        if (response.data.status) {
          setCompanyProfile(response.data.data);
          storage.user.setItem(response.data.data);
          getSubscription(response.data.data, showLoader);
        }
        setLoading(false);
      })
      .catch((error) => {
        message.error(
          error.response
            ? error?.response?.data?.data?.message
            : "No Response From Server",
          5
        );
        setLoading(false);
      });
  };

  // Get payment status
  const getPaymentStatus = (id) => {
    (selectedGateway === 'STRIPE' ? ApiCalls.verifyStripePayment(id) : ApiCalls.verifyRazorPayPayment(id))
      .then((response) => {
        if (response.data.status) {
          getCompanyprofile(false);
          setStripeModal({
            show: false,
            clientsecret: null,
            transactionId: null
          });
          setPaymentStatus("Success");
          setPaymentStatusModel(true);
          setSelectedGateway(null);
        } else {
          setPaymentStatus("Failed");
          setPaymentStatusModel(true);
          message.error(response.data.data.message);
        }
      })
      .catch((error) =>
        message.error(
          error.response
            ? error?.response?.data?.data?.message
            : "No Response From Server",
          5
        )
      );
  };

  function loadScript(src) {
    return new Promise((resolve) => {
      const script = document.createElement("script");
      script.src = src;
      script.onload = () => {
        resolve(true);
      };
      script.onerror = () => {
        resolve(false);
      };
      document.body.appendChild(script);
    });
  }

  // Display Stripe
  const displayStripe = (responseObject) => {
    setStripeModal({
      show: true,
      clientsecret: responseObject?.clientSecret,
      transactionId: responseObject?.transactionId
    });
  };

  // Display razorpay
  async function displayRazorpay(responseObject) {
    const res = await loadScript(
      "https://checkout.razorpay.com/v1/checkout.js"
    );

    if (!res) {
      alert("Razorpay SDK failed to load. Are you online?");
      return;
    }
    setOpen(false);

    const options = {
      key: process.env.REACT_APP_RAZORPAY_KEY,
      amount: Number(
        (responseObject.amount + responseObject.serviceTaxValue) * 100
      ).toString(),
      currency: responseObject.currency,
      name: "TeleHealings",
      description: "",
      order_id: responseObject.orderId,
      handler: async function (response) {
        getPaymentStatus(responseObject.transactionId);
      },
      prefill: {
        name: companyProfile.companyName,
        email: companyProfile.companyEmail,
      },
      notes: {
        address: companyProfile.companyAddress,
      },
      theme: {
        color: "#4385C6",
      },
    };

    const paymentObject = new window.Razorpay(options);
    paymentObject.open();
  }

  const donePayment = () => {
    setPaymentStatusModel(false);
    history.push(`/user/base/home/corporate/dashboard/view-doctors`);
  };

  // On proceed for payment
  const onOk = (gateway) => {
    setSelectedGateway(gateway);
    let companyId = localStorage.getItem("companyId");
    let data = {
      gateway
    }
    if (companyProfile.subscriptionDetails?._id) {
      ApiCalls.updateSubscriptionPlans(companyId, subscriptionId, data)
        .then((response) => {
          if (response.data.status) {
            if(gateway === 'STRIPE') {
              displayStripe(response.data.data);
            } else if(gateway === 'RAZOR_PAY') {
              displayRazorpay(response.data.data);
            }
            setOpen(false);
          }
        })
        .catch((error) => {
          message.error(
            error.response
              ? error?.response?.data?.data?.message
              : "No Response From Server",
            5
          );
        });
    } else {
      ApiCalls.getBuySubscriptionPlans(companyId, subscriptionId, data)
        .then((response) => {
          if (response.data.status) {
            if(gateway === 'STRIPE') {
              displayStripe(response.data.data);
            } else if(gateway === 'RAZOR_PAY') {
              displayRazorpay(response.data.data);
            }
            setOpen(false);
          }
        })
        .catch((error) => {
          message.error(
            error.response
              ? error?.response?.data?.data?.message
              : "No Response From Server",
            5
          );
        });
    }
  };

  const selectedCard = (item) => {
    setSubscriptionId(item._id);
    setOpen(true);
  };

  const onCancel = () => {
    setOpen(false);
  };

  useEffect(() => {
    getCompanyprofile(true);
    // eslint-disable-next-line
  }, []);

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 470,
    height: 600,
    bgcolor: "background.paper",
    border: "2px solid #CCF9FF",
    borderRadius: "8px",
    boxShadow: 24,
    padding: "35px",
  };

  return loading ? (
    <LoadingOutlined />
  ) : (
    <SubscriptionCardPlansContainer>
      <HeadingPlans>
        {companyProfile.subscriptionDetails
          ? "Upgrade Plan"
          : "Subscription plans"}
      </HeadingPlans>
      <CardPlansWrapper>
        {isSubscription.map((item) => (
          <Card key={item._id} selected={item.selected}>
            {item.selected ? <Popular>Your Plan</Popular> : null}
            <CardHeading selected={item.selected} disabled={!item.upgradable}>
              {item.planName}
            </CardHeading>
            <CardDiscription
              selected={item.selected}
              disabled={!item.upgradable}
            >
              Company can add {item.doctorCount} therapist
            </CardDiscription>
            <Amount selected={item.selected} disabled={!item.upgradable}>
              ₹{item.planPrice}
            </Amount>
            {item.selected ? null : (
              <CorporateCustomButton
                onClick={() => (item.upgradable ? selectedCard(item) : {})}
                disabled={!item.upgradable}
              >
                Go {item.planName}
              </CorporateCustomButton>
            )}
          </Card>
        ))}
      </CardPlansWrapper>
      {open && (
        <Modal
          hideBackdrop
          open={open}
          aria-labelledby="child-modal-title"
          aria-describedby="child-modal-description"
        >
          <Box sx={{ ...style, width: 420 }}>
            {companyProfile.subscriptionDetails ? (
              <img
                src={rising}
                alt="No img found"
                height="270px"
                width="246px"
              />
            ) : (
              <img
                src={rafiki2}
                alt="No img found"
                height="270px"
                width="246px"
              />
            )}
            <div style={{ margin: "20px 0px 0px 20px" }}>
              <Heading>Great Choice</Heading>
              <Text>
                You're one step away. Click on "proceed" for payment options
              </Text>
            </div>

            <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
              <CustomButton
                cancel={true}
                onClick={onCancel}
                style={{ marginRight: "10px" }}
              >
                Cancel
              </CustomButton>
              {/* <CustomButton
                style={{ marginRight: "10px", width: "160px" }}
                onClick={() => onOk('STRIPE')}
                loading={loading}
              >
                Pay using stripe
              </CustomButton> */}
              <CustomButton
                style={{ width: "200px" }}
                onClick={() => onOk('RAZOR_PAY')}
                loading={loading}
              >
                Pay using razorpay
              </CustomButton>
            </div>
            {/* <StripeText>Use stripe if outside India</StripeText> */}
          </Box>
        </Modal>
      )}
      {paymentStatusModel ? (
        <ConfirmationModal
          paymentStatusModel={paymentStatusModel}
          onOk={donePayment}
          onCancel={() => setPaymentStatusModel(false)}
          paymentStatus={paymentSatus}
        />
      ) : null}

      {/* Stripe payment modal */}
      {/* {stripeModal.show ? (
        <Modal open={stripeModal.show}>
          <Elements stripe={stripePromise} options={{ clientSecret: stripeModal.clientsecret }}>
            <StripePaymentGateway
              transactionId={stripeModal.transactionId}
              verifyPayment={getPaymentStatus}
              onCancel={() => setStripeModal({ show: false, clientsecret: null, transactionId: null })}
            />
          </Elements>
        </Modal>
      ) : null} */}

    </SubscriptionCardPlansContainer>
  );
};

export default SubscriptionPlan;
