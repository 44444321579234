import styled from "styled-components";
import { Modal } from "antd";

export const StyledModal = styled(Modal)`
  width: ${(props) =>
    props.map
      ? "1000px !important"
      : props.availability
      ? "430px !important"
      : props.fullScreen
      ? "1300px !important"
      : ""};
  height: ${(props) => (props.map ? "90vh !important" : "")};
  padding-bottom: 0;

  & .ant-modal-content {
    border-radius: 10px;
    overflow: hidden;
  }

  & .ant-modal-header {
    border-bottom: none;
    padding: 30px 30px 20px 30px;
  }

  & .ant-modal-title {
    font-size: 23px;
    font-family: Metropolis Bold;
    color: #35364f;
  }

  & .ant-modal-body {
    padding: 0 30px;
  }

  & .ant-modal-footer {
    display: ${(props) => (props.hideFooter ? "none" : "flex")};
    justify-content: space-between;
    align-items: flex-start;
    border-top: none;
    padding: 30px;
  }

  & .ant-radio-group {
    width: 100%;
  }
`;

export const ButtonContainer = styled.div`
  width: ${(props) => (props.showOne ? "100%" : props.type === "NormalPatient" ? "30%" : "47%")};
`;
