import React from "react";
import {
  ProgressTabWrapper,
  LogoContainer,
  InfoTextContainer,
  InfoText,
  ProgressContainer,
  ProfileTabItem,
  ProgressCircle,
  ProfileTabItemName,
  SelectedCircle,
  ConnectingLine,
  ImageContainer,
} from "./styledComponents";
import TextLogo from "../../assets/icons/Logo/TextLogo";
import { profileTabItems } from "./profileTabItems";

const ProgressTab = ({ lastCompletedPage, currentPage, setCurrentPage }) => {
  return (
    <>
      <ProgressTabWrapper>
        <LogoContainer>
          <TextLogo white={true} />
        </LogoContainer>
        <InfoTextContainer>
          <InfoText>Complete your profile</InfoText>
          <InfoText sub={true}>
            Provide rich information that attracts the patients
          </InfoText>
        </InfoTextContainer>
        <ProgressContainer>
          {profileTabItems &&
            profileTabItems.map((item, index) => (
              <>
                <ProfileTabItem key={item.id}>
                  <ProgressCircle
                    completed={index <= lastCompletedPage}
                    // onClick={() => setCurrentPage(item.id)}
                  >
                    {index === currentPage ? <SelectedCircle /> : null}
                    {index !== 6 ? <ConnectingLine /> : null}
                  </ProgressCircle>
                  <ProfileTabItemName selected={index === currentPage}>
                    {item.name}
                  </ProfileTabItemName>
                </ProfileTabItem>
              </>
            ))}
        </ProgressContainer>
        <ImageContainer></ImageContainer>
      </ProgressTabWrapper>
    </>
  );
};

export default ProgressTab;
