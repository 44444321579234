import HomeIcon from "../../assets/images/patientModuleIcons/dashboard.svg";
import AppointmentsIcon from "../../assets/images/CorporateModule/groups.svg";
import AccountIcon from "../../assets/images/CorporateModule/account_circle.svg";

const corporateLinks = [
  {
    key: "1",
    name: "Dashboard",
    path: "/user/base/home/corporate/dashboard",
    icon: HomeIcon,
  },
  {
    key: "2",
    name: "Employee List",
    path: "/user/base/home/corporate/employees-list",
    icon: AppointmentsIcon,
  },
  {
    key: "3",
    name: "Profile",
    path: "/user/base/home/corporate/profile",
    icon: AccountIcon,
  },
  {
    key: "4",
    name: "Survay Form",
    path: "/user/base/home/corporate/survay-form",
    icon: AccountIcon,
  },

];

export default corporateLinks;
