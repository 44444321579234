import React from "react";
import {
  Wrapper,
  SuccessMessage,
} from "../../../BookAppointment/styledComponents";
import PrimaryButton from "../../../../components/PrimaryButton";
import PaymentSuccess from "../../../../assets/images/CoporatePatientModule/PaymentSuccess.png";
import Text from "antd/lib/typography/Text";
import useWindowDimensions from "../../../../utils/useWindowDimensions";

const SuccessInfo = ({ onOk }) => {
  const {width}=useWindowDimensions();

  return (
    <>
      <Wrapper>
        <SuccessMessage>Payment Successful!</SuccessMessage>
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginBottom:"20px"
          }}
        >
          <img
            src={PaymentSuccess}
            height={width<600?"150px":"200px"}
            width={width<600?"150px":"200px"}
            alt=""
          />
        </div>
        <Text style={{ alignItem: "center", margin: "20px"}}>
          Your payment has successfully done.
        </Text>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
            margin: "20px",
          }}
        >
          <PrimaryButton label="Done" onClick={onOk} />
        </div>
      </Wrapper>
    </>
  );
};

export default SuccessInfo;
