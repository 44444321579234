import React, { useState, useEffect } from "react";
import { Form, Input, Select, DatePicker, message, Spin } from "antd";
import { StyledRow, StyledColumn } from "../BasicProfile/styledComponents";
import { StyledFormItem } from "../../signup/styledComponents";
import {
  FormContainer,
  ButtonContainer,
  StyledButton,
  SpinContainer,
} from "../EducationExperience/styledComponents";
import moment from "moment";
import ApiCalls from "../../../apis/ApiCalls";

const AddLicense = ({
  setAddNew,
  countries,
  fetchWorkProfile,
  selectedItem,
  setEditItem,
}) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  const onFinish = (val) => {
    let data = { medicalLicencesDetails: { ...val } };
    data.medicalLicencesDetails.licenceExpirationDate = moment(
      val.licenceExpirationDate
    ).toISOString();
    if (selectedItem) data.medicalLicencesDetails._id = selectedItem._id;
    setLoading(true);
    ApiCalls.updateDoctorExtraInfo(data)
      .then((response) => {
        if (response.data.status) {
          message.success(
            `${selectedItem ? "Edited succesfully" : "Added successfully"}`
          );
          fetchWorkProfile(setLoading, setAddNew, setEditItem);
        } else message.error(response.data.data.message);
      })
      .catch((error) => {
        message.error(
          error.response
            ? error?.response?.data?.data?.message
            : "No Response From Server",
          5
        );
      });
  };

  useEffect(() => {
    if (selectedItem) {
      let setItem = { ...selectedItem };
      setItem.licenceExpirationDate = moment(setItem.licenceExpirationDate);
      form.setFieldsValue({ ...setItem });
    }
    // eslint-disable-next-line
  }, []);
  return (
    <>
      <div style={{ width: "100%", height: "15px" }} />
      <FormContainer>
        {loading ? (
          <SpinContainer>
            <Spin spinning={true} />
          </SpinContainer>
        ) : (
          <Form form={form} onFinish={onFinish}>
            <StyledRow>
              <StyledColumn>
                <StyledFormItem
                  name="licenceCountry"
                  label="License Country"
                  rules={[
                    {
                      required: true,
                      message: "License Country is required",
                    },
                  ]}
                  type2={true}
                >
                  <Select
                    showSearch
                    placeholder="Select Country"
                    filterOption={(inputValue, option) =>
                      option.value
                        .toUpperCase()
                        .includes(inputValue.toUpperCase())
                    }
                  >
                    {countries &&
                      countries.map((item, index) => (
                        <Select.Option key={index} value={item.name}>
                          {item.name}
                        </Select.Option>
                      ))}
                  </Select>
                </StyledFormItem>
              </StyledColumn>
              <StyledColumn>
                <StyledFormItem
                  name="licenceNumber"
                  label="License Number"
                  rules={[
                    {
                      required: true,
                      message: "License number is required",
                    },
                  ]}
                  type2={true}
                >
                  <Input placeholder="Enter license number" autoFocus={true} />
                </StyledFormItem>
              </StyledColumn>
            </StyledRow>
            <StyledRow>
              <StyledColumn>
                <StyledFormItem
                  name="npiNumber"
                  label="NPI Number"
                  type2={true}
                >
                  <Input placeholder="Enter npi number" />
                </StyledFormItem>
              </StyledColumn>
              <StyledColumn>
                <StyledFormItem
                  name="licenceExpirationDate"
                  label="License Expiration Date"
                  rules={[
                    {
                      required: true,
                      message: "License expiration date is required",
                    },
                  ]}
                  type2={true}
                >
                  <DatePicker
                    style={{ width: "100%" }}
                    format="DD-MM-YYYY"
                    placeholder="Select expiration date"
                    inputReadOnly={true}
                    allowClear={false}
                    disabledDate={function disabledDate(current) {
                      return (
                        current && current.valueOf() < moment().endOf("day")
                      );
                    }}
                  />
                </StyledFormItem>
              </StyledColumn>
            </StyledRow>
            <StyledRow submit={true}>
              <ButtonContainer>
                <StyledButton
                  onClick={() => {
                    selectedItem ? setEditItem(null) : setAddNew(false);
                  }}
                >
                  Cancel
                </StyledButton>
                <StyledButton htmlType={true} type="primary">
                  Add
                </StyledButton>
              </ButtonContainer>
            </StyledRow>
          </Form>
        )}
      </FormContainer>
    </>
  );
};

export default AddLicense;
