import React, { useState, useEffect } from "react";
import {
  EmployeeListContainer,
  Heading,
  EmployeeListData,
  ProceedButton,
  Text,
  StyledSpin
} from "./styledComponent";
import EmployeeListDataTable from "./EmployeeListTableData";
import ApiCalls from "../../../apis/ApiCalls";
import {
  message,
  Modal,
  Row,
  Col,
  Button,
  Input,
  Tabs,
  Pagination
} from "antd";
import { PlusOutlined } from "@ant-design/icons";
import NotFound from '../../../components/NotFound';
import { emailValidation } from "../../../utils/validation";

const EmployeeList = () => {
  const [employeeList, setEmployeeList] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const [loading, setLoading] = useState(true);
  const [open, setOpen] = useState(false);
  const [email, setEmail] = useState("");
  const [activeTab, setActiveTab] = useState("0");
  const [emailError, setEmailError] = useState(null);

  const getEmployee = (currentPage) => {
    setLoading(true);
    let companyId = localStorage.getItem("companyId");
    ApiCalls.getEmployeeList(companyId, activeTab, currentPage)
      .then((response) => {
        if (response.data.status) {
          setTotalCount(response.data.data.count);
          setEmployeeList(response.data.data.data);
        } else {
          setEmployeeList([]);
          setTotalCount(0);
          if(response.data?.data?.message) {
            message.error(response.data?.data?.message);
          }
        }
        setLoading(false);
      })
      .catch((err) => {
        setEmployeeList([]);
        setLoading(false);
      });
  };

  const topCloseModal = () => {
    setOpen(false);
    setEmail("");
    setEmailError(null);
  };

  const refresh =() =>{
    setCurrentPage(0);
    getEmployee(0);
  }

  useEffect(() => {
    getEmployee(currentPage);
    // eslint-disable-next-line
  }, [currentPage]);

  useEffect(() => {
    setCurrentPage(0);
    getEmployee(0);
    // eslint-disable-next-line
  }, [activeTab]);

  const SendInvitation = () => {
    let request = { email };
    ApiCalls.CompanyEmployeeInvite(request)
      .then((response) => {
        if (response.data.status) {
          topCloseModal();
          setCurrentPage(0);
          getEmployee(0);
          message.success(response.data.data);
        } else {
          message.error("Failed to send an invite");
        }
      })
      .catch((err) => {
        setLoading(false);
        message.error(err.response.data.data.message);
      });
  };

  const AddEmployee = () => {
    setOpen(true);
  };

  const onEmailChange = (e) => {
    setEmail(e.target.value);
    setEmailError(emailValidation(e.target.value));
  };

  return (
    <>
      <EmployeeListContainer>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <Heading>Employee List</Heading>
          <ProceedButton add2={true} onClick={AddEmployee}>
            <PlusOutlined /> Add Employee
          </ProceedButton>
        </div>
        <>
          {open && (
            <Modal
              onCancel={topCloseModal}
              visible={open}
              width="600px"
              footer={
                <Row justify="end" gutter={16}>
                  <Col>
                    <Button size="large" onClick={topCloseModal}>
                      Cancel
                    </Button>
                  </Col>
                  <Col>
                    <Button
                      type="primary"
                      size="large"
                      onClick={SendInvitation}
                      disabled={emailError || email.length === 0}
                    >
                      Send Invite
                    </Button>
                  </Col>
                </Row>
              }
            >
              <Row>
                <Text mysub={true}>Send an invitation</Text>
              </Row>
              <Row>
                <Input
                  value={email}
                  onChange={onEmailChange}
                  placeholder="Send an invite"
                  type="email"
                  style={{
                    borderColor: emailError ? "red" : "",
                    marginTop: "20px",
                  }}
                />
                <p style={{ color: "red", fontSize: "12px", margin: "0px" }}>
                  {emailError}
                </p>
              </Row>
            </Modal>
          )}
        </>

        <Tabs
          onChange={(key) => setActiveTab(key)}
          size="large"
          activeKey={activeTab}
          style={{ minHeight: "70vh" }}
        >

          {/* Pending  */}
          <Tabs.TabPane tab="Pending" key="0">
            <EmployeeListData>
              {loading ? (
                <StyledSpin />
              ) : employeeList?.length > 0 ? (
                <EmployeeListDataTable employeeList={employeeList} getEmployeeList={refresh} />
              ) : (
                <NotFound text='No Employees Found' />
              )}
            </EmployeeListData>
          </Tabs.TabPane>

          {/* Accpeted */}
          <Tabs.TabPane tab="Accepted" key="1">
            <EmployeeListData>
              {loading ? (
                <StyledSpin />
              ) : employeeList?.length > 0 ? (
                <EmployeeListDataTable employeeList={employeeList} getEmployeeList={refresh} />
              ) : (
                <NotFound text='No Employees Found' />
              )}
            </EmployeeListData>
          </Tabs.TabPane>

        </Tabs>

        {/* Pagination */}
        {(totalCount > 0 && !loading) ? (
          <Pagination
            style={{ margin: "auto" }}
            current={currentPage + 1}
            onChange={(page) => setCurrentPage(page - 1)}
            total={totalCount}
          />
        ) : null}

      </EmployeeListContainer>
    </>
  );
};

export default EmployeeList;
