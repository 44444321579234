import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Row, Col, message, Form, Input, Divider } from "antd";
import { connect } from "react-redux";
import ApiCalls from "../../apis/ApiCalls";
import { withRouter, useParams } from "react-router-dom";
import { compose } from "redux";
import storage from "../../storage/index";
import reduxActions from "../../redux-store/actions/index";
import { Main } from "../UpdateProfilePatient/styledComponents";
import {
  FormWrapper,
  StyledFormItem,
  LogoContainer,
} from "../signup/styledComponents";
import PrimaryButton from "../../components/PrimaryButton/index";
import {
  WelcomeWrapper,
  TextContainer,
  UserTypesWrapper,
  WelcomeText,
  UserTypeContainer,
  UserName,
  SignupInfo,
  SignupText,
  SignupContainer,
  ForgotPassword,
  ImageContainer,
  LoginSignupContainer,
  FormContainer,
} from "./styledComponents";

import jwt from "jwt-decode";
import Banner from "./Banner";
import PhoneForm from "./PhoneForm";
import PasswordForm from "./PasswordForm";
import PatientTherapistSelector from "./PatientTherapistSelector";
import SignInWithPassword from "./Buttons/SignInWithPassword";
import SignInUsingOtp from "./Buttons/SignInUsingOtp";
import SignIn from "./Buttons/SignIn";
import SendViaWhatsapp from "./Buttons/SendViaWhatsapp";
import SendViaSMS from "./Buttons/SendViaSMS";
import OtpVerification from "./OtpVerification";
import SignInWithGoogle from "../signup/Buttons/SignInWithGoogle";

export const formatPhoneNumber = (phoneNumber, countryCode) => {
  const codeLength = countryCode.length;
  const formattedNumber = `${phoneNumber.slice(
    0,
    codeLength
  )}-${phoneNumber.slice(codeLength)}`;
  return formattedNumber;
};

const Login = (props) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [loginType, setLoginType] = useState(null);
  const { userType } = useParams();

  const [phoneNumber, setPhoneNumber] = useState();
  const [phoneNumberFormat, setPhoneNumberFormat] = useState();
  const [password, setPassword] = useState();

  const [loginUsingPassword, setLoginUsingPassword] = useState(true);
  const [loginUsingOtp, setLoginUsingOtp] = useState(false);
  const [verificationSend, setVerificationSend] = useState(false);
  const [otp, setOtp] = useState(new Array(6).fill(""));
  const [enableSignUpButton, setEnableSignUpButton] = useState(false);
  const [channel, setChannel] = useState("");
  const [loginMethod, setLoginMethod] = useState("password");

  const onSendLoginOtp = () => {
    if (!phoneNumber) {
      return message.error("Please enter a valid Phone Number");
    }

    let formattedPhoneNumber = formatPhoneNumber(
      phoneNumber,
      phoneNumberFormat
    );

    let data = {
      phoneNumber: formattedPhoneNumber,
      channel: channel,
      userType: userType,
    };

    ApiCalls.sendLoginOtp(data)
      .then((response) => {
        if (response.data.status === 1) {
          setVerificationSend(true);
        }
      })
      .catch((error) => {
        message.error(
          error.response
            ? error?.response?.data?.data?.message
            : "No Response From Server",
          5
        );
      });
  };

  const onFinish = () => {
    setLoading(true);

    if (!phoneNumber) {
      return message.error("Please enter a valid Phone Number");
    }

    if (!password) {
      return message.error("Please enter the Password");
    }

    let values = {};
    let formattedPhoneNumber = formatPhoneNumber(
      phoneNumber,
      phoneNumberFormat
    );

    values.login = formattedPhoneNumber;
    values.password = password;

    if (loginType === "patient") {
      ApiCalls.patientLogin(values)
        .then((response) => {
          if (response.data.status === 1) {
            setLoading(false);
            localStorage.setItem("userType", response.data?.data?.userType);
            message.success("Logged in successfully");

            storage.authToken.setItem(response.data.data.authToken);
            storage.accountStatus.setItem(response.data.data.status);
            props.dispatch(
              reduxActions.authActions.setAuthToken(
                response.data.data.authToken
              )
            );
            if (response.data.data.page !== 0) {
              props.history.push("/user/base/home/dashboard");
            } else {
              props.history.push("/user/patient/update-profile");
            }
          } else {
            message.error(response.data.message);
          }
        })
        .catch((error) => {
          setLoading(false);
          message.error(
            error.response
              ? error?.response?.data?.data?.message
              : "No Response From Server",
            5
          );
        });
    } else if (loginType === "doctor") {
      // Doctor login
      ApiCalls.doctorLogin(values)
        .then((response) => {
          if (response.data.status === 1) {
            setLoading(false);
            message.success("Logged in successfully");
            localStorage.setItem("userType", response.data?.data?.userType);
            storage.authToken.setItem(response.data.data.authToken);
            storage.accountStatus.setItem(response.data.data.status);
            storage.pageInfo.setItem(response.data.data.page);

            props.dispatch(
              reduxActions.authActions.setAuthToken(
                response.data.data.authToken
              )
            );

            if (response.data.data.page === 5) {
              props.history.push("/user/base/home/dashboard");
            } else {
              props.history.push({
                pathname: "/user/doctor/update-profile",
                state: { page: response.data.data.page },
              });
            }
          } else {
            message.error(response.data.message);
          }
        })
        .catch((error) => {
          setLoading(false);
          message.error(
            error.response
              ? error?.response?.data?.data?.message
              : "No Response From Server",
            5
          );
        });
    }
  };

  const verifyLoginOtp = () => {
    if (!phoneNumber) {
      return message.error("Please enter a valid Phone Number");
    }

    let otpLength = otp.join("");
    if (otpLength.length !== 6) {
      return message.error("Please enter the valid OTP");
    }

    let formattedPhoneNumber = formatPhoneNumber(
      phoneNumber,
      phoneNumberFormat
    );

    let data = {
      login: formattedPhoneNumber,
      otp: otp.join(""),
    };

    if (userType === "patient") {
      ApiCalls.verifyLoginOtp(data)
        .then((response) => {
          if (response.data.status === 1) {
            setLoading(false);
            localStorage.setItem("userType", response.data?.data?.userType);
            message.success("Logged in successfully");

            storage.authToken.setItem(response.data.data.authToken);
            storage.accountStatus.setItem(response.data.data.status);
            storage.pageInfo.setItem(response.data.data.page);
            props.dispatch(
              reduxActions.authActions.setAuthToken(
                response.data.data.authToken
              )
            );
            if (response.data.data.page === 4) {
              props.history.push("/user/base/home/dashboard");
            } else {
              props.history.push("/user/patient/update-profile");
            }
          } else {
            message.error(response.data.message);
          }
        })
        .catch((error) => {
          setLoading(false);
          if (
            error?.response?.data?.data?.message ===
            "Phone Number is not verified"
          ) {
            props.history.push("/user/patient/reverify");
          }
          message.error(
            error.response
              ? error?.response?.data?.data?.message
              : "No Response From Server",
            5
          );
        });
    } else if (userType === "doctor") {
      ApiCalls.verifyProviderLoginOtp(data)
        .then((response) => {
          if (response.data.status === 1) {
            setLoading(false);
            localStorage.setItem("userType", response.data?.data?.userType);
            message.success("Logged in successfully");

            storage.authToken.setItem(response.data.data.authToken);
            storage.accountStatus.setItem(response.data.data.status);
            storage.pageInfo.setItem(response.data.data.page);
            props.dispatch(
              reduxActions.authActions.setAuthToken(
                response.data.data.authToken
              )
            );
            if (response.data.data.page === 5) {
              props.history.push("/user/base/home/dashboard");
            } else {
              props.history.push("/user/doctor/update-profile");
            }
          } else {
            message.error(response.data.message);
          }
        })
        .catch((error) => {
          setLoading(false);
          if (
            error?.response?.data?.data?.message ===
            "Phone Number is not verified"
          ) {
            props.history.push("/user/doctor/reverify");
          }
          message.error(
            error.response
              ? error?.response?.data?.data?.message
              : "No Response From Server",
            5
          );
        });
    }
  };

  const onResendOtp = () => {
    if (!phoneNumber) {
      return message.error("Please enter a valid Phone Number");
    }

    let formattedPhoneNumber = formatPhoneNumber(
      phoneNumber,
      phoneNumberFormat
    );

    let data = {
      phoneNumber: formattedPhoneNumber,
      channel: channel,
      userType: userType,
    };

    ApiCalls.sendLoginOtp(data)
      .then((response) => {
        if (response.data.status === 1) {
          message.success(response.data.data);
        }
      })
      .catch((error) => {
        message.error(
          error.response
            ? error?.response?.data?.data?.message
            : "No Response From Server",
          5
        );
      });
  };

  useEffect(() => {
    try {
      const decodedToken = jwt(storage.authToken.getItem());
      const accountStatus = storage.accountStatus.getItem();
      if (decodedToken.exp * 1000 >= Date.now()) {
        if (accountStatus === "active")
          props.history.push("/user/base/home/dashboard");
      } else {
        localStorage.clear();
        if (userType) {
          props.history.push(`/user/${userType}/login`);
        } else {
          props.history.push("/user/patient/login");
        }
      }
    } catch (e) {
      if (userType) {
        props.history.push(`/user/${userType}/login`);
      } else {
        props.history.push("/user/patient/login");
      }
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (userType === "patient") setLoginType("patient");
    else if (userType === "doctor") setLoginType("doctor");
    else if (userType === "corporate") setLoginType("corporate");
  }, [userType]);

  useEffect(() => {
    if (otp.every((digit) => digit !== "")) {
      setEnableSignUpButton(true);
    } else {
      setEnableSignUpButton(false);
    }
  }, [otp]);

  useEffect(() => {
    if (channel) {
      onSendLoginOtp();
    }
  }, [channel]);

  return (
    <>
      <LoginSignupContainer>
        <Banner />
        {verificationSend ? (
          <OtpVerification
            enableSignUpButton={enableSignUpButton}
            otp={otp}
            setOtp={setOtp}
            verifyLoginOtp={verifyLoginOtp}
            onResendOtp={onResendOtp}
          />
        ) : (
          <FormContainer>
            <div className="FormTitle">
              <span className="primary">Welcome Back!</span>
              <span className="secondary">
                Sign in to continue your wellness journey{" "}
              </span>
            </div>

            <div className="SelectorWrapper">
              <PatientTherapistSelector
                loginType={loginType}
                onClickPatient={() => props.history.push("/user/patient/login")}
                onClickTherapist={() =>
                  props.history.push("/user/doctor/login")
                }
              />
            </div>

            <div className="InputWrapper">
              {loginMethod === "otp" && (
                <>
                  <PhoneForm
                    phoneNumber={phoneNumber}
                    setPhoneNumber={setPhoneNumber}
                    setPhoneNumberFormat={setPhoneNumberFormat}
                  />
                </>
              )}
              {loginMethod === "password" && (
                <>
                  <div
                    style={{
                      display: "flex",
                      gap: "16px",
                      flexDirection: "column",
                    }}
                  >
                    <div>
                      <PhoneForm
                        phoneNumber={phoneNumber}
                        setPhoneNumber={setPhoneNumber}
                        setPhoneNumberFormat={setPhoneNumberFormat}
                      />
                    </div>
                    <div>
                      <PasswordForm setPassword={setPassword} />
                    </div>
                  </div>
                </>
              )}
            </div>

            <div className="AdditionalInfoWrapper">
              <div className="rememberMeWrapper">
                <input type="checkbox" />
                <span>Remember Me</span>
              </div>
              <div>
                <span
                  className="forgotPassword"
                  style={{
                    fontSize: "16px",
                    fontWeight: "700",
                    color: "#2C509F",
                  }}
                  onClick={() =>
                    props.history.push(`/user/${userType}/forgot-password`)
                  }
                >
                  <a>Forgot Password ?</a>
                </span>
              </div>
            </div>

            <div className="ButtonsWrapper">
              <>
                {loginMethod === "password" && (
                  <>
                    <SignInUsingOtp
                      setLoginMethod={() => setLoginMethod("otp")}
                    />
                    <SignIn onFinish={onFinish} />
                    <SignInWithGoogle />
                  </>
                )}

                {loginMethod === "otp" && (
                  <>
                    <SignInWithPassword
                      setLoginMethod={() => setLoginMethod("password")}
                    />

                    <SendViaWhatsapp
                      sendVerification={() => {
                        setChannel("whatsapp");
                      }}
                    />
                    <SendViaSMS
                      sendVerification={() => {
                        setChannel("sms");
                      }}
                    />
                  </>
                )}
              </>
            </div>
            <div className="signUpInfoWrapper">
              <span className="createAccount">Create an account?</span>
              <span
                className="signUpLink"
                onClick={() => props.history.push(`/user/${userType}/signup`)}
              >
                <a>Sign Up</a>
              </span>
            </div>
          </FormContainer>
        )}{" "}
      </LoginSignupContainer>
    </>
  );
};

const mapStateToProps = (store) => {
  return {
    cities: store.lookups.cities,
    credentials: store.lookups.credentials,
  };
};

export default compose(connect(mapStateToProps), withRouter)(Login);
