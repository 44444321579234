import React from "react";
import { TimePickerContainer, DeleteTime } from "./styledComponents";
import { TimePicker } from "antd";
import { CloseOutlined } from "@ant-design/icons";
import moment from "moment";

const SelectTimeElement = ({
  visible,
  startTime,
  endTime,
  item,
  workingHours,
  setTime,
  position,
  showTimePicker,
  day,
  pickTime,
}) => {
  return (
    <>
      <TimePickerContainer visible={visible}>
        <TimePicker
          onSelect={(time) =>
            setTime(workingHours, item, time, true, position, false, day)
          }
          allowClear={false}
          format={"hh:mm A"}
          minuteStep={5}
          use12Hours={true}
          inputReadOnly={true}
          suffixIcon={<></>}
          value={startTime ? startTime : moment("00:00", "hh:mm A")}
        />

        <span>-</span>

        <TimePicker
          onSelect={(time) =>
            setTime(workingHours, item, time, false, position, false, day)
          }
          allowClear={false}
          format={"hh:mm A"}
          minuteStep={5}
          use12Hours={true}
          inputReadOnly={true}
          suffixIcon={<></>}
          value={endTime ? endTime : moment("00:00", "hh:mm A")}
        />
        {position === 0 || position ? (
          <DeleteTime
            onClick={() => showTimePicker(item, false, position, day, true)}
          >
            <CloseOutlined />
          </DeleteTime>
        ) : null}
      </TimePickerContainer>
    </>
  );
};

export default SelectTimeElement;
