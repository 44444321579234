const setHealthRecord = (healthRecord = {}) => ({
    type: 'SET_HEALTH_RECORD',
    healthRecord
})
const removeHealthRecord = () => ({
    type: 'REMOVE_HEALTH_RECORD',
})
const setMedicalReports = (medicalReports = []) => ({
    type: 'SET_MEDICAL_REPORTS',
    medicalReports
})

export default {
    setHealthRecord,
    removeHealthRecord,
    setMedicalReports
}