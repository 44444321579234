import * as React from "react";

function LogoutIcon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={20.538}
      height={20}
      {...props}
    >
      <path
        d="M12.994 5.368v-.933A3.685 3.685 0 009.309.75H4.434A3.685 3.685 0 00.75 4.435v11.13a3.685 3.685 0 003.684 3.685h4.885a3.675 3.675 0 003.675-3.672v-.943m6.793-4.633H7.746m9.113-2.915l2.928 2.915-2.928 2.916"
        fill="none"
        stroke="#898996"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
      />
    </svg>
  );
}

export default LogoutIcon;
