import styled from "styled-components";

export const DayCardsContainer = styled.div`
  width: 100%;
  height: auto;
  padding-bottom: 30px;
`;

export const DayCard = styled.div`
  position: relative;
  max-height: ${(props) => (props.collapse ? "2000px" : "60px")};
  padding-bottom: ${(props) => (props.collapse ? "20px" : "0")};
  width: 100%;
  margin-top: 14px;
  border: 1px solid #dde5ed;
  border-radius: 10px;
  transition: all 0.25s ease-out;
`;

export const InitialDiv = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 60px;
  width: 100%;
`;
export const DayName = styled.p`
  margin-bottom: 0;
  padding-left: 23px;
  font-family: "Gilroy SemiBold";
  font-size: 17px;
  color: #35364f;
`;

export const ToggleContainer = styled.span`
  padding-right: 15px;
  padding-bottom: 6px;
`;

export const SetTimeDiv = styled.div`
  /* transform: ${(props) => (props.collapse ? "scaleY(1)" : "scaleY(0)")}; */
  /* min-height: ${(props) => (props.collapse ? "60px" : "0")};
  transition: all 0.25s ease-out; */
`;

export const TimePickerContainer = styled.div`
  position: relative;
  height: auto;
  width: 230px;
  margin-left: 23px;
  display: ${(props) => (props.visible ? "flex" : "none")};
  transition-delay: 1s;
  justify-content: space-between;
  align-items: center;
  & .ant-picker {
    position: relative;
    display: ${(props) => (props.visible ? "flex" : "none")};
    transition-delay: 1s;
    width: 90px;
    height: 38px;
    border: 1px solid #dde5ed;
    border-radius: 10px;
    & input {
      color: #35364f;
      font-size: 14px;
      font-family: 'Urbanist', sans-serif;
    }
  }

  & > :nth-child(4) {
    visibility: hidden;
  }

  &:hover {
    & > :nth-child(4) {
      visibility: visible !important;
    }
  }
`;

export const AddButton = styled.button`
  border: none;
  background: none;
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-left: 23px;
  display: ${(props) => (props.visible ? "flex" : "none")};
  cursor: pointer;
  & .anticon-plus-circle {
    margin-bottom: 3px;
    color: #4385C6;
  }
`;

export const ButtonName = styled.span`
  font-size: 15px;
  font-family: 'Urbanist', sans-serif;
  color: #4385C6;
  padding-left: 10px;
  cursor: pointer;
`;

export const WorkingHourDiv = styled.div`
  height: 50px;
  width: auto;
  display: flex;
  align-items: center;
  & .date-select {
    position: relative;
    margin-left: 23px;
    width: 103px;
    height: 38px;
    border: 1px solid #dde5ed;
    border-radius: 10px;
    & input {
      color: #35364f;
      font-size: 14px;
      font-family: 'Urbanist', sans-serif;
    }
    & .ant-picker-suffix {
      display: none;
    }
  }
`;

export const BreakDiv = styled.div`
  height: auto;
  width: auto;
`;

export const ListBreakTime = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 20px;
  & > :nth-child(n) {
    margin-top: 20px;
    /* margin-left: 20px; */
  }
`;

export const DeleteTime = styled.div`
  /* position: absolute; */
  top: 20%;
  right: -8%;
  cursor: pointer;
  & svg {
    height: 15px;
    width: 15px;
  }
`;

export const AddNewButton = styled.button`
  position: absolute;
  right: 0;
  bottom: 0;
  border: none;
  background: none;
  font-size: 18px;
  font-family: "Gilroy SemiBold";
  color: #4385C6;
`;
