import React from "react";
import {
  Route,
  BrowserRouter as Router,
  Switch,
  Redirect,
} from "react-router-dom";
import configureStore from "./redux-store/store/configureStore";
import { Provider } from "react-redux";
import DocPatient from "./doc-patient-modules/index";
// import Hospital from "./hospital-modules/index";
// import LabPharmacy from "./lab-pharmacy-modules/index";
import { ConfigProvider, Empty } from "antd";
import GlobalStyle from "./globalStyle";
import "./App.less";

const store = configureStore();

function App() {
  return (
    <Provider store={store}>
      <ConfigProvider renderEmpty={() => <Empty description={false} />}>
        <GlobalStyle />
        <Router>
          <Switch>
            <Route path="/user" render={(props) => <DocPatient />} />
            {/* <Route path="/hospital" render={(props) => <Hospital />} />
              <Route path="/lab-pharmacy" render={(props) => <LabPharmacy />} /> */}
            <Redirect to="/user/patient/login" />
          </Switch>
        </Router>
      </ConfigProvider>
    </Provider>
  );
}

export default App;
