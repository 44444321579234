import React from "react";

function WhatsAppIcon(props) {
  return (
    <svg
      width="25"
      height="25"
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.1007 12.3563C1.10014 14.3728 1.62702 16.3417 2.62888 18.0771L1.00488 24.0066L7.07298 22.4156C8.75134 23.3292 10.6318 23.808 12.5427 23.8081H12.5478C18.8561 23.8081 23.9913 18.6748 23.994 12.3653C23.9952 9.30792 22.8056 6.43295 20.6443 4.26997C18.4834 2.10718 15.6094 0.915458 12.5473 0.914062C6.23819 0.914062 1.10339 6.04709 1.10079 12.3563"
        fill="url(#paint0_linear_2289_8060)"
      />
      <path
        d="M0.692308 12.3527C0.691657 14.4417 1.23742 16.481 2.27501 18.2786L0.592773 24.4207L6.87845 22.7726C8.61035 23.7168 10.5603 24.2147 12.5445 24.2154H12.5496C19.0843 24.2154 24.4039 18.8975 24.4067 12.3621C24.4078 9.19488 23.1755 6.21656 20.937 3.97609C18.6982 1.73591 15.7214 0.501302 12.5496 0.5C6.0138 0.5 0.694913 5.81721 0.692308 12.3527ZM4.43566 17.969L4.20096 17.5965C3.21435 16.0277 2.69361 14.2149 2.69435 12.3534C2.6964 6.9213 7.11724 2.50186 12.5533 2.50186C15.1859 2.50298 17.6599 3.52921 19.5208 5.39116C21.3815 7.2533 22.4054 9.72865 22.4048 12.3614C22.4024 17.7935 17.9814 22.2135 12.5496 22.2135H12.5457C10.7771 22.2126 9.04245 21.7376 7.5297 20.84L7.1697 20.6265L3.43966 21.6045L4.43566 17.969Z"
        fill="url(#paint1_linear_2289_8060)"
      />
      <path
        d="M9.58581 7.39771C9.36386 6.9044 9.13027 6.89445 8.9192 6.8858C8.74637 6.87836 8.54879 6.87892 8.35139 6.87892C8.15381 6.87892 7.83279 6.95324 7.56144 7.24952C7.28981 7.54608 6.52441 8.26273 6.52441 9.72031C6.52441 11.1779 7.58609 12.5866 7.73409 12.7845C7.88227 12.982 9.78367 16.0689 12.795 17.2564C15.2977 18.2433 15.807 18.047 16.3502 17.9975C16.8934 17.9482 18.1031 17.2811 18.3499 16.5892C18.5969 15.8975 18.5969 15.3046 18.5228 15.1807C18.4488 15.0572 18.2512 14.9831 17.9549 14.835C17.6586 14.6869 16.202 13.9701 15.9305 13.8712C15.6588 13.7724 15.4613 13.7231 15.2638 14.0198C15.0662 14.3159 14.4988 14.9831 14.3259 15.1807C14.1532 15.3787 13.9802 15.4034 13.684 15.2552C13.3876 15.1065 12.4334 14.7941 11.3014 13.7849C10.4207 12.9996 9.82609 12.0298 9.65325 11.7331C9.48041 11.437 9.63474 11.2764 9.7833 11.1288C9.91641 10.996 10.0797 10.7828 10.2279 10.6099C10.3757 10.4369 10.425 10.3134 10.5238 10.1158C10.6226 9.91808 10.5732 9.74506 10.4992 9.59687C10.425 9.44868 9.84925 7.98347 9.58581 7.39771Z"
        fill="white"
      />
      <defs>
        <linearGradient
          id="paint0_linear_2289_8060"
          x1="1150.46"
          y1="2310.17"
          x2="1150.46"
          y2="0.914062"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#1FAF38" />
          <stop offset="1" stop-color="#60D669" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_2289_8060"
          x1="1191.29"
          y1="2392.57"
          x2="1191.29"
          y2="0.5"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#F9F9F9" />
          <stop offset="1" stop-color="white" />
        </linearGradient>
      </defs>
    </svg>
  );
}

export default WhatsAppIcon;
