import React, { useState } from "react";
import EyeOnIcon from "../../../assets/icons/EyeOnIcon";
import EyeOffIcon from "../../../assets/icons/EyeOffIcon";

const Password = ({
  password,
  setPassword,
  confirmPassword,
  setConfirmPassword,
}) => {
  const [passwordVisible, setPasswordVisible] = useState(false);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        maxWidth: "815px",
        width: "100%",
        padding: "5% 0",
        gap: "clamp(24px, 4vw, 36px)",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "12px",
        }}
      >
        <span
          style={{
            fontSize: "clamp(24px, 5vw, 32px)",
            fontWeight: "700",
            color: "#262626",
            fontFamily: "Metropolis Bold, sans-serif",
          }}
        >
          Let's Create a Password
        </span>
        <span
          style={{
            fontSize: "clamp(16px, 3vw, 20px)",
            fontWeight: "400",
            color: "#262626",
            fontFamily: "Metropolis Regular, sans-serif",
          }}
        >
          Provide an extra safety for your app and information
        </span>
      </div>
      {["Enter a Password", "Confirm Password"].map((label, index) => (
        <div
          key={index}
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "12px",
          }}
        >
          <span
            style={{
              fontSize: "clamp(16px, 3vw, 20px)",
              fontWeight: "700",
              color: "#565656",
              fontFamily: "Metropolis Bold, sans-serif",
            }}
          >
            {label}
          </span>
          <div
            style={{
              height: "clamp(60px, 10vw, 90px)",
              borderRadius: "8px",
              border: "1px solid #ABABAB",
              padding: "16px",
              backgroundColor: "#FBFBFB",
              display: "flex",
              gap: "4px",
              alignItems: "center",
            }}
          >
            <input
              style={{
                fontSize: "clamp(20px, 4vw, 28px)",
                fontWeight: "700",
                color: "#000000",
                fontFamily: "Metropolis Bold, sans-serif",
                border: "none",
                outline: "none",
                backgroundColor: "#FBFBFB",
                width: "90%",
              }}
              type={passwordVisible ? "text" : "password"}
              value={index === 0 ? password : confirmPassword}
              onChange={(e) => {
                index === 0
                  ? setPassword(e.target.value)
                  : setConfirmPassword(e.target.value);
              }}
            />
            <div onClick={() => setPasswordVisible(!passwordVisible)}>
              {passwordVisible ? <EyeOnIcon /> : <EyeOffIcon />}
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default Password;
