import {
  createStore,
  combineReducers,
  // applyMiddleware
} from "redux";
import lookupsReducer from "../reducers/lookupsReducer";
// import logger from "redux-logger";
import authReducer from "../reducers/authReducer";
import healthRecordReducer from "../reducers/healthRecordReducer";
import onlineReducer from "../reducers/onlineReducer";
import patientReducer from "../reducers/patientReducer";
import messageReducer from "../reducers/messageReducer";
import fileReducer from "../reducers/fileReducer";
import callReducer from "../reducers/callReducer";

export default () => {
  const store = createStore(
    combineReducers({
      auth: authReducer,
      lookups: lookupsReducer,
      healthRecord: healthRecordReducer,
      online: onlineReducer,
      patient: patientReducer,
      messages: messageReducer,
      files: fileReducer,
      call: callReducer,
    })
    // applyMiddleware(logger)
  );
  return store;
};
