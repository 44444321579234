import React, { useEffect, useState } from "react";
import {
  UploadAvatarContainer,
  UploadLeftSection,
  UploadRightSection,
  AvatarWrapper,
  AvatarContainer,
  TextContainer,
  UploadInfo,
  StyledSpin,
} from "./styledComponents";
import PrimaryButton from "../../../components/PrimaryButton/index";
import { Upload, message } from "antd";
import ApiCalls from "../../../apis/ApiCalls";
import CustomButton from "../../Utils/CustomButton";
import ImageNotAvailable from "../../../assets/images/CorporateModule/Image_not_available.png";

const UploadFile = ({
  file,
  setFile,
  title,
  info,
  type,
  image,
  itemId,
  setSelectedItem,
  fetchWorkProfile,
  triggerUpload,
  fileForm,
  setFileForm,
}) => {
  const [loading, setLoading] = useState(false);

  const saveFile = (formData, file) => {
    setLoading(true);
    ApiCalls.uploadDoctorFile(formData)
      .then((response) => {
        if (response.data.status) {
          message.success(
            `${
              itemId
                ? "File uploaded succesfully"
                : "Profile pic updated successfully"
            }`
          );
          if (type === "profile_pic") setSelectedItem(file);
          if (type === "educationalDetails") setSelectedItem(file);
          if (type === "experienceDetails") setSelectedItem(file);
          fetchWorkProfile(false, false, setSelectedItem);
        } else {
          message.error(
            `${
              itemId ? "Failed uploading file" : "Failed updating profile pic"
            }`
          );
        }
      })
      .catch((e) => {
        // Error
      })
      .finally(() => {
        setLoading(false);
      });
  };

  function beforeUpload(file) {
    if (file.type === "image/png" || file.type === "image/jpeg") {
      var img = document.createElement("img");
      img.src = URL.createObjectURL(file);
      img.onload = function () {
        var width = img.width;
        var height = img.height;
        if (width > 150 && height > 150) {
          // setFile(file);
          const formData = new FormData();
          let success = true;
          switch (type) {
            case "profile_pic":
              formData.append("type", type);
              formData.append("uploadFile", file);
              break;
            case "medicalLicences":
              formData.append("type", type);
              formData.append("uploadFile", file);
              formData.append("fileId", itemId);
              break;
            case "certificateDetails":
              formData.append("type", type);
              formData.append("uploadFile", file);
              formData.append("fileId", itemId);
              break;
            case "educationalDetails":
              formData.append("type", type);
              formData.append("uploadFile", file);
              break;
            case "experienceDetails":
              formData.append("type", type);
              formData.append("uploadFile", file);
              break;
            default:
              success = false;
              break;
          }
          setFile && setFile(file);
          setFileForm && setFileForm(formData);

          triggerUpload === undefined ||
            (triggerUpload === true && success && saveFile(formData, file));
        } else {
          message.error("Minimum image size is 150px by 150px");
        }
      };
    } else {
      message.error("You can only upload images of .png and .jpeg format");
    }
    return false;
  }

  useEffect(() => {
    if (triggerUpload === true) {
      saveFile(fileForm, file);
    }
  }, [triggerUpload]);

  return (
    <>
      <UploadAvatarContainer className="upload-file-custom">
        <UploadLeftSection>
          <AvatarWrapper>
            <AvatarContainer>
              {(file || image) && (
                <img
                  src={!file ? image : URL.createObjectURL(file)}
                  style={{ width: "100%" }}
                  alt="No img found"
                  onError={(e) => {
                    if (e.target.src !== ImageNotAvailable) {
                      e.target.src = ImageNotAvailable;
                    }
                  }}
                />
              )}
              {loading && <StyledSpin size="small" spinning="true" />}
            </AvatarContainer>
          </AvatarWrapper>
          <TextContainer>
            <UploadInfo>{title}</UploadInfo>
            <UploadInfo sub={true}>{info}</UploadInfo>
          </TextContainer>
        </UploadLeftSection>
        <UploadRightSection>
          <Upload
            name="file"
            showUploadList={false}
            beforeUpload={beforeUpload}
            customRequest={() => {}}
          >
            {/* <PrimaryButton label="Browse Image" /> */}
            <CustomButton
              buttonName={"Upload File"}
              customStyles={{
                height: "40px",
              }}
            />
          </Upload>
        </UploadRightSection>
      </UploadAvatarContainer>
    </>
  );
};

export default UploadFile;
