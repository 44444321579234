import React from "react";

const NotVisibleIcon = () => {
  return (
    <svg
      width="20"
      height="21"
      viewBox="0 0 20 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_1720_51151)">
        <path
          d="M14.9502 15.4502C13.5257 16.536 11.7911 17.1376 10.0002 17.1668C4.16683 17.1668 0.833496 10.5002 0.833496 10.5002C1.87007 8.56843 3.30778 6.88069 5.05016 5.55018M8.25016 4.03352C8.82377 3.89925 9.41105 3.83213 10.0002 3.83352C15.8335 3.83352 19.1668 10.5002 19.1668 10.5002C18.661 11.4465 18.0577 12.3375 17.3668 13.1585M11.7668 12.2668C11.538 12.5125 11.262 12.7095 10.9553 12.8461C10.6486 12.9828 10.3176 13.0562 9.9819 13.0622C9.64623 13.0681 9.3128 13.0063 9.0015 12.8806C8.69021 12.7549 8.40743 12.5677 8.17003 12.3303C7.93264 12.0929 7.74549 11.8101 7.61975 11.4988C7.49402 11.1875 7.43227 10.8541 7.43819 10.5184C7.44411 10.1828 7.51759 9.85172 7.65423 9.54506C7.79087 9.23839 7.98787 8.96239 8.2335 8.73352"
          stroke="#848484"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M0.833496 1.3335L19.1668 19.6668"
          stroke="#848484"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_1720_51151">
          <rect
            width="20"
            height="20"
            fill="white"
            transform="translate(0 0.5)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default NotVisibleIcon;
