import React, { useState, useEffect } from "react";
import {
  StyledForm,
  StyledRow,
  StyledColumn,
  SelectGenderDiv,
  GenderItem,
  MapButton,
  CheckBoxInfoContainer,
} from "./styledComponents";
import { StyledFormItem } from "../../signup/styledComponents";
import { Input, DatePicker, Select, Form, message } from "antd";
import moment from "moment";
import ApiCalls from "../../../apis/ApiCalls";
import { SubHeading } from "../styledComponents";
import PrimaryButton from "../../../components/PrimaryButton/index";

const DoctorDetails = ({
  trigger,
  setCurrentPage,
  setLastCompletedPage,
  setTrigger,
  setViewMap,
  address,
  location,
  setTimeZone,
  oauthData,
}) => {
  const [form] = Form.useForm();
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [selectedState, setSelectedState] = useState(null);
  const [countries, setCountries] = useState([]);
  const [cityList, setCityList] = useState([]);
  const [statesList, setStatesList] = useState([]);
  const [gender, setGender] = useState("Male");
  const [hiddenFields, setHiddenFields] = useState([]);
  const [showAddressInProfile, setShowAddressInProfile] = useState(true);
  const [showDOBInProfile, setShowDOBInProfile] = useState(true);

  const onFinish = (val) => {
    let data = {};
    let address = {};
    data.dob = moment(val.dob).toISOString();
    data.gender = gender;
    data.facebookId = val.facebookId;
    data.linkedinId = val.linkedinId;
    data.aboutYou = val.aboutYou;
    data.name = val.name;
    address.city = val.primaryCity;
    address.latitude = `${location.lat}° N`;
    address.longitude = `${location.lng}° E`;
    address.address = val.address;
    data.address = address;
    data.hiddenFields = hiddenFields;

    if (!oauthData) {
      data.email = val.email;
      data.password = val.password;
    }

    ApiCalls.updateDoctorBasicInfo(data)
      .then((response) => {
        if (response.data.status) {
          setTrigger(0);
          message.success("Updated basic info successfully");
          setCurrentPage((prev) => prev + 1);
          setLastCompletedPage((prev) => prev + 1);
        } else {
          message.error(response.data.data.message);
        }
      })
      .catch((error) => {
        message.error(
          error.response
            ? error?.response?.data?.data?.message
            : "No Response From Server",
          5
        );
      });
  };

  useEffect(() => {
    ApiCalls.getCountries().then((response) => {
      if (response.data.status) setCountries(response.data.data);
    });
  }, []);

  useEffect(() => {
    selectedCountry &&
      ApiCalls.getStates(selectedCountry.countryCode).then((response) => {
        if (response.data.status) setStatesList(response.data.data.state);
      });
  }, [selectedCountry]);

  useEffect(() => {
    selectedState &&
      ApiCalls.getAvailableCities(
        selectedCountry.countryCode,
        selectedState.stateCode
      ).then((response) => {
        if (response.data.status) setCityList(response.data.data.city);
      });
    // eslint-disable-next-line
  }, [selectedState]);

  useEffect(() => {
    trigger && form.submit();
    // eslint-disable-next-line
  }, [trigger]);

  useEffect(() => {
    form.setFieldsValue({ address: address });
    // eslint-disable-next-line
  }, [address]);

  useEffect(() => {
    if (showAddressInProfile === false && !hiddenFields.includes("address")) {
      setHiddenFields([...hiddenFields, "address"]);
    } else if (
      showAddressInProfile === true &&
      hiddenFields.includes("address")
    ) {
      setHiddenFields(hiddenFields.filter((field) => field !== "address"));
    }

    if (showDOBInProfile === false && !hiddenFields.includes("dob")) {
      setHiddenFields([...hiddenFields, "dob"]);
    } else if (showDOBInProfile === true && hiddenFields.includes("dob")) {
      setHiddenFields(hiddenFields.filter((field) => field !== "dob"));
    }
  }, [showAddressInProfile, showDOBInProfile]);

  return (
    <>
      <StyledForm form={form} onFinish={onFinish}>
        <SubHeading type2={true}>Select your gender</SubHeading>
        <SelectGenderDiv>
          <GenderItem
            type="button"
            selected={gender === "Male"}
            onClick={() => setGender("Male")}
          >
            Male
          </GenderItem>
          <GenderItem
            type="button"
            selected={gender === "Female"}
            onClick={() => setGender("Female")}
          >
            Female
          </GenderItem>
        </SelectGenderDiv>
        <StyledRow>
          <StyledColumn>
            <StyledFormItem
              name="name"
              label="Full Name"
              rules={[{ required: true, message: "Full Name is required" }]}
              type2={true}
            >
              <Input placeholder="Provide a Name" />
            </StyledFormItem>
          </StyledColumn>
          <StyledColumn>
            {" "}
            {oauthData?.email ? (
              <div
                style={{
                  width: "226.9px",
                  height: "60px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                Email <br />
                {oauthData?.email}
              </div>
            ) : (
              <StyledFormItem
                name="email"
                label="Email"
                rules={[
                  {
                    required: true,
                    message: "Email is required",
                  },
                ]}
                type2={true}
              >
                <Input placeholder="Enter your email" />
              </StyledFormItem>
            )}
          </StyledColumn>
        </StyledRow>

        {!oauthData && (
          <StyledRow>
            <StyledColumn>
              <StyledFormItem
                name="password"
                label="Password"
                rules={[{ required: true, message: "Password is required" }]}
                type2={true}
              >
                <Input.Password placeholder="Create a Password" />
              </StyledFormItem>
            </StyledColumn>

            <StyledColumn>
              <StyledFormItem
                dependencies={["password"]}
                name="confirm"
                label="Confirm password"
                hasFeedback
                rules={[
                  {
                    required: true,
                    message: "Confirm Password is required",
                  },
                  ({ getFieldValue }) => ({
                    validator(rule, value) {
                      if (!value || getFieldValue("password") === value) {
                        return Promise.resolve();
                      }
                      return Promise.reject(
                        "The two passwords that you entered do not match!"
                      );
                    },
                  }),
                ]}
                type2={true}
              >
                <Input.Password
                  type="password"
                  placeholder="Confirm Password"
                />
              </StyledFormItem>
            </StyledColumn>
          </StyledRow>
        )}

        <StyledRow style={{ paddingBottom: "4px" }}>
          <StyledColumn>
            <StyledRow style={{ paddingBottom: "4px" }}>
              <StyledFormItem
                name="address"
                label="Address"
                rules={[
                  {
                    required: true,
                    message: "Address is required",
                  },
                ]}
                type2={true}
              >
                <Input placeholder="Pick from Map" />
              </StyledFormItem>
              <MapButton>
                <PrimaryButton label="Map" onClick={() => setViewMap(true)} />
              </MapButton>
            </StyledRow>
          </StyledColumn>
          <StyledColumn>
            <StyledFormItem
              name="dob"
              label="Date of birth"
              rules={[
                {
                  required: true,
                  message: "DOB is required",
                },
              ]}
              type2={true}
            >
              <DatePicker
                style={{ width: "100%" }}
                format="DD-MM-YYYY"
                placeholder="Select birth date"
                allowClear={false}
                inputReadOnly={true}
                disabledDate={function disabledDate(current) {
                  return (
                    (current && current.valueOf() > moment().endOf("day")) ||
                    current.valueOf() < moment().subtract(100, "years")
                  );
                }}
              />
            </StyledFormItem>
          </StyledColumn>
        </StyledRow>

        <StyledRow>
          <StyledColumn>
            <CheckBoxInfoContainer>
              <input
                type="checkbox"
                checked={showAddressInProfile}
                onChange={() => setShowAddressInProfile(!showAddressInProfile)}
              />
              <span>Show Address in Profile</span>
            </CheckBoxInfoContainer>{" "}
          </StyledColumn>
          <StyledColumn>
            <CheckBoxInfoContainer>
              <input
                type="checkbox"
                checked={showDOBInProfile}
                onChange={() => setShowDOBInProfile(!showDOBInProfile)}
              />
              <span>Show DOB in Profile</span>
            </CheckBoxInfoContainer>
          </StyledColumn>
        </StyledRow>

        <StyledRow>
          <StyledFormItem
            name="aboutYou"
            label="About You"
            rules={[{ required: true, message: "About you is required" }]}
            type2={true}
          >
            <Input.TextArea placeholder="Give a brief introduction" />
          </StyledFormItem>
        </StyledRow>
      </StyledForm>
    </>
  );
};

export default DoctorDetails;
