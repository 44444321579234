import { Form, Spin } from "antd";
import styled from "styled-components";

export const UploadAvatarContainer = styled.div`
  display: flex;
  justify-content: space-between;
  height: 112px;
  // height: 60px;
  width: 100%;
  padding: 22.5px 22px;
  border: 1px solid #dde5ed;
  border-radius: 10px;
`;

export const UploadLeftSection = styled.div`
  height: 100%;
  // width: 70%;
  width: 60%;
  display: flex;
  align-items: space-between;
`;

export const UploadRightSection = styled.div`
  height: 100%;
  width: 114px;
  display: flex;
  justify-content: center;
  align-items: center;

  & > span {
    width: 100%;
  }

  & .ant-upload {
    width: 100%;
  }
`;

export const AvatarWrapper = styled.div`
  height: 100%;
  width: 60px;
  display: flex;
  align-items: center;
`;

export const AvatarContainer = styled.div`
  position: relative;
  height: 60px;
  width: 60px;
  border-radius: 50%;
  // height: 40px;
  // width: 30px;
  // border-radius: 10%;
  background-color: lightgrey;
  & img {
    height: 100%;
    width: 100%;
    border-radius: 50%;
  }
`;

export const TextContainer = styled.div`
  height: 100%;
  padding-left: 14px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const UploadInfo = styled.span`
  font-family: ${(props) =>
    props.sub ? "'Urbanist', sans-serif " : "Gilroy SemiBold"};
  font-size: ${(props) => (props.sub ? "14px" : "17px")};
  line-height: 1.3;
  color: #65667d;
  /* height: ${(props) => (props.sub ? "25px" : "")}; */
`;

export const StyledForm = styled(Form)`
  margin-top: 30px;
  margin-bottom: 30px;
`;

export const StyledRow = styled.div`
  display: flex;
  justify-content: ${(props) => (props.submit ? "flex-end" : "space-between")};
  height: auto;
  width: 100%;
  padding-bottom: 14px;
  & > .ant-form-item {
    width: 100%;
  }
`;

export const StyledColumn = styled.div`
  height: auto;
  width: calc(50% - 7px);
`;

export const SelectGenderDiv = styled.div`
  display: flex;
  justify-content: space-between;
  width: 345px;
  height: 50px;
  margin-top: 15px;
`;

export const GenderItem = styled.button`
  width: 165px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 0.5px solid #dde5ed;
  border-color: ${(props) => (props.selected ? "#4385C6" : "#dde5ed")};
  border-radius: 10px;
  background: none;
  cursor: pointer;
  color: ${(props) => (props.selected ? "#4385C6" : "#35364f")};
  font-size: 14px;
  font-family: "Urbanist", sans-serif;
`;

export const StyledSpin = styled(Spin)`
  position: absolute;
  z-index: 1;
  top: 35%;
  left: 40%;
`;

export const MapButton = styled.div`
  width: 200px;
  height: 67px;
  display: flex;
  align-items: center;
  padding-left: 10px;
`;

export const CheckBoxInfoContainer = styled.div`
  display: flex;
  gap: 12px;
  align-items: center;
  & input {
    width: 17px;
    height: 17px;
  }
  & span {
    font-size: 16px;
    font-weight: 400;
    color: #000000;
    font-family: Metropolis Regular;
  }
`;
