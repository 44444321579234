import { Skeleton, Menu } from "antd";
import styled from "styled-components";

export const PageTitle = styled.div`
  font-size: 25px;
  font-family: "Gilroy Semibold";
  color: #35364f;
`;

export const ViewDoctorsWrapper = styled.div`
  width: 100%;
  height: auto;
  padding: 35px 35px 35px 45px;
`;

export const ViewDoctorsContainer = styled.div`
  position: relative;
  width: 100%;
  height: auto;
  min-height: calc(100vh - 245px);
`;

export const DoctorCardContainer = styled.div`
  width: 100%;
  margin-top: 23px;
  border-radius: 10px;
  padding: 32px 20px;
  background-color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  
  @media only screen and (max-width: 900px) {
    padding: 30px 0px;
  }
`;

export const ViewMoreButton = styled.p`
  color: #4385C6;
  font-family: 'Urbanist', sans-serif;
  font-size: 16px;
  font-weight: 500;

  &:hover{
    cursor:pointer;
    opacity: 0.7;
  }

  @media only screen and (max-width:600px){
    font-size:14px;
    margin-top:5px;
  }
`;

export const DoctorBasicInfoContainer = styled.div`
  display: flex;
  width: 100%;
  height: ${(props) => (props.summary ? "88px" : props.type3 ? "70px" : "auto")};
  margin-top: ${(props) => (props.summary ? "20px" : "0")};
  @media only screen and (max-width: 600px) {
    flex-direction: column;
    align-items: center;
  }
`;

export const DoctorPhotoContainer = styled.div`
  height: ${(props) => (props.type3 ? "70px" : props.photo ? "63px" : "88px")};
  width: ${(props) => (props.type3 ? "70px" : props.photo ? "63px" : "88px")};
  border-radius: 50%;
  overflow: hidden;
  background-color: lightgray;

  & img {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }

  @media only screen and (max-width: 900px) {
    width: 50px;
    height: 50px;
  }
  @media only screen and (max-width: 600px) {
    width: 70px;
    height: 70px;
  }
`;

export const NameContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: ${(props) => (props.patient ? "space-evenly" : "flex-start")};
  padding-left: ${(props) => (props.type3 ? "2px" : "18px")};

  @media only screen and (max-width: 900px) {
    margin-right: 15px;
  }
  @media only screen and (max-width: 600px) {
    align-items: center;
    padding-left: 0;
    text-align: center;
  }
`;

export const DoctorName = styled.span`
  font-size: 20px;
  font-weight: 800;
  color: #03183c;
  text-transform: capitalize;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  @media only screen and (max-width: 900px) {
    font-size: 17px;
    max-width: ${({ profile }) => (profile ? "200px" : "70px")};
  }
  @media only screen and (max-width: 600px) {
    font-size: 18px;
    max-width: 100%;
  }
`;

export const DoctorExperience = styled.span`
  font-size: 16px;
  font-weight: 800;
  color: #6f6f6f;
  text-transform: capitalize;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  @media only screen and (max-width: 900px) {
    font-size: 17px;
    max-width: ${({ profile }) => (profile ? "200px" : "70px")};
  }
`;

export const RatingStar = styled.span`
  font-size: 16px;
  font-weight: 800;
  color: #03183c;
`;

export const DoctorSpecialisation = styled.div`
  font-size: 14px;
  font-weight: 500;
  color: #6f6f6f;
  text-transform: capitalize;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  @media only screen and (max-width: 600px) {
    font-size: 12px;
    width: 100%;
  }
`;

export const Star = styled.span`
  color: gold;
  font-size: 18px;
`;

export const CalendarTime = styled.div`
  padding-right: 3px;
  display: inline-block;
  flex: 1;
`;

export const DoctorRating = styled.div`
  font-size: ${(props) => (props.profile ? "18px" : "18px")};
  font-family: 'Urbanist', sans-serif;
  color: #898996;
  
  @media only screen and (max-width: 600px) {
    font-size: 16px;
  }
`;

export const DoctorAdvancedInfoContainer = styled.div`
  height: 100%;
  width: 345px;

  @media only screen and (max-width: 900px) {
    width: calc(100vw - 200px);
    margin-right: 15px;
  }
`;

export const DoctorStatsContainer = styled.div`
  height: ${(props) => (props.profile ? "40%" : "43px")};
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: ${(props) => (props.profile ? "flex-end" : "center")};
  padding-left: ${(props) => (props.profile ? "20px" : null)};

  @media only screen and (max-width: 900px) {
    margin-left: -100px;
    width: 40vw;
  }

  @media only screen and (max-width: 600px) {
    margin-left: -70px;
    width: 70vw;
  }
`;

export const DoctorTagsContainer = styled.div`
  height: 50%;
  width: 93%;
  display: flex;
  & > :not(:first-of-type) {
    margin-left: 10px;
  }
  align-items: flex-end;
  padding-left: ${(props) => (props.profile ? "20px" : null)};
`;

export const Divider = styled.div`
  height: 61px;
  width: 1px;
  background: #ededed;
  border-radius: 10px;
`;

export const DoctorStat = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
`;

export const StatName = styled.div`
  font-size: 16px;
  font-family: 'Urbanist', sans-serif;
  color: #03183C;

  @media only screen and (max-width: 600px) {
    font-size: 12px;
  }
`;

export const StatValue = styled.div`
  font-size: 16px;
  font-family: 'Urbanist', sans-serif;
  font-weight: 500;
  color: #4385C6;

  @media only screen and (max-width: 600px) {
    font-size: 12px;
  }
`;

export const DoctorTag = styled.div`
  padding: 5px 15px;
  background-color: #e6effc;
  color: #4385C6;
  font-size: 12px;
  border-radius: 5px;
`;

export const CardActionContainer = styled.div`
  height: 100%;
  @media only screen and (min-width: 1600px) {
    width: 300px;
  }
  /* outline: 0.5px dotted grey; */
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const CustomButton = styled.button`
  border: none;
  height:${(props) => props.fab ? "44px" : props.color ? "44px" : "40px"};
  width: ${(props) => props.fab ? "171px" : props.color ? "171px" : "144px"};
  color: ${(props) => props.fab ? "#4385C6" : "#fff"};
  background-color: ${(props) =>
    props.disabled ? "grey" : props.color ? "#4385C6" : props.fab ? "#fff" : "#4385C6"};
  border-radius: 10px;
  margin-right: ${({ fab }) => fab ? "20px" : "0px"};
  font-size: ${({ fab }) => fab ? "16px" : "14px"};
  font-family: 'Urbanist', sans-serif;
  font-weight: ${({ fab }) => fab ? 500 : 400};
  border: ${(props) => props.fab ? "1px solid #4385C6" : "0"};
  cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};
  &:hover {
    background-color: ${(props) =>
    props.disabled ? "grey" : props.color ? "#4385C6" : props.fab ? "#fff" : "#EB9F23"};
  
    color:${(props) =>
    props.color ? "#fff" : props.fab ? "#4385C6" : "#fff"};
  }

  @media only screen and (max-width: 900px) {
    width: 100px;
    font-size: 12px;
    padding:10px;
  }

  @media only screen and (max-width: 600px) {
    height: 35px;
    font-size: 10px;
    margin-right: ${({ fab }) => fab ? "10px" : "0px"};
  }
`;

export const FilterTabContainer = styled.div`
  height: 55px;
  width: 100%;
  margin-top: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  overflow-x: scroll;
  overflow-y: hidden;

  &::-webkit-scrollbar {
    width: 3px !important;
    height: 3px !important;
  }
`;

export const SearchContainer = styled.div`
  height: 100%;
  width: calc(100% - 800px - 20px - 100px);
  display: flex;
  align-items: center;
  background-color: white;
  border-radius: 10px;

  & .ant-select {
    height: 100%;
    width: 100%;
    font-size: 16px;
    font-size: 16px;
    font-family: 'Urbanist', sans-serif;
    color: #35364f;
    & .ant-select-selector {
      height: 100%;
      width: 100%;
      border: none;
      border-radius: 10px;
      box-shadow: none !important;
      & .ant-select-selection-search-input {
        height: 100%;
      }
      & .ant-select-selection-placeholder,
      & .ant-select-selection-item {
        vertical-align: middle;
        line-height: 58px;
      }
    }
  }
  @media only screen and (min-width: 1500px) {
    width: calc(100% - 650px - 30px - 100px);
  }
  @media only screen and (min-width: 1800px) {
    width: calc(100% - 800px - 30px - 100px);
  }
`;

export const DropdownContainer = styled.div`
  height: 100%;
  width: 800px;
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  column-gap: 10px;
  cursor: pointer;
  font-size: 16px;
  font-family: 'Urbanist', sans-serif;
  color: #35364f;
  & .ant-select {
    height: 100%;
    width: 100%;
    font-size: 16px;
    background: #fff;
    border-radius: 10px;

    & .ant-select-selector {
      height: 100%;
      width: 100%;
      & .ant-select-selection-search-input {
        height: 100%;
      }
      & .ant-select-selection-placeholder,
      & .ant-select-selection-item {
        vertical-align: middle;
        line-height: 55px;
        text-overflow: ellipsis;
        white-space: nowrap;
        width: 100px;
      }
    }
  }
  @media only screen and (min-width: 1500px) {
    width: 650px;
  }
  @media only screen and (min-width: 1800px) {
    width: 800px;
  }
`;

export const PaginationContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 30px;
  & .ant-pagination-item-link,
  & .ant-pagination-item {
    border-radius: 10px;
    & a {
      font-family: 'Urbanist', sans-serif;
      font-size: 14px;
    }
  }
`;

export const ApplyButton = styled.button`
  border: none;
  background-color: #4385C6;
  height: 100%;
  width: 100px;
  color: white;
  border-radius: 10px;
  font-size: 16px;
  font-family: 'Urbanist', sans-serif;
  cursor: pointer;
  transition: 0.3s;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.04), 0px 4px 8px rgba(0, 0, 0, 0.06);
  &:hover {
    background-color: #fff;
    color:#4385C6;
    border: 1px solid #4385C6;
  }

  @media only screen and (max-width: 600px) {
    height: fit-content;
    font-size: 12px;
    padding: 10px 15px;
  }
`;

export const DoctorCardSkeleton = styled(Skeleton)`
  height: ${(props) => (props.myDoctors ? "231px" : "152px")};
  margin-top: 23px;
  border-radius: 10px;
  background-color: white;
  & .ant-skeleton-header {
    position: relative;
    width: 20%;
    & .ant-skeleton-avatar-circle {
      position: absolute;
      top: 50%;
      left: 50%;
      height: 90px;
      width: 90px;
      transform: translate(-50%, -50%);
    }
  }
`;

export const EmptyResultMessage = styled.span`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-family: 'Urbanist', sans-serif;
  font-weight:500;
  font-size: 24px;
  color: #c4c4c4;
`;

export const StyledMenu = styled(Menu)`
  width: 160px;
  font-size: 16px;
  font-family: 'Urbanist', sans-serif;
  color: #35364f;
  @media only screen and (min-width: 1500px) {
    width: 208px;
  }
  @media only screen and (min-width: 1800px) {
    width: 256px;
  }
`;
