import React from "react";
import ResetPasswordForm from "./ResetPasswordForm";
import { Main } from "../UpdateProfilePatient/styledComponents";

const ResetPassword = ({ accountVerification }) => {
  return (
    <Main login={true}>
      <ResetPasswordForm accountVerification={accountVerification} />
    </Main>
  );
};

export default ResetPassword;
