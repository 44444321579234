import React from 'react';
import ReactDOM from 'react-dom';
import styled from 'styled-components';

const BackdropWrap = styled.div`
  position: absolute;
  inset: 0;
  background-color: #000;
  opacity: 0.5;
  z-index: 400;
`;

const Backdrop = ({ onClick }) => (
  ReactDOM.createPortal(<BackdropWrap onClick={() => onClick() ?? {}} />, document.getElementById('modal-root'))
);

export default Backdrop;