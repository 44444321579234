import React from 'react'
import { Button } from 'antd'
import styled from 'styled-components'

const PrimaryButton = ({
  onClick,
  label,
  block = false,
  htmlType,
  size,
  loading,
  disabled
}) => {
  const Wrapper = styled.div`
    .ant-btn {
      color: #fff;
      border: none;
      border-radius: 8px;
      min-height: 40px;
      font-size: 13px;
      min-width: 75px;
      font-weight:bold;
      font-family:Montserrat;
    }
    .ant-typography {
      color: #ffffff;
    }
  `
  return (
    <Wrapper>
      <Button
        onClick={onClick}
        block={block}
        htmlType={htmlType}
        loading={loading}
        disabled={disabled}
        size='large'
        type="primary"
      >
       {label}
      </Button>
    </Wrapper>
  )
}
export default PrimaryButton
