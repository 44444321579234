import React from "react";
import {
  DayCardsContainer,
  DayCard,
  DayName,
  ToggleContainer,
  InitialDiv,
  SetTimeDiv,
  BreakDiv,
  AddButton,
  ButtonName,
  WorkingHourDiv,
  ListBreakTime,
  AddNewButton,
} from "./styledComponents";
import SelectTimeElement from "./SelectTimeElement";
import { Switch, Space, DatePicker, Divider } from "antd";
import moment from "moment";
import { PlusCircleOutlined } from "@ant-design/icons";

const SpecialSlots = ({
  item,
  setDateTime,
  showTimePicker,
  special,
  onToggle,
  addNew,
}) => {
  return (
    <>
      <DayCardsContainer>
        <DayCard key={item.name} collapse={item.open}>
          <InitialDiv>
            <DayName>{special ? "Special Time" : "Off Time"}</DayName>
            <ToggleContainer>
              <Switch
                size="small"
                checked={item.open}
                onChange={(state) => onToggle(state, special)}
              />
            </ToggleContainer>
          </InitialDiv>
          <SetTimeDiv collapse={item.open}>
            {item.slot &&
              item.slot.map((day, dayPosition) => (
                <>
                  <WorkingHourDiv>
                    {day.date ? (
                      <Space direction="vertical" style={{ cursor: "pointer" }}>
                        <DatePicker
                          format={"DD/MM/YYYY"}
                          inputReadOnly={true}
                          allowClear={false}
                          suffixIcon={<></>}
                          className="date-select"
                          value={moment(day.date)}
                          disabledDate={function disabledDate(current) {
                            return (
                              current &&
                              current.valueOf() <
                                (special
                                  ? moment().add(1, "day").startOf("day")
                                  : moment().startOf("day"))
                            );
                          }}
                          onChange={(val) =>
                            setDateTime(
                              special,
                              item,
                              false,
                              false,
                              false,
                              val,
                              dayPosition
                            )
                          }
                        />
                      </Space>
                    ) : (
                      <AddButton
                        visible={item.open}
                        onClick={() =>
                          showTimePicker(true, special, false, dayPosition)
                        }
                      >
                        <PlusCircleOutlined />
                        <ButtonName>Add Date</ButtonName>
                      </AddButton>
                    )}
                    {/* {day.date && (
                      <DeleteTime
                        onClick={() => addNew(special, true, dayPosition)}
                      >
                        <CloseOutlined />
                      </DeleteTime>
                    )} */}
                  </WorkingHourDiv>

                  <BreakDiv>
                    <ListBreakTime>
                      {day.timeArray.length > 0 &&
                        day.timeArray.map((time, timePosition) => (
                          <SelectTimeElement
                            visible={item.open}
                            startTime={time?.startTime}
                            endTime={time?.endTime}
                            item={item}
                            workingHours={special}
                            setTime={setDateTime}
                            position={timePosition}
                            day={dayPosition}
                            showTimePicker={showTimePicker}
                          />
                        ))}
                    </ListBreakTime>

                    <AddButton
                      visible={item.open}
                      onClick={() =>
                        showTimePicker(false, special, false, dayPosition)
                      }
                    >
                      <PlusCircleOutlined />
                      <ButtonName>Add Time</ButtonName>
                    </AddButton>
                  </BreakDiv>
                  {dayPosition !== item.slot.length - 1 && <Divider />}
                </>
              ))}
          </SetTimeDiv>
          {item.open && item.slot[0].timeArray.length > 0 && (
            <AddNewButton onClick={() => addNew(special)}>
              {" "}
              + Add New
            </AddNewButton>
          )}
        </DayCard>
      </DayCardsContainer>
    </>
  );
};

export default SpecialSlots;
