import React, { useState, useEffect } from "react";
import { Form, Input, message, Spin } from "antd";
import { StyledRow, StyledColumn } from "../BasicProfile/styledComponents";
import { StyledFormItem } from "../../signup/styledComponents";
import {
  FormContainer,
  ButtonContainer,
  StyledButton,
  SpinContainer,
} from "../EducationExperience/styledComponents";
import ApiCalls from "../../../apis/ApiCalls";

const AddCertification = ({
  setAddNew,
  fetchWorkProfile,
  selectedItem,
  setEditItem,
}) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  const onFinish = (val) => {
    let data = { certificateDetails: { ...val } };
    if (selectedItem) data.certificateDetails._id = selectedItem._id;
    setLoading(true);
    ApiCalls.updateDoctorExtraInfo(data)
      .then((response) => {
        if (response.data.status) {
          message.success(
            `${selectedItem ? "Edited succesfully" : "Added successfully"}`
          );
          fetchWorkProfile(setLoading, setAddNew, setEditItem);
        } else message.error(response.data.data.message);
      })
      .catch((error) => {
        message.error(
          error.response
            ? error?.response?.data?.data?.message
            : "No Response From Server",
          5
        );
      });
  };

  useEffect(() => {
    selectedItem && form.setFieldsValue(selectedItem);
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <div style={{ width: "100%", height: "15px" }} />
      <FormContainer>
        {loading ? (
          <SpinContainer>
            <Spin spinning={true} />
          </SpinContainer>
        ) : (
          <Form form={form} onFinish={onFinish}>
            <StyledRow>
              <StyledColumn>
                <StyledFormItem
                  name="certificates"
                  label="Certification"
                  rules={[
                    {
                      required: true,
                      message: "Certification name required",
                    },
                  ]}
                  type2={true}
                >
                  <Input
                    placeholder="Enter certification name"
                    autoFocus={true}
                  />
                </StyledFormItem>
              </StyledColumn>
              <StyledColumn>
                <StyledFormItem
                  name="year"
                  label="Year"
                  rules={[
                    {
                      required: true,
                      message: "Year is required",
                    },
                  ]}
                  type2={true}
                >
                  <Input placeholder="Enter year" />
                </StyledFormItem>
              </StyledColumn>
            </StyledRow>
            <StyledRow submit={true}>
              <ButtonContainer>
                <StyledButton
                  onClick={() => {
                    selectedItem ? setEditItem(null) : setAddNew(false);
                  }}
                >
                  Cancel
                </StyledButton>
                <StyledButton htmlType={true} type="primary">
                  Add
                </StyledButton>
              </ButtonContainer>
            </StyledRow>
          </Form>
        )}
      </FormContainer>
    </>
  );
};

export default AddCertification;
