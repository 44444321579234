import React, { useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Checkbox from "@mui/material/Checkbox";
import { message } from "antd";
import ApiCalls from "../../../apis/ApiCalls";
import { CustomButton } from "./styledComponent";
import ImageNotAvailable from "../../../assets/images/CorporateModule/Image_not_available.png";
import { PlusOutlined } from "@ant-design/icons";
import { ProceedButton } from "./styledComponent";
import { useHistory } from "react-router-dom";
import storage from '../../../storage';

function DoctorListDataTable({ data, setData, edit, setEdit, getDoctor }) {
  const [checkedList, setCheckedList] = useState([]);
  const history = useHistory();
  let companyProfile = storage.user.getItem();

  const onCheckboxSelectionChange = (e, item) => {
    let temp = [...data];
    let tempCheckList = [];
    for (let i = 0; i < temp.length; i++) {
      if (temp[i]._id === item._id) {
        if (temp[i].selected === false) {
          tempCheckList.push(temp[i]._id);
        }
        temp[i].selected = !temp[i].selected;
      } else if (temp[i].selected) {
        tempCheckList.push(temp[i]._id);
      }
    }
    setData(temp);
    setCheckedList(tempCheckList);
  };

  const deleteDoctor = () => {
    if (checkedList.length > 0) {
      let companyId = localStorage.getItem("companyId");
      data.forEach((item, index) => {
        if (item.selected) {
          let providerId = item?._id;
          ApiCalls.deleteSelectedDoctors(companyId, providerId)
            .then((response) => {
              if (response.data.status) {
                setCheckedList([]);
                message.success("Succesfully deleted Item");
              }
            })
            .catch((error) => {
              message.error(
                error.response
                  ? error?.response?.data?.data?.message
                  : "No Response From Server",
                5
              );
            });
        }
        if (index === data.length - 1) {
          setTimeout(() => {
            getDoctor(false);
            setEdit(false);
          }, 1000);
        }
      });
    }
  };

  const cancelOpreation = () => {
    let temp = [...data];
    for (let i = 0; i < temp.length; i++) {
      temp[i].selected = false;
    }
    setData(temp);
    setEdit(false);
    setCheckedList([]);
  };

  return (
    <div>
      {data.length > 0 ? (
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell />
                <TableCell>Name</TableCell>
                <TableCell align="right">Expertise</TableCell>
                <TableCell align="right">Rating</TableCell>
                <TableCell align="right">Consultation Fees</TableCell>
                {edit && <TableCell align="right">Selection</TableCell>}
              </TableRow>
            </TableHead>

            <TableBody>
              {data.map((item) => (
                <TableRow
                  key={item._id}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  style={item.selected ? { backgroundColor: "#ECECEC" } : {}}
                >
                  <TableCell>
                    <img
                      alt="No img found"
                      src={item.profilePic}
                      height="25px"
                      width="25px"
                      style={{ borderRadius: "50%" }}
                      onError={(e) => {
                        if (e.target.src !== ImageNotAvailable) {
                          e.target.src = ImageNotAvailable;
                        }
                      }}
                    />
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {item.fullName}
                  </TableCell>
                  <TableCell align="right">{item.consultationType}</TableCell>
                  <TableCell align="right">{item.rating}</TableCell>
                  <TableCell align="right">₹{item.consultationFee}</TableCell>
                  {edit && (
                    <TableCell align="right">
                      <Checkbox
                        checked={item.selected}
                        onChange={(e) => onCheckboxSelectionChange(e, item)}
                      />
                    </TableCell>
                  )}
                </TableRow>
              ))}{" "}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <p
          style={{
            flex: 1,
            justifyContent: "center",
            alignItem: "center",
            textAlign: "center",
            padding: "50px",
            fontSize: "20px",
            fontWeight: "500",
            color: "#03183C",
          }}
        >
          No therapists Found!
        </p>
      )}

      {edit ? (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-end",
          }}
        >
          <CustomButton cancel={true} onClick={cancelOpreation}>
            Cancel
          </CustomButton>
          <CustomButton
            delete={true}
            disabled={checkedList.length === 0}
            onClick={deleteDoctor}
          >
            Remove
          </CustomButton>
        </div>
      ) : (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-end",
            margin: "20px",
          }}
        >
          {data.length < companyProfile.subscriptionDetails.doctorCount ? (
            <ProceedButton
              add={true}
              onClick={() =>
                history.push("/user/base/home/corporate/dashboard/add-doctor")
              }
            >
              <PlusOutlined /> Add Therapists
            </ProceedButton>
          ) : null}
          {data.length > 0 ? (
            <ProceedButton onClick={() => setEdit(true)}>
              Edit Therapists
            </ProceedButton>
          ) : null}
        </div>
      )}
    </div>
  );
}

export default DoctorListDataTable;
