import styled from "styled-components";

export const SectionHeading = styled.p`
  font-family: Metropolis Regular;
  font-size:  ${(props) => (props.mainHead ? "24px" : "17px")};
  color: ${(props) => (props.mainHead ? "#03183C" : "#4385C6")};
`;

export const Description = styled.p`
  font-family: Metropolis Regular;
  font-size: 15px;
  color:  #6F6F6F;
`;

export const ListItem = styled.li`
  font-family: Metropolis Regular;
  font-size: 15px;
`;

export const TermsAndConditions = styled.a`
  font-family: Metropolis Regular;
`;

export const Wrappers = styled.div`
`;