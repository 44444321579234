import { createGlobalStyle } from "styled-components";
import GilroyRegularWoff from "./assets/fonts/Gilroy-Regular.woff";
import GilroyMediumWoff from "./assets/fonts/Gilroy-Medium.woff";
import GilroySemiBoldWoff from "./assets/fonts/Gilroy-SemiBold.woff";
import GilroyBoldWoff from "./assets/fonts/Gilroy-Bold.woff";
import MetropolisBlack from "./assets/fonts/./FontNew/Fonts/ChrisSimpson-Metropolis/Metropolis-Black.otf";
import MetropolisBold from "./assets/fonts/./FontNew/Fonts/ChrisSimpson-Metropolis/Metropolis-Bold.otf";
import MetropolisRegular from "./assets/fonts/./FontNew/Fonts/ChrisSimpson-Metropolis/Metropolis-Regular.otf";

import MetropolisExtraBold from "./assets/fonts/./FontNew/Fonts/ChrisSimpson-Metropolis/Metropolis-ExtraBold.otf";

const GlobalStyle = createGlobalStyle`
  input[type=text]:focus {
    outline: none !important;
    outline-offset: 2px !important; 
  }

  input[type=text]{
    outline: none !important;
  }

  @font-face {
    font-family: 'Metropolis ExtraBold';
    src: local('Metropolis ExtraBold'), url(${MetropolisExtraBold}) format('opentype');   
  }

  // Metropolis
  @font-face {
    font-family: 'Metropolis Regular';
    src: local('Metropolis Regular'), url(${MetropolisRegular}) format('opentype');   
  }


  // Nexa Bold
  @font-face {
    font-family: 'Metropolis Bold';
    src: local('Metropolis Bold'), url(${MetropolisBold}) format('opentype');   
  }

  // NexaBlack
  @font-face {
    font-family: 'Metropolis Black';
    src: local('Metropolis Black'), url(${MetropolisBlack}) format('opentype');   
  }

  @font-face {
      font-family: 'Gilroy Regular';
      src: local('Gilroy Regular'), url(${GilroyRegularWoff}) format('woff');   
    }

  @font-face {
      font-family: 'Gilroy Medium';
      src: local('Gilroy Medium'), url(${GilroyMediumWoff}) format('woff');     
    }
  @font-face {
      font-family: 'Gilroy SemiBold';
      src: local('Gilroy SemiBold'), url(${GilroySemiBoldWoff}) format('woff');    
    }
  
  @font-face {
      font-family: 'Gilroy Bold';
      src: local('Gilroy Bold'), url(${GilroyBoldWoff}) format('woff');
    }

  .ant-picker-footer {
    display: none ;
  }

  .ant-picker-time-panel-column::after{
    height: 0;
  }

  .ant-dropdown-menu{
    position: absolute;
    right: -21px;
    max-height: 250px;
    overflow: auto;
    font-family: "Gilroy Medium"   ;
    & > li > a {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  div > .anticon-loading, main > .anticon-loading{
    color: #4385C6;
  }

  .ant-popover-inner{
    border-radius: 10px !important;
  }

  .ant-popover-inner-content{
    padding: 12px 0 !important;
  }

  .app-action-dropdown{
    top: 52px !important;
    left: calc(100vw - 290px)  !important;
  }

`;
export default GlobalStyle;
