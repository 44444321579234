import React from 'react';
import {
  NewMessageNotification,
  UserPFP,
  SenderName,
  Chatinfo,
  ChatMsg,
  CloseMsgNotification
} from './styledComponents';
import { CloseOutlined } from '@ant-design/icons';
import { useHistory } from 'react-router';
import ImageNotAvailable from '../../assets/images/CorporateModule/Image_not_available.png';

const MessageNotification = ({ message, setMessageStack, setMinimizeWindow }) => {
  const history = useHistory();

  return (
    <>
      <NewMessageNotification
        onClick={() => {
          setMinimizeWindow(true);
          setMessageStack(null);
          history.push('/user/base/home/messages');
        }}
      >
        <UserPFP>
          <img
            src={message.profilePic}
            alt='No img found'
            onError={(e) => {
              if (e.target.src !== ImageNotAvailable) {
                e.target.src = ImageNotAvailable;
              }
            }}
          />
        </UserPFP>
        <Chatinfo>
          <SenderName>{message.fullName}</SenderName>
          <ChatMsg>
            {message.chatType !== 'text'
              ? message.chatType
              : message.msg.length > 60
              ? message.msg.slice(0, 60) + '...'
              : message.msg}
          </ChatMsg>
        </Chatinfo>
        <CloseMsgNotification
          onClick={(e) => {
            e.stopPropagation();
            setMessageStack(null);
          }}
          title='Clear Notification'
        >
          <CloseOutlined />
        </CloseMsgNotification>
      </NewMessageNotification>
    </>
  );
};

export default MessageNotification;
