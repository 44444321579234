import React, { useState, useEffect, useRef } from 'react';
import {
  PaymentHistoryContainer,
  Heading,
  HeaderWrapper,
  PaymentHistoryWrapper,
  EditButton,
  RowWrapper,
  WrapDetails,
  Wrap,
  Text2,
  Item,
  Text
} from '../styledComponent';
import { useHistory } from 'react-router-dom';
import { Divider, message } from 'antd';
import moment from 'moment';
import mdi_Subscription from '../../../../assets/images/CorporateModule/PaymentHistory/mdi_subscriptions.png';
import starFourPoint_Icon from '../../../../assets/images/CorporateModule/PaymentHistory/star-four-points.png';
import ImageNotAvailable from '../../../../assets/images/CorporateModule/Image_not_available.png';
import ApiCalls from '../../../../apis/ApiCalls';
import InfiniteScroll from 'react-infinite-scroller';
import { LoadingOutlined } from '@ant-design/icons';

const PaymentHistory = () => {
  const [loading, setLoading] = useState(false);
  const [companiesPaymentDetails, setCompaniesPaymentDetails] = useState([]);
  const [hasMore, setHasMore] = useState(false);
  const [scrollLoading, setScrollLoading] = useState(false);
  const parentRef = useRef();
  const history = useHistory();

  const getAllPaymentHistory = (page) => {
    if (page === 0) setLoading(true);
    setScrollLoading(true);
    let params = { page: page, limit: 10 };
    ApiCalls.fetchAllCompaniesPaymentDetails(params)
      .then((response) => {
        if (response.data.status) {
          let res = response.data.data.paymentDetails;
          if (page === 0) {
            setCompaniesPaymentDetails(res);
          } else {
            setCompaniesPaymentDetails(companiesPaymentDetails.concat(res));
          }
          setHasMore(response.data.data.count > companiesPaymentDetails.length);
        }
        setScrollLoading(false);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        setScrollLoading(false);
        message.error(
          error.response ? error?.response?.data?.data?.message : 'No Response From Server',
          5
        );
      });
  };

  useEffect(() => {
    getAllPaymentHistory(0);
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <PaymentHistoryContainer ref={parentRef}>
        <HeaderWrapper>
          <Heading>Payment history</Heading>
          <EditButton
            onClick={() => history.push('/user/base/home/corporate/profile/edit-profile')}
          >
            Edit Profile
          </EditButton>
        </HeaderWrapper>
        <>
          {loading ? (
            <LoadingOutlined />
          ) : (
            <PaymentHistoryWrapper>
              <InfiniteScroll
                initialLoad={false}
                pageStart={0}
                loadMore={getAllPaymentHistory}
                hasMore={!scrollLoading && hasMore}
                useWindow={false}
                getScrollParent={() => parentRef.current}
              >
                {companiesPaymentDetails?.length > 0 &&
                  companiesPaymentDetails.map((item) => {
                    return (
                      <RowWrapper>
                        <WrapDetails>
                          <Wrap>
                            {item.transactionType === 'creditPurchase' ? (
                              <img
                                src={starFourPoint_Icon}
                                alt='No img'
                                height='25px'
                                width='25px'
                                onError={(e) => {
                                  if (e.target.src !== ImageNotAvailable) {
                                    e.target.src = ImageNotAvailable;
                                  }
                                }}
                              />
                            ) : (
                              <img
                                src={mdi_Subscription}
                                alt='No img'
                                height='25px'
                                width='25px'
                                onError={(e) => {
                                  if (e.target.src !== ImageNotAvailable) {
                                    e.target.src = ImageNotAvailable;
                                  }
                                }}
                              />
                            )}

                            {item.transactionType === 'creditPurchase' ? (
                              <Text> Credits added successfully </Text>
                            ) : (
                              <Text> {item.subscriptionName} Subscription Purchased </Text>
                            )}
                          </Wrap>
                          <Wrap>
                            <Text style={{ fontWeight: 800 }}>₹{item.companyPaidAmount}</Text>
                          </Wrap>
                        </WrapDetails>
                        <Text2 sub={true}>
                          {moment(item.paymentDate).format('Do MMMM YYYY - hh:mm A')}
                        </Text2>
                        <Divider />
                      </RowWrapper>
                    );
                  })}
                {scrollLoading && (
                  <Item loading={true}>
                    <LoadingOutlined />
                  </Item>
                )}
              </InfiniteScroll>
            </PaymentHistoryWrapper>
          )}
        </>
      </PaymentHistoryContainer>
    </>
  );
};

export default PaymentHistory;