import React from "react";

const PatientTherapistSelector = ({
  loginType,
  onClickPatient,
  onClickTherapist,
}) => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div
        style={{
          display: "flex",
          width: "199px",
          height: "44px",
          justifyContent: "center",
          alignItems: "center",
          borderBottom: loginType === "patient" ? "4px solid #3768B0" : "none",
        }}
        onClick={onClickPatient}
        onMouseOver={(e) => (e.target.style.cursor = "pointer")}
      >
        <span
          style={{
            fontSize: "20px",
            fontWeight: "700",
            color: "#2C509F",
          }}
        >
          Patient
        </span>
      </div>

      <div
        style={{
          display: "flex",
          width: "199px",
          height: "44px",
          justifyContent: "center",
          alignItems: "center",
          borderBottom: loginType === "doctor" ? "4px solid #74ABE8" : "none",
        }}
        onClick={onClickTherapist}
        onMouseOver={(e) => (e.target.style.cursor = "pointer")}
      >
        <span
          style={{
            fontSize: "20px",
            fontWeight: "700",
            color: "#74ABE8",
          }}
        >
          Therapist
        </span>
      </div>
    </div>
  );
};

export default PatientTherapistSelector;
