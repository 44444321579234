import React from "react";
import {
  DayCardsContainer,
  DayCard,
  DayName,
  ToggleContainer,
  InitialDiv,
  SetTimeDiv,
  AddButton,
  ButtonName,
  WorkingHourDiv,
  BreakDiv,
  ListBreakTime,
} from "./styledComponents";
import SelectTimeElement from "./SelectTimeElement";
import { Switch } from "antd";
import { PlusCircleOutlined } from "@ant-design/icons";

const GeneralAvailabity = ({ daysList, setTime, showTimePicker, onToggle }) => {
  return (
    <>
      <DayCardsContainer>
        {daysList.map((item) => (
          <DayCard key={item.day} collapse={item.open}>
            <InitialDiv>
              <DayName>{item.day}</DayName>
              <ToggleContainer>
                <Switch
                  size="small"
                  checked={item.open}
                  onChange={(state) => onToggle(state, item)}
                />
              </ToggleContainer>
            </InitialDiv>
            <SetTimeDiv collapse={item.open}>
              <WorkingHourDiv>
                {item.startTime ? (
                  <SelectTimeElement
                    visible={item.open}
                    startTime={item.startTime}
                    endTime={item.endTime}
                    item={item}
                    workingHours={true}
                    setTime={setTime}
                  />
                ) : (
                  <AddButton
                    visible={item.open}
                    onClick={() => showTimePicker(item, true)}
                  >
                    <PlusCircleOutlined />
                    <ButtonName>Add Working Hours</ButtonName>
                  </AddButton>
                )}
              </WorkingHourDiv>
              <BreakDiv>
                {item.addBreak.length > 0 && (
                  <ListBreakTime>
                    {item.addBreak.map((breakTime, index) => (
                      <SelectTimeElement
                        visible={item.open}
                        startTime={breakTime.startTime}
                        endTime={breakTime.endTime}
                        item={item}
                        workingHours={false}
                        setTime={setTime}
                        position={index}
                        key={index}
                        showTimePicker={showTimePicker}
                      />
                    ))}
                  </ListBreakTime>
                )}

                <AddButton
                  visible={item.open}
                  onClick={() => showTimePicker(item)}
                >
                  <PlusCircleOutlined />
                  <ButtonName>Add Break</ButtonName>
                </AddButton>
              </BreakDiv>
            </SetTimeDiv>
          </DayCard>
        ))}
      </DayCardsContainer>
    </>
  );
};

export default GeneralAvailabity;
