import React from "react";

const SignInUsingOtp = ({ setLoginUsingOtp, setLoginUsingPassword }) => {
  return (
    <button
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        padding: "18px 10px",
        border: "none",
        backgroundColor: "#FFFFFF",
      }}
      onMouseOver={(e) => (e.target.style.cursor = "pointer")}
      onClick={() => {
        setLoginUsingPassword(false);
        setLoginUsingOtp(true);
      }}
    >
      <span
        style={{
          fontSize: "16px",
          fontWeight: "700",
          color: "#2C509F",
        }}
      >
        Sign in using OTP or Google
      </span>
    </button>
  );
};

export default SignInUsingOtp;
