import styled from "styled-components";

export const SurveyFormContainer = styled.div`

`;

export const FormContainer = styled.div`
  padding: 30px;
`;

export const SurveyFormHeader = styled.div`
  padding:30px;
  width: 100%;
  height: 120px;
  left: 288px;
  top: 84px;
  background: #FFFFFF;
  box-shadow: 0px 31px 12px rgba(107, 107, 107, 0.01), 0px 18px 11px rgba(107, 107, 107, 0.05), 0px 8px 8px rgba(107, 107, 107, 0.09), 0px 2px 4px rgba(107, 107, 107, 0.1), 0px 0px 0px rgba(107, 107, 107, 0.1);
`;
export const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items:flex-start;
  .ant-btn {
    border: none;
    border-radius: 6px;
    height: 40px;
    width: 150px;
    background: #32AFBF;
    color: #fff;
    margin-left: 10px;
    margin-right: 10px;
  }
`;
export const Heading = styled.h3`
  font-family: 'Urbanist';
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 150%;
  color: #03183C;
`;

export const Image = styled.div`
  background: #D9D9D9;
  margin-right: 10px;
  border-radius: 2px;
  padding: 5px;
  width: 30px;
  height: 30px;
  & img{
    color: #6F6F6F;
  }
`;

export const SurveyFormWrapper = styled.div`
  display: grid;
  grid-row-start: 1;
  grid-row-end: 3;
`;

export const SurveyFormBox = styled.div`
  display: flex;
  flex-direction: row;
  background: #FFFFFF;
  box-shadow: 0px 37px 15px rgba(107, 107, 107, 0.01), 0px 21px 12px rgba(107, 107, 107, 0.05), 0px 9px 9px rgba(107, 107, 107, 0.09), 0px 2px 5px rgba(107, 107, 107, 0.1), 0px 0px 0px rgba(107, 107, 107, 0.1);
  border-radius: 5px;
  height:220px;
  width:520px;
  margin: 30px;
`;

export const SurveyFormBoxSecond = styled.div`
  display: flex;
  flex-direction: column;
  background: #FFFFFF;
  box-shadow: 0px 37px 15px rgba(107, 107, 107, 0.01), 0px 21px 12px rgba(107, 107, 107, 0.05), 0px 9px 9px rgba(107, 107, 107, 0.09), 0px 2px 5px rgba(107, 107, 107, 0.1), 0px 0px 0px rgba(107, 107, 107, 0.1);
  border-radius: 5px;
  height:220px;
  width:80%;
  margin: 30px;
  padding: 20px;
`;

export const NumberText = styled.p`
  font-family: 'Urbanist';
  font-style: normal;
  font-weight: 600;
  font-size: 48px;
  line-height: 150%;
  color: #13140F;
  height: 200px;
  width: 150px;
  padding-top: 70px;
  text-align: center;
`;
export const Divider = styled.div`
  width:1px;
  height: 190px;
  background: lightgray;
  margin: 15px;
`;
export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px;
`;
export const TextHeading = styled.div`
  font-family: 'Urbanist';
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 150%;
  color: #13140F;
  margin-bottom: 10px;
`;
export const SubText = styled.p`
  font-family: 'Urbanist';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 150%;
  color: #13140F;
  width: 100%;
`;
export const Text = styled.p`
  font-family: 'Urbanist';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 150%;
  color: #6F6F6F;
`;
