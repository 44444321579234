const healthRecordDefaultState = {}

export default (state = healthRecordDefaultState, action) => {
    switch (action.type) {
        case "SET_HEALTH_RECORD":
            return ({
                ...state,
                ...action.healthRecord
            });
        case "REMOVE_HEALTH_RECORD":
            return ({
                ...state,
                healthRecord: {}
            });
        case "SET_MEDICAL_REPORTS":
            return ({
                ...state,
                medicalReports: action.medicalReports
            })
        default:
            return state
    }
}