const lookupsReducerDefaultState = {
    cities: [],
    credentials: []
}

export default (state = lookupsReducerDefaultState, action) =>{
    switch(action.type){
        case 'SET_CITIES': 
            return {
                ...state,
                cities:action.cities.data.city
            };
        case 'SET_CREDENTIALS':
            return {
                ...state,
                credentials:action.credentials
            }
        default:
            return state
    }
}