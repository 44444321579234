
export default (state = [], action) => {
    switch (action.type) {
        case "ADD_MESSAGE":
            return (state.concat(action.message))



        case "CLEAR_MESSAGES":
            return ([])
        default:
            return state
    }
}