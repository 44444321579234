import React, { useState, useEffect } from "react";
import {
  UpdateDetailsWrapper,
  UpdateDetailsLayout,
  PageNavigation,
  PageUpdateDetailsContainer,
  NavigateButtonsWrapper,
  NavigateButtonContainer,
  StepContainer,
} from "./styledComponents";
import PrimaryButton from "../../components/PrimaryButton/index";
import BasicProfile from "./BasicProfile";
import EducationExperience from "./EducationExperience";
import ServicesSpecialities from "./ServicesSpecialities";
import LicenseCertification from "./LicenseCertification";
import Availability from "./Availability";
import Pricing from "./Pricing";
import BankDetails from "./BankDetails";
import ApiCalls from "../../apis/ApiCalls";
import { message, Steps } from "antd";

const UpdateDetails = ({
  currentPage,
  setCurrentPage,
  setLastCompletedPage,
  edit,
}) => {
  const [certificateDetails, setCertificateDetails] = useState([]);
  const [educationalDetails, setEducationalDetails] = useState([]);
  const [experienceDetails, setExperienceDetails] = useState([]);
  const [medicalLicences, setMedicalLicences] = useState([]);
  const [profilePic, setProfilePic] = useState("");
  const [servicesValues, setServicesValues] = useState([]);
  const [timeZone, setTimeZone] = useState("");
  const [trigger, setTrigger] = useState(0);
  const [timeValues, setTimeValues] = useState([]);
  const [pricingValues, setPricingValues] = useState([]);
  const [bankDetails, setBankDetails] = useState([]);
  const [currentStep, setCurrentStep] = useState(0);

  const { Step } = Steps;

  const stepOnChange = (current) => {
    setCurrentStep(current);
    switch (current) {
      case 0:
        setCurrentPage(1);
        break;
      case 1:
        setCurrentPage(2);
        break;
      case 2:
        setCurrentPage(3);
        break;
      case 3:
        setCurrentPage(4);
        break;
      case 4:
        setCurrentPage(5);
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    if (edit)
      switch (currentPage) {
        case 1:
          setCurrentStep(0);
          break;
        case 2:
          setCurrentStep(1);
          break;
        case 3:
          setCurrentStep(2);
          break;
        case 4:
          setCurrentStep(3);
          break;
        case 5:
          setCurrentStep(4);
          break;
        default:
          break;
      }
    // eslint-disable-next-line
  }, [currentPage]);

  const fetchWorkProfile = (setLoading, setAddNew, setEditItem) => {
    ApiCalls.getDoctorWorkProfile()
      .then((response) => {
        if (response.data.status) {
          const {
            certificateDetails,
            educationalDetails,
            experienceDetails,
            medicalLicences,
            profilePic,
            timeZone,
            services,
            specialization,
            languagesKnown,
            tagLine,
            timeSlot,
            specialTime,
            offDays,
            consultationFee,
            consultationType,
            totalWorkExperiance,
            consultationDuration,
            followUpDays,
            currency,
            accountDetails,
            doctorAddedService,
          } = response.data.data;
          setCertificateDetails(certificateDetails);
          setEducationalDetails(educationalDetails);
          setExperienceDetails(experienceDetails);
          setMedicalLicences(medicalLicences);
          setProfilePic(profilePic);
          setTimeZone(timeZone);
          if (!setEditItem && !setAddNew) {
            // setCurrentPage(response.data.data.page);
            // setLastCompletedPage(response.data.data.page);
          }
          setServicesValues({
            services,
            doctorAddedService,
            specialization,
            languagesKnown,
            tagLine,
          });
          setTimeValues({
            timeSlot,
            specialTime,
            offDays,
          });
          setPricingValues({
            consultationFee,
            consultationType,
            totalWorkExperiance,
            consultationDuration,
            followUpDays,
            currency,
          });
          setBankDetails(accountDetails);
        } else {
          message.error(response.data.data.message);
        }
      })
      .catch((err) => {
        // Error
      })
      .finally(() => {
        setLoading && setLoading(false);
        setAddNew && setAddNew(false);
        setEditItem && setEditItem(null);
      });
  };

  useEffect(() => {
    fetchWorkProfile();
  }, []);

  return (
    <>
      <UpdateDetailsWrapper edit={edit}>
        <UpdateDetailsLayout edit={edit}>
          <PageUpdateDetailsContainer>
            {edit ? (
              <StepContainer>
                <Steps current={currentStep} onChange={stepOnChange}>
                  <Step title="Education & Experience" />
                  <Step title="Services & Specialities" />
                  <Step title="Set Availability" />
                  <Step title="Pricing" />
                  <Step title="Bank Details" />
                </Steps>
              </StepContainer>
            ) : null}
            {(() => {
              switch (currentPage) {
                case 0:
                  return (
                    <BasicProfile
                      profilePic={profilePic}
                      fetchWorkProfile={fetchWorkProfile}
                      setTimeZone={setTimeZone}
                      trigger={trigger}
                      setTrigger={setTrigger}
                      setCurrentPage={setCurrentPage}
                      setLastCompletedPage={setLastCompletedPage}
                    />
                  );
                case 1:
                  return (
                    <EducationExperience
                      educationalDetails={educationalDetails}
                      experienceDetails={experienceDetails}
                      fetchWorkProfile={fetchWorkProfile}
                      trigger={trigger}
                      setTrigger={setTrigger}
                      setCurrentPage={setCurrentPage}
                      setLastCompletedPage={setLastCompletedPage}
                    />
                  );
                case 2:
                  return (
                    <ServicesSpecialities
                      trigger={trigger}
                      setTrigger={setTrigger}
                      setCurrentPage={setCurrentPage}
                      setLastCompletedPage={setLastCompletedPage}
                      selectedValues={servicesValues}
                      fromMyAccount={edit}
                    />
                  );
                // case 3:
                //   return (
                //     <LicenseCertification
                //       certificateDetails={certificateDetails}
                //       medicalLicences={medicalLicences}
                //       fetchWorkProfile={fetchWorkProfile}
                //       trigger={trigger}
                //       setTrigger={setTrigger}
                //       setCurrentPage={setCurrentPage}
                //       setLastCompletedPage={setLastCompletedPage}
                //     />
                //   );
                case 3:
                  return (
                    <Availability
                      timeZone={timeZone}
                      trigger={trigger}
                      setTrigger={setTrigger}
                      setCurrentPage={setCurrentPage}
                      setLastCompletedPage={setLastCompletedPage}
                      fromMyAccount={edit}
                      selectedValues={timeValues}
                    />
                  );
                case 4:
                  return (
                    <Pricing
                      trigger={trigger}
                      setTrigger={setTrigger}
                      setCurrentPage={setCurrentPage}
                      setLastCompletedPage={setLastCompletedPage}
                      selectedValues={pricingValues}
                      fromMyAccount={edit}
                    />
                  );
                case 5:
                  return (
                    <BankDetails
                      trigger={trigger}
                      setTrigger={setTrigger}
                      setCurrentPage={setCurrentPage}
                      setLastCompletedPage={setLastCompletedPage}
                      selectedValues={bankDetails}
                      fromMyAccount={edit}
                    />
                  );

                default:
                  break;
              }
            })()}
          </PageUpdateDetailsContainer>
          <PageNavigation>
            <NavigateButtonsWrapper>
              <NavigateButtonContainer>
                {/* <PrimaryButton
                  label="Previous"
                  cancel={true}
                  disabled={currentPage === 0}
                  onMouseDown={() => setCurrentPage(currentPage - 1)}
                /> */}
              </NavigateButtonContainer>
              <NavigateButtonContainer>
                <PrimaryButton
                  label={edit ? "Save" : currentPage === 5 ? "Finish" : "Next"}
                  onMouseDown={() => setTrigger((prev) => prev + 1)}
                />
              </NavigateButtonContainer>
            </NavigateButtonsWrapper>
          </PageNavigation>
        </UpdateDetailsLayout>
      </UpdateDetailsWrapper>
    </>
  );
};

export default UpdateDetails;
