import styled from "styled-components";

export const LoginSignupContainer = styled.div`
  display: flex;
  padding: 32px;
  gap: 24px;
  min-height: 100vh;
  width: 100vw;
  justify-content: center;
  align-items: center;

  @media only screen and (max-width: 1200px) {
    flex-direction: column;
    padding: 16px;
  }

  @media only screen and (max-width: 700px) {
    padding: 16px;
  }
`;

export const FormContainer = styled.div`
  @media only screen and (max-width: 1200px) {
    width: 90%;
    padding: 0px;
  }

  @media only screen and (max-width: 700px) {
    width: 90%;
    padding: 0px;
  }

  display: flex;
  flex-direction: column;
  gap: 36px;
  padding: 64px;

  & .FormTitle {
    display: flex;
    flex-direction: column;

    & .primary {
      font-size: 32px;
      font-weight: 400;
      color: #000000;
      font-family: Metropolis Black;
    }

    & .secondary {
      font-size: 20px;
      font-weight: 400;
      color: #000000;
      font-family: Metropolis Regular;
    }
  }

  & .SelectorWrapper {
    display: flex;
    flex-direction: column;
  }

  & .InputWrapper {
    display: flex;
    flex-direction: column;
    gap: 8px;

    & span {
      font-size: 18px;
      font-weight: 700;
      color: #848484;
    }
  }

  & .AdditionalInfoWrapper {
    display: flex;
    justify-content: space-between;

    & .rememberMeWrapper {
      display: flex;
      gap: 12px;
      align-items: center;

      & input {
        width: 17px;
        height: 17px;
      }

      & span {
        font-size: 16px;
        font-weight: 400;
        color: #000000;
        font-family: Metropolis Regular;
      }
    }

    & .forgotPassword {
      font-size: 16px;
      font-weight: 700;
      color: #2c509f;
      cursor: pointer;
      font-family: Metropolis Bold;
    }
  }

  & .signUpInfoWrapper {
    display: flex;
    justify-content: center;
    gap: 6px;

    & .createAccount {
      font-size: 16px;
      font-weight: 400;
      color: #000000;
      font-family: Metropolis Regular;
    }

    & .signUpLink {
      font-size: 16px;
      font-weight: 700;
      color: #2c509f;
      cursor: pointer;
      font-family: Metropolis Bold;
    }
  }

  & .ButtonsWrapper {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }
`;

export const WelcomeWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  height: 220px;
  width: 100%;
  border-bottom: 1 solid;
  border-bottom-color: 1 solid #000;
  padding-left: 30px;
  padding-right: 30px;
  background-color: #fff;
`;
export const ImageContainer = styled.image`
  margin: 50px auto 20px;
  cursor: pointer;
  & img {
    width: 50vw;
    @media only screen and (max-width: 1200px) {
      width: 80vw;
      margin: 10px;
    }
  }
`;

export const LogoContainer = styled.div`
  height: 46px;
  width: 46px;
  & svg {
    height: 100%;
    width: 100%;
  }
`;

export const TextContainer = styled.div`
  height: calc(100% - 55px);
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  margin-bottom: 20px;
`;

export const UserTypesWrapper = styled.div`
  height: ${(props) => (props.Corporate ? "50px" : "100px")};
  width: 100%;
  display: flex;
  justify-content: space-between;
  @media only screen and (max-width: 700px) {
    /* flex-wrap: wrap;
    margin-top: 15px; */
  }
`;

export const WelcomeText = styled.p`
  font-size: ${(props) => (props.secondary ? "16px" : "24px")};
  font-weight: ${(props) => (props.secondary ? "none" : "500")};
  font-family: "Urbanist", sans-serif;
  color: ${(props) => (props.secondary ? "#6F6F6F" : "#03183C")};
  margin: 0;
`;

export const UserTypeContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  cursor: pointer;
  background: ${(props) => (props.selected ? "#4385C6" : "#fff")};
  border: 1px solid none;
  border-radius: 8px;
  box-shadow: 22px 46px 20px rgba(168, 168, 168, 0.01),
    12px 26px 17px rgba(168, 168, 168, 0.05),
    5px 12px 13px rgba(168, 168, 168, 0.09),
    1px 3px 7px rgba(168, 168, 168, 0.1), 0px 0px 0px rgba(168, 168, 168, 0.1);
  color: ${(props) => (props.selected ? "#fff" : "#03183C")};
  &:hover {
    transform: scale(1.03);
  }
  transition: 0.3s transform ease-in-out;
  @media only screen and (max-width: 700px) {
    width: 40%;
    /* flex-wrap: wrap; */
  }
`;

export const IconContainer = styled.div`
  height: 24px;
  width: 24px;
  & svg {
    height: 100%;
    width: 100%;
  }

  @media only screen and (max-width: 600px) {
    height: 20px;
    width: 20px;
  }
`;

export const UserName = styled.p`
  font-family: "Urbanist", sans-serif;
  font-weight: 500s;
  font-size: 18px;
  margin-bottom: 0;
  padding: 0 5px 0 10px;
  opacity: 1;

  @media only screen and (max-width: 600px) {
    font-size: 16px;
  }
`;

export const SignupInfo = styled.div`
  width: 100%;
  padding-left: 30px;
  margin-top: 15px;
  padding-bottom: 35px;
`;

export const SignupText = styled.p`
  width: ${(props) => (props.sub ? "85px" : "")};
  font-family: ${(props) =>
    props.sub ? "Gilroy Bold" : "'Urbanist', sans-serif"};
  font-size: 18px;
  color: ${(props) => (props.sub ? "#4385C6" : "#35364f")};
  margin-bottom: 0;
  margin-top: ${(props) => (props.sub ? "5px" : "")};
  cursor: ${(props) => (props.sub ? "pointer" : "")};
  & .anticon-arrow-right {
    font-size: 16px;
    margin-left: 3px;
  }
`;

export const SignupContainer = styled.div`
  height: auto;
  margin-top: 100px;

  @media only screen and (max-width: 1200px), (max-height: 1200px) {
    margin-top: 20px;
  }
`;

export const ForgotPassword = styled.span`
  color: #03183c;
  font-family: "Urbanist", sans-serif;
  font-size: 17px;
  cursor: pointer;

  @media only screen and (max-width: 900px) {
    font-size: 14px;
  }
`;
