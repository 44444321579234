import styled from "styled-components";
import { Row, Col, Form } from "antd";
import SignupBG from "../../assets/backgrounds/signupBg.png";

export const RootContainer = styled(Col)`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Main = styled(Row)`
  /* background-image: url(${SignupBG}); */
  /* background-color: whitesmoke;
  background-size: auto 100%;
  background-repeat: repeat; */
  overflow-x: hidden;
  height: ${({ userType }) => (userType === "corporate" ? "auto" : "100vh")};
  /* width: 100vw; */
`;

export const LogoContainer = styled(Col)``;
export const CorporateContainer = styled.div``;

export const CorporateWrapper = styled.div`
  width: 100%;
  padding: 0px 30px;
`;

export const Heading = styled.h1`
  font-family: "Urbanist";
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  color: #03183c;
`;

export const Text = styled.p`
  font-family: "Urbanist";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 150%;
  color: #6f6f6f;
`;

export const FileInputWrapper = styled.div`
  position: relative;
  margin-bottom: 0px;
  margin-left: 25px;
  height: 135px;
  border: none;
  width: 90%;
  line-height: 20px;
  border-radius: 8px;
  font-size: 16px;
  font-family: "Urbanist", sans-serif;
  color: #35364f;
  padding: 35px 15px 15px 0px;
  background-color: #f4f5f7 !important;
`;

export const FormContainer = styled.div`
  width: ${(props) => (props.profile ? "100%" : "")};
`;

export const FormWrapper = styled.div`
  width: 100%;
  border-radius: 10px;
  overflow: hidden;
  margin-bottom: 14px;
  display: flex;
  flex-direction: row;
  transform: ${({ login }) => (login ? "scale(0.8)" : "")};

  & .ant-row {
    margin-top: 5px;
  }
  & .ant-form {
    width: 100%;
  }

  @media only screen and (max-width: 1200px) {
    height: auto;
    width: auto;
    display: flex;
    flex-direction: column;
    margin-top: 0;
    transform: none;
    padding: 20px;
  }
`;

export const FormEditWrapper = styled.div`
  padding: 20px;
  width: 100%;

  @media only screen and (max-width: 960px) {
    padding: 20px 0;
  }
`;

export const TextHere = styled(Form.Item)`
  display: flex;
  flex-direction: row;
  font-family: "Urbanist";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 150%;
  color: #6f6f6f;
`;

export const ImagePicker = styled.image``;

export const InputWrapper = styled.div`
  position: relative;
  margin-bottom: 0px;
  width: 50vw;

  & input {
    line-height: 20px;
    border-radius: 8px;
    font-size: 16px;
    font-family: "Urbanist", sans-serif;
    color: #35364f;
    padding: 30px 15px 10px 15px;
    background-color: #f4f5f7 !important;
    border: none;

    @media only screen and (max-width: 900px) {
      font-size: 12px;
      height: 50px;
    }
  }
`;

export const StyledFormItem = styled(Form.Item)`
  position: relative;
  margin-bottom: 0px;
  background-color: #fbfbfb;
  @media only screen and (max-width: 900px) {
    padding: 0 15px !important;
  }
  & input {
    line-height: 20px;
    border-radius: 8px;
    font-size: 16px;
    font-family: Metropolis Regular;
    color: #35364f;
    padding: 30px 15px 10px 15px;
    background-color: #fbfbfb !important;
    border: ${(props) => (props.type2 ? "none" : "")};
    margin-left: ${({ name }) => (name === "password" ? "-10px" : "")};
    @media only screen and (max-width: 900px) {
      font-size: 12px;
      height: 50px;
    }
  }

  & #ant-file-upload-button {
    display: none;
    background-color: red;
  }

  & #password,
  #confirm {
    line-height: 20px;
    border-radius: 8px;
    font-size: 16px;
    font-family: Metropolis Regular;
    font-weight: 500;
    color: #35364f;
    padding: ${(props) =>
      props.corporatePassword ? " 20px 15px 20px 15px" : "35px 15px 10px 15px"};
    background-color: #fbfbfb !important;

    @media only screen and (max-width: 900px) {
      font-size: 12px;
    }
  }

  & .ant-form-item-required::before {
    content: "" !important;
  }

  & .ant-form-item-required::after {
    display: inline-block !important;
    margin-left: 4px;
    color: #ff4d4f;
    font-size: 14px;
    font-family: "Urbanist", sans-serif;
    line-height: 1;
    content: "*" !important;

    @media only screen and (max-width: 900px) {
      font-size: 12px;
    }
  }

  & .ant-form-item-label {
    padding: 0;
    position: absolute;
    top: 5px;
    left: ${(props) => (props.left || props.type2 ? "12px" : "42px")};
    z-index: 1;
    & label {
      font-size: 14px;
      font-family: "Urbanist", sans-serif;
      color: #65667d;
      &::after {
        content: "";
      }

      @media only screen and (max-width: 900px) {
        font-size: 12px;
      }
    }

    @media only screen and (max-width: 900px) {
      left: 25px;
    }
  }

  & .ant-input-affix-wrapper {
    background-color: #f4f5f7 !important;
    border-radius: 10px;
    padding: 0 10px;
    border: ${(props) => (props.type2 ? "none" : null)};
  }

  & .react-tel-input {
    height: 67px;

    & .form-control {
      height: 100%;
      width: 100%;
      line-height: 20px !important;
      border-radius: 8px;
      font-size: 16px;
      font-family: Metropolis Regular;
      color: #35364f;
      padding: 35px 15px 10px 15px !important;
      background-color: #f4f5f7 !important;
      border: ${(props) => (props.type2 ? "none !important" : "")};

      @media only screen and (max-width: 900px) {
        font-size: 14px;
      }
    }

    & .flag-dropdown {
      position: absolute;
      top: 0;
      right: 0;
      border-bottom-right-radius: 10px;
      border-top-right-radius: 10px;
      border: ${(props) => (props.type2 ? "none !important" : "")};

      & .country-list {
        position: absolute;
        left: -260px;
      }
    }
  }

  & .ant-input-prefix {
    color: #6b6b6b;
  }

  & .ant-picker {
    height: 67px;
    padding-left: 0px;
    border-radius: 10px !important;
    background-color: #f4f5f7 !important;
    border: ${(props) => (props.type2 ? "none" : "")};
    & .anticon-calendar {
      font-size: 23px;
    }
  }
  & .ant-select-selector {
    height: 67px !important;
    border-radius: 10px !important;
    background-color: #f4f5f7 !important;
    padding: 35px 15px 10px 15px !important;
    border: ${(props) => (props.type2 ? "none !important" : "")};
    @media only screen and (max-width: 900px) {
      height: 50px !important;
      padding: 0 15px !important;
      display: flex;
      align-items: center;
    }
  }
  & .ant-select-selection-placeholder {
    line-height: 20px !important;
    /* font-size: 16px;
    font-family: 'Urbanist', sans-serif;
    color: #35364f !important; */
  }
  & .ant-select-selection-item {
    line-height: 20px !important;
    font-size: 16px;
    font-family: "Urbanist", sans-serif;
    color: #35364f !important;
    @media only screen and (max-width: 900px) {
      font-size: 14px;
    }
  }
  & .ant-select-selection-search-input {
    padding: 25px 4px 0px !important;
    line-height: 20px !important;
    height: 100% !important;
  }

  & textarea {
    min-height: 120px;
    line-height: 20px;
    border-radius: 8px;
    font-size: 16px;
    font-family: "Urbanist", sans-serif;
    color: #35364f;
    padding: 35px 15px 10px 15px;
    background-color: #f4f5f7 !important;
    border: ${(props) => (props.type2 ? "none" : "")};

    @media only screen and (max-width: 900px) {
      font-size: 12px;
    }
  }
`;

export const FormHeading = styled.p`
  font-size: 20px;
  font-family: "Gilroy Bold";
  padding: 30px 30px 0;
  margin: 0;
`;

export const WelcomeText = styled.p`
  font-size: 16px;
  font-family: "Urbanist", sans-serif;
  padding: 0 30px;
  margin: 0;
`;

export const DoctorMessageContainer = styled.div`
  margin-top: 5px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #4385c6;
  padding: 20px 30px 30px;
`;

export const QuestionText = styled.span`
  font-family: "Gilroy Regular";
  font-size: 18px;
  color: white;
  padding: 0 0 5px;
`;

export const InfoText = styled.span`
  text-align: center;
  font-family: "Gilroy Regular";
  font-size: 14px;
  color: white;
  padding: 5px 0 15px;
`;

export const OtpInfoContainer = styled.div`
  margin-bottom: 20px;
  padding: 10px 30px 0 30px;
  width: 100%;
`;

export const OtpInfo = styled.div`
  color: #4385c6;
  font-family: "Urbanist", sans-serif;
  font-size: 16px;
`;

export const ResendText = styled.span`
  color: #4385c6;
  font-family: "Urbanist", sans-serif;
  font-size: 16px;
  cursor: pointer;
`;
