import styled from "styled-components";

export const LoadingComponentContainer = styled.div`
  position: relative;
  height: 100vh;
  width: 100vw;
  & > .anticon-loading {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    & svg {
      height: 2rem;
      width: 2rem;
    }
  }
`;
