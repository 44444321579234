import authActions from "./authActions";
import lookupsActions from "./lookupsActions";
import healthRecordActions from "./healthRecordActions";
import onlineActions from './onlineActions';
import patientActions from './patientActions'
import messageActions from './messageActions';
import fileActions from './fileActions';
import callActions from "./callActions";
const reduxActions = {
    authActions,
    lookupsActions,
    healthRecordActions,
    onlineActions,
    patientActions,
    messageActions,
    fileActions,
    callActions
}

export default reduxActions