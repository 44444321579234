import React, { useState, useEffect } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Checkbox from '@mui/material/Checkbox';
import { message } from 'antd';
import ApiCalls from '../../../apis/ApiCalls';
import { useHistory } from 'react-router-dom';
import { CustomButton } from './styledComponent';
import ImageNotAvailable from '../../../assets/images/CorporateModule/Image_not_available.png';

function EditDoctor() {
  const [doctorList, setDoctorList] = useState([]);
  let history = useHistory();

  const onCheckboxSelectionChange = (e, item) => {
    let temp = [...doctorList];
    for (let i = 0; i < temp.length; i++) {
      if (temp[i]._id === item._id) {
        temp[i].selected = !temp[i].selected;
        break;
      }
    }
    setDoctorList(temp);
  };

  const getdoctorList = () => {
    ApiCalls.getDoctorList()
      .then((response) => {
        if (response.data.status) {
          if (response?.data?.data?.data?.length > 0) {
            let temp = [];
            response.data.data.data.forEach((dat) => {
              temp.push({
                ...dat,
                selected: false
              });
            });
            setDoctorList(temp);
          }
        } else {
          message.error("Can't fetch therapist list");
        }
      })
      .catch((err) => {
        setDoctorList([]);
      });
  };

  useEffect(() => {
    getdoctorList();
  }, []);

  return (
    <div
      style={{
        height: 'calc(100vh - 80px)',
        overflowY: 'auto',
        overflowX: 'hidden'
      }}
    >
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label='simple table'>
          <TableHead>
            <TableRow>
              <TableCell />
              <TableCell>Name</TableCell>
              <TableCell align='right'>Expertise</TableCell>
              <TableCell align='right'>Rating</TableCell>
              <TableCell align='right'>Consultation Fees</TableCell>
              <TableCell align='right'>Selection</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {doctorList.map((item) => (
              <TableRow
                key={item._id}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                style={item.selected ? { backgroundColor: '#ECECEC' } : {}}
              >
                <TableCell>
                  <img
                    alt='No img found'
                    src={item.profilePic}
                    height='25px'
                    width='25px'
                    style={{ borderRadius: '50%' }}
                    onError={(e) => {
                      if (e.target.src !== ImageNotAvailable) {
                        e.target.src = ImageNotAvailable;
                      }
                    }}
                  />
                </TableCell>
                <TableCell component='th' scope='row'>
                  {item.fullName}
                </TableCell>
                <TableCell align='right'>{item.consultationType}</TableCell>
                <TableCell align='right'>{item.rating}</TableCell>
                <TableCell align='right'>₹{item.consultationFee}</TableCell>
                <TableCell align='right'>
                  <Checkbox
                    checked={item.selected}
                    onChange={(e) => onCheckboxSelectionChange(e, item)}
                  />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <CustomButton second={true} onClick={() => history.push('   ')}>
        Edit Therapists
      </CustomButton>
    </div>
  );
}

export default EditDoctor;
