import React, { useEffect, useRef, useState } from "react";

export function Countdown({ seconds, onComplete }) {
  const [timeLeft, setTimeLeft] = useState(seconds);
  const intervalRef = useRef();

  useEffect(() => {
    intervalRef.current = setInterval(() => {
      setTimeLeft((t) => t - 1);
    }, 1000);
    return () => clearInterval(intervalRef.current);
  }, []);

  useEffect(() => {
    if (timeLeft <= 0) {
      clearInterval(intervalRef.current);
      if (onComplete) {
        onComplete();
      }
    }
  }, [timeLeft, onComplete]);

  return <>{timeLeft}s</>;
}
