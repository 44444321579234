import styled from "styled-components";
import updateProfileBg from "../../assets/backgrounds/updateProfileBg.png";
import doctorImage from "../../assets/images/doctor1@2x.png";

export const PageLayout = styled.div`
  height: 100vh;
  width: 100vw;
  margin: 0;
  padding: 0;
  display: flex;
`;

export const ProgressTabWrapper = styled.div`
  height: 100%;
  background-image: url(${updateProfileBg});
  width: 550px;
  padding-top: 65px;
  padding-left: 50px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  /* z-index: -2; */
  @media only screen and (max-width: 1500px) {
    width: 475px;
    padding-top: 20px;
  }
`;

export const LogoContainer = styled.div`
  position: relative;
  height: 30px;
  width: auto;
  & svg {
    height: 100%;
    width: auto;
  }
`;

export const WhiteBackground = styled.div`
  position: absolute;
  top: -5px;
  left: -4px;
  height: 40px;
  width: 40px;
  border-radius: 50%;
  background-color: #fff;
`;

export const InfoTextContainer = styled.div`
  height: 100px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  padding: 30px 0;
  @media only screen and (max-width: 1500px) {
    height: 90px;
    padding: 15px 0;
  }
`;

export const InfoText = styled.div`
  font-size: ${(props) => (props.sub ? "16px" : "20px")};
  color: white;
  font-family: "Urbanist", sans-serif;
  @media only screen and (max-width: 1500px) {
    font-size: ${(props) => (props.sub ? "14px" : "18px")};
  }
`;

export const ProgressContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  height: 340px;
  width: 100%;
  padding: 10px 0;
  @media only screen and (max-width: 1500px) {
    height: 280px;
  }
`;

export const ProfileTabItem = styled.div`
  display: flex;
  align-items: center;
  height: auto;
`;

export const ProgressCircle = styled.div`
  position: relative;
  height: 25px;
  width: 25px;
  border-radius: 50%;
  background-color: ${(props) => (props.completed ? "#4385C6" : null)};
  border: ${(props) =>
    props.completed ? "1px solid #4385C6" : "1px solid white"};
  @media only screen and (max-width: 1500px) {
    height: 20px;
    width: 20px;
  }
`;

export const ProfileTabItemName = styled.div`
  margin-top: 4px;
  margin-left: 20px;
  color: ${(props) => (props.selected ? "#4385C6" : "#fff")};
  font-family: "Urbanist", sans-serif;
  font-size: 16px;
  line-height: 1;
  @media only screen and (max-width: 1500px) {
    margin-top: 2px;
    font-size: 14px;
  }
`;

export const SelectedCircle = styled.div`
  position: absolute;
  height: 50%;
  width: 50%;
  top: 23%;
  left: 23%;
  border-radius: 50%;
  background-color: white;
`;

export const ConnectingLine = styled.div`
  position: absolute;
  bottom: -26px;
  left: 9.5px;
  height: 25px;
  border: 0.5px solid #fff;
  @media only screen and (max-width: 1500px) {
    bottom: -23px;
    left: 7.5px;
    height: 23px;
  }
`;

export const ImageContainer = styled.div`
  height: 365px;
  width: 100%;
  margin-left: 50px;
  background-image: url(${doctorImage});
  background-size: auto 128%;
  /* background-position: 0%; */
  background-repeat: no-repeat;
  @media only screen and (max-width: 1500px) {
    height: 225px;
    background-position: 0% 0%;
  }
`;

export const UpdateDetailsWrapper = styled.div`
  height: 100%;
  width: ${(props) => (props.edit ? "100%" : "calc(100% - 550px)")};
  overflow: auto;
  @media only screen and (max-width: 1500px) {
    width: ${(props) => (props.edit ? "100%" : "calc(100% - 475px)")};
  }
`;

export const UpdateDetailsLayout = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  min-height: 100%;
  padding: ${(props) => (props.edit ? "0 10px" : "103px 55px 73px")};
  @media only screen and (max-width: 1800px) {
    padding: ${(props) => (props.edit ? "0 10px" : "55px")};
  }
`;

export const PageUpdateDetailsContainer = styled.div`
  position: relative;
  height: auto;
  width: 100%;
`;
export const StepContainer = styled.div`
  position: absolute;
  width: 100%;

  & .ant-steps-item-icon,
  .ant-steps-item-content {
    font-family: "Urbanist", sans-serif;
  }
`;

export const PageNavigation = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const NavigateButtonsWrapper = styled.div`
  height: auto;
  width: 190px;
  display: flex;
  justify-content: flex-end;
`;

export const NavigateButtonContainer = styled.div`
  height: auto;
  width: 48%;
`;

export const UpdateAreaContainer = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 50px
`;

export const PageHeading = styled.h3`
  margin-bottom: 0;
  font-family: "Gilroy SemiBold";
  font-size: 25px;
  color: #35364f;
  &::after {
    content: "*";
    color: red;
    display: ${(props) => (props.mandatory ? null : "none")};
  }
`;

export const SubHeading = styled.h6`
  margin-bottom: 0;
  font-family: "Urbanist", sans-serif;
  font-size: 17px;
  padding-bottom: ${(props) => (props.type2 ? "" : "35px")};
  color: #35364f;
`;

export const CompletedMessageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;

export const MailIconContainer = styled.div`
  margin-top: 70px;
`;

export const CompletedMessage = styled.p`
  max-width: 382px;
  text-align: center;
  margin-top: ${(props) => (props.sub ? "20px" : "35px")};
  margin-bottom: 0;
  font-size: ${(props) => (props.sub ? "15px" : "24px")};
  font-family: ${(props) =>
    props.sub ? "'Urbanist', sans-serif" : "Gilroy Bold"};
  color: ${(props) => (props.sub ? "#65667D" : "#35364f")};
  line-height: ${(props) => (props.sub ? "1.8" : "")};
`;

export const RedirectButtonContainer = styled.div`
  position: relative;
  height: 152px;
  width: 243px;
  & button {
    position: absolute;
    top: 30px;
    left: 0;
  }
`;
