import React, { useState } from "react";
import { Form, Input, Checkbox, Row, Col, message } from "antd";
import ApiCalls from "../../apis/ApiCalls";
import { NavLink, useParams, withRouter } from "react-router-dom";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import {
  FormWrapper,
  StyledFormItem,
  FormHeading,
  WelcomeText,
  DoctorMessageContainer,
  QuestionText,
  InfoText,
} from "./styledComponents";
import PrimaryButton from "../../components/PrimaryButton/index";

const PatientRegister = (props) => {
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const [phoneNoFormat, setPhoneNoFormat] = useState(null);
  const { userType } = useParams();

  const onFinish = (values) => {
    const { confirm, acceptCheckbox, mobile, ...formData } = {
      ...values,
    };
    formData.firstName = formData.firstName.trim();
    formData.lastName = formData.lastName.trim();
    if (formData.email || formData.email === "")
      formData.email =
        formData.email.trim() === "" ? undefined : formData.email.trim();

    formData.phone = {};
    formData.phone.countryCode = phoneNoFormat.countryCode;
    formData.phone.phoneNumber = mobile.replace(
      phoneNoFormat.dialCode,
      `${phoneNoFormat.dialCode}-`
    );
    setLoading(true);
    if (userType === "patient") {
      ApiCalls.patientRegister(formData)
        .then((response) => {
          if (response.data.status === 1) {
            if (response.data.data.token)
              props.history.push(
                `/user/patient/signup/otp-verification/${response.data.data.token}`
              );
            else {
              message.success(
                "Signup successful. Please verify your e-mail to login"
              );
              props.history.push("/user/patient/login");
            }
          }
        })
        .catch((error) => {
          if (error.response) {
            message.error(error.response.data?.data?.message, 5);
          }
        })
        .finally(() => setLoading(false));
    } else if (userType === "doctor") {
      ApiCalls.doctorRegister(formData)
        .then((response) => {
          if (response.data.status === 1) {
            message.success(
              "Signup successful. Please verify your e-mail to login"
            );
            props.history.push("/user/doctor/login");
          } else {
            message.error(response.data.message);
          }
        })
        .catch((error) => {
          if (error.response) {
            message.error(
              error.response
                ? error?.response?.data?.data?.message
                : "No Response From Server",
              5
            );
          }
        })
        .finally(() => setLoading(false));
    }
  };

  return (
    <>
      <FormWrapper>
        <Col span={24}>
          <FormHeading>Create an account</FormHeading>
          <WelcomeText>
            Great, it's a pleasure to have you here. Let's get you set up.
          </WelcomeText>
          <Row>
            <Form
              layout="vertical"
              form={form}
              initialValues={{
                layout: "vertical",
              }}
              onFinish={onFinish}
            >
              <Row
                style={{
                  justifyContent: "space-between",
                  width: "100%",
                }}
              >
                <Col
                  style={{
                    width: "49%",
                  }}
                >
                  <StyledFormItem
                    label="First Name"
                    name="firstName"
                    rules={[
                      {
                        required: true,
                        message: "Please enter only alphabets",
                        pattern: new RegExp(
                          "^([A-Za-z]|(([A-Za-z]+)([.,] |[-']| )*)+[A-Za-z]+.?)$"
                        ),
                      },
                    ]}
                    style={{
                      paddingRight: "0",
                    }}
                  >
                    <Input placeholder="First Name" autoFocus="true" />
                  </StyledFormItem>
                </Col>
                <Col
                  style={{
                    width: "49%",
                  }}
                >
                  <StyledFormItem
                    name="lastName"
                    label="Last Name"
                    rules={[
                      {
                        required: true,
                        message: "Please enter only alphabets",
                        pattern: new RegExp(
                          "^([A-Za-z]|(([A-Za-z]+)([.,] |[-']| )*)+[A-Za-z]+.?)$"
                        ),
                      },
                    ]}
                    style={{
                      paddingLeft: "0",
                    }}
                    left={true}
                  >
                    <Input placeholder="Last Name" />
                  </StyledFormItem>
                </Col>
              </Row>

              <Row>
                <Col span={24}>
                  <StyledFormItem
                    name="email"
                    label="Email"
                    type="email"
                    rules={[
                      {
                        required: userType === "patient" ? false : true,
                        message: "Email is required",
                      },
                      {
                        type: "email",
                      },
                    ]}
                  >
                    <Input type="email" placeholder="Email" />
                  </StyledFormItem>
                </Col>
              </Row>

              <Row>
                <Col span={24}>
                  <StyledFormItem
                    name="mobile"
                    label="Phone Number"
                    rules={[
                      {
                        required: true,
                        message: "Phone number is required",
                      },
                    ]}
                  >
                    <PhoneInput
                      placeholder="Mobile"
                      inputProps={{
                        name: "mobile",
                        required: true,
                      }}
                      country="ae"
                      onChange={(val, val2) => setPhoneNoFormat(val2)}
                    />
                  </StyledFormItem>
                </Col>
              </Row>
              <Row>
                <Col span={24}>
                  <StyledFormItem
                    name="password"
                    label="Password"
                    rules={[
                      {
                        required: true,
                        message: "Password is required",
                      },
                      {
                        validator: (_, value) =>
                          value !== undefined
                            ? value.length !== 0
                              ? value.length >= 8
                                ? Promise.resolve()
                                : Promise.reject(
                                    "Password should contain minimum 8 letters"
                                  )
                              : Promise.resolve()
                            : Promise.resolve(),
                      },
                    ]}
                  >
                    <Input.Password
                      type="password"
                      placeholder="Password"
                      className="InputPassword"
                    />
                  </StyledFormItem>
                </Col>
              </Row>

              <Row>
                <Col span={24}>
                  <StyledFormItem
                    dependencies={["password"]}
                    name="confirm"
                    label="Confirm Password"
                    hasFeedback
                    rules={[
                      {
                        required: true,
                        message: "Confirm Password is required",
                      },
                      ({ getFieldValue }) => ({
                        validator(rule, value) {
                          if (!value || getFieldValue("password") === value) {
                            return Promise.resolve();
                          }
                          return Promise.reject(
                            "The two passwords that you entered do not match!"
                          );
                        },
                      }),
                    ]}
                  >
                    <Input.Password
                      type="password"
                      placeholder="Confirm Password"
                      className="InputPassword"
                    />
                  </StyledFormItem>
                </Col>
              </Row>

              <Row
                justify="center"
                align="center"
                style={{ marginTop: "10px" }}
              >
                <Col span={24}>
                  <StyledFormItem
                    name="acceptCheckbox"
                    valuePropName="checked"
                    rules={[
                      {
                        validator: (_, value) =>
                          value
                            ? Promise.resolve()
                            : Promise.reject("Should accept agreement"),
                      },
                    ]}
                  >
                    <Checkbox style={{ fontSize: "12px" }}>
                      By checking this box,you agree to Telehealings's{" "}
                      <a
                        style={{ color: "#4385C6", fontSize: "12px" }}
                        href="https://www.telehealings.com/terms-and-conditions"
                        // eslint-disable-next-line
                        target="_blank"
                      >
                        Service & License Agreement
                      </a>
                    </Checkbox>
                  </StyledFormItem>
                </Col>
              </Row>

              <Row justify="center" style={{ marginTop: "10px" }}>
                <Col span={24}>
                  <StyledFormItem>
                    <PrimaryButton
                      htmlType="submit"
                      loading={loading}
                      label="Sign up"
                    />
                  </StyledFormItem>
                </Col>
              </Row>
              <Row
                justify="center"
                style={{ margin: "10px auto 10px auto", fontSize: "14px" }}
              >
                Already have an account ?
                <NavLink to="/user/patient/login" style={{ color: "#4385C6" }}>
                  &nbsp;Sign in
                </NavLink>
              </Row>
            </Form>
          </Row>
        </Col>
        <DoctorMessageContainer>
          <QuestionText>Are you a therapist?</QuestionText>
          <InfoText>
            More than 6 million patients search and find the therapist through us
            each month
          </InfoText>
          <PrimaryButton
            label="Join Now"
            secondary={true}
            onClick={() => props.history.push("/user/doctor/signup")}
          />
        </DoctorMessageContainer>
      </FormWrapper>
    </>
  );
};
export default withRouter(PatientRegister);
