import React from "react";
import {
  UpdateAreaContainer,
  PageHeading,
  SubHeading,
} from "../styledComponents";
import BankDetailsForm from "./BankDetailsForm";

const BankDetails = (props) => {
  return (
    <>
      <UpdateAreaContainer>
        <PageHeading>Bank Details</PageHeading>
        <SubHeading>Please let us know your bank details</SubHeading>
        <BankDetailsForm {...props} />
      </UpdateAreaContainer>
    </>
  );
};

export default BankDetails;
