import React from 'react';
import styled from 'styled-components';
import { NOT_FOUND } from '../assets/images'; 

const Wrapper = styled.div`
  width: 100%;
  height: auto;
  padding-top: 100px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const EmptyImage = styled.img`
  width: auto;
  height: auto;
  max-width: 200px;
`;

const EmptyText = styled.p`
  font-size: 18px;
  text-align: center;
  font-weight: 600;
  margin-top: 30px;
`;

const NotFound = ({ text }) => (
  <Wrapper>
    <EmptyImage src={NOT_FOUND} alt='' />
    <EmptyText>{text}</EmptyText>
  </Wrapper>
);

export default NotFound;