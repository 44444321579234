const setOnlineDoctors = (onlineDoctors = []) => ({
    type: 'SET_ONLINE_DOCTORS',
    onlineDoctors
})
const clearOnlineDoctors = () => ({
    type:'CLEAR_ONLINE_DOCTORS'
})
const setOnlinePatients = (onlinePatients = []) => ({
    type: 'SET_ONLINE_PATIENTS',
    onlinePatients
})
const clearOnlinePatients = () => ({
    type:'CLEAR_ONLINE_PATIENTS'
})
const onlineActions = {
    setOnlineDoctors,
    clearOnlineDoctors,
    setOnlinePatients,
    clearOnlinePatients
}
export default onlineActions