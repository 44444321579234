import React from "react";
import loginIllustration from "../../assets/images/login-illustration.png";
import telehealingsLogo from "../../assets/images/telehealings_logo.png";
import styled from "styled-components";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  justify-content: center;
  align-items: center;

  @media only screen and (max-width: 1200px) {
    gap: 0px;
  }
`;

const LogoContainer = styled.div`
  display: flex;
  padding: 16px 20px;
  width: 326px;

  @media only screen and (max-width: 1200px) {
    padding: 0px;
    justify-content: center;
  }

  @media only screen and (max-width: 700px) {
    padding: 0px;
    justify-content: center;
  }
`;

const IllustrationContainer = styled.div`
  display: flex;
  padding: 16px 20px;
  gap: 10px;
`;

const IllustrationImage = styled.img`
  width: 564px;
  height: 559px;

  @media only screen and (max-width: 1200px) {
    width: 40vw;
    height: 40vw;
    margin: 10px;
  }

  @media only screen and (max-width: 700px) {
    width: 40vw;
    height: 40vw;
    margin: 10px;
  }
`;

const MainLogo = styled.img`
  height: 93px;

  @media only screen and (max-width: 1200px) {
    // display: none;
    height: 60px;
  }

  @media only screen and (max-width: 700px) {
    // display: none;
    height: 40px;
  }
`;

const Banner = () => {
  return (
    <Container>
      <LogoContainer>
        <MainLogo src={telehealingsLogo} />
      </LogoContainer>
      <IllustrationContainer>
        <IllustrationImage src={loginIllustration} />
      </IllustrationContainer>
    </Container>
  );
};

export default Banner;
