import React, { useEffect, useState } from 'react'
import { Description, ListItem, SectionHeading, Wrappers } from '../MyAccount/PrivacyPolicy/styledComponents';
import ApiCalls from '../../apis/ApiCalls';
import { message } from 'antd';

const ConfidentialityAgreement = () => {
    const [agreement, setAgreement] = useState([])

    const getAgreement = () => {
      ApiCalls.fetchConfidentialityAgreement()
        .then((response) => {
          if (response.data.status) {
            setAgreement(response.data.data);
          }
        })
        .catch((error) => {
          message.error(
            error.response
              ? error?.response?.data?.data?.message
              : "No Response From Server",
            5
          );
        });
    };
   
    useEffect(() => {
      getAgreement();
    }, []);

    
  return (
    <Wrappers>
      {agreement.map((item) => (
          <>
          {item.heading ? <SectionHeading mainHead={true}>{item.heading}</SectionHeading> : null}
            {item.title ? <SectionHeading>{item.title}</SectionHeading> : null}
            {item.description ? (
              <Description>{item.description}</Description>
            ) : null}
            {item.ul ? (
              <ul>
                {item.ul.map((item) => {
                  return <ListItem>{item.li}</ListItem>;
                })}
              </ul>
            ) : null}
          </>
        )
      )}
    </Wrappers>
  )
}

export default ConfidentialityAgreement