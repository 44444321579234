const callReducerDefaultState = {
  extendedCallInfo: {
    callExtended: false,
    scheduleId: "",
  },
};

export default (state = callReducerDefaultState, action) => {
  switch (action.type) {
    case "SET_CALL":
      return (state.call = action.call);
    case "CLEAR_CALL":
      return {};
    case "SET_EXTENDED_APPOINTMENT_INFO":
      return {
        ...state,
        extendedCallInfo: action.extendedCallInfo,
      };
    default:
      return state;
  }
};
