import styled from "styled-components";

export const MyAccountWrapper = styled.div`
  display: flex;
  height: auto;
  width: 100%;
  padding: 30px 30px 30px 50px;
  @media only screen and (max-width:1200px){
    flex-direction: column;
    padding: 50px;
  }
  
  @media only screen and (max-width: 360px){
    padding: 12px;
  }
`;

export const PageName = styled.h3`
  font-family: Metropolis Black;
  font-weight: 400;
  font-size: 24px;
  color: #03183C;
`;

export const MenuDropDown = styled.div`
display: none;
  @media only screen and (max-width:1200px){
   display:block;
   height: 50%;
   width: 50%;
 }
`;

export const MenuContainer = styled.div`
  height: auto;
  width: 294px;

  @media only screen and (max-width:1200px){
    display: none;
    width:auto;
    margin: 10px;
    width: 100vw;
  }
`;

export const SelectedPageContainer = styled.div`
  height: auto;
  width: calc(100% - 294px);
  display:flex;
  flex-direction:column;
  margin-left: 30px;
  margin-top: 20px;
  @media only screen and (max-width:1200px){
    width: 100%;
    margin: 0px
  }
`;

export const PageInfo = styled.p`
  font-family: 'Urbanist', sans-serif;
  font-weight: 400;
  font-size: 16x;
  color: #6F6F6F;
`;

export const MenuItem = styled.div`
  display: flex;
  justify-content: start;
  height:${(props)=>props.ItemType? "90px" : "20px"};
  width: ${(props)=>props.ItemType? "230px" : "214px"};
  cursor: pointer;
  ${'' /* border-bottom: ${(props)=> props.ItemType? "1px solid rgba(168, 175, 189, 0.4)" : "0"}; */}
  padding-top:${(props)=>props.ItemType? "15px" : "0"};

  @media only screen and (max-width: 1200px) {
    background-color: #fff;
    color: #4385C6;
    padding-top: 0px;
    height:${(props)=>props.ItemType? "35px" : "20px"};
    width: ${(props)=>props.ItemType? "180px" : "120px"};
    border-bottom: ${(props)=> props.ItemType? "none" : "0"};
  }

  @media only screen and (max-width: 600px){
    width: ${(props)=>props.ItemType? "140px" : "120px"};
  }
`;

export const MenuItemName = styled.span`
  font-family: Metropolis Regular;
  font-size: 20px;
  font-weight: 500;
  color: ${(props) => (props.selected ? "#2C509F" : "#35364f")};
  text-transform: capitalize;
  margin-left: 20px;
  margin-top: 10px;

  @media only screen and (max-width: 1200px){
    font-size: 14px;
  }

  @media only screen and (max-width: 600px){
    font-size: 12px;
  }
`;

export const MenuItemIcon = styled.span`
  height: 20px;
  width: 20px;
  & svg {
    width: ${(props) => (props.item.name == "Manage Account" && "28px")};
    height: ${(props) => (props.item.name == "Manage Account" && "28px")};
    margin-left: ${(props) => (props.item.name == "Manage Account" ? "5px": "0px")};
    color: ${(props) => (props.item.name == "Manage Account" && "blue")};
  }
  & svg circle,
  path {
    stroke: ${(props) => (props.selected ? "#4385C6 !important" : null)};
  }
`;

export const MenuItemsContainer = styled.div`
  margin-top: 30px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: ${(props) => (props.patient ? "290px" : "290px")};
  width: 100%;
  border-radius: 10px;
  background: #E3E3E34D;

  @media only screen and (max-width: 1200px){
    height: auto;
    border-radius: 7px;
    cursor: pointer;
    width: auto;
    margin: 0;
    margin-top: 10px;  
    padding-top: 10px;
    margin-left: -10px;
    background-color: #fff;
    -webkit-box-shadow: 0px 0px 6px 0px rgba(204,204,204,0.5);
    -moz-box-shadow: 0px 0px 6px 0px rgba(204,204,204,0.5);
    box-shadow: 0px 0px 6px 0px rgba(204,204,204,0.5);
  }
`;

export const AccountInfoWrapper = styled.div`
  height: auto;
  width: 100%;
  border-radius: 10px;
  background: #fff;
  padding: 25px 0px 30px 0px;
  /* overflow: scroll; */
  @media only screen and (max-width:1200px){
   width: 100%;
 }
 @media only screen and (max-width:900px){
   width: auto;
 }
`;

export const SelectedPageName = styled.h6`
  font-family:Metropolis Bold;
  font-size: 20px;
  font-weight: 700;
  color: #000;
  text-transform: capitalize;
  ${'' /* margin-left: ${(props)=>(props.name === "Account Info" ? "30px" : "0px")}; */}
  @media only screen and (max-width: 1200px) {
    margin-left: 0px;
  }
`;

export const ManageDoctorProfile = styled.div`
  margin-top: -120px;
  padding-right:50px;
  display: flex;
  flex-direction:column;
  align-items: flex-end;
  height: auto;
  width: 100%;
  border-radius: 10px;

  /* padding: 25px 15px 30px 40px; */
  @media only screen and (max-width: 1200px) {
    flex-direction:wrap;
  }
`;

export const ManageProfileText = styled.span`
  font-family: 'Urbanist', sans-serif;
  font-weight: 500;
  font-size: 16px;
  color: #03183C;
`;

export const ManageProfileButton = styled.button`
  height: 60px;
  width: 185px;
  border-radius: 7px;
  background: #4385C6;
  border: 1px solid #ebeaed;
  font-family: 'Urbanist', sans-serif;
  font-size: 14px;
  color: #fff;
  cursor: pointer;
  align-self: flex-end;
  margin-right: 50px;
  margin-top: -160px;
`;

export const ModalContent = styled.div`
  max-height: 70vh;
  height: 100%;
  width: 100%;
  overflow: auto;
`;

export const LogoutConfirmationText = styled.p`
  margin-top: 20px;
  font-family: 'Urbanist', sans-serif;
  font-size: 16px;
  color: #000;
  text-align: center;
`;
