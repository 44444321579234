import React, { useEffect, useState } from "react";

const Email = ({ email, setEmail, skipEmail }) => {
  const [oauthData, setOauthData] = useState(null);

  useEffect(() => {
    const storedData = localStorage.getItem("oauthData");
    if (storedData) {
      setOauthData(JSON.parse(storedData));
    }
  }, []);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        maxWidth: "815px",
        width: "100%",
        padding: "5% 0",
        gap: "clamp(24px, 4vw, 36px)",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "12px",
        }}
      >
        <span
          style={{
            fontSize: "clamp(24px, 5vw, 32px)",
            fontWeight: "700",
            color: "#262626",
            fontFamily: "Metropolis Bold, sans-serif",
          }}
        >
          What is your email?
        </span>
        <span
          style={{
            fontSize: "clamp(16px, 3vw, 20px)",
            fontWeight: "400",
            color: "#262626",
            fontFamily: "Metropolis Regular, sans-serif",
          }}
        >
          Help us reach out to you with better recommendations (Optional)
        </span>
      </div>
      <div style={{ display: "flex", justifyContent: "center" }}>
        <input
          style={{
            width: "100%",
            maxWidth: "386px",
            height: "clamp(60px, 10vw, 90px)",
            borderRadius: "8px",
            border: "1px solid #ABABAB",
            padding: "16px",
            backgroundColor: "#FBFBFB",
            fontSize: "clamp(20px, 4vw, 28px)",
            fontWeight: "700",
            color: "#000000",
            fontFamily: "Metropolis Bold, sans-serif",
            textAlign: "center",
          }}
          value={email}
          onChange={(e) => {
            setEmail(e.target.value);
          }}
          disabled={oauthData?.email}
        />
      </div>
      <span
        style={{
          display: "flex",
          justifyContent: "center",
          fontSize: "clamp(16px, 3vw, 20px)",
          fontWeight: "700",
          color: "#2C509F",
          fontFamily: "Metropolis Bold, sans-serif",
        }}
        onMouseOver={(e) => (e.target.style.cursor = "pointer")}
        onClick={skipEmail}
      >
        Skip this step
      </span>
    </div>
  );
};

export default Email;
