import React from "react";

const FormatPhoneNumber = ({ phoneNumber, countryCode }) => {
  const codeLength = countryCode.length;
  const formattedNumber = `${phoneNumber.slice(
    0,
    codeLength
  )}-${phoneNumber.slice(codeLength)}`;
  return formattedNumber;
};

export default FormatPhoneNumber;
