import React, { useEffect, useState } from "react";
import {
  UpdateAreaContainer,
  PageHeading,
  SubHeading,
} from "../styledComponents";
import { StyledRow } from "../ServicesSpecialities/styledComponents";
import { Form, Input, message, Select } from "antd";
import { FormContainer } from "../EducationExperience/styledComponents";
import { StyledFormItem } from "../../signup/styledComponents";
import { SectionHeading } from "../ServicesSpecialities/styledComponents";
import { DisplayDurationContainer, DurationItem } from "./styledComponents";
import ApiCalls from "../../../apis/ApiCalls";

const Pricing = ({
  trigger,
  setTrigger,
  setCurrentPage,
  setLastCompletedPage,
  selectedValues,
  fromMyAccount,
}) => {
  const [form] = Form.useForm();
  const [selectedDuration, setSelectedDuration] = React.useState(15);
  const [currencies, setCurrencies] = useState([]);

  const duration = [
    { id: 15, value: "15 mins" },
    { id: 30, value: "30 mins" },
    { id: 45, value: "45 mins" },
    { id: 60, value: "1 hr" },
    { id: 90, value: "1.5 hr" },
    { id: 120, value: "2 hrs" },
  ];

  const getCurrencies = () => {
    ApiCalls.fetchCurrencies()
      .then((response) => {
        if (response.data.status) {
          setCurrencies(response.data.data.currencies);
        }
      })
      .catch((err) => { /*Error*/ });
  };

  const onFinish = (value) => {
    let data = {
      consultationFee: parseInt(value.consultationFee),
      ...value,
      consultationDuration: selectedDuration,
    };

    ApiCalls.updateDoctorWorkProfileInfo(data)
      .then((response) => {
        if (response.data.status) {
          setTrigger(0);
          message.success("Updated pricing details successfully");
          if (!fromMyAccount) {
            setCurrentPage((prev) => prev + 1);
            setLastCompletedPage((prev) => prev + 1);
          }
        } else {
          message.error(response.data.data.message);
        }
      })
      .catch((error) =>
        message.error(
          error.response
            ? error?.response?.data?.data?.message
            : "No Response From Server",
          5
        )
      );
  };

  useEffect(() => {
    trigger && form.submit();
    // eslint-disable-next-line
  }, [trigger]);

  useEffect(() => {
    getCurrencies();
  }, []);

  useEffect(() => {
    if (selectedValues.currency) {
      selectedValues.consultationFee =
        selectedValues.consultationFee.toString();
      form.setFieldsValue({ ...selectedValues });
      setSelectedDuration(selectedValues.consultationDuration);
    }
    // eslint-disable-next-line
  }, [selectedValues]);

  return (
    <>
      <UpdateAreaContainer>
        <PageHeading>Pricing</PageHeading>
        <SubHeading>
          Let your customer know your prices so they can choose based on their
          budget!
        </SubHeading>
        <FormContainer style={{ padding: "0" }}>
          <Form form={form} onFinish={onFinish}>
            <StyledRow>
              <StyledFormItem
                name="currency"
                label="Currency"
                rules={[
                  {
                    required: true,
                    message: "Currency is required",
                  },
                ]}
                type2={true}
              >
                <Select
                  placeholder="Select currency"
                  showSearch
                  filterOption={(inputValue, option) =>
                    option.value
                      .toUpperCase()
                      .includes(inputValue.toUpperCase())
                  }
                  disabled={fromMyAccount}
                >
                  {currencies.map((currency) => {
                    return (
                      <Select.Option key={currency._id} value={currency.code}>
                        {currency.code}
                      </Select.Option>
                    );
                  })}
                </Select>
              </StyledFormItem>
            </StyledRow>

            <StyledRow>
              <StyledFormItem
                name="consultationFee"
                label="Consultation Fee"
                rules={[
                  {
                    required: true,
                    message: "Consultation fee is required",
                  },
                ]}
                type2={true}
              >
                <Input placeholder="Enter consultation fee" />
              </StyledFormItem>
            </StyledRow>
            <SectionHeading>Experience</SectionHeading>
            <StyledRow>
              <StyledFormItem
                name="totalWorkExperiance"
                label="Total Work Experience in Years"
                rules={[
                  {
                    required: true,
                    message: "Total work experience is required",
                  },
                ]}
                type2={true}
              >
                <Input placeholder="Enter your experience in years" />
              </StyledFormItem>
            </StyledRow>
            {fromMyAccount && (
              <>
                <SectionHeading>Follow up days</SectionHeading>
                <StyledRow>
                  <StyledFormItem
                    name="followUpDays"
                    label="Follow up days"
                    rules={[
                      {
                        required: true,
                        message: "Follow up days is required",
                      },
                      () => ({
                        validator(rule, value) {
                          if (
                            (parseInt(value) >= 2 && parseInt(value) <= 30) ||
                            value === ""
                          ) {
                            return Promise.resolve();
                          }
                          return Promise.reject(
                            "Follow up days should be between 2 & 30"
                          );
                        },
                      }),
                    ]}
                    type2={true}
                  >
                    <Input placeholder="In days between 2 and 30" />
                  </StyledFormItem>
                </StyledRow>
              </>
            )}
          </Form>
        </FormContainer>
        <SectionHeading>Period of Consultation</SectionHeading>
        <DisplayDurationContainer>
          {duration.map((item) => (
            <DurationItem
              key={item.id}
              type="text"
              selected={item.id === selectedDuration}
              onClick={() => setSelectedDuration(item.id)}
            >
              {item.value}
            </DurationItem>
          ))}
        </DisplayDurationContainer>
      </UpdateAreaContainer>
    </>
  );
};

export default Pricing;
