import styled from "styled-components";
import { Popover } from "antd";

export const NotificationsContainer = styled.div`
  position: relative;
  width: 309px;
  height: 387px;
  overflow-y: auto;
  overflow-x: hidden;
  border-radius: 10px;
  @media only screen and (max-width:800px){
    height: auto;
    width: auto;
  }

  & .anticon-loading {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  & > div > :not(:last-child) {
    border-bottom: 1px solid #f5f6fa;
  }
`;

export const StyledPopover = styled(Popover)`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

export const NotificationItemContainer = styled.div`
  position: relative;
  display: flex;
  height: auto;
  width: 100%;
  padding: 20px 16px;
  background: ${(props) => (props.read ? null : "aliceblue")};
  cursor: ${(props) => (props.pointer ? "pointer" : null)}; 
`;

export const NotificationIconContainer = styled.div`
  height: 40px;
  width: 40px;
  border-radius: 50%;
  background: ${(props) => `url(${props.bg})`};
  background-position: center;
  background-size: auto auto;
  background-repeat: no-repeat;
  background-color: #fff;
`;

export const NotificationInfo = styled.div`
  height: auto;
  width: calc(100% - 40px);
  padding: 0 0 0 15px;
`;

export const NotificationTitle = styled.h6`
  margin-bottom: 0;
  font-family: "Gilroy Bold";
  font-size: 15px;
  color: #35364f;
`;

export const NotificationTime = styled.span`
  font-family: 'Urbanist', sans-serif;
  font-size: 14px;
  color: #65667d;
`;

export const NotificationMessage = styled.p`
  margin-top: 5px;
  margin-bottom: 0;
  font-family: "Gilroy SemiBold";
  font-size: 14px;
  color: #35364f;
  line-height: 1.3;
`;

export const MarkAsRead = styled.button`
  position: absolute;
  top: 45px;
  right: 5px;
  border: none;
  background: none;
  font-family: 'Urbanist', sans-serif;
  font-size: 13px;
  color: #4385C6;
  cursor: pointer;
`;

export const ClearAll = styled.button`
  width: 100%;
  border: none;
  background: none;
  font-family: 'Urbanist', sans-serif;
  font-size: 14px;
  color: #f94848;
  cursor: pointer;
`;

export const EmptyMessage = styled.p`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  font-family: 'Urbanist', sans-serif;
  font-size: 14px;
  color: #35364f;
  text-align: center;
`;

export const NotificationBellIconWrapper = styled.div`
  position: relative;
  right:30px;
`;

export const NotificationUnreadReminder = styled.div`
  position: absolute;
  top: -5px;
  right: -2px;
  height: 10px;
  width: 10px;
  border-radius: 50%;
  background-color: #4385C6;
`;
