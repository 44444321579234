import React from 'react';
import { StyledModal, ButtonContainer } from './styledComponents';
import PrimaryButton from '../PrimaryButton/index';
const CustomModal = ({
  children,
  visible,
  onCancel,
  title,
  onOk,
  map,
  loading,
  availability,
  okLabel,
  cancelLabel,
  hideFooter,
  showOne,
  cancelAppointment,
  disabled,
  fullScreen,
  type,
  onStripe,
  onRazorPay,
  showFinalConfirmation,
  buttonName
}) => {
  return (
    <>
      <StyledModal
        id={visible}
        visible={visible}
        centered={true}
        closable={false}
        title={title}
        footer={[
          <>
            {!showOne && (
              <ButtonContainer type={type}>
                <PrimaryButton
                  cancel={true}
                  label={cancelLabel ? cancelLabel : 'Cancel'}
                  onClick={onCancel}
                  buttonName={buttonName}
                />
              </ButtonContainer>
            )}
          </>,
          <>
            {(type === 'NormalPatient' && !showFinalConfirmation) ? (
              <>
                {/* <ButtonContainer style={{ width: '50%', marginRight: '15px' }}>
                  <PrimaryButton
                    label='Pay using stripe'
                    onClick={onStripe}
                    loading={loading}
                    cancelAppointment={cancelAppointment}
                    disabled={disabled}
                  />
                </ButtonContainer> */}
                <ButtonContainer>
                  <PrimaryButton
                    label='Pay using razorpay'
                    onClick={onRazorPay}
                    loading={loading}
                    cancelAppointment={cancelAppointment}
                    disabled={disabled}
                  />
                </ButtonContainer>
              </>
            ) : (
              <ButtonContainer showOne={showOne}>
                <PrimaryButton
                  label={okLabel ? okLabel : map ? 'Done' : 'Add'}
                  onClick={onOk}
                  loading={loading}
                  cancelAppointment={cancelAppointment}
                  disabled={disabled}
                  buttonName={buttonName}
                />
              </ButtonContainer>
            )}
          </>
        ]}
        destroyOnClose={true}
        map={map}
        availability={availability}
        hideFooter={hideFooter}
        cancelAppointment={cancelAppointment}
        fullScreen={fullScreen}
      >
        {children}
      </StyledModal>
    </>
  );
};

export default CustomModal;
