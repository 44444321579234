import React, { useRef, useEffect } from "react";
import { ListItemContainer, Item } from "./styledComponents";
import { message, Select } from "antd";
import InfiniteScroll from "react-infinite-scroller";
import { LoadingOutlined } from "@ant-design/icons";

const CustomSearchSelect = ({
  valueKeyName,
  title,
  searchValue,
  setSearchValue,
  placeholder,
  emptyMessage,
  selectedValue,
  setSelectedValue,
  searchFunction,
  scrollLoading,
  hasMore,
  data,
}) => {
  const parentRef = useRef();
  const searchSelectRef = useRef();

  const onSearch = (val) => {
    const reg = new RegExp(/^[a-zA-Z0-9_.-]*$/);
    if ((!Number.isNaN(val) && reg.test(val)) || val === "" || val === "-") {
      setSearchValue(val);
    } else {
      message.destroy();
      message.warning("Unsupported character detected in search field");
    }
  };

  const onClear = () => {
    setSelectedValue(null);
  };

  const dropdownRender = () => {
    return (
      <ListItemContainer ref={parentRef}>
        {data.length < 1 && scrollLoading ? (
          <LoadingOutlined />
        ) : data.length < 1 ? (
          <span>{emptyMessage}</span>
        ) : (
          <InfiniteScroll
            initialLoad={false}
            pageStart={0}
            loadMore={searchFunction}
            hasMore={!scrollLoading && hasMore}
            useWindow={false}
            key={searchValue}
            getScrollParent={() => parentRef.current}
          >
            {data.map((item) => {
              return (
                <Item
                  id={item._id}
                  selected={selectedValue === item[valueKeyName]}
                  onClick={() => {
                    setSelectedValue(item[valueKeyName]);
                    searchSelectRef.current.blur();
                  }}
                >
                  {item[valueKeyName]}
                </Item>
              );
            })}
            {scrollLoading && (
              <Item loading={true}>
                <LoadingOutlined />
              </Item>
            )}
          </InfiniteScroll>
        )}
      </ListItemContainer>
    );
  };

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      searchFunction(0);
    }, 500);
    return () => {
      clearInterval(delayDebounceFn);
    };
    // eslint-disable-next-line
  }, [searchValue]);

  return (
    <Select
      title={title}
      showSearch
      placeholder={placeholder}
      onSearch={onSearch}
      value={[selectedValue]}
      dropdownRender={dropdownRender}
      bordered={false}
      ref={searchSelectRef}
      allowClear={true}
      onClear={onClear}
    />
  );
};

export default CustomSearchSelect;
