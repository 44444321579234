import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom';
import axios from "axios"
import { LoadingOutlined } from "@ant-design/icons";
import { BlogsPageWrapper, PageTitle, EmptyResultMessageRecord } from "./styledComponents"

const BlogDetail = () => {
    const { slug } = useParams();
    const [blogData, setBlogData] = useState(null);
    const [loading, setLoading] = useState(true);
    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(`https://telehealings.com/wp-json/wp/v2/posts`);
                const selectedItem = response.data.find(item => item.slug === slug);
                setBlogData(selectedItem);
                setLoading(false);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData();
    }, []);
    const modifyImageDimensions = (htmlString) => {
        const regex = /<img.*?src="(.*?)".*?>/g;
        const modifiedHtml = htmlString.replace(regex, (match, src) => {
            const newSrc = `${src}?w=75%&h=75%`;
            return `<img src="${newSrc}" width="75%" height="75%" />`;
        });
        return { __html: modifiedHtml };
    };
    return (
        <BlogsPageWrapper>
            <PageTitle>Article Detail</PageTitle>
            <div>
                {loading ? (
                    <LoadingOutlined />
                ) : blogData ? (
                    <div dangerouslySetInnerHTML={modifyImageDimensions(blogData.content.rendered)} />
                ) : (
                    <div>
                        {/* <NoImageFound src={family} width="auto" height="auto" /> */}
                        <EmptyResultMessageRecord>No article found !!!</EmptyResultMessageRecord>
                    </div>
                )}
            </div>
        </BlogsPageWrapper>
    )
}

export default BlogDetail