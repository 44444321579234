import React, { useState } from "react";
import OtpInput from "../signup/OtpInput";
import { Countdown } from "../Hooks/CountdownTimer";
import SignUp from "./Buttons/SignUp";

const OtpVerification = ({ otp, setOtp, verifyLoginOtp, onResendOtp }) => {
  const [isResendActive, setIsResendActive] = useState(true);

  const handleResend = () => {
    if (!isResendActive) {
      onResendOtp();
      setIsResendActive(true);
    }
  };

  return (
    <>
      <div style={{ display: "flex", flexDirection: "column", gap: "36px" }}>
        <span
          style={{
            fontSize: "32px",
            fontWeight: "400",
            color: "#000000",
            fontFamily: "Metropolis Black",
          }}
        >
          Enter OTP Code 🔐
        </span>
        <div style={{ maxWidth: "400px" }}>
          <span
            style={{
              fontSize: "20px",
              fontWeight: "400",
              color: "#000000",
              fontFamily: "Metropolis Regular",
            }}
          >
            Check your messages for a message from Telehealings. Enter the
            One-Time-Password (OTP) you received below.
          </span>
        </div>
        <OtpInput otp={otp} setOtp={setOtp} />

        <div
          style={{
            display: "flex",
            width: "100%",
            justifyContent: "'center",
            alignItems: "center",
            gap: "8px",
          }}
        >
          <span
            style={{
              fontSize: "12px",
              fontWeight: "700",
              color: "#000000",
              fontFamily: "Metropolis Bold",
            }}
          >
            Didn’t Receive Code?
          </span>
          <span
            style={{
              fontSize: "12px",
              fontWeight: "700",
              color: isResendActive ? "#000000" : "#2C509F",
              fontFamily: "Metropolis Bold",
              cursor: isResendActive ? "not-allowed" : "pointer",
              opacity: isResendActive ? 0.5 : 1,
              pointerEvents: isResendActive ? "none" : "auto",
            }}
            onClick={handleResend}
          >
            Resend{" "}
            {isResendActive ? (
              <Countdown
                seconds={45}
                onComplete={() => setIsResendActive(false)}
              />
            ) : (
              ""
            )}
          </span>
        </div>
        <SignUp onFinish={verifyLoginOtp} />
      </div>
    </>
  );
};

export default OtpVerification;
