import React, { useState, useEffect } from 'react';
import {
  CardContainer,
  Details,
  EmployeeProfileContainer,
  EmployeeInfo,
  Profile,
  Name,
  EmailId,
  Number,
  EmployeeDetails,
  EmployeeTaken,
  CreditUsed,
  JoingingDate,
  EmployeeEvent,
  BlockButton,
  CancelButton,
  DividerLine
} from './styledComponent';
import { useLocation } from 'react-router-dom';
import { Divider, message } from 'antd';
import { CircularProgress } from '@mui/material';
import Mail from '../../../assets/images/CorporateModule/mail.png';
import ApiCalls from '../../../apis/ApiCalls';
import moment from 'moment/moment';
import ImageNotAvailable from '../../../assets/images/CorporateModule/Image_not_available.png';

const BlockUser = () => {
  const location = useLocation();
  const [employeeDetails, setEmployeeDetails] = useState({});
  let employee = location.state.employee;
  const [blockLoader, setBlockLoader] = useState(false);

  // Fetch employee details
  const getEmployeeDetails = () => {
    ApiCalls.getEmployeeDetails(employee._id)
      .then((res) => {
        if (res.data.status) {
          setEmployeeDetails(res.data.data);
        }
      })
      .catch((error) => {
        message.error(
          error.response ? error?.response?.data?.data?.message : 'No Response From Server',
          5
        );
      });
  };

  // Block employee
  const onBlockEmployee = () => {
    let companyId = employee?.companyDetails?.companyId;
    let employeeId = employee?._id;
    setBlockLoader(true);
    ApiCalls.blockEmployee(companyId, employeeId)
      .then((res) => {
        if (res.data.status) {
          message.success('Successfully blocked from emloyess list');
          getEmployeeDetails();
        }
        setBlockLoader(false);
      })
      .catch((error) => {
        message.error(
          error.response ? error?.response?.data?.data?.message : 'No Response From Server',
          5
        );
        setBlockLoader(false);
      });
  };

  // Unblock employee
  const onUnBlockEmployee = () => {
    let companyId = employee?.companyDetails?.companyId;
    let employeeId = employee?._id;
    setBlockLoader(true);
    ApiCalls.unBlockEmployee(companyId, employeeId)
      .then((res) => {
        if (res.data.status) {
          message.success('Unblocked the employee');
          getEmployeeDetails();
        }
        setBlockLoader(false);
      })
      .catch((error) => {
        message.error(
          error.response ? error?.response?.data?.data?.message : 'No Response From Server',
          5
        );
        setBlockLoader(false);
      });
  };

  useEffect(() => {
    getEmployeeDetails();
    // eslint-disable-next-line
  }, []);

  return (
    <CardContainer>
      <EmployeeProfileContainer>
        <EmployeeInfo>
          <Profile>
            <img
              src={employeeDetails.profilePic}
              alt='No img found'
              onError={(e) => {
                if (e.target.src !== ImageNotAvailable) {
                  e.target.src = ImageNotAvailable;
                }
              }}
            />
          </Profile>
          <Name userName={true}>{employeeDetails.fullName}</Name>
          <Details
            style={{
              display: 'flex',
              flexDirection: 'row',
              marginTop: '15px'
            }}
          >
            <img
              src={Mail}
              alt='No img found'
              height='15px'
              width='25px'
            />
            <EmailId>{employeeDetails.email}</EmailId>
          </Details>
        </EmployeeInfo>
        <DividerLine></DividerLine>

        <EmployeeDetails>
          <EmployeeTaken>Appointments taken</EmployeeTaken>
          <Number>{employeeDetails.totalAppointmentsTaken}</Number>
          <Divider />
          <CreditUsed>Credit Used</CreditUsed>
          <Number>{employeeDetails.creditsUsed}</Number>
          <Divider />
          <JoingingDate>Joining Date</JoingingDate>
          <Number>{moment(employeeDetails.createdAt).format('Do MMMM YYYY')}</Number>

          <EmployeeEvent>
            {employeeDetails?.status === 'blocked' ? (
              <CancelButton onClick={onUnBlockEmployee}>
                {blockLoader ? <CircularProgress size={20} color='inherit' /> : 'Unblock'}
              </CancelButton>
            ) : (
              <BlockButton onClick={onBlockEmployee}>
                {blockLoader ? <CircularProgress size={20} color='inherit' /> : 'Block'}
              </BlockButton>
            )}
          </EmployeeEvent>
        </EmployeeDetails>
      </EmployeeProfileContainer>
    </CardContainer>
  );
};

export default BlockUser;
