import React from "react";
import { useEffect } from "react";
import { OtpInfoContainer, OtpInfo, ResendText } from "./styledComponents";

const Timer = ({
  minutes,
  setMinutes,
  seconds,
  setSeconds,
  getNewOtp,
  videoCall,
  recording,
}) => {
  useEffect(() => {
    let myInterval = setInterval(() => {
      if (recording) {
        if (seconds < 60) {
          setSeconds(seconds + 1);
        } else {
          setMinutes(minutes + 1);
          setSeconds(1);
        }
      } else {
        if (seconds > 0) {
          setSeconds(seconds - 1);
        }
        if (seconds === 0) {
          if (minutes === 0) {
            clearInterval(myInterval);
          } else {
            setMinutes(minutes - 1);
            setSeconds(59);
          }
        }
      }
    }, 1000);
    return () => {
      clearInterval(myInterval);
    };
  });

  return (
    <>
      {videoCall || recording ? (
        <>
          {minutes < 10 ? `0${minutes}` : `${minutes}`}:
          {seconds < 10 ? `0${seconds}` : `${seconds}`}
        </>
      ) : (
        <OtpInfoContainer>
          <OtpInfo>
            {minutes === 0 && seconds === 0 ? null : (
              <>
                Your OTP will expire in {minutes}:
                {seconds < 10 ? `0${seconds}.` : `${seconds}.`}
              </>
            )}

            <ResendText onClick={() => getNewOtp()}> Resend OTP</ResendText>
          </OtpInfo>
        </OtpInfoContainer>
      )}
    </>
  );
};

export default Timer;
