import React from "react";
import styled from "styled-components";

const StyledHeading = styled.div`
  font-size: 32px;
  font-weight: 400;
  color: #000000;
  font-family: Metropolis Black;
`;

const Heading = ({ title }) => {
  return <StyledHeading>{title}</StyledHeading>;
};

export default Heading;
