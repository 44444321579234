import styled from "styled-components";

export const ImageContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: ${(props) => (props.medication ? "117px" : "85px")};
  width: ${(props) => (props.medication ? "173px" : "85px")};
  margin-left: 27px;
  border: 1px solid #dde5ed;
  border-radius: 10px;
  cursor: pointer;
  overflow: hidden;
  & img {
    height: 100%;
    width: auto;
  }
  & .anticon {
    color: #35364f;
    font-size: 25px;
  }
`;
