import io from "socket.io-client";
import { SOCKET_SERVER } from "./SocketConstants";
import storage from "../storage";
import { message } from "antd";

export default class SocketClient {
  socket;
  token;

  connect(socketPath) {
    this.token = storage.authToken.getItem();
    this.socket = null;
    this.socket = io.connect(`${SOCKET_SERVER}/${socketPath}`, {
      forceNew: true,
      query: { token: this.token },
    });
    return new Promise((resolve, reject) => {
      this.socket.on("applicationErrors", (error) =>
        message.error(error.message)
      );
      this.socket.on("error", (error) => console.log(error.message));
      resolve();
    });
  }

  disconnect() {
    return new Promise((resolve, reject) => {
      if (!this.socket) return reject("No socket connection.");
      this.socket.disconnect(() => {
        this.socket = null;
        resolve();
      });
    });
  }

  async emit(event, data) {
    await this.socket;
    return new Promise(async (resolve, reject) => {
      if (this.socket) {
        this.socket.emit(event, data);
        resolve();
      } else {
        reject(`${event} No socket connection available for emit event`);
      }
    });
  }

  async on(event, fun) {
    await this.socket;
    return new Promise((resolve, reject) => {
      if (this.socket) {
        this.socket.on(event, fun);
        resolve();
      } else {
        reject(
          `${event} No socket connection available for on event`,
          this.socket
        );
      }
    });
  }
  async off(event, fun) {
    await this.socket;
    return new Promise((resolve, reject) => {
      if (this.socket) {
        this.socket.off(event, fun);
        resolve();
      } else {
        reject(
          `${event} No socket connection available for on event`,
          this.socket
        );
      }
    });
  }

  removeAllListeners(event) {
    this.socket.removeAllListeners(event);
  }
}
