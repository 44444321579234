import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Form, Input, Checkbox, Row, Col, message, Divider, Select } from 'antd';
import { NavLink, useHistory, useParams, withRouter } from 'react-router-dom';
import {
  FormWrapper,
  StyledFormItem,
  CorporateContainer,
  CorporateWrapper,
  Heading,
  Text,
  FormContainer
} from './styledComponents';
import PrimaryButton from '../../components/PrimaryButton/index';
import { UserTypesWrapper, UserTypeContainer, UserName } from '../login/styledComponents';
import ApiCalls from '../../apis/ApiCalls';

const CustomCol = styled(Col)`
  & > div {
    margin-bottom: 0px;
  }
  .success:hover {
    border: 2px solid red;
  }
`;

const FormStyle = styled(Form)`
  /* width: 100%; */
`;

const CorporateRegister = (props) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [loginType, setLoginType] = useState(null);
  const [category, setCategory] = useState([]);
  const [companyRole, setCompanyRole] = useState([]);
  const [companySize, setCompanySize] = useState([]);
  const { userType } = useParams();
  // const fileInputRef = useRef(null);
  const history = useHistory();

  // Input file change
  // const fileChange = (e) => {
  //   console.log(e.target.files);
  // };

  // On image pick
  // const imageChange = () => {
  //   fileInputRef.current.click();
  // };

  // User type change
  useEffect(() => {
    if (userType === 'patient') setLoginType('patient');
    else if (userType === 'doctor') setLoginType('doctor');
    else if (userType === 'corporate') setLoginType('corporate');
    // eslint-disable-next-line
  }, [userType]);

  // Sign up corporate
  const SignUpCorporate = (values) => {
    let companyCategory = JSON.parse(values.companyCategory);

    let request = {
      companyCategory: {
        categoryName: companyCategory.categoryName,
        categoryId: companyCategory._id
      },
      companyName: values.companyName,
      companyWebsite: values.companyWebsite,
      companyEmail: values.companyEmail,
      email: values.email,
      companySize: values.employeeCount,
      location: values.location,
      password: values.password,
      role: values.role,
      currency: values.currency
    };

    // Corporate register api
    ApiCalls.CorporateRegister(request)
      .then((response) => {
        if (response.data.status === 1) {
          message.success('Signup successful. Please verify your e-mail to login');
          props.history.push('/user/corporate/login');
        } else {
          message.error(response.data.message);
        }
      })
      .catch((error) => {
        if (error.response) {
          message.error(
            error.response ? error?.response?.data?.data?.message : 'No Response From Server',
            5
          );
        }
      })
      .finally(() => setLoading(false));
  };

  // Company categories api
  const AllCategories = () => {
    ApiCalls.getAllCategories()
      .then((response) => {
        if (response.data.status === 1) {
          let tempRole = [];
          response.data.data.forEach((item) => {
            tempRole.push({
              label: item.categoryName,
              value: JSON.stringify(item)
            });
          });
          setCategory(tempRole);
        }
      })
      .catch((error) => {
        if (error.response) {
          message.error(
            error.response ? error?.response?.data?.data?.message : 'No Response From Server',
            5
          );
        }
      });
  };

  // Company roles api
  const CompanyRole = () => {
    ApiCalls.CompanySignupDetails()
      .then((response) => {
        if (response.data.status === 1) {
          let tempRole = [];
          let tempSize = [];
          response.data.data.companySignupRoles.forEach((item) => {
            tempRole.push({
              label: item,
              value: item
            });
          });
          response.data.data.companySize.forEach((item) => {
            tempSize.push({
              label: item,
              value: item
            });
          });
          setCompanyRole(tempRole);
          setCompanySize(tempSize);
        }
      })
      .catch((error) => {
        if (error.response) {
          message.error(
            error.response ? error?.response?.data?.data?.message : 'No Response From Server',
            5
          );
        }
      });
  };

  useEffect(() => {
    // Fetching company categories and roles
    AllCategories();
    CompanyRole();
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <CorporateContainer>
        <CorporateWrapper>
          <Heading>Create an account</Heading>
          <Text>Great, it’s a pleasure to have you here, Let’s get you set up.</Text>

          <UserTypesWrapper Corporate={true}>
            <UserTypeContainer
              selected={loginType === 'patient'}
              onClick={() => {
                history.push('/user/patient/login');
              }}
            >
              <UserName>Patient</UserName>
            </UserTypeContainer>

            <UserTypeContainer
              selected={loginType === 'doctor'}
              onClick={() => {
                history.push('/user/doctor/login');
              }}
            >
              <UserName>Therapist</UserName>
            </UserTypeContainer>
            <UserTypeContainer
              selected={loginType === 'corporate'}
              onClick={() => {
                history.push('/user/corporate/login');
              }}
            >
              <UserName>Corporate</UserName>
            </UserTypeContainer>
          </UserTypesWrapper>
          <Divider />
        </CorporateWrapper>
        <FormContainer>
          <FormWrapper>
            <Col span={24}>
              <Row>
                <FormStyle
                  layout='vertical'
                  form={form}
                  initialValues={{
                    layout: 'vertical'
                  }}
                  onFinish={SignUpCorporate}
                >
                  <Row
                    style={{
                      justifyContent: 'space-between',
                      width: '100%',
                      marginBottom: '15px'
                    }}
                  >
                    <CustomCol span={24}>
                      <StyledFormItem 
                        name='role'
                        rules={[
                          {
                            required: true,
                            message: 'Role is required'
                          }
                        ]}  
                      >
                        <Select
                          style={{ cursor: 'pointer' }}
                          showSearch
                          placeholder='Role'
                          optionFilterProp='children'
                          filterOption={(input, option) =>
                            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                          }
                          options={companyRole}
                        />
                      </StyledFormItem>
                    </CustomCol>
                  </Row>

                  <Row style={{ marginBottom: '15px' }}>
                    <CustomCol span={24}>
                      <StyledFormItem
                        name='email'
                        rules={[
                          {
                            required: true,
                            message: 'Email is required'
                          }
                        ]}
                        key={loginType === 'Corporate'}
                      >
                        <Input placeholder='Your Email' />
                      </StyledFormItem>
                    </CustomCol>
                  </Row>

                  <Row style={{ marginBottom: '15px' }}>
                    <CustomCol span={24}>
                      <StyledFormItem
                        name='password'
                        rules={[
                          {
                            required: true,
                            message: 'Password is required'
                          }
                        ]}
                        key={loginType === 'corporate'}
                      >
                        <Input.Password
                          type='password'
                          placeholder='Your Password'
                          size='large'
                          corporatePassword={true}
                        />
                      </StyledFormItem>
                    </CustomCol>
                  </Row>

                  <Row style={{ marginBottom: '15px' }}>
                    <CustomCol span={24}>
                      <StyledFormItem
                        dependencies={['password']}
                        name='confirm'
                        hasFeedback
                        rules={[
                          {
                            required: true,
                            message: 'Confirm Password is required'
                          },
                          ({ getFieldValue }) => ({
                            validator(rule, value) {
                              if (!value || getFieldValue('password') === value) {
                                return Promise.resolve();
                              }
                              return Promise.reject(
                                'The two passwords that you entered do not match!'
                              );
                            }
                          })
                        ]}
                      >
                        <Input.Password type='password' placeholder='Confirm Password' />
                      </StyledFormItem>
                    </CustomCol>
                  </Row>

                  <Row style={{ marginBottom: '15px' }}>
                    <CustomCol span={24}>
                      <StyledFormItem
                        name='location'
                        rules={[
                          {
                            required: true,
                            message: 'Local/Area is required'
                          }
                        ]}
                        key={loginType === 'corporate'}
                      >
                        <Input type='Local' placeholder='Local/Area' size='large' />
                      </StyledFormItem>
                    </CustomCol>
                  </Row>

                  <Row style={{ marginBottom: '15px' }}>
                    <CustomCol span={24}>
                      <StyledFormItem name='companyName'>
                        <Input type='Category' placeholder='Name of company' size='large' />
                      </StyledFormItem>
                    </CustomCol>
                  </Row>

                  <Row style={{ marginBottom: '15px' }}>
                    <CustomCol span={24}>
                      <StyledFormItem name='companyEmail'>
                        <Input type='Category' placeholder='Email of company' size='large' />
                      </StyledFormItem>
                    </CustomCol>
                  </Row>

                  <Row style={{ marginBottom: '15px' }}>
                    <CustomCol span={24}>
                      <StyledFormItem name='companyCategory'>
                        <Select
                          style={{ cursor: 'pointer' }}
                          showSearch
                          placeholder='Category of company'
                          optionFilterProp='children'
                          filterOption={(input, option) =>
                            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                          }
                          options={category}
                        />
                      </StyledFormItem>
                    </CustomCol>
                  </Row>

                  <Row style={{ marginBottom: '15px' }}>
                    <CustomCol span={24}>
                      <StyledFormItem name='employeeCount'>
                        <Select
                          style={{ cursor: 'pointer' }}
                          showSearch
                          placeholder='Number of employees'
                          optionFilterProp='children'
                          filterOption={(input, option) =>
                            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                          }
                          options={companySize}
                        />
                      </StyledFormItem>
                    </CustomCol>
                  </Row>

                  <Row style={{ marginBottom: '15px' }}>
                    <CustomCol span={24}>
                      <StyledFormItem name='companyWebsite'>
                        <Input type='Website' placeholder='Company Website' size='large' />
                      </StyledFormItem>
                    </CustomCol>
                  </Row>

                  <Row style={{ marginBottom: '15px' }}>
                    <CustomCol span={24}>
                      <StyledFormItem name='currency'>
                        <Select
                          style={{ cursor: 'pointer' }}
                          showSearch
                          placeholder='Currency'
                          optionFilterProp='children'
                          filterOption={(input, option) =>
                            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                          }
                          options={[
                            {
                              label: 'INR',
                              value: 'INR'
                            }
                          ]}
                        />
                      </StyledFormItem>
                    </CustomCol>
                  </Row>
                  {/* <Row style={{ marginBottom: "23px" }}>
                    <CustomCol span={24}>
                      <FileInputWrapper onClick={imageChange}>
                        <StyledFormItem
                          corporateCompanyLogo={true}
                          name="logo"
                          // label="Company Logo"
                        >
                          <input
                            type="file"
                            placeholder="Click here to upload image"
                            size="large"
                            style={{ display: "none" }}
                            ref={fileInputRef}
                            // onChange={fileChange}
                          />
                          <img
                            src={Addlogo}
                            alt="No img found"
                            style={{
                              height: "55px",
                              width: "55px",
                              cursor: "pointer",
                            }}
                            
                            // ref={fileInputRef}
                            
                          />
                          <TextHere>
                            Click{" "}
                            <span
                              style={{ color: "#4385C6", cursor: "pointer" }}
                            >
                              here
                            </span>{" "}
                            to upload image
                          </TextHere>
                        </StyledFormItem>
                      </FileInputWrapper>
                    </CustomCol>
                  </Row> */}

                  <Row justify='center' align='center' style={{ marginTop: '10px' }}>
                    <Col span={24}>
                      <StyledFormItem
                        name='acceptCheckbox'
                        valuePropName='checked'
                        rules={[
                          {
                            validator: (_, value) =>
                              value ? Promise.resolve() : Promise.reject('Should accept agreement')
                          }
                        ]}
                      >
                        <Checkbox style={{ fontSize: '12px' }}>
                          By checking this box,you agree to Telehealings's{' '}
                          <a
                            style={{ color: '#3E9EAB', fontSize: '12px' }}
                            href='https://www.telehealings.com/terms-and-conditions'
                            // eslint-disable-next-line
                            target='_blank'
                          >
                            Service & License Agreement
                          </a>
                        </Checkbox>
                      </StyledFormItem>
                    </Col>
                  </Row>

                  <Row justify='center' style={{ marginTop: '10px' }}>
                    <Col span={24}>
                      <StyledFormItem>
                        <PrimaryButton label='Sign Up' htmlType='submit' loading={loading} />
                        <Divider />
                      </StyledFormItem>
                    </Col>
                  </Row>

                  <Row justify='center' style={{ margin: '10px auto 10px auto', fontSize: '14px' }}>
                    Already have an account ?
                    <NavLink to='/user/corporate/login' style={{ color: '#3E9EAB' }}>
                      &nbsp;Sign in
                    </NavLink>
                  </Row>
                </FormStyle>
              </Row>
            </Col>
          </FormWrapper>
        </FormContainer>
      </CorporateContainer>
    </>
  );
};

export default withRouter(CorporateRegister);