import React, { useState, useEffect } from "react";
import {
  StyledForm,
  StyledRow,
  StyledColumn,
} from "../BasicProfile/styledComponents";
import { StyledFormItem } from "../../signup/styledComponents";
import { Input, message, Select } from "antd";
import ApiCalls from "../../../apis/ApiCalls";
import { useHistory } from "react-router-dom";

const BankDetailsForm = ({
  trigger,
  setTrigger,
  setCurrentPage,
  setLastCompletedPage,
  selectedValues,
  fromMyAccount,
}) => {
  const [form] = StyledForm.useForm();
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [selectedState, setSelectedState] = useState(null);
  const [countries, setCountries] = useState([]);
  const [cityList, setCityList] = useState([]);
  const [statesList, setStatesList] = useState([]);
  const history = useHistory();

  const onFinish = (values) => {
    let payload = {
      accountDetails: {
        bankName: values?.bankName,
        accountHolderName: values?.accountHolderName,
        accountNumber: values?.accountNumber,
        ifsc: values?.ifsc,
        branchName: values?.branchName,
        city: values?.city,
        countryCode: selectedCountry?.countryCode,
        stateCode: selectedState?.stateCode,
        state: selectedState?.name,
        country: selectedCountry?.name,
        timeZone: selectedCountry?.timezones[0].zoneName,
      },
    };

    let details = payload.accountDetails;
    let optionalForm = () => {
      let isOptional = true;
      Object.keys(details).forEach(function (key) {
        if (details[key] !== undefined && details[key] !== "") {
          isOptional = false;
        }
      });
      return isOptional;
    };

    if (optionalForm()) {
      setTrigger(0);
      if (!fromMyAccount) {
        setCurrentPage((prev) => prev + 1);
        setLastCompletedPage((prev) => prev + 1);
        history.push("/user/doctor/update-profile?completed=true");
      }
    } else {
      ApiCalls.updateDoctorWorkProfileInfo(payload)
        .then((response) => {
          if (response.data.status) {
            setTrigger(0);
            message.success("Updated bank details successfully");
            if (!fromMyAccount) {
              setCurrentPage((prev) => prev + 1);
              setLastCompletedPage((prev) => prev + 1);
              history.push("/user/doctor/update-profile?completed=true");
            }
          }
        })
        .catch((error) => {
          message.error(
            error.response
              ? error?.response?.data?.data?.message
              : "No Response From Server",
            5
          );
        });
    }
  };

  useEffect(() => {
    ApiCalls.getCountries().then((response) => {
      if (response.data.status) {
        const countries = response.data.data;
        if (selectedValues?.bankName) {
          setSelectedCountry(
            countries.find(
              (country) => country.countryCode === selectedValues.countryCode
            )
          );
        }
        setCountries(countries);
      }
    });
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    selectedCountry &&
      ApiCalls.getStates(selectedCountry.countryCode).then((response) => {
        if (response.data.status) {
          const states = response.data.data.state;
          if (selectedValues?.bankName) {
            setSelectedState(
              states.find(
                (state) => state.stateCode === selectedValues.stateCode
              )
            );
          }
          setStatesList(states);
        }
      });
    // eslint-disable-next-line
  }, [selectedCountry]);

  useEffect(() => {
    selectedState &&
      ApiCalls.getAvailableCities(
        selectedCountry.countryCode,
        selectedState.stateCode
      ).then((response) => {
        if (response.data.status) setCityList(response.data.data.city);
      });
    // eslint-disable-next-line
  }, [selectedState]);

  useEffect(() => {
    trigger && form.submit();
    // eslint-disable-next-line
  }, [trigger]);

  useEffect(() => {
    if (selectedValues?.bankName) {
      selectedValues.reAccountNumber = selectedValues.accountNumber;
      form.setFieldsValue({ ...selectedValues });
    }
    // eslint-disable-next-line
  }, [selectedValues]);

  return (
    <>
      <StyledForm form={form} onFinish={onFinish}>
        <StyledRow>
          <StyledFormItem
            name="accountHolderName"
            label="Account holder name"
            rules={[
              {
                required: false,
                message: "Account holder name is required",
              },
            ]}
            type2={true}
          >
            <Input placeholder="Enter account holder name" />
          </StyledFormItem>
        </StyledRow>
        <StyledRow>
          <StyledColumn>
            <StyledFormItem
              name="accountNumber"
              label="Account number"
              rules={[
                {
                  required: false,
                  message: "Account number is required",
                },
                {
                  message: "Only number is allowed",
                  pattern: new RegExp("^[0-9]+$"),
                },
              ]}
              type2={true}
            >
              <Input placeholder="Enter your account number" />
            </StyledFormItem>
          </StyledColumn>
          <StyledColumn>
            <StyledFormItem
              dependencies={["accountNumber"]}
              name="reAccountNumber"
              label="Re-enter account number"
              hasFeedback
              rules={[
                {
                  required: false,
                  message: "Re-enter account number",
                },
                ({ getFieldValue }) => ({
                  validator(rule, value) {
                    if (!value || getFieldValue("accountNumber") === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(
                      "The two account numbers that you entered do not match"
                    );
                  },
                }),
              ]}
              type2={true}
            >
              <Input placeholder="Verify your account number" />
            </StyledFormItem>
          </StyledColumn>
        </StyledRow>
        <StyledRow>
          <StyledFormItem
            name="ifsc"
            label="SWIFT/IFSC code"
            rules={[
              {
                required: false,
                message: "SWIFT/IFSC code is required",
              },
            ]}
            type2={true}
          >
            <Input placeholder="Enter your SWIFT/IFSC code" />
          </StyledFormItem>
        </StyledRow>
        <StyledRow>
          <StyledFormItem
            name="bankName"
            label="Bank name"
            rules={[
              {
                required: false,
                message: "Bank name is required",
              },
            ]}
            type2={true}
          >
            <Input placeholder="Enter your bank  name" />
          </StyledFormItem>
        </StyledRow>
        <StyledRow>
          <StyledFormItem
            name="branchName"
            label="Branch name"
            rules={[
              {
                required: false,
                message: "Branch name is required",
              },
            ]}
            type2={true}
          >
            <Input placeholder="Enter branch name" />
          </StyledFormItem>
        </StyledRow>
        <StyledRow>
          <StyledFormItem
            name="country"
            label="Branch country"
            rules={[
              {
                required: false,
                message: "Branch country is required",
              },
            ]}
            type2={true}
          >
            <Select
              showSearch
              placeholder="Select branch country"
              onSelect={(_, value) => setSelectedCountry(countries[value.key])}
              filterOption={(inputValue, option) =>
                option.value.toUpperCase().includes(inputValue.toUpperCase())
              }
            >
              {countries &&
                countries.map((item, index) => (
                  <Select.Option key={index} value={item.name}>
                    {item.name}
                  </Select.Option>
                ))}
            </Select>
          </StyledFormItem>
        </StyledRow>
        <StyledRow>
          <StyledFormItem
            name="state"
            label="State"
            style={{
              paddingRight: "0",
            }}
            // rules={[
            //   {
            //     required: true,
            //     message: "State is required",
            //   },
            // ]}
            type2={true}
          >
            <Select
              showSearch
              placeholder="Select State"
              onSelect={(_, value) => setSelectedState(statesList[value.key])}
              filterOption={(inputValue, option) =>
                option.value.toUpperCase().includes(inputValue.toUpperCase())
              }
            >
              {statesList &&
                statesList.map((item, index) => (
                  <Select.Option key={index} value={item.name}>
                    {item.name}
                  </Select.Option>
                ))}
            </Select>
          </StyledFormItem>
        </StyledRow>
      </StyledForm>
    </>
  );
};

export default BankDetailsForm;
