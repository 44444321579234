import { Spin } from 'antd';
import styled from "styled-components";

export const CorporateDashboardContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: auto;
  width: 100%;
  background-color: white;
`;
export const CardContainer = styled.div`
  padding: 15px;
  background-color: #fff;
  height: calc(100vh - 80px);
  overflow-y: auto;
  @media only screen and (max-width: 700px) {
    padding: 40px;
  }
`;

export const SubscriptionCard = styled.div`
  display: flex;
  flex-direction: row;
  width: calc(100% - 100px);
  height: ${(props) =>
    props.mysub ? "380px" : props.welsub ? "280px" : "280px"};
  background: #ffffff;
  box-shadow: 0px 35px 14px rgba(143, 143, 143, 0.01),
    0px 20px 12px rgba(143, 143, 143, 0.05),
    0px 9px 9px rgba(143, 143, 143, 0.09), 0px 2px 5px rgba(143, 143, 143, 0.1),
    0px 0px 0px rgba(143, 143, 143, 0.1);
  border-radius: 7px;
  margin:20px 20px 45px 20px;
  margin-top: ${(props) => (props.welsub ? "10px" : "0px")};
  padding: ${(props) => (props.mysub ? "0px" : props.welsub ? "0px" : "20px")};
  @media only screen and (max-width: 1200px) {
    height: auto;
    width: auto;
  }
  @media only screen and (max-width: 1150px) {
    flex-wrap: wrap;
  }
  @media only screen and (max-width: 700px) {
    margin-bottom: 40px;
  }
`;

export const SubsImage = styled.image`
  margin: ${(props) => (props.employee ? "0px 35px" : "0px 35px")};
  padding: ${(props) => (props.employee ? "0px 35px" : "0px 35px")};
  width: 40vw;
  @media only screen and (max-width: 700px) {
    width: 100%;
    height: auto;
    padding: 0px;
    margin: 0px;
  }
  & img {
    height: auto;
    width: ${(props) => (props.employee ? "300px" : "250px")};
    padding: 10px;
    @media only screen and (max-width: 700px) {
      width: 100%;
      padding: 0px;
      margin: 0px;
    }
  }
`;
export const SubsDetails = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${(props) => (props.welsub ? "0px 10px" : "0px 20px")};
  margin: ${(props) => (props.welsub ? "10px 10px" : "20px 15px")};
  width: 100%;
  @media only screen and (max-width: 1200px) {
    padding: auto;
  }
  @media only screen and (max-width: 900px) {
    width: auto;
  }
`;
export const Heading = styled.h1`
  font-family: "Urbanist";
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 150%;
  color: #03183c;
  @media only screen and (max-width: 1200px) {
    font-size: 22px;
  }
  @media only screen and (max-width: 900px) {
    font-size: 20px;
  }
`;
export const Discription = styled.p`
  font-family: "Urbanist";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  color: #6f6f6f;
`;
export const Details = styled.div`
  display: grid;
  grid-gap: 1px;
  grid-template-columns: repeat(3, 1fr);
  grid-auto-rows: 1fr;
  justify-content: center;
  align-items: center;
  width: 100%;
  @media only screen and (max-width: 700px) {
    width: auto;
    grid-template-columns: repeat(1, 1fr);
  }
`;
export const Discount = styled.p`
  font-family: "Urbanist";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  color: #6f6f6f;
  width: 100%;
`;
export const AddDoctors = styled.p`
  font-family: "Urbanist";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  color: #6f6f6f;
  width: 100%;
`;
export const Validity = styled.p`
  font-family: "Urbanist";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  color: #6f6f6f;
`;
export const ExplorePlans = styled.div`
  font-size: 16px;
  background-color: white;
  color: #4385C6;
  cursor: pointer;
`;
export const CustomButton = styled.div`
  display: flex;
  justify-content: ${(props) => (props.RechargeCredits ? "none" : "center")};
  align-items: ${(props) => (props.RechargeCredits ? "none" : "center")};
  height: ${(props) => (props.RechargeCredits ? "auto" : "55px")};
  width: 150px;
  font-size: 14px;
  border: ${(props) =>
    props.cancel
      ? "1px solid #FF5353"
      : props.cancel2
        ? "1px solid #3E9EAB"
        : "none"};
  margin-top: ${(props) => (props.second ? "30px" : "none")};
  border-radius: ${(props) => (props.RechargeCredits ? "none" : "7px")};
  float: ${(props) => (props.second ? "right" : "")};
  color: ${(props) =>
    props.RechargeCredits
      ? "#3E9EAB"
      : props.cancel
        ? "#FF5353"
        : props.cancel2
          ? "#3E9EAB"
          : props.disabled
            ? "#6F6F6F"
            : "#fff"};
  background: ${(props) =>
    props.RechargeCredits
      ? "none"
      : props.cancel
        ? "#fff"
        : props.cancel2
          ? "#fff"
          : props.disabled
            ? "#ECECEC"
            : "linear-gradient(101.55deg,#3e9eab 0%,#63adb3 33.36%,#72b4b7 70.3%,#6db2b7 100%);"};
  box-shadow: ${(props) =>
    props.RechargeCredits
      ? "none"
      : "0px 0px 4px rgba(0, 0, 0, 0.04), 0px 4px 8px rgba(0, 0, 0, 0.06);"};
  cursor: pointer;
  margin: ${(props) =>
    props.delete ? "20px 10px" : props.cancel ? "20px 10px" : "none"};
    
    &:hover {
    background:${(props) =>
    props.delete ? "#ECECEC" : props.cancel ? "red" : "#fff"};
    color:${(props) =>
    props.delete ? "#fff" : props.cancel ? "#fff" : "#4385C6"};
    border:${(props) =>
    props.delete ? "1px solid #fff" : props.cancel ? "none" : props.RechargeCredits ? "" : "1px solid #4385C6"};
  }
  
  @media only screen and (max-width: 900px) {
    height: ${(props) => (props.RechargeCredits ? "auto" : "45px")};
    float: none;
    font-size: ${(props) => (props.RechargeCredits ? "15px" : "12px")};
  }
`;
export const SubscriptionCardPlansContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #fff;
  padding: 40px;
  height: calc(100vh - 80px);
  overflow-y: auto;
  overflow-x: hidden;
`;
export const HeadingPlans = styled.h1`
  text-align: center;
  font-family: "Urbanist";
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 150%;
  color: #03183c;
  @media only screen and (max-width: 1200px) {
    font-size: auto;
  }
`;
export const CustomButtonPlans = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  @media only screen and (max-width: 900px) {
    flex-direction: column;
  }
`;
export const Monthly = styled.div`
  width: 150px;
  height: 54px;
  background: linear-gradient(
    101.55deg,
    #3e9eab 0%,
    #63adb3 33.36%,
    #72b4b7 70.3%,
    #6db2b7 100%
  );
  border-radius: 7px;
  font-family: "Urbanist";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  color: #ffffff;
  text-align: center;
  padding: 15px;
  cursor: pointer;
  margin-right: 20px;
  @media only screen and (max-width: 900px) {
    margin-right: 0px;
    margin-bottom: 20px;
  }
`;
export const Yearly = styled.div`
  width: 150px;
  height: 54px;
  background: #ffffff;
  box-shadow: 22px 46px 20px rgba(168, 168, 168, 0.01),
    12px 26px 17px rgba(168, 168, 168, 0.05),
    5px 12px 13px rgba(168, 168, 168, 0.09),
    1px 3px 7px rgba(168, 168, 168, 0.1), 0px 0px 0px rgba(168, 168, 168, 0.1);
  border-radius: 7px;
  font-family: "Urbanist";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  color: #03183c;
  text-align: center;
  padding: 15px;
  cursor: pointer;
`;

export const CardPlansWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-auto-rows: 1fr;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin: 50px 10px 20px 10px;
  padding: 20px;
  grid-gap: 40px;
  @media only screen and (max-width: 1200px) {
    grid-template-columns: repeat(2, 2fr);
    padding: 0px;
  }
  @media only screen and (max-width: 700px) {
    grid-template-columns: repeat(1, 1fr);
    padding: 30px;
  }
`;
export const Card = styled.div`
  height: 366px;
  width: 274px;
  padding: 45px;
  background: ${(props) => (props.selected ? "#6DB2B7" : "#fff")};
  box-shadow: 22px 46px 20px rgba(168, 168, 168, 0.01),
    12px 26px 17px rgba(168, 168, 168, 0.05),
    5px 12px 13px rgba(168, 168, 168, 0.09),
    1px 3px 7px rgba(168, 168, 168, 0.1), 0px 0px 0px rgba(168, 168, 168, 0.1);
  border-radius: 7px;
  cursor: pointer;
`;
export const Popular = styled.p`
  position: absolute;
  height: 24px;
  width: 184px;
  text-align: center;
  margin-top: -80px;
  font-family: "Urbanist";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 150%;
  color: #3e9eab;
`;
export const CardHeading = styled.h2`
  font-family: "Urbanist";
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 150%;
  text-align: center;
  color: ${(props) =>
    props.selected ? "#fff" : props.disabled ? "#A8AFBD" : "#03183c"};
  flex: none;
  order: 0;
  flex-grow: 0;
`;
export const CardDiscription = styled.p`
  font-family: "Urbanist";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  text-align: center;
  color: ${(props) =>
    props.selected ? "#fff" : props.disabled ? "#A8AFBD" : "#6f6f6f"};
  flex: none;
  order: 0;
  flex-grow: 0;
  padding: 10px;
`;
export const Amount = styled.p`
  margin: 20px;
  font-family: "Urbanist";
  font-style: normal;
  font-weight: 500;
  font-size: 32px;
  line-height: 150%;
  text-align: ${(props) => (props.amount ? "" : "center")};
  color: ${(props) =>
    props.selected
      ? "#fff"
      : props.disabled
        ? "#A8AFBD"
        : props.amount
          ? "#4385C6"
          : "#03183c"};
  -webkit-background-clip: text;
  /* -webkit-text-fill-color: transparent; */
  background-clip: text;
  /* text-fill-color: transparent; */
  flex: none;
  order: 1;
  flex-grow: 0;
`;
export const CorporateCustomButton = styled.div`
  width: 184px;
  height: 54px;
  padding: 15px;
  background: ${(props) =>
    props.disabled
      ? "#A8AFBD"
      : "linear-gradient(101.55deg, #3E9EAB 0%, #63ADB3 33.36%, #72B4B7 70.3%, #6DB2B7 100%);"};
  border-radius: 7px;
  font-family: "Urbanist";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  text-align: center;
  line-height: 150%;
  color: #fff;
  flex: none;
  order: 0;
  flex-grow: 0;
  margin-top: ${(props) => (props.contact ? "60px" : "")};
  cursor: pointer;
`;
export const DoctorSelectionContainer = styled.div`
  background-color: #fff;
  padding: 40px;
  height: calc(100vh - 80px);
  overflow-y: auto;
  overflow-x: hidden;
`;

export const Header = styled.div``;

export const LogoutConfirmationText = styled.div`
  margin-top: 20px;
  font-family: "Urbanist", sans-serif;
  font-size: 16px;
  color: #000;
  text-align: center;
`;

export const SearchBar = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 20px;
`;
export const ProceedButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 180px;
  height: 52px;
  text-align: center;
  padding: 15px;
  margin-left: 10px;
  background: ${(props) =>
    props.proceed ? "#ECECEC" : props.add ? "#4385C6" : props.cancel ? "#fff" : "#4385C6"};
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.04), 0px 4px 8px rgba(0, 0, 0, 0.06);
  border-radius: 7px;
  border: ${(props) => (props.cancel ? "1px solid red" : "none")};
  font-family: "Urbanist";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  color: ${(props) => (props.proceed ? "#13140F" : props.cancel ? "red" : "#fff")};
  flex: none;
  order: 0;
  flex-grow: 0;
  transition: 0.3s;
  cursor: pointer;
  &:hover {
    background: ${(props) =>
    props.proceed ? "#ECECEC" : props.add ? "#fff" : props.cancel ? "red" : "#fff"};
    color:${(props) =>
    props.proceed ? "#fff"
      : props.cancel ? "#fff" : "#4385C6"};
    border: ${(props) =>
    props.proceed ? "1px solid #ECECEC" : props.cancel ? "1px solid red" : "1px solid #4385C6"};
  }
  @media only screen and (max-width: 900px) {
    font-size: 14px;
    width: auto;
    height: 42px;
  }
  @media only screen and (max-width: 700px) {
    font-size: 12px;
  }
`;
export const DoctorList = styled.div`
  margin: 20px 0px 20px 0px;
`;
export const EmployeeListContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 50px;
`;
export const EmployeeListData = styled.div`
  margin: 20px 0px 20px 0px;
`;
export const EmployeeProfileContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  @media only screen and (max-width: 900px) {
    flex-direction: column;
  }
`;
export const EmployeeInfo = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
export const Profile = styled.div`
  position: relative;
  background: ${(props) => (props.tele ? "#fff" : "#D9D9D9")};
  box-shadow: 0px 70px 28px rgba(135, 135, 135, 0.01),
    0px 39px 24px rgba(135, 135, 135, 0.05),
    0px 18px 18px rgba(135, 135, 135, 0.09),
    0px 4px 10px rgba(135, 135, 135, 0.1), 0px 0px 0px rgba(135, 135, 135, 0.1);
  border-radius: 50%;
  height: 331px;
  width: 331px;
 
  & img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
  }
  @media only screen and (max-width: 1200px) {
    width: 300px;
    height: 300px;
  }
  @media only screen and (max-width: 900px) {
    width: 250px;
    height: 250px;
  }
`;
export const Name = styled.div`
  font-family: "Urbanist";
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 150%;
  color: #03183c;
  text-align: center;
  margin-top: ${(props) => (props.userName ? "15px" : "")};
`;
export const EmailId = styled.div`
  color: #6f6f6f;
  text-align: center;
  margin-left: 10px;
`;
export const EmployeeDetails = styled.div`
  padding: 40px 20px 20px 20px;
  width: 100%;
  @media only screen and (max-width: 1200px) {
  }
`;
export const EmployeeTaken = styled.div`
  font-family: "Urbanist";
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 150%;
  color: #03183c;
  @media only screen and (max-width: 900px) {
    font-size: 22px;
  }
  @media only screen and (max-width: 600px) {
    font-size: 20px;
  }
`;
export const Number = styled.div`
  font-family: "Urbanist";
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  line-height: 150%;
  background: linear-gradient(
    101.55deg,
    #3e9eab 0%,
    #63adb3 33.36%,
    #72b4b7 70.3%,
    #6db2b7 100%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
`;
export const CreditUsed = styled.div`
  font-family: "Urbanist";
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 150%;
  color: #03183c;
  @media only screen and (max-width: 900px) {
    font-size: 22px;
  }
  @media only screen and (max-width: 600px) {
    font-size: 20px;
  }
`;
export const JoingingDate = styled.div`
  font-family: "Urbanist";
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 150%;
  color: #03183c;
  @media only screen and (max-width: 900px) {
    font-size: 22px;
  }
  @media only screen and (max-width: 600px) {
    font-size: 20px;
  }
`;
export const EmployeeEvent = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 55px;
`;
export const BlockButton = styled.div`
  height: 44px;
  width: 150px;
  background: #ff5353;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.04), 0px 4px 8px rgba(0, 0, 0, 0.06);
  border-radius: 7px;
  text-align: center;
  padding: 10px;
  color: #fff;
  margin-left: 10px;
  cursor: pointer;
`;
export const CancelButton = styled.div`
  height: 44px;
  width: 150px;
  background: linear-gradient(
    101.55deg,
    #3e9eab 0%,
    #63adb3 33.36%,
    #72b4b7 70.3%,
    #6db2b7 100%
  );
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.04), 0px 4px 8px rgba(0, 0, 0, 0.06);
  border-radius: 7px;
  text-align: center;
  padding: 10px;
  color: #fff;
  cursor: pointer;
`;
export const HeaderWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
`;

export const EditButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background: #ffffff;
  border: 1px solid #4385C6;
  border-radius: 7px;
  width: 150px;
  padding: 10px;
  margin: 10px;
  font-family: "Urbanist";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 150%;
  color: #4385C6;
  cursor: pointer;
  &:hover {
    background-color: #4385C6;
    color:#fff;
  }
  @media only screen and (max-width: 900px) {
    height: auto;
    font-size: 14px;
  }
  @media only screen and (max-width: 700px) {
    font-size: 12px;
  }
`;
export const DividerLine = styled.div`
  height: ${(props) =>
    props.editProfile
      ? "100vh"
      : props.mysub
        ? "50vh"
        : props.welsub
          ? "36vh"
          : "65vh"};
  width: 1px;
  background-color: #ececec;
  margin: 10px 40px 10px 40px;
  @media only screen and (max-width: 900px) {
    height: 0px;
  }
`;
export const ProfileContainer = styled.div`
  padding: 10px;
`;

export const ItemWrapper = styled.div`
  display:flex;
  align-items: center;
`;

export const EmployeeBasic = styled.div`
  display: flex;
  flex-direction: row;
  justify-content:${(props) => (props.historyPayment ? "space-between" : "")};
  align-items:${(props) => (props.historyPayment ? "center" : "")};
`;
export const Text = styled.h3`
  font-family: "Urbanist";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 150%;
  color: ${(props) => (props.mysub ? "#6F6F6F" : "#03183C")};
  margin-left: ${(props) =>
    props.mysub ? "0px" : props.welsub ? "0px" : "10px"};
`;
export const Text2 = styled.p`
  font-family: "Urbanist";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  color: #6f6f6f;
  margin-left: ${(props) => (props.sub ? "25px" : "")};
`;
export const AddPicture = styled.div`
  position: absolute;
  z-index: 5;
  width: 72px;
  height: 72px;
  background: #4385C6;
  border-radius: 50%;
  padding: 18px;
  bottom: 0px;
  right: 25px;
  background-color: #4385C6;
  cursor: pointer;
  &:hover {
    background: gray;
  }
`;
export const SaveButton = styled.button`
  background: #4385C6;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.04), 0px 4px 8px rgba(0, 0, 0, 0.06);
  border: 1px solid #4385C6;
  border-radius: 7px;
  font-family: "Urbanist";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 150%;
  text-align: center;
  color: #ffffff;
  height: 44px;
  width: 330px;
  margin-top: 30px;
  cursor: pointer;
  &:hover {
    background-color: #fff;
    color:#4385C6;
    border: 1px solid #4385C6;
  }
  @media only screen and (max-width: 1200px) {
    width: 220px;
  }

  @media only screen and (max-width: 600px) {
    width: 100%;
  }
`;
export const WalletContainer = styled.div`
  padding: 20px;
  margin: 10px 50px;
  @media only screen and (max-width: 1200px) {
    width: auto;
    margin: 0px;
  }
`;
export const WalletWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  margin-top: 30px;
  @media only screen and (max-width: 900px) {
    flex-direction: column;
    width: auto;
  }
`;
export const WalletHeader = styled.h3`
  font-family: "Urbanist";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 150%;
  color: #13140f;
`;
export const WalletText = styled.p`
  font-family: "Urbanist";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 150%;
  color: #6f6f6f;
`;
export const WalletImage = styled.div`
  width: 40vw;
  padding: 10px 50px;
  @media only screen and (max-width: 1200px) {
    width: 100%;
    padding: 0px;
  }
  @media only screen and (max-width: 700px) {
    width: 100%;
  }
  & img {
    height: 55vh;
    width: 25vw;
    @media only screen and (max-width: 900px) {
      width: 60%;
    }
    @media only screen and (max-width: 700px) {
      width: 100%;
    }
  }
  @media only screen and (max-width: 900px) {
    width: 100%;
  }
`;

export const Price = styled.p`
  font-size: 14px;
  color: #6f6f6f;
`;
export const AddWalletContainer = styled.div`
  padding: 30px;
  width: 50vw;
  margin-top: 50px;
  @media only screen and (max-width: 900px) {
    width: auto;
  }
`;
export const AddCreditButton = styled.button`
  height: 40px;
  width: 200px;
  background: #4385C6;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.04), 0px 4px 8px rgba(0, 0, 0, 0.06);
  border-radius: 7px;
  font-family: "Urbanist";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  cursor: pointer;
  line-height: 150%;
  text-align: center;
  color: #ffffff;
  padding: 8px;
  opacity: ${({ disabled }) => disabled ? 0.3 : 1};
  border: none;
  outline: none;
`;
export const SubsDetailsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-items: ${(props) => (props.second ? "flex-end" : "flex-start")};
  justify-content: space-between;
  width: 100%;
  margin-top: ${(props) => (props.second ? "30px" : "none")};
  @media only screen and (max-width: 1200px) {
    padding: 20px;
  }
  @media only screen and (max-width: 900px) {
    padding: 20px;
    flex-direction: column;
  }
`;
export const MainsHeading = styled.h1`
  font-family: "Urbanist";
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  line-height: 150%;
  background: linear-gradient(
    101.55deg,
    #3e9eab 0%,
    #63adb3 33.36%,
    #72b4b7 70.3%,
    #6db2b7 100%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
`;
export const Days = styled.div`
  font-family: "Urbanist";
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  background: linear-gradient(
    101.55deg,
    #3e9eab 0%,
    #63adb3 33.36%,
    #72b4b7 70.3%,
    #6db2b7 100%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  & span {
    font-family: "Urbanist";
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    background: linear-gradient(
      101.55deg,
      #3e9eab 0%,
      #63adb3 33.36%,
      #72b4b7 70.3%,
      #6db2b7 100%
    );
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    margin-left: 5px;
    cursor: pointer;
  }
`;
export const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-items: ${(props) => (props.second ? "flex-end" : "flex-start")};
  justify-content: space-between;
  align-items: ${(props) => (props.second ? "flex-end" : "flex-start")};
  width: 100%;
  margin: 4px;
  @media only screen and (max-width: 700px) {
    width: auto;
    justify-content: start;
    flex-direction: column;
  }
`;

export const View = styled.div``;

export const StyledSpin = styled(Spin)`
  position: absolute; 
  left: 50%; 
  top: 40%;
`;

export const PaymentHistoryContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 50px;
  height: 80vh;
  overflow: auto;
`;

export const PaymentHistoryWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 5px;
`;

export const RowWrapper = styled.div`
   display: flex;
  flex-direction: column;
`;

export const WrapDetails = styled.div`
  display: flex;
  flex-direction: row;
  justify-content:space-between;
  align-items:center;
  padding: 10px;
`;

export const Wrap = styled.div`
 display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  padding:10px;
`;

export const Item = styled.p`
  display: ${(props) => (props.loading ? "flex" : null)};
  justify-content: center;
  align-items: center;
  min-height: 40px;
  max-height: 80px;
  width: 100%;
  padding: 5px 10px;
  line-height: 30px;
  vertical-align: middle;
  margin-bottom: 0;
  font-family: ${(props) =>
    props.selected ? "Gilroy SemiBold" : "'Urbanist', sans-serif"};
  font-weight: 500;
  font-size: 14px;
  color: #262c3d;
  cursor: pointer;
  background: ${(props) => (props.selected ? "aliceblue" : null)};
  &:hover {
    background: #e2e5e7;
  }
`;

export const StripeText = styled.p`
  color: red;
  font-size: 18px;
  font-weight: 500;
  margin: 0px;
  padding: 0px;
  margin-top: 10px;
`;

//FormList styling

export const FormListContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 50px;
`;
export const HeadingText = styled.h2`
  font-family: 'Urbanist';
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 150%;
  color: #03183C;
`;
export const FormListWrapper = styled.h2`
 display: flex;
  flex-direction: row;
  justify-items: space-between;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  margin: 4px;
  @media only screen and (max-width: 700px) {
    width: auto;
    justify-content: start;
    flex-direction: column;
  }
`;
export const SubHeadingText = styled.h4`
  font-family: 'Urbanist';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 150%;
  color: #13140F;
`;

