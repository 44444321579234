import React, { useState } from "react";
import { message } from "antd";
import {
  StyledUpload,
  UploadDiv,
  IconContainer,
  StyledSpin,
  UploadDivWrapper,
} from "./styledComponents";
import CameraIcon from "../../assets/icons/CameraIcon";
import ApiCalls from "../../apis/ApiCalls";

const UploadPhoto = () => {
  const [loading, setLoading] = useState(false);
  const [imageFile, setImageFile] = useState("");

  const saveProfilePicture = (formData, file) => {
    setLoading(true);
    ApiCalls.uploadPatientFile(formData)
      .then((response) => {
        if (response.data.status) {
          message.success("Profile pic updated successfully");
          setImageFile(file);
        } else {
          message.error("Failed updating profile pic");
        }
      })
      .catch((e) => {
        /* Error */
      })
      .finally(() => {
        setLoading(false);
      });
  };

  function beforeUpload(file) {
    if (
      file.type === "image/png" ||
      ("image/jpeg" && file.type !== "image/svg+xml")
    ) {
      var img = document.createElement("img");
      img.src = URL.createObjectURL(file);
      img.onload = function () {
        var width = img.width;
        var height = img.height;
        if (width > 150 && height > 150) {
          const formData = new FormData();
          formData.append("type", "profile_pic");
          formData.append("uploadFile", file);
          saveProfilePicture(formData, file);
        } else {
          message.error("Minimum image size is 150px by 150px");
        }
      };
    } else {
      message.error("You can only upload images of .png and .jpeg format");
    }
    return false;
  }

  return (
    <>
      <StyledUpload
        name="file"
        listType="picture-card"
        className="avatar-uploader"
        showUploadList={false}
        beforeUpload={beforeUpload}
        customRequest={() => {}}
      >
        <UploadDivWrapper>
          {loading ? (
            <StyledSpin spinning={true} size="small" />
          ) : (
            <UploadDiv>
              {imageFile && (
                <img
                  src={URL.createObjectURL(imageFile)}
                  alt="avatar"
                  style={{ width: "100%" }}
                />
              )}
              <IconContainer>
                <CameraIcon />
              </IconContainer>
            </UploadDiv>
          )}
        </UploadDivWrapper>
      </StyledUpload>
    </>
  );
};

export default UploadPhoto;
