import { Input, Select } from "antd";
import styled from "styled-components";
const { Option } = Select;

export const StyledRow = styled.div`
  display: flex;
  flex-direction: column;
  height: auto;
  width: 100%;
  padding-bottom: 30px;
`;

export const StyledSelect = styled(Select)`
  & input {
    line-height: 20px !important;
    border-radius: 10px;
    font-size: 16px !important;
    font-family: 'Urbanist', sans-serif !important;
    color: #35364f !important;
    padding: 35px 15px 10px 15px !important;
    background-color: #f4f5f7 !important;
    border: ${(props) => (props.type2 ? "none" : "")};
    margin: 0 !important;
  }
  & .ant-select-selection-item {
    display: none;
  }
  & .ant-select-selector {
    height: 70px;
    border-radius: 10px;
    width: 100%;
    padding: 0;
    border: none;
    background-color: #f4f5f7 !important;
  }

  & .ant-select-selection-search {
    width: 100% !important;
  }

  & .ant-select-selection-placeholder {
    top: 65% !important;
    left: 15px !important;
  }
`;

export const StyledInput = styled(Input)`
  width: 300px;
  line-height: 20px !important;
  border-radius: 10px;
  font-size: 16px !important;
  font-family: 'Urbanist', sans-serif !important;
  color: #35364f !important;
  padding: 15px !important;
  background-color: #f4f5f7 !important;
  border: none;
  margin: 0 !important;
`;

export const SectionHeading = styled.h6`
  margin-bottom: 0;
  font-family: "Gilroy SemiBold";
  font-size: 18px;
  padding-top: 14px;
  padding-bottom: 15px;
  color: #35364f;
  &::after {
    content: "*";
    color: red;
    display: ${(props) => (props.mandatory ? null : "none")};
  }
`;

export const SectionSubHeading = styled.p`
  margin-bottom: 0;
  font-family: 'Urbanist', sans-serif;
  font-size: 15px;
  color: #35364f;
`;

export const StyledOption = styled(Option)``;

export const Label = styled.div`
  padding: 0;
  position: absolute;
  top: 12px;
  left: 15px;
  z-index: 1;
  font-size: 14px;
  font-family: 'Urbanist', sans-serif;
  color: #65667d;
  &::after {
    display: inline-block !important;
    margin-left: 4px;
    color: #ff4d4f;
    font-size: 14px;
    font-family: SimSun, sans-serif;
    line-height: 1;
    content: "*" !important;
  }
`;

export const SelectedItemsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

export const SelectedItem = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: auto;
  min-width: 233px;
  padding: 0 8px 0 13px;
  border: 1px solid #f4f5f7;
  border-radius: 10px;
  margin-left: 12px;
  margin-top: 12px;
`;

export const ItemValue = styled.span`
  width: 180px;
  padding: 13px 0;
  font-family: 'Urbanist', sans-serif;
  font-size: 14px;
  color: #35364f;
  text-transform: capitalize;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;

export const IconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 26px;
  width: 26px;
  border-radius: 50%;
  background-color: #898996;
  cursor: pointer;
  & .anticon-close {
    font-size: 10px;
    color: white;
  }
`;

export const AddMoreContainer = styled.div`
  margin-top: 30px;
  display: flex;
  gap: 20px;
`;
