import React, { useState, useEffect } from "react";
import { Input, message } from "antd";
import {
  DoctorSelectionContainer,
  Heading,
  Header,
  SearchBar,
  ProceedButton,
  DoctorList,
  Text,
} from "./styledComponent";
import {
  SearchInputContainer,
  SearchIconContainer,
} from "../PatientDashboard/styledComponents";
import SearchIcon from "../../../assets/icons/DashBoardIcons/SearchIcon";
import DataTable from "./DoctorTableData";
import ApiCalls from "../../../apis/ApiCalls";
import { LeftCircleFilled, LoadingOutlined } from "@ant-design/icons";
import storage from "../../../storage";
import { useHistory } from "react-router-dom";

const DoctorSelectionList = () => {
  const [edit, setEdit] = useState(false);
  const [doctorList, setDoctorList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchValue, setSearchValue] = useState("");
  const [doctorSearchList, setDoctorSearchList] = useState([]);
  let companyProfile = storage.user.getItem();
  const history = useHistory();

  const getDoctor = (showLoader = true) => {
    if (showLoader) {
      setLoading(true);
    }

    let companyId = localStorage.getItem("companyId");
    ApiCalls.getCompanySelectedProviders(companyId)
      .then((response) => {
        setSearchValue("");
        if (response.data.status) {
          if (response?.data?.data?.length > 0) {
            localStorage.setItem(
              "selectedDoctorNumber",
              response.data.data.length
            );
            let temp = [];
            response.data.data.forEach((dat) => {
              temp.push({
                ...dat,
                selected: false,
              });
            });
            setDoctorList(temp);
            setDoctorSearchList(temp);
          } else {
            localStorage.setItem("selectedDoctorNumber", 0);
            setDoctorList([]);
            setDoctorSearchList([]);
          }
        } else {
          message.error("Can't fetch therapist list");
          setDoctorList([]);
          setDoctorSearchList([]);
        }
        setLoading(false);
      })
      .catch((err) => {
        setDoctorList([]);
        setDoctorSearchList([]);
        setLoading(false);
        setSearchValue("");
      });
  };

  useEffect(() => {
    getDoctor();
  }, []);

  // Search selected doctor
  const search = () => {
    if (searchValue?.trim("")?.length > 0) {
      let temp = [];
      doctorList.forEach((item) => {
        if (
          item.fullName?.toLowerCase()?.includes(searchValue?.toLowerCase())
        ) {
          temp.push(item);
        }
      });
      setDoctorSearchList(temp);
    } else {
      setDoctorSearchList(doctorList);
    }
  };

  useEffect(() => {
    if (searchValue?.trim("")?.length === 0) {
      setDoctorSearchList(doctorList);
    }
    // eslint-disable-next-line
  }, [searchValue]);

  return loading ? (
    <LoadingOutlined />
  ) : (
    <DoctorSelectionContainer>
      <div style={{ display: "flex", flexDirection: "row" }}>
        <LeftCircleFilled
          onClick={() => history.goBack()}
          style={{ color: "#4385C6", fontSize: "22px" }}
        />
        <Text style={{ color: "#4385C6" }}>Go Back</Text>
      </div>
      <Header>
        <Heading>Therapist selection</Heading>
      </Header>
      <Text welsub={true}>
        You can add upto {companyProfile.subscriptionDetails.doctorCount}{" "}
        therapists
      </Text>

      {/* Search */}
      <SearchBar>
        <SearchInputContainer>
          <SearchIconContainer>
            <SearchIcon />
          </SearchIconContainer>
          {/* <AutoComplete
            placeholder="Search"
            options={doctorList}
            onSearch={(val) => setSearchValue(val)}
            onSelect={(val) => setSearchValue(val)}
            value={searchValue}
          /> */}
          <Input
            placeholder="Search"
            value={searchValue}
            onChange={(e) => setSearchValue(e.target.value)}
          />
        </SearchInputContainer>

        {/* Search doctors */}
        <ProceedButton
          onClick={search}
          proceed={searchValue?.trim("")?.length === 0}
        >
          Search
        </ProceedButton>
      </SearchBar>
      <DoctorList>
        <DataTable
          getDoctor={getDoctor}
          data={doctorSearchList}
          edit={edit}
          setEdit={setEdit}
          setData={setDoctorSearchList}
        />
      </DoctorList>
    </DoctorSelectionContainer>
  );
};

export default DoctorSelectionList;
