import React, { useEffect } from "react";
import { message } from "antd";
import ApiCalls from "../../apis/ApiCalls";
import { useState } from "react";
import { useParams, useHistory } from "react-router-dom";
import { FormContainer, LoginSignupContainer } from "./styledComponents";
import Banner from "./Banner";
import Heading from "../Utils/Heading";
import SecondaryInfo from "../Utils/SecondaryInfo";
import PatientTherapistSelector from "./PatientTherapistSelector";
import PhoneForm from "../Utils/PhoneForm";
import CustomButton from "../Utils/CustomButton";
import FormatPhoneNumber from "../Utils/Functions/FormatPhoneNumber";
import WhatsAppIcon from "../../assets/icons/WhatsAppIcon";
import OtpVerification from "./OtpVerification";

const ForgetPassword = () => {
  const { userType } = useParams();
  const [forgotPasswordUserType, setForgotPasswordUserType] = useState(null);
  const history = useHistory();

  const [verificationSend, setVerificationSend] = useState(false);
  const [otp, setOtp] = useState(new Array(6).fill(""));
  const [phoneNumber, setPhoneNumber] = useState();
  const [phoneNumberFormat, setPhoneNumberFormat] = useState();
  const [channel, setChannel] = useState("");

  useEffect(() => {
    if (userType === "patient") setForgotPasswordUserType("patient");
    else if (userType === "doctor") setForgotPasswordUserType("doctor");
  }, [userType]);

  const onSendVerification = ({ selectedChannel }) => {
    if (!phoneNumber) {
      return message.error("Please enter a valid Phone Number");
    }

    let formattedPhoneNumber = FormatPhoneNumber({
      phoneNumber: phoneNumber,
      countryCode: phoneNumberFormat,
    });

    let data = {
      verificationContact: formattedPhoneNumber,
      channel: selectedChannel,
    };

    ApiCalls.sendForgotPasswordVerification(data)
      .then((response) => {
        if (response.data.status === 1) {
          setVerificationSend(true);
        }
      })
      .catch((error) => {
        message.error(
          error.response
            ? error?.response?.data?.data?.message
            : "No Response From Server",
          5
        );
      });
  };

  const verifyForgotPasswordCode = () => {
    if (!phoneNumber) {
      return message.error("Please enter a valid Phone Number");
    }

    let otpLength = otp.join("");
    if (otpLength.length !== 6) {
      return message.error("Please enter the valid OTP");
    }

    let formattedPhoneNumber = FormatPhoneNumber({
      phoneNumber: phoneNumber,
      countryCode: phoneNumberFormat,
    });

    let data = {
      verificationContact: formattedPhoneNumber,
      code: otp.join(""),
      userType: userType,
    };

    ApiCalls.verifyForgotPasswordCode(data)
      .then((response) => {
        if (response.data.status === 1) {
          history.push(
            `/user/${userType}/reset-password/${response.data.data.authToken}`
          );
        }
      })
      .catch((error) => {
        message.error(
          error.response
            ? error?.response?.data?.data?.message
            : "No Response From Server",
          5
        );
      });
  };

  const handleChannelSelect = async (channel) => {
    setChannel(channel);
    await onSendVerification({ selectedChannel: channel });
  };

  return (
    <LoginSignupContainer>
      <Banner />
      {verificationSend ? (
        <OtpVerification
          otp={otp}
          setOtp={setOtp}
          verifyLoginOtp={verifyForgotPasswordCode}
          onResendOtp={() => onSendVerification({ selectedChannel: channel })}
        />
      ) : (
        <FormContainer>
          <div
            style={{
              display: "flex",
              flexDirection: " column",
            }}
          >
            <Heading title={"Account Recovery"} />
            <div style={{ maxWidth: "398px", minWidth: "310px" }}>
              <SecondaryInfo
                info={
                  "Enter your mobile number below to receive a One-Time Password (OTP) via SMS."
                }
              />
            </div>
          </div>
          <PatientTherapistSelector
            loginType={forgotPasswordUserType}
            onClickPatient={() => history.push("/user/patient/forgot-password")}
            onClickTherapist={() =>
              history.push("/user/doctor/forgot-password")
            }
          />
          <PhoneForm
            phoneNumber={phoneNumber}
            setPhoneNumber={setPhoneNumber}
            setPhoneNumberFormat={setPhoneNumberFormat}
          />
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "16px",
            }}
          >
            <CustomButton
              buttonName={"Send Codes to WhatsApp"}
              onClick={() => handleChannelSelect("whatsapp")}
              icon={<WhatsAppIcon />}
              customStyles={{
                backgroundColor: "#075E54",
              }}
            />
            <CustomButton
              buttonName={"Send OTP via SMS"}
              onClick={() => handleChannelSelect("sms")}
            />
          </div>
        </FormContainer>
      )}
    </LoginSignupContainer>
  );
};

export default ForgetPassword;
