import { Radio, Form } from "antd";
import styled from "styled-components";

export const BookAppointmentWrapper = styled.div`
  width: 100%;
  height: auto;
  padding: 35px 35px 35px 45px;

  @media only screen and (max-width: 600px) {
    padding: 20px;
  }
`;
export const Wrapper = styled.div`
  width:350px;
  height: auto;
  padding: 35px 35px 35px 45px;
  background-color: white;
  border-radius: 10px;
  align-self: center;
  margin: auto;
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  @media (max-width: 1200px) {
    padding: 15px;
  }
  @media (max-width: 600px) {
    width:90vw !important;
  }
`;

export const AppointmentInfoContainer = styled.div`
  min-height: clamp(539px, calc(100vh - 197px), 3000px);
  width: 100%;
  padding: 20px 20px;
  background-color: white;
  border-radius: 10px;

  @media only screen and (max-width: 1200px) {
    width: 90%;
  }

  @media only screen and (max-width: 768px) {
    padding: 15px;
  }

  @media only screen and (max-width: 600px) {
    width: 100%;
    min-height: auto;
    padding: 10px;
  }
`;

export const SummaryTabWrapper = styled.div`
  width: 360px;
  height: auto;
  border-radius: 10px;
  background-color: white;
  padding: 22px 13px 25px 13px;

  @media only screen and (max-width: 600px) {
    width: 100%;
  }
`;

export const InfoContainer = styled.div`
  height: 80px;
  width: 100%;
  border-radius: 10px;
  padding: 0px 40px;
  display: flex;
  align-items: center;
  background-color: #fef3e1;
  & svg {
    height: 30px;
    width: 25px;
  }
`;

export const InfoMessage = styled.div`
  font-size: 15px;
  font-family: "Urbanist", sans-serif;
  color: #35364f;
  margin-left: ${(props) => (props.picture ? "5px" : "20px")};
`;

export const QuestionContainer = styled.div`
  margin-top: 20px;
  width: 100%;
`;

export const SectionTitle = styled.div`
  font-size: 16px;
  font-family: "Gilroy SemiBold";
  color: #35364f;
  padding-bottom: ${(props) => (props.additional ? "0" : "10px")};
  margin-left: ${(props) => (props.additional ? "20px" : "0")};
`;

export const QuestionTextArea = styled.textarea`
  min-height: 80px;
  width: 100%;
  border-radius: 10px;
  border: 1px solid #ebedf0;
  font-size: 13px;
  padding: 20px 15px;
  font-family: "Urbanist", sans-serif;
  color: #35364f;
  &:active,
  :focus,
  :focus-visible {
    border: 1px solid lightgrey !important;
    outline: none;
  }
`;

export const UploadPictureContainer = styled.div`
  margin-top: 20px;
  width: 100%;
`;

export const PicturesWrapper = styled.div`
  min-height: 56px;
  width: 100%;
  display: grid;
  grid-gap: 15px;
  grid-template-columns: repeat(auto-fill, 56px);

  & > img {
  }
`;

export const PictureContainer = styled.div`
  position: relative;
  height: 56px;
  width: 56px;
  box-shadow: 0 0 0 2px #c4c4c4;
  border-radius: 5px;

  & img {
    height: 100%;
    width: 100%;
    object-fit: cover;
    border-radius: 5px;
  }

  &:hover {
    & > div {
      display: flex !important;
    }
  }
`;

export const DeletePicture = styled.div`
  position: absolute;
  top: -8px;
  right: -8px;
  display: none;
  justify-content: center;
  align-items: center;
  height: 15px;
  width: 15px;
  border-radius: 50%;
  background: #c4c4c4;
  cursor: pointer;
  & svg {
    height: 10px;
    width: 10px;
  }

  &:hover {
    display: flex !important;
  }
`;

export const ToggleContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  margin-top: 20px;
`;

export const ItemContainer = styled.div`
  margin-top: 20px;
  width: 100%;
  & .ant-checkbox-wrapper {
    & > :not(:first-of-type) {
      font-family: "Urbanist", sans-serif;
      font-size: 14px;
      color: #35364f;
    }
  }
`;

export const SubHeading = styled.div`
  font-size: 14px;
  font-family: "Urbanist", sans-serif;
  font-weight: 500;
  color: #35364f;
  padding-bottom: 10px;
`;

export const SliderWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
`;

export const SliderContainer = styled.div`
  position: relative;
  width: 97%;
  & .ant-slider-track {
    background: #4385C6;
    height: 3px;
  }
  & .ant-slider-handle {
    height: 15px;
    width: 15px;
    border: solid 4px #4385C6;
  }
  & .ant-slider-dot {
    display: none;
  }
`;

export const SummaryItemHeading = styled.div`
  line-height: 1;
  margin-top: 23px;
  margin-bottom: 10px;
  font-size: 16px;
  color: #6f6f6f;
  font-family: "Urbanist", sans-serif;
  font-weight: 500;
`;

export const SummaryItemValue = styled.span`
  font-size: 16px;
  font-family: "Urbanist", sans-serif;
  font-weight: 500;
  color: #03183c;
`;

export const ProfileWrapper = styled.div`
  position: relative;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  row-gap: 25px;
  min-height: 70px;
  & > .anticon-loading {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`;

export const ProfileContainer = styled.div`
  height: 65px;
  width: 48px;
  cursor: pointer;
`;

export const PhotoContainer = styled.div`
  position: relative;
  height: 48px;
  width: 100%;
  border-radius: 50%;
  background-color: ${(props) => (props.add ? "#4385C6" : "crimson")};
  box-shadow: ${(props) => (props.selected ? "0 0 0 1px #4385C6" : "")};

  & img {
    height: 100%;
    width: 100%;
    border-radius: 50%;
  }
`;

export const PatientName = styled.div`
  width: 100%;
  padding-top: 8px;
  text-align: center;
  text-transform: capitalize;
  font-size: 13px;
  font-family: "Urbanist", sans-serif;
  color: ${(props) => (props.selected ? "#4385C6" : "#35364F")};
`;

export const ShortFormText = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 12px;
  text-transform: uppercase;
  color: white;
  & svg {
    height: 15px;
    width: 15px;
  }
`;

export const StyledRadio = styled(Radio)`
  font-size: 14px;
  margin-bottom: 10px;
  width: 45%;
  /* display: flex; */
  /* & .ant-radio-wrapper {
    width: 47%;
  } */

  & .ant-radio-inner::after {
    top: 0;
    left: 0;
    width: 14px;
    height: 14px;
    content: ${(props) => (props.content ? `${props.content}` : "")};
  }
`;

export const StyledForm = styled(Form)`
  & > :not(:first-of-type) {
    margin-top: 13px;
  }
`;

export const ListDetailsContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 15px;
`;

export const AddButton = styled.button`
  cursor: pointer;
  border: none;
  background: none;
  font-size: 16px;
  font-family: Metropolis Bold;
  color: #4385C6;
`;

export const StyledSliderValue = styled.span`
  font-family: Metropolis Regular;
  font-size: 12px;
  color: #65667d;
`;

export const UploadDiv = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 56px;
  width: ${(props) =>
    props.small ? "110px" : "calc(100vw - 266px - 80px - 370px - 70px)"};
  background: #f1f3ff;
  border: 1px dashed #4385C6;
  border-radius: 10px;
  cursor: pointer;

  @media only screen and (max-width: 900px) {
    width: 30vw;
  }

  @media only screen and (max-width: 600px) {
    width: 70vw;
  }
`;

export const UploadMessage = styled.span`
  padding: 2.5px;
  color: #4385C6;
  font-family: Metropolis Regular;
  font-weight: 500;
  font-size: ${(props) => (props.plus ? "30px" : "13px")};
`;

export const AppointmentTime = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  justify-content: center;

  @media only screen and (max-width: 600px) {
    text-align: center;
  }
`;

export const InfoTitle = styled.h6`
  margin-bottom: 0;
  font-family: Metropolis Regular;
  font-size: 16px;
  color: #03183c;
`;

export const EditTextButton = styled.button`
  position: absolute;
  top: 0;
  right: 0;
  border: none;
  background: none;
  font-family: "Urbanist", sans-serif;
  font-weight: 500;
  font-size: 16px;
  color: #65667d;
  cursor: pointer;
`;

export const ItemRow = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: ${(props) => (props.first ? "15px" : "10px")};

  @media only screen and (max-width: 600px) {
    flex-direction: column;
    margin-top: 10px;
  }
`;

export const StyledRow = styled.tr`
  display: grid;
  grid-template-columns: repeat(2, 2fr);
  min-height: 64px;
  ${'' /* padding: 15px 40px; */}
  cursor: pointer;
`;

export const Value = styled.span`
  font-family: "Urbanist", sans-serif;
  font-size: 16px;
  font-weight: 900;

  @media only screen and (max-width: 600px) {
    font-size: 14px;
  }
`;

export const SuccessMessage = styled.h6`
  width: 100%;
  padding: 33px 70px 20px 70px;
  padding-top: ${(props) => (props.type2 ? "0" : "33px")};
  margin-bottom: 0;
  text-align: center;
  font-family: ${(props) =>
    props.type2 ? "'Urbanist', sans-serif" : "Gilroy Bold"};
  font-weight: 500;
  font-size: ${(props) => (props.type2 ? "16px" : "25px")};
  color: #35364f;
`;

export const FailedMessage = styled.div`
  width: 100%;
  padding: 33px 70px 20px 70px;
  padding-top: ${(props) => (props.type2 ? "0" : "33px")};
  margin-bottom: 0;
  text-align: center;
  font-family: ${(props) =>
    props.type2 ? "'Urbanist', sans-serif" : "Gilroy Bold"};
  font-weight: 500;
  font-size: ${(props) => (props.type2 ? "16px" : "25px")};
  color: #35364f;
`;

export const StyledName = styled.span`
  font-family: "Gilroy SemiBold";
  font-size: 16px;
  color: #35364f;
`;

export const DoneButtonContainer = styled.div`
  width: 440px;
  padding: 31px 0 36px 0;
  margin: 0 auto;
`;

export const SuccessAppointmentInfo = styled.div`
  position: relative;
  display: flex;
  margin: 10px 0;
  height: 126px;
  width: 100%;
  padding: 20px;
`;

export const SuccessTimeInfo = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  width: auto;
  margin-left: ${(props) => (props.second ? "27px" : "22px")};
`;

export const SuccessTimeValue = styled.span`
  line-height: 1;
  font-family: ${(props) =>
    props.doctor ? "Gilroy Bold" : "'Urbanist', sans-serif"};
  font-weight: 500;
  font-size: ${(props) =>
    props.date
      ? "22px"
      : props.doctor
        ? "18px"
        : props.type2
          ? "16px"
          : "14px"};
  color: ${(props) => (props.type2 ? "#35364F" : "#4385C6")};
`;

export const SuccessFee = styled.span`
  position: absolute;
  bottom: 17px;
  right: 20px;
`;

export const CardLeftDiv = styled.div``;
export const CardRightDiv = styled.div``;

export const Note = styled.h6`
  position: relative;
  width: 100%;
  padding: 33px 70px 20px 70px;
  padding-top: 0;
  margin-bottom: 0;
  text-align: center;
  font-family: "Gilroy SemiBold";
  font-size: 14px;
  color: #35364f;
  & .anticon-warning {
    position: absolute;
    top: 11px;
    left: 45px;
    font-size: 22px;
  }
`;

export const SliderValue = styled.span`
  position: absolute;
  top: -31px;
  left: 50%;
  transform: translate(-50%, 0);
  font-family: "Gilroy SemiBold";
  font-size: 16px;
`;

export const LabelValue = styled.label`
margin-left: 20px
`;

export const CheckBoxValue = styled.input`
margin-right: 5px
`;

export const ButtonComponent = styled.button`
  background: #3768B0; 
  color: white; 
  border: none; 
  width: 200px; 
  border-radius: 10px; 
  padding: 10px;
  cursor: pointer;

  @media only screen and (max-width: 768px) {
    width: 150px;
    font-size: 14px;
    padding: 8px;
  }

  @media only screen and (max-width: 480px) {
    width: 120px;
    font-size: 12px;
    padding: 6px;
  }
`

export const InputTextBox = styled.input`
  width: 100%; 
  padding: 10px; 
  font-size: 16px; 
  border-radius: 10px; 
  border: 1px solid #ABABAB; 
  width: 80%;

  @media only screen and (max-width: 768px) {
    font-size: 14px;
    padding: 8px;
  }

  @media only screen and (max-width: 480px) {
    font-size: 12px;
    padding: 6px;
  }
`

export const OptionSelect = styled.div`
  border: 1px solid #ABABAB;
  width: 80%;
  padding: 10px;
  font-size: 16px;
  font-weight: 900;
  border-radius: 10px;
  margin-bottom: 10px;
  color: black;
  cursor: pointer;

  @media only screen and (max-width: 600px) {
    font-size: 14px;
  }
`

export const AppointmentQuestions = styled.div`
padding: 20px 80px;
@media only screen and (max-width: 600px) {
    padding: 0px;
  }
`

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;

export const HeaderRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 10px;
  box-sizing: border-box;

  @media (max-width: 768px) {
    align-items: center;
    margin-left: -40px;
  }
`;

export const BackButton = styled.span`
  flex: 1;
  display: flex;
  justify-content: flex-start;
`;

export const ProgressIndicators = styled.span`
  display: flex;
  align-items: center;
  flex: 2;
  justify-content: center;
  
  @media (max-width: 768px) {
    margin-top: 10px;
    margin-left: 30px;
    margin-right: 30px;
  }
`;

export const Indicator = styled.span`
  margin-right: 5px;
  background-color: ${props => props.active ? '#74ABE8' : '#E3E3E3'};
  padding: 3px;
  width: 70px;
  height: 10px;
  border-radius: 10px;

  @media (max-width: 768px) {
    width: 30px;
    margin-bottom: 10px;
  }
`;

export const QuestionCount = styled.span`
  flex: 1;
  text-align: center;
  font-size: 16px;
  font-weight: 900;
  
  @media (max-width: 768px) {
    font-size: 14px;
  }
`;

export const ButtonComponents = styled.button`
  background: #3768B0; 
  color: white; 
  border: none; 
  border-radius: 10px; 
  padding: 18px 10px;
  font-family: 'Metropolis Black', sans-serif;
  font-weight: 700;
  font-size: 16px;
  width: 30%;
  min-width: 150px;
  cursor: pointer;
  margin: 10px;
  box-sizing: border-box;

  @media (max-width: 768px) {
    width: 80%;
    font-size: 14px;
    padding: 14px 10px;
  }

  @media (max-width: 480px) {
    width: 100%;
    font-size: 12px;
    padding: 12px 5px;
  }
`;

export const ButtonContainer = styled.div`
  text-align: center;
  padding: 10px;

  @media (max-width: 768px) {
    padding: 8px;
  }
`;