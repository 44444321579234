import React, { useState } from "react";
import styled from "styled-components";
import { Row, Col, message, Form } from "antd";
import PhoneInput from "react-phone-input-2";
import ApiCalls from "../../apis/ApiCalls";
import { NavLink, useParams, useHistory } from "react-router-dom";
import { Main } from "../UpdateProfilePatient/styledComponents";
import {
  FormWrapper,
  StyledFormItem,
  LogoContainer,
  FormHeading,
  WelcomeText,
} from "../signup/styledComponents";
import PrimaryButton from "../../components/PrimaryButton/index";
import TextLogo from "../../assets/icons/Logo/TextLogo";
import { Input } from "@mui/material";

const CustomCol = styled(Col)``;

const FormStyle = styled(Form)`
  width: 100%;
`;

const ReverifyUserEmail = () => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [emailAddress, setEmailAddress] = useState("");
  const { userType } = useParams();
  const history = useHistory();

  const onFinish = (values) => {
    values.userType = userType;
    setLoading(true);
    ApiCalls.reverifyUserEmail(values)
      .then((response) => {
        if (response.data.status) {
          history.push(`/user/${userType}/login`);
          setLoading(false);
        }
      })
      .catch((error) => {
        setLoading(false);
        message.error(
          error.response
            ? error?.response?.data?.data?.message
            : "No Response From Server",
          5
        );
      });
  };

  return (
    <Main login={true}>
      <Row>
        <LogoContainer>
          <TextLogo />
        </LogoContainer>
      </Row>
      <Row style={{ marginBottom: "114px" }}>
        <Col span={24}>
          <FormWrapper>
            <FormStyle
              layout="vertical"
              form={form}
              initialValues={{
                layout: "vertical",
              }}
              onFinish={onFinish}
            >
              <FormHeading>Verify Email</FormHeading>

              <WelcomeText>
                If you haven't received a verification email, you can resend it
                from here
              </WelcomeText>
              <Row>
                <CustomCol span={24}>
                  <StyledFormItem
                    name="email"
                    label="Email Address"
                    rules={[
                      {
                        required: true,
                        message: "Email is required",
                      },
                    ]}
                  >
                    <Form.Item
                      name="email"
                      rules={[
                        {
                          type: "email",
                          message: "Please enter valid Email",
                        },
                      ]}
                    >
                      <Input
                        sx={{ width: "100%" }}
                        placeholder="Enter email here"
                        inputProps={{
                          name: "email",
                          required: true,
                        }}
                        onChange={(e) => {
                          setEmailAddress(e.target.value);
                        }}
                      />
                    </Form.Item>
                  </StyledFormItem>
                </CustomCol>
              </Row>

              <Row>
                <Col span={24}>
                  <StyledFormItem>
                    <PrimaryButton
                      type="primary"
                      block={true}
                      htmlType="submit"
                      loading={loading}
                      label="Resend Email"
                    />
                  </StyledFormItem>
                </Col>
              </Row>
              <Row>
                <NavLink
                  to={`/user/${userType}/login`}
                  style={{
                    paddingLeft: "30px",
                    marginBottom: "20px",
                    color: "#4385C6",
                    fontFamily: "Gilroy Medium",
                  }}
                >
                  Back to login
                </NavLink>
              </Row>
            </FormStyle>
          </FormWrapper>
        </Col>
      </Row>
    </Main>
  );
};

export default ReverifyUserEmail;
