import React from "react";
import SuccessInfo from "./SuccessInfo";
import FailedPayment from "./FailedPayment";
import Modal from "@mui/material/Modal";

const ConfirmationModal = ({
  onOk,
  onCancel,
  paymentStatus,
  paymentStatusModel,
}) => {
  return (
    <>
      <Modal
        title="dummy"
        open={paymentStatusModel}
        onCancel={onCancel}
        // okLabel="Done"
        onOk={onOk}
      >
        {paymentStatus === "Success" ? (
          <SuccessInfo onOk={onOk} />
        ) : (
          <FailedPayment onOk={onOk} />
        )}
      </Modal>
    </>
  );
};

export default ConfirmationModal;
