import styled from "styled-components";
import { Menu, Layout, Dropdown } from "antd";

const { Header } = Layout;

export const StyleHeaderBottomBorder = styled.div`
  height: 81px;
  width: 100%;
  border-bottom: 1px solid rgba(168, 175, 189, 0.4);
`;

export const StyledHeader = styled(Header)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 2;
  height: 80px;
  width: 100%;
  padding: 15px 35px;
  background: #fff;

  @media only screen and (max-width: 900px) {
    flex-direction: ${({ reverse }) => (reverse ? "row-reverse" : "")};
    padding-left: 70px;
  }
`;

export const StyledHeaderMenu = styled(Menu)`
  width: 232px;
  top: 15px;
  padding: 15px;
  font-family: "Montserrat", sans-serif;
  left: 28px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  & > :not(:first-of-type) {
    margin-top: 10px;
  }
  & .ant-dropdown-menu-item {
    display: flex;
    & > a {
      margin-left: 5px;
    }
  }

  .ant-dropdown-menu-item > a,
  .ant-dropdown-menu-submenu-title > a,
  .ant-dropdown-menu-item,
  .ant-dropdown-menu-submenu-title {
    font-family: "Urbanist", sans-serif;
    color: #35364f;
    font-size: 14px;
  }
`;

export const RightContainer = styled.div`
  display: flex;
  align-items: center;
  height: 50px;

  @media only screen and (max-width: 900px) {
    margin-left: 70px;
  }

  @media only screen and (max-width: 600px) {
    margin-left: 30px;
  }
`;

export const StyledDropdown = styled(Dropdown)`
  display: flex;
  align-items: center;
  height: 55px;
  width: 232px;
  padding: 0 10px;
  border: ${(props) =>
    props.UserDropDown ? "1px solid #03183C" : "1px solid #f5f8fa"};
  border-radius: 7px;
`;
export const UserButton = styled.div`
  margin-bottom: 40px;
  width: 232px;
  padding-top: 45px;
  margin-left: 20px;
  border-top: 1px solid rgba(168, 175, 189, 0.4);
  overflow-x: hidden;
  position: relative;
  top: 0px;
`;

export const ProfilePicContainer = styled.div`
  /* display: flex;
  justify-content: center;
  align-items: center; */
  /* height: 33px;
  width: 33px; */
  & img {
    height: 33px;
    width: 33px;
    border-radius: 50%;
    margin-left: 10px;
  }
`;

export const UserName = styled.p`
  margin: 0;
  padding: 0;
  margin-left: 10px;
  width: 170px;
  background-color: ${(props) => (props.activeKey ? "#4385C6" : "#fff")};;
  font-family: "Urbanist", sans-serif;
  font-weight: 600;
  font-size: 16px;
  color: ${(props) => (props.activeKey ? "#fff" : "#03183C")};
  text-overflow: ellipsis;
  white-space: nowrap;
  text-transform: capitalize;
`;

export const AppUserInfo = styled.div`
  cursor: pointer;
  height: 44px;
  width: 228px;
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.25);
  border-radius: 7px;
  display: flex;
  flex-direction: row;
  align-items: center;
  border: 1px solid ${(props) => (props.activeKey ? "#4385C6" : "#03183C")};
  background-color: ${(props) => (props.activeKey ? "#4385C6" : "#fff")};
`;

export const SearchContainer = styled.div`
  height: 100%;
  width: 70%;
  display: flex;
  align-items: center;
  background-color: transparent;
  border-radius: 10px;
  border: none;
  display: block;

  & .ant-select {
    height: 100%;
    width: 100%;
    color: #ABABAB;
    font-weignt: 400;
    font-size: 16px;
    font-family: Metropolis Regular;
    color: #35364f;
    border: none;
    & .ant-select-selector {
      height: 100%;
      width: 100%;
      border: none;
      border-radius: 10px;
      box-shadow: none !important;
      & .ant-select-selection-search-input {
        height: 100%;
        padding: 10px;
        border: none;
        border-radius: 10px;
      }
      & .ant-select-selection-placeholder,
      & .ant-select-selection-item {
        vertical-align: middle;
        line-height: 58px;
        border: none;
      }
    }
  }
  @media only screen and (min-width: 1500px) {
    width: calc(100% - 650px - 30px - 100px);
  }
  @media only screen and (min-width: 1800px) {
    width: calc(100% - 800px - 30px - 100px);
  }

  @media only screen and (max-width: 900px) {
    display: none;
  }
`;

export const StyledInput = styled.input`
  .ant-input {
    background-color: #E3E3E34D !important;
    border: none;
  }
`;

export const DoctorPhotoContainer = styled.div`
  height: ${(props) => (props.type3 ? "70px" : props.photo ? "63px" : "88px")};
  width: ${(props) => (props.type3 ? "70px" : props.photo ? "63px" : "88px")};
  border-radius: 50%;
  overflow: hidden;
  background-color: lightgray;

  & img {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }

  @media only screen and (max-width: 900px) {
    width: 50px;
    height: 50px;
  }
`;

export const StyledOption = styled.div`
  &:hover {
    background-color: #DFDFDF;
  }
  transition: background-color 0.3s ease;
`;