const setCities = (cities = []) => ({
    type: 'SET_CITIES',
    cities
})
const setCredentials = (credentials = []) => ({
    type: 'SET_CREDENTIALS',
    credentials
})

export default {
    setCities,
    setCredentials
}