import React, { useState, useEffect } from 'react';
import { UpdateAreaContainer, PageHeading, SubHeading } from '../styledComponents';
import { SectionHeading, SectionSubHeading } from '../ServicesSpecialities/styledComponents';
import {
  Address,
  AddEducation,
  CollegeName,
  StyledCard,
  ListCardContainer,
  Qualification,
  EditOption
} from '../EducationExperience/styledComponents';
import AddLicense from './AddLicense';
import AddCertification from './AddCertification';
import ApiCalls from '../../../apis/ApiCalls';
import UploadFile from '../BasicProfile/UploadFile';
import { message } from 'antd';
import { CloseOutlined, EditOutlined, UploadOutlined, DeleteOutlined } from '@ant-design/icons';
import { ImageContainer } from './styledComponents';
import moment from 'moment';
import ImageNotAvailable from '../../../assets/images/CorporateModule/Image_not_available.png';

const LicenseCertification = ({
  certificateDetails,
  medicalLicences,
  trigger,
  fetchWorkProfile,
  setTrigger,
  setCurrentPage,
  setLastCompletedPage
}) => {
  const [addNewLicense, setAddNewLicense] = useState(true);
  const [addNewCertification, setAddNewCertification] = useState(false);
  const [countries, setCountries] = useState([]);
  const [editMedicalItem, setEditMedicalItem] = useState(null);
  const [uploadMedicalItem, setUploadMedicalItem] = useState(null);
  const [editCertificateItem, setEditCertificateItem] = useState(null);
  const [uploadCertificateItem, setUploadCertificateItem] = useState(null);
  const [licenseFile, setLicenseFile] = useState(null);
  const [certificationFile, setCertificationFile] = useState(null);
  // eslint-disable-next-line
  const [loading, setLoading] = useState(false);
  // eslint-disable-next-line
  const [temp, setTemp] = useState(false);

  const checkUpdated = () => {
    if (medicalLicences.length < 1) message.error('Medical license information is mandatory');
    else {
      let noMedicalLicense = medicalLicences.filter((item) => !item.url);
      if (noMedicalLicense.length > 0) {
        message.error('Upload medical license');
      } else {
        setTrigger(0);
        setCurrentPage((prev) => prev + 1);
        setLastCompletedPage((prev) => prev + 1);
      }
    }
  };

  const deleteItem = (value, isLicense) => {
    let data = {};
    if (value.url) delete value.url;
    if (isLicense) data.medicalLicencesDetails = { ...value, status: 'delete' };
    else data.certificateDetails = { ...value, status: 'delete' };
    ApiCalls.updateDoctorExtraInfo(data)
      .then((response) => {
        if (response.data.status) {
          message.success('Succesfully deleted item');
          fetchWorkProfile(setLoading, setTemp);
        } else message.error('Failed to delete');
      })
      .catch((error) =>
        message.error(
          error.response ? error?.response?.data?.data?.message : 'No Response From Server',
          5
        )
      );
  };

  useEffect(() => {
    ApiCalls.getCountries()
      .then((response) => {
        if (response.data.status) setCountries(response.data.data);
        else message.error(response.data.data.message);
      })
      .catch((err) => { /*Error*/ });
  }, []);

  useEffect(() => {
    trigger && checkUpdated();
    // eslint-disable-next-line
  }, [trigger]);
  return (
    <>
      <UpdateAreaContainer>
        <PageHeading>License & Certification</PageHeading>
        <SubHeading>Please add your License & Certification</SubHeading>
        <SectionHeading style={{ paddingTop: '0', paddingBottom: '5px' }} mandatory={true}>
          Medical Board License
        </SectionHeading>
        <ListCardContainer margin={true}>
          {medicalLicences.length > 0
            ? medicalLicences.map((item) => (
                <>
                  {editMedicalItem === item._id ? (
                    <AddLicense
                      setAddNew={setAddNewLicense}
                      countries={countries}
                      fetchWorkProfile={fetchWorkProfile}
                      setEditItem={setEditMedicalItem}
                      selectedItem={item}
                    />
                  ) : (
                    <StyledCard image={true} selected={uploadMedicalItem === item._id}>
                      <ImageContainer
                        onClick={() => {
                          setUploadMedicalItem(null);
                          !uploadMedicalItem && setUploadMedicalItem(item._id);
                        }}
                        title={uploadMedicalItem ? 'Cancel upload' : 'Upload file'}
                      >
                        {item.url ? (
                          <img
                            src={item.url}
                            alt='No img found'
                            onError={(e) => {
                              if (e.target.src !== ImageNotAvailable) {
                                e.target.src = ImageNotAvailable;
                              }
                            }}
                          />
                        ) : uploadMedicalItem === item._id ? (
                          <CloseOutlined />
                        ) : (
                          <UploadOutlined />
                        )}
                      </ImageContainer>
                      <div>
                        <CollegeName>{item.licenceNumber}</CollegeName>
                        <Qualification>{item.licenceCountry}</Qualification>
                        <Address>{moment(item.licenceExpirationDate).format('DD-MM-YYYY')}</Address>
                        <Address>{item.npiNumber}</Address>
                        <EditOption onClick={() => setEditMedicalItem(item._id)}>
                          <EditOutlined />
                          Edit
                        </EditOption>
                        <EditOption delete={true} onClick={() => deleteItem(item, true)}>
                          <DeleteOutlined />
                          Delete
                        </EditOption>
                      </div>
                    </StyledCard>
                  )}
                  {uploadMedicalItem === item._id ? (
                    <UploadFile
                      file={licenseFile}
                      setFile={setLicenseFile}
                      title='Upload License Image'
                      info='Please upload Medical Board License image that must be readable'
                      itemId={uploadMedicalItem}
                      type='medicalLicences'
                      fetchWorkProfile={fetchWorkProfile}
                      setSelectedItem={setUploadMedicalItem}
                    />
                  ) : null}
                </>
              ))
            : null}
        </ListCardContainer>
        {addNewLicense && (
          <AddLicense
            setAddNew={setAddNewLicense}
            countries={countries}
            fetchWorkProfile={fetchWorkProfile}
          />
        )}
        {!addNewLicense && (
          <AddEducation onClick={() => setAddNewLicense(true)}>
            + Add New Medical Board License
          </AddEducation>
        )}

        <SectionHeading style={{ paddingTop: '20px', paddingBottom: '7px' }}>
          Certification & Awards
        </SectionHeading>
        <SectionSubHeading>
          This information is only optional. You can skip it and update later.
        </SectionSubHeading>
        <ListCardContainer margin={true}>
          {certificateDetails.length > 0
            ? certificateDetails.map((item) => (
                <>
                  {editCertificateItem === item._id ? (
                    <AddCertification
                      setAddNew={setAddNewCertification}
                      setEditItem={setEditCertificateItem}
                      fetchWorkProfile={fetchWorkProfile}
                      selectedItem={item}
                    />
                  ) : (
                    <StyledCard image={true} selected={uploadCertificateItem === item._id}>
                      <ImageContainer
                        onClick={() => {
                          setUploadCertificateItem(null);
                          !uploadCertificateItem && setUploadCertificateItem(item._id);
                        }}
                        title={uploadCertificateItem ? 'Cancel upload' : 'Upload file'}
                      >
                        {item.url ? (
                          <img src={item.url} alt='No img found' />
                        ) : uploadCertificateItem === item._id ? (
                          <CloseOutlined />
                        ) : (
                          <UploadOutlined />
                        )}
                      </ImageContainer>
                      <div>
                        <CollegeName>{item.certificates}</CollegeName>
                        <Address>{item.year}</Address>
                        <EditOption onClick={() => setEditCertificateItem(item._id)}>
                          <EditOutlined />
                          Edit
                        </EditOption>
                        <EditOption delete={true} onClick={() => deleteItem(item, false)}>
                          <DeleteOutlined />
                          Delete
                        </EditOption>
                      </div>
                    </StyledCard>
                  )}
                  {uploadCertificateItem === item._id ? (
                    <UploadFile
                      file={certificationFile}
                      setFile={setCertificationFile}
                      title='Upload Certification Image'
                      info='Please upload Certification image that must be readable'
                      itemId={uploadCertificateItem}
                      type='certificateDetails'
                      fetchWorkProfile={fetchWorkProfile}
                      setSelectedItem={setUploadCertificateItem}
                    />
                  ) : null}
                </>
              ))
            : null}
        </ListCardContainer>
        {addNewCertification && (
          <AddCertification
            setAddNew={setAddNewCertification}
            fetchWorkProfile={fetchWorkProfile}
          />
        )}
        {!addNewCertification && (
          <AddEducation onClick={() => setAddNewCertification(true)}>
            + Add New Certifications & Awards
          </AddEducation>
        )}
      </UpdateAreaContainer>
    </>
  );
};

export default LicenseCertification;
