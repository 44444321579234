import styled from "styled-components";

export const DisplayDurationContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, 113px);
  grid-gap: 30px;
  height: auto;
  width: 100%;
  padding-bottom: 30px;
`;

export const DurationItem = styled.button`
  border: none;
  height: 55px;
  width: 113px;
  border-radius: 10px;
  background-color: ${(props) => (props.selected ? "#4385C6" : "#F7F8F9")};
  color: ${(props) => (props.selected ? "#FFF" : "#35364F")};
  font-size: 16px;
  font-family: 'Urbanist', sans-serif;
  cursor: pointer;
  &:hover {
    background-color: #4385C6;
    color: #fff;
  }
`;
