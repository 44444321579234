import React, { useState, useEffect, Suspense, lazy } from "react";
import {
  Link,
  Route,
  Switch as RouterSwitch,
  useLocation,
  useHistory,
} from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import {
  StyledMenu,
  VideoCallContainer,
  StyledWindowButton,
  StyledLayout,
  StyledSider,
  StyledContent,
  TabIcons,
  MoreIcon,
  BookSessionWrapper,
} from "./styledComponents";
import Backdrop from "../../components/Backdrop/Backdrop";
import {
  UserButton,
  ProfilePicContainer,
  UserName,
  AppUserInfo,
} from "../Base/styledComponents";
import SocketActions from "../../sockets/SocketActions";
import {
  CompressOutlined,
  ExpandOutlined,
  LoadingOutlined,
} from "@ant-design/icons";
import { MORE_ICON } from "../../assets/images";
import patientLinks from "./patientLinks";
import doctorLinks from "./doctorLinks";
import MessageNotification from "./MessageNotification";
import useWindowDimensions from "../../utils/useWindowDimensions";
import CorporateDashboard from "../Dashboard/CorporateDashboard/index";
import corporateLinks from "./corporateLinks";
import EmployeeList from "../Dashboard/CorporateDashboard/EmployeeList";
import BlockUser from "../Dashboard/CorporateDashboard/BlockUser";
import ProfileCorporate from "../Dashboard/CorporateDashboard/Profile";
import EditProfile from "../Dashboard/CorporateDashboard/EditProfile";
import PrivacyPolicy from "../MyAccount/PrivacyPolicy";
import ImageNotAvailable from "../../assets/images/CorporateModule/Image_not_available.png";
import { GoogleOAuthProvider } from "@react-oauth/google";
import PaymentHistory from "../Dashboard/CorporateDashboard/PaymentHistory/PaymentHistory";
import FormList from "../Dashboard/CorporateDashboard/FormList";
import SurveyForm from "../SurveyForm/SurveyForm";
import QuestionNairs from "../SurveyForm/Questionnairs";
import ConfidentialityAgreement from "../ConfidentialityAgreement";
import Blogs from "../Blogs/Blogs";
import BlogDetail from "../Blogs/BlogDetail";
import PaymentStatus from "../Payment/SuccessInfo";

import LogoutIcon from "../../assets/icons/SettingsIcons/LogoutIcon";
import CustomModal from "../../components/CustomModal";
import { LogoutConfirmationText } from "../MyAccount/styledComponents";
import storage from "../../storage";
import reduxActions from "../../redux-store/actions";

const DashBoard = lazy(() => import("../Dashboard"));
const MyAccount = lazy(() => import("../MyAccount"));
const HealthRecord = lazy(() => import("../HealthRecord"));
const EmptyComponent = lazy(() => import("../../components/EmptyComponent"));
const ViewDoctors = lazy(() => import("../ViewDoctors"));
const DoctorProfile = lazy(() => import("../DoctorProfile"));
const BookAppointment = lazy(() => import("../BookAppointment"));
const Appointments = lazy(() => import("../Appointments"));
const Messages = lazy(() => import("../Messages"));
const VideoCall = lazy(() => import("../VideoCall"));
const CallPopUp = lazy(() => import("../VideoCall/CallPopUp"));
const CreatePrescription = lazy(() => import("../CreatePrescription"));
const SymptomChecker = lazy(() => import("../SymptomChecker"));
const Earnings = lazy(() => import("../Earnings"));
const Review = lazy(() => import("../Review"));
const Medication = lazy(() => import("../Medication"));
const FamilyMembers = lazy(() => import("../FamilyMembers"));
const MyDoctors = lazy(() => import("../MyDoctors"));
const Patients = lazy(() => import("../Patients"));
const Store = lazy(() => import("../Store"));
// const CorporateDashboard = lazy(() =>import ('../Corporate/index'));
// import LogoutIcon from "../../assets/icons/SettingsIcons/LogoutIcon";
// import LogoutIcon from "../../../src/assets/icons/SettingsIcons/LogoutIcon";

const Home = () => {
  const [activeKey, setActiveKey] = useState("0");
  const [showIncomingCall, setShowIncomingCall] = useState(false);
  const [callData, setCallData] = useState([]);
  const [showDeclinedMessage, setShowDeclinedMessage] = useState(false);
  const [openVideoCall, setOpenVideoCall] = useState(false);
  const [minimizeWindow, setMinimizeWindow] = useState(false);
  const [showReviewModal, setShowReviewModal] = useState(false);
  const [reviewInfo, setReviewInfo] = useState(null);
  const [messageStack, setMessageStack] = useState(null);
  const [prescriptionLoading, setPrescriptionLoading] = useState(false);
  const user = useSelector((state) => state.auth.userData);
  const callInfo = useSelector((state) => state.call);
  const location = useLocation();
  const history = useHistory();
  const [showMenuSlider, setShowMenuSlider] = useState(false);
  const { width } = useWindowDimensions();
  const dispatch = useDispatch();
  const [showConfirmation, setShowConfirmation] = useState(false);

  const onSelect = (param) => {
    setActiveKey(param.key);
    if (width <= 900) {
      setShowMenuSlider(false);
    }
  };

  useEffect(() => {
    if (Object.keys(user).length > 0) {
      if (user.userType === "provider") {
        setActiveKey(() => {
          const activeLink = doctorLinks.find(
            (link) => location.pathname === link.path
          );
          return activeLink ? activeLink.key : "0";
        });
      } else if (user.userType === "patient") {
        setActiveKey(() => {
          const activeLink = patientLinks.find(
            (link) => location.pathname === link.path
          );
          return activeLink ? activeLink.key : "0";
        });
      } else {
        setActiveKey(() => {
          const activeLink = corporateLinks.find(
            (link) => location.pathname === link.path
          );
          return activeLink ? activeLink.key : "0";
        });
      }
    }
  }, [location.pathname, user]);

  const callTriggeredCB = (response) => {
    setCallData(response.data);
    setShowIncomingCall(true);
  };

  const receiveDeclineMsgCB = (response) => {
    setCallData(response);
    setShowDeclinedMessage(true);
  };

  useEffect(() => {
    if (user.userType) {
      SocketActions.callTriggered(callTriggeredCB);
      SocketActions.receiveDeclineMsg(receiveDeclineMsgCB);
    }
    return () => {
      if (user.userType) {
        SocketActions.offCallTriggered(callTriggeredCB);
        SocketActions.receiveDeclineMsg(receiveDeclineMsgCB);
      }
    };
  }, [user]);

  useEffect(() => {
    if (callInfo.token) {
      setOpenVideoCall(true);
      setReviewInfo(callInfo);
    } else {
      setOpenVideoCall(false);
    }
  }, [callInfo]);

  const receiveMessageCB = (res) => {
    if (callInfo.roomId !== res.roomId) {
      setMessageStack(null);
      setMessageStack(res);
      SocketActions.messageAcknowledgement({
        patientId: res.patientId,
        providerId: res.providerId,
        msgId: [res._id],
      });
    }
  };

  useEffect(() => {
    if (user.userType) SocketActions.receiveMessage(receiveMessageCB);
    if (location.pathname === "/user/base/home/messages")
      SocketActions.offReceiveMessage(receiveMessageCB);

    return () => {
      if (user.userType) SocketActions.offReceiveMessage(receiveMessageCB);
    };
    // eslint-disable-next-line
  }, [location, callInfo, user]);

  const goToUserProfile = () => {
    history.push("/user/base/home/my-account");
    if (width <= 900) {
      setShowMenuSlider(false);
    }
  };

  const logout = async () => {
    SocketActions.disconnect();
    storage.authToken.clear();
    storage.user.clear();
    dispatch(reduxActions.authActions.removeAuthToken());
    dispatch(reduxActions.authActions.removeUserData());
    setShowConfirmation(false);
    history.push("/user/patient/login");
  };

  return (
    <>
      <StyledLayout color="#fff">
        {messageStack && (
          <MessageNotification
            message={messageStack}
            setMessageStack={setMessageStack}
            setMinimizeWindow={setMinimizeWindow}
          />
        )}

        {width <= 900 && (
          <>
            {/* Backdrop  */}
            {showMenuSlider && (
              <Backdrop onClick={() => setShowMenuSlider(false)} />
            )}

            {/* Drawer menu toggle */}
            <MoreIcon src={MORE_ICON} onClick={() => setShowMenuSlider(true)} />
          </>
        )}

        {/* Menu Slider */}
        <StyledSider
          width={"288px"}
          height={"778px"}
          theme="light"
          showSlider={showMenuSlider}
          style={{ height: "88vh", overflowY: "auto" }}
        >

          {user.userType === "patient" &&
            <BookSessionWrapper>
              <button
                onClick={() => history.push("/user/base/home/view-doctors")}
                onMouseOver={(e) => (e.target.style.cursor = "pointer")}
              >
                <span>Book a session</span>
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z"
                    stroke="white"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M12 8V16"
                    stroke="white"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M8 12H16"
                    stroke="white"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </button>
            </BookSessionWrapper>}

          <StyledMenu
            theme="light"
            mode="inline"
            defaultSelectedKeys={activeKey}
            onSelect={onSelect}
            selectedKeys={activeKey}
          >
            {user.userType === "provider"
              ? doctorLinks.map((mainLink) => (
                <StyledMenu.Item
                  key={mainLink.key}
                  icon={
                    <Link to={mainLink.path}>
                      <TabIcons
                        active={mainLink.key === activeKey}
                        src={mainLink.icon}
                      />
                    </Link>
                  }
                >
                  <Link to={mainLink.path}>
                    <span
                      style={{
                        fontSize: "14px",
                        fontWeight: "700",
                        fontFamily: "Metropolis Bold",
                      }}
                    >
                      {mainLink.name}
                    </span>
                  </Link>
                </StyledMenu.Item>
              ))
              : user.userType === "patient"
                ? patientLinks.map((mainLink) => (
                  <StyledMenu.Item
                    key={mainLink.key}
                    icon={
                      <Link to={mainLink.path}>
                        <TabIcons
                          active={mainLink.key === activeKey}
                          src={mainLink.icon}
                        />
                      </Link>
                    }
                  >
                    <Link to={mainLink.path}>
                      <span
                        style={{
                          fontSize: "14px",
                          fontWeight: "700",
                          fontFamily: "Metropolis Bold",
                        }}
                      >
                        {mainLink.name}
                      </span>
                    </Link>
                  </StyledMenu.Item>
                ))
                : corporateLinks.map((mainLink) => (
                  <StyledMenu.Item
                    key={mainLink.key}
                    icon={
                      <Link to={mainLink.path}>
                        <TabIcons
                          active={mainLink.key === activeKey}
                          src={mainLink.icon}
                        />
                      </Link>
                    }
                  >
                    <Link to={mainLink.path}>
                      <span
                        style={{
                          fontSize: "14px",
                          fontWeight: "700",
                          fontFamily: "Metropolis Bold",
                        }}
                      >
                        {mainLink.name}
                      </span>
                    </Link>
                  </StyledMenu.Item>
                ))}
          </StyledMenu>
          {user.userType === "provider" || user.userType === "patient" ? (
            <UserButton>
              <AppUserInfo
                activeKey={location.pathname === "/user/base/home/my-account"}
                onClick={goToUserProfile}
              >
                <ProfilePicContainer>
                  <img
                    src={user.profilePic}
                    alt="No img found"
                    onError={(e) => {
                      if (e.target.src !== ImageNotAvailable) {
                        e.target.src = ImageNotAvailable;
                      }
                    }}
                  />
                </ProfilePicContainer>
                <UserName
                  activeKey={
                    location.pathname === "/user/base/home/my-account"
                  }
                >
                  {user?.name}
                </UserName>
              </AppUserInfo>
              <div
                style={{
                  display: "flex",
                  marginTop: "20px",
                  marginLeft: "20px",
                  cursor: "pointer",
                }}
                onClick={() => {
                  setShowConfirmation(true);
                }}
              >
                <LogoutIcon style={{ color: "#FF837C" }} />
                <p
                  style={{
                    color: "#FF837C",
                    fontFamily: "Metropolis Bold",
                    fontSize: "14px",
                    fontWeight: "700",
                    marginLeft: "20px",
                  }}
                >
                  Logout
                </p>
              </div>
            </UserButton>
          ) : null}
        </StyledSider>

        <StyledContent>
          <Suspense fallback={<LoadingOutlined />}>
            <RouterSwitch>
              <Route
                path={`/user/base/home/dashboard`}
                render={() => <DashBoard user={user} />}
              />
              <Route
                path={`/user/base/home/corporate/dashboard`}
                render={() => <CorporateDashboard user={user} />}
              />
              <Route
                exact
                path={`/user/base/home/corporate/employees-list`}
                render={() => <EmployeeList user={user} />}
              />
              <Route
                exact
                path={`/user/base/home/corporate/profile`}
                render={() => <ProfileCorporate user={user} />}
              />
              <Route
                exact
                path={`/user/base/home/corporate/employees-list`}
                render={() => <FormList user={user} />}
              />
              <Route
                exact
                path={`/user/base/home/corporate/profile/edit-profile`}
                render={() => <EditProfile user={user} />}
              />
              <Route
                exact
                path={`/user/base/home/corporate/profile/my-payment-history`}
                render={() => <PaymentHistory user={user} />}
              />
              <Route
                exact
                path={`/user/base/home/corporate/profile/privacy-policy`}
                render={() => <PrivacyPolicy user={user} />}
              />
              <Route
                exact
                path={`/user/base/home/confidentiality-agreement`}
                render={() => <ConfidentialityAgreement />}
              />
              <Route
                exact
                path={`/user/base/home/corporate/employees-list/user-details`}
                render={() => <BlockUser user={user} />}
              />
              <Route path={`/user/base/home/store`} render={() => <Store />} />
              <Route
                path={`/user/base/home/view-doctors`}
                render={() => <ViewDoctors />}
              />
              <Route
                path={`/user/base/home/doctor-profile/:id`}
                render={() => <DoctorProfile />}
              />
              <Route
                path={`/user/base/home/book-appointment/:scheduleId`}
                render={() => <BookAppointment />}
              />
              <Route
                path={`/user/base/home/appointments`}
                render={() => (
                  <GoogleOAuthProvider
                    clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}
                  >
                    <Appointments />
                  </GoogleOAuthProvider>
                )}
              />
              <Route
                path={`/user/base/home/messages`}
                render={() => <Messages />}
              />
              <Route
                path={`/user/base/home/create-prescription/:patientId/:prescriptionId`}
                render={() => (
                  <CreatePrescription
                    setMinimizeWindow={setMinimizeWindow}
                    prescriptionLoading={prescriptionLoading}
                    setPrescriptionLoading={setPrescriptionLoading}
                  />
                )}
              />
              {/* <Route path={`/user/base/home/corporate/`} render={() => <SymptomChecker />} /> */}
              <Route
                path={`/user/base/home/symptom-checker`}
                render={() => <SymptomChecker />}
              />
              <Route
                path={`/user/base/home/my-account`}
                render={(props) => <MyAccount />}
              />
              <Route
                path={`/user/base/home/earnings`}
                render={() => <Earnings />}
              />
              <Route
                path={`/user/base/home/medication`}
                render={() => <Medication />}
              />
              <Route
                path={`/user/base/home/health-record`}
                render={() => <HealthRecord />}
              />
              <Route
                path={`/user/base/home/family-members`}
                render={() => <FamilyMembers />}
              />
              <Route
                path={`/user/base/home/my-doctors`}
                render={() => <MyDoctors />}
              />
              <Route
                path={`/user/base/home/survay-form`}
                render={() => <SurveyForm />}
              />
              <Route
                path={`/user/base/home/patients`}
                render={() => <Patients />}
              />
              <Route
                path={`/user/base/home/articles-blogs`}
                render={() => <Blogs />}
              />
              <Route
                path={`/user/base/home/articles-blogs/:slug`}
                render={() => <BlogDetail />}
              />
              <Route
                path={`/user/base/home/booking-status`}
                render={() => <PaymentStatus />}
              />
              <Route
                path="*"
                exact={true}
                render={() => (
                  <EmptyComponent
                    message="404,Page not found"
                    minHeight="100vh"
                  />
                )}
              />
            </RouterSwitch>
          </Suspense>
        </StyledContent>
      </StyledLayout>
      <Suspense fallback={<></>}>
        {(showIncomingCall || showDeclinedMessage) && (
          <CallPopUp
            showIncomingCall={showIncomingCall}
            setShowIncomingCall={setShowIncomingCall}
            callData={callData}
            showDeclinedMessage={showDeclinedMessage}
            setShowDeclinedMessage={setShowDeclinedMessage}
          />
        )}
        {openVideoCall && (
          <VideoCallContainer minimizeWindow={minimizeWindow}>
            <StyledWindowButton
              title={minimizeWindow ? "Maximize" : "Minimize"}
              minimizeWindow={minimizeWindow}
              onClick={() => {
                if (!prescriptionLoading) setMinimizeWindow((prev) => !prev);
              }}
            >
              {minimizeWindow ? <ExpandOutlined /> : <CompressOutlined />}
            </StyledWindowButton>
            {callInfo.token ? (
              <VideoCall
                callData={callInfo}
                minimizeWindow={minimizeWindow}
                setMinimizeWindow={setMinimizeWindow}
                setShowReviewModal={setShowReviewModal}
              />
            ) : null}
          </VideoCallContainer>
        )}
        {showReviewModal && (
          <Review
            visible={showReviewModal}
            setShowReviewModal={setShowReviewModal}
            callInfo={reviewInfo}
          />
        )}
      </Suspense>

      <CustomModal
        visible={showConfirmation}
        cancelAppointment={true}
        title={"Logout"}
        okLabel={"Confirm"}
        onCancel={() => setShowConfirmation(false)}
        onOk={() => logout()}
      >
        <LogoutConfirmationText>
          Are you sure you want to logout?
        </LogoutConfirmationText>
      </CustomModal>
    </>
  );
};

export default Home;
