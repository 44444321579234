import React from "react";
import styled from "styled-components";

const StyledSecondaryInfo = styled.div`
  font-size: 20px;
  font-weight: 400;
  color: #000000;
  font-family: Metropolis Regular;
`;

const SecondaryInfo = ({ info }) => {
  return <StyledSecondaryInfo>{info}</StyledSecondaryInfo>;
};

export default SecondaryInfo;
