import React, { useState, useEffect } from "react";
import {
    SurveyFormContainer,
    SurveyFormHeader,
    Heading,
    SubText,
    SurveyFormWrapper,
    Wrapper,
    Image,
    SurveyFormBox,
    SurveyFormBoxSecond,
    FormContainer,
} from "./styledComponents";
import UnknownDocument from "../../assets/images/patientModuleIcons/unknown_document.svg";
import { CustomButton } from "../ViewDoctors/styledComponents";
import G8 from "../../assets/images/g8.png";
import { Input, Radio, Space, Row, Button, Col, Modal, message, Form, Checkbox } from 'antd';
import { useHistory, useParams } from "react-router-dom";
import ApiCalls from "../../apis/ApiCalls";

const QuestionNairs = () => {
    const [value, setValue] = useState(null);
    const [loading, setLoading] = useState(true);
    const [showModal, setShowModal] = useState(false);
    const [questionnairs, setQuestionnairs] = useState([]);
    const [submitAnswer, setSubmitAnswer] = useState([]);
    const [questionNumber, setQuestionNumber] = useState(0);
    const [next, setNext] = useState(false);
    const history = useHistory();
    const param = useParams();
    const [answerList, setAnswerList] = useState([]);

    const onChange = (e) => {
        const newValue = e.target.value;
        setValue(newValue);
        let temp = [...answerList];
        temp[questionNumber].selectedAnswers = [newValue];
    };

    const goBackToHome = () => {
        setShowModal(false);
        history.goBack();
    }

    const goBack = () => {
        history.goBack();
    }

    const NextPage = () => {
        // if (questionNumber >= answerList.length) {
        //     let temp = [...answerList]
        //     temp.push({
        //         questionId: questionnairs.questionsList[questionNumber + 1]._id,
        //         selectedAnswers: []
        //     })
        //     setAnswerList(temp);
        // }

        setQuestionNumber(questionNumber => questionNumber + 1)
    }

    // to get questionlist api
    const getSingleFormQuestionnairs = () => {
        setLoading(true);
        ApiCalls.getQuestionList(param.id)
            .then((response) => {
                if (response.data.status) {
                    if (response.data?.data?.questionsList.length > 0) {
                        let temp = [];
                        response.data.data.questionsList.forEach(item=>{
                            temp.push({ questionId:item._id, selectedAnswers: [] })
                        })
                        setAnswerList(temp)
                    }
                    setQuestionnairs(response?.data?.data)
                    message.success("Please Submit Questionnairs details")
                }
                setLoading();
            })
            .catch((err) => {
                setLoading();
                message.error("something went wrong")
            });
    };


    // To get Submitted Answer for single form
    const SubmittedAnswerForSingleForm = () => {
        setLoading(true);
        let data = {
            formId : param.id,
            answerList,
        }
        ApiCalls.submitAnswer( data)
            .then((response) => {
                if (response.data.status) {
                    setShowModal(true);
                    message.success("All the answers has been submitted successfully")
                }
                setLoading(false);
            })
            .catch((err) => {
                setLoading(false);
                message.error("something went wrong")
            });
    };


    useEffect(() => {
        getSingleFormQuestionnairs();
    }, [])

    const selectedCheckBox=(checkedValues) =>{
        console.log(checkedValues)
        let temp = [...answerList];
        temp[questionNumber].selectedAnswers = checkedValues;
    }

    const SubmitTheForm = (e) => {
        e.preventDefault();
        SubmittedAnswerForSingleForm();
    }

    return (
        <SurveyFormContainer>
            <SurveyFormHeader>
                <Wrapper>

                    <Image>
                        <img src={UnknownDocument} height="20px" width="20px" />
                    </Image>
                    <Heading>
                        Questionnaire
                    </Heading>
                </Wrapper>
                <SubText>
                    Provide your valuable feedback to help us improve our services
                </SubText>
            </SurveyFormHeader>

            <FormContainer>
                <Form onSubmit={SubmitTheForm}>
                    {questionnairs?.questionsList?.length > 0 &&
                        <SurveyFormWrapper style={{ background: "#f6f6f6", alignSelf: "center" }}>

                            <SurveyFormBox
                                style={{ width: "80%", padding: "30px" }}
                            >
                                <div>
                                    <SubText>
                                        Q. {questionNumber + 1} {questionnairs?.questionsList[questionNumber]?.question} ?
                                    </SubText>
                                    {
                                        questionnairs.questionsList[questionNumber].answerType === "singleSelect" ? (
                                            <Radio.Group
                                                name="radiogroup"
                                                onChange={onChange} value={answerList[questionNumber].selectedAnswers.length > 0 ? answerList[questionNumber].selectedAnswers[0] : ""}
                                                defaultChecked={value}
                                            >
                                                <Space direction="vertical">
                                                    {
                                                        questionnairs.questionsList[questionNumber]?.options?.map((item, index) => (

                                                            <Radio
                                                                key={index}
                                                                value={item}
                                                            >
                                                                {item}
                                                            </Radio>
                                                        ))
                                                    }
                                                </Space>
                                            </Radio.Group>
                                        ) : (
                                            <Checkbox.Group options={questionnairs.questionsList[questionNumber].options.map(
                                                item =>(
                                                    {
                                                        label:item,
                                                        value:item
                                                    }
                                                )
                                            )} defaultValue={[]} onChange={selectedCheckBox} />
                                        )
                                    }

                                </div>
                            </SurveyFormBox>

                        </SurveyFormWrapper>
                    }


                    {next &&
                        <SurveyFormBoxSecond>
                            <SubText>
                                Provide your valuable feedback to help us improve our services
                            </SubText>
                            <Input style={{ height: "150px", width: "450px", marginBottom: "20px" }} />
                            <Radio>Skip</Radio>
                        </SurveyFormBoxSecond>
                    }


                    <Wrapper style={{ justifyContent: "flex-end", alignContent: "flex-end" }}>
                        <CustomButton fab={true} onClick={goBack} >Back</CustomButton>
                        {questionNumber < questionnairs?.questionsList?.length - 1 ? (
                            <CustomButton onClick={NextPage}>Next</CustomButton>
                        ) : (
                            <Button type="submit" size="large" onClick={SubmitTheForm}>
                                Submit
                            </Button>
                        )}





                    </Wrapper>
                </Form>
                {showModal &&
                    <Modal
                        visible={showModal}
                        width="450px"
                        height="550px"
                        style={{
                            padding: "50px",
                            borderRadius: "5px",
                        }}
                        footer={
                            <Row justify="end" gutter={16}>
                                <Col>
                                    <Button type="primary" size="large" onClick={goBackToHome}>
                                        Go Back To Home
                                    </Button>
                                </Col>
                            </Row>
                        }
                    >
                        <img src={G8} style={{ padding: "10px", margin: "20px" }} />
                        <Row>
                            <SubText>
                                Thank you very much for providing such valuable feedback !
                            </SubText>
                        </Row>
                    </Modal>
                }
            </FormContainer>
        </SurveyFormContainer>
    )
}
export default QuestionNairs;