import styled from "styled-components";

export const PageWrapper = styled.div`
  position: relative;
  min-height: calc(100vh - 80px);
  height: auto;
  width: 100%;
  padding: 35px 35px 35px 40px;
`;

export const ListItemWrapper = styled.div`
  height: auto;
  width: auto;
  border-radius: 10px;
`;

export const Divider = styled.div`
  height: 1px;
  width: 100%;
  background: #ededed;
  border-radius: 10px;
  margin-top: 30px;
`;

export const ViewAppointmentsButton = styled.button`
  /* margin-top: 20px; */
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 185px;
  border: none;
  background: none;
  cursor: pointer;
  font-family: 'Urbanist', sans-serif;
  font-size: 17px;
  color: #03183C;
  & .anticon {
    font-size: 12px;
    transform: ${(props) => (props.opened ? "rotate(180deg)" : null)};
    transition: all 0.25s linear;
  }
`;

export const CollapsibleWrapper = styled.div`
  height: auto;
  width: 100%;
  padding: 0 20px 30px 20px;
`;

export const AppointmentsContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, 300px);
  grid-gap: 20px;
  min-height: ${(props) => (props.opened ? "200px" : "0")};
  transition: all 0.25s linear;
  & * {
    display: ${(props) => (props.opened ? null : "none")};
  }
`;

export const AppointmentCard = styled.div`
  height: 117px;
  width: 100%;
  padding: 0 15px;
  border-right: ${({ lastItem }) => !lastItem ? "1px solid #A8AFBD" : "none"};
`;

export const StatusInfo = styled.div`
  width: 170px;
  padding: 15px 30px;
  border-radius: 10px;
  background: ${({ schedule }) =>
    changeValueAccordingToSchedule(schedule, "#E1F7ED", "0")};
  color: ${({ schedule }) =>
    changeValueAccordingToSchedule(schedule, "#2FBF7C", "#4385C6")};
  text-align: center;
  text-transform: capitalize;
`;

function changeValueAccordingToSchedule(schedule, color, defaultColor) {
  switch (schedule) {
    case "active":
      return color;
    case "completed":
      return color;
    default:
      return defaultColor;
  }
}
