import React, { useState } from "react";

const PasswordForm = ({ setPassword }) => {
  const [passwordVisibe, setPasswordVisibe] = useState(false);

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        backgroundColor: "#FBFBFB",
        borderRadius: "4px",
        height: "55px",
        padding: "0px 16px",
      }}
    >
      <svg
        width="24"
        height="25"
        viewBox="0 0 24 25"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M19 11.5H5C3.89543 11.5 3 12.3954 3 13.5V20.5C3 21.6046 3.89543 22.5 5 22.5H19C20.1046 22.5 21 21.6046 21 20.5V13.5C21 12.3954 20.1046 11.5 19 11.5Z"
          stroke="#565656"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M7 11.5V7.5C7 6.17392 7.52678 4.90215 8.46447 3.96447C9.40215 3.02678 10.6739 2.5 12 2.5C13.3261 2.5 14.5979 3.02678 15.5355 3.96447C16.4732 4.90215 17 6.17392 17 7.5V11.5"
          stroke="#565656"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>

      <input
        type={passwordVisibe ? "text" : "password"}
        style={{
          width: "100%",
          fontSize: "24px",
          fontWeight: "500",
          border: "none",
          backgroundColor: "#FBFBFB",
          padding: "0px 16px",
          outline: "none",
        }}
        onChange={(e) => {
          setPassword(e.target.value);
        }}
      ></input>
      <button
        style={{
          border: "none",
          backgroundColor: "#FBFBFB",
        }}
        onMouseOver={(e) => (e.target.style.cursor = "pointer")}
        onClick={() => setPasswordVisibe(!passwordVisibe)}
      >
        <svg
          width="20"
          height="21"
          viewBox="0 0 20 21"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clip-path="url(#clip0_1720_51151)">
            <path
              d="M14.9502 15.4502C13.5257 16.536 11.7911 17.1376 10.0002 17.1668C4.16683 17.1668 0.833496 10.5002 0.833496 10.5002C1.87007 8.56843 3.30778 6.88069 5.05016 5.55018M8.25016 4.03352C8.82377 3.89925 9.41105 3.83213 10.0002 3.83352C15.8335 3.83352 19.1668 10.5002 19.1668 10.5002C18.661 11.4465 18.0577 12.3375 17.3668 13.1585M11.7668 12.2668C11.538 12.5125 11.262 12.7095 10.9553 12.8461C10.6486 12.9828 10.3176 13.0562 9.9819 13.0622C9.64623 13.0681 9.3128 13.0063 9.0015 12.8806C8.69021 12.7549 8.40743 12.5677 8.17003 12.3303C7.93264 12.0929 7.74549 11.8101 7.61975 11.4988C7.49402 11.1875 7.43227 10.8541 7.43819 10.5184C7.44411 10.1828 7.51759 9.85172 7.65423 9.54506C7.79087 9.23839 7.98787 8.96239 8.2335 8.73352"
              stroke="#848484"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M0.833496 1.3335L19.1668 19.6668"
              stroke="#848484"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </g>
          <defs>
            <clipPath id="clip0_1720_51151">
              <rect
                width="20"
                height="20"
                fill="white"
                transform="translate(0 0.5)"
              />
            </clipPath>
          </defs>
        </svg>
      </button>
    </div>
  );
};

export default PasswordForm;
