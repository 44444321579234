import { message } from "antd";
import React, { useEffect, useRef } from "react";

const MapComponent = ({ setAddress, location, setLocation }) => {
  const google = window.google;
  const firstRender = useRef(true);
  let marker = null;

  function initMap() {
    const map = new google.maps.Map(document.getElementById("map"), {
      center: location,
      zoom: 14,
    });

    marker = new google.maps.Marker({
      position: location,
      map,
      title: "Click to zoom",
    });

    map.addListener("click", (mapsMouseEvent) => {
      marker.setMap(null);
      marker = new google.maps.Marker({
        position: mapsMouseEvent.latLng.toJSON(),
        map,
      });
      setLocation(mapsMouseEvent.latLng.toJSON());
    });
  }

  useEffect(() => {
    initMap();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (!firstRender.current) {
      fetch(
        `https://maps.googleapis.com/maps/api/geocode/json?latlng=${location.lat},${location.lng}&key=${process.env.REACT_APP_GOOGLE_MAP_KEY}`
      )
        .then((response) => {
          return response.json();
        })
        .then((response) => {
          if (response.status === "OK") {
            message.success("Obtained address of selected location");
            setAddress(response.results[0].formatted_address);
          } else message.error("Cannot fetch address");
        })
        .catch((err) => console.log(err));
    } else firstRender.current = false;
    // eslint-disable-next-line
  }, [location]);

  return (
    <>
      <div style={{ height: "calc(90vh - 150px)", width: "950px" }} id="map" />
    </>
  );
};

export default MapComponent;
