import React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { useHistory } from 'react-router-dom';
import moment from 'moment/moment';
import ApiCalls from '../../../apis/ApiCalls';
import { message } from 'antd';
import ImageNotAvailable from '../../../assets/images/CorporateModule/Image_not_available.png';

function EmployeeListDataTable({ employeeList, getEmployeeList }) {
  let history = useHistory();

  const onBlockEmployee = (e, item) => {
    e.stopPropagation();
    let companyId = item?.companyDetails?.companyId;
    let employeeId = item?._id;
    ApiCalls.blockEmployee(companyId, employeeId)
      .then((res) => {
        if (res.data.status) {
          message.success('Successfully blocked from emloyess list');
          getEmployeeList();
        }
      })
      .catch((error) => {
        message.error(
          error.response ? error?.response?.data?.data?.message : 'No Response From Server',
          5
        );
      });
  };

  const onUnBlockEmployee = (e, item) => {
    e.stopPropagation();
    let companyId = item?.companyDetails?.companyId;
    let employeeId = item?._id;
    ApiCalls.unBlockEmployee(companyId, employeeId)
      .then((res) => {
        if (res.data.status) {
          message.success('Unblocked the employee');
          getEmployeeList();
        }
      })
      .catch((error) => {
        message.error(
          error.response ? error?.response?.data?.data?.message : 'No Response From Server',
          5
        );
      });
  };

  return (
    <div className='App'>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label='simple table'>
          <TableHead>
            <TableRow>
              <TableCell />
              <TableCell>Name</TableCell>
              <TableCell>Joining Date</TableCell>
              <TableCell>Appointment Taken</TableCell>
              <TableCell>Credit Used</TableCell>
              <TableCell>Buttons</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {employeeList?.map((item) => (
              <TableRow
                key={item._id}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                style={{ backgroundColor: item.selected ? '#ECECEC' : 'none', cursor: 'pointer' }}
                onClick={() =>
                  history.push({
                    pathname: '/user/base/home/corporate/employees-list/user-details',
                    state: { employee: item }
                  })
                }
              >
                <TableCell>
                  <img
                    alt='No img found'
                    src={item.profilePic}
                    height='25px'
                    width='25px'
                    style={{ borderRadius: '50%' }}
                    onError={(e) => {
                      if (e.target.src !== ImageNotAvailable) {
                        e.target.src = ImageNotAvailable;
                      }
                    }}
                  />
                </TableCell>
                <TableCell component='th' scope='row'>
                  {item.fullName}
                </TableCell>
                <TableCell>{moment(item.createdAt).format('DD MMM YYYY')}</TableCell>
                <TableCell>{item.totalAppointmentsTaken}</TableCell>
                <TableCell>{item.creditsUsed}</TableCell>
                <TableCell
                  style={{
                    display: 'flex',
                    justifyContent: 'flex-start',
                    cursor: 'pointer'
                  }}
                >
                  {item.status === 'blocked' ? (
                    <div
                      style={{
                        backgroundColor: ' #4385C6',
                        borderRadius: '10px',
                        padding: '10px',
                        width: '80px',
                        height: '40px',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center'
                      }}
                      onClick={(e) => onUnBlockEmployee(e, item)}
                    >
                      <p
                        style={{
                          color: 'white',
                          textAlign: 'center',
                          marginTop: '12px',
                          fontSize: '12px'
                        }}
                      >
                        Unblock
                      </p>
                    </div>
                  ) : (
                    <div
                      style={{
                        backgroundColor: 'red',
                        borderRadius: '10px',
                        padding: '10px',
                        width: '80px',
                        height: '40px',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center'
                      }}
                      onClick={(e) => onBlockEmployee(e, item)}
                    >
                      <p
                        style={{
                          color: 'white',
                          textAlign: 'center',
                          marginTop: '12px',
                          fontSize: '12px'
                        }}
                      >
                        Block
                      </p>
                    </div>
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}

export default EmployeeListDataTable;
