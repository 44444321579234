
const consultingPatientDefaultState = {
    record: {
        allergies: [],
        currentMedications: [],
        dob: '',
        height: '',
        medicalConditions: [],
        medicalReports: [],
        surgeries: [],
        weight:''
    },
    profile: {},
    soap: {}
}

export default (state = consultingPatientDefaultState, action) => {
    switch (action.type) {
        case "SET_PATIENT_RECORD":
            return ({
                ...state,
                record: action.record
            });
        case "REMOVE_PATIENT_RECORD":
            return ({
                ...state,
                record: {}
            });
        case "SET_PATIENT_PROFILE":
            return ({
                ...state,
                profile: action.profile
            });
        case "REMOVE_PATIENT_PROFILE":
            return ({
                ...state,
                profile: {}
            });
        case "UPDATE_SOAP":
            return ({
                ...state,
                soap: { ...state.soap, ...action.soap }
            });
        case "REMOVE_SOAP":
            return ({
                ...state,
                soap: {}
            });
        default:
            return state
    }
}