export const days = [
  {
    day: "Sunday",
    open: false,
    startTime: null,
    endTime: null,
    addBreak: [],
  },
  {
    day: "Monday",
    open: false,
    startTime: null,
    endTime: null,
    addBreak: [],
  },
  {
    day: "Tuesday",
    open: false,
    startTime: null,
    endTime: null,
    addBreak: [],
  },
  {
    day: "Wednesday",
    open: false,
    startTime: null,
    endTime: null,
    addBreak: [],
  },
  {
    day: "Thursday",
    open: false,
    startTime: null,
    endTime: null,
    addBreak: [],
  },
  {
    day: "Friday",
    open: false,
    startTime: null,
    endTime: null,
    addBreak: [],
  },
  {
    day: "Saturday",
    open: false,
    startTime: null,
    endTime: null,
    addBreak: [],
  },
];
