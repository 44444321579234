import styled from "styled-components";

export const CouponSection = styled.div`
  background: #E3E3E3;
  border-radius: 10px;
  padding: 20px;
`;

export const FeesValue = styled.span`
  font-family: Metropolis Regular;
  font-size: 16px;
  font-weight: 400;
  color: #262626;

  @media only screen and (max-width: 600px) {
    font-size: 14px;
  }
`;

export const TotalValue = styled.span`
  font-family: Metropolis Black;
  font-size: 20px;
  font-weight: 400;
  color: #2C509F;

  @media only screen and (max-width: 600px) {
    font-size: 18px;
  }
`;

export const PaymentTitle = styled.h6`
  margin-bottom: 0;
  font-family: Metropolis Regular;
  font-size: 24px;
  color: #03183c;
  font-weight: 700;

  @media only screen and (max-width: 600px) {
    font-size: 20px;
  }
`;

export const CouponButton = styled.span`
  font-size: 16px;
  font-weight: 900;
  display: flex;
  align-items: center;
  color: #2C509F;
  cursor: pointer;

  @media only screen and (max-width: 600px) {
    font-size: 14px;
  }
`;

export const StyledModal = styled.span`
  width: ${(props) =>
    props.map
      ? "1000px !important"
      : props.availability
      ? "430px !important"
      : props.fullScreen
      ? "1300px !important"
      : ""};
  height: ${(props) => (props.map ? "90vh !important" : "")};
  padding-bottom: 0;

  & .ant-modal-content {
    border-radius: 10px;
    overflow: hidden;
  }

  & .ant-modal-header {
    border-bottom: none;
    padding: 30px 30px 20px 30px;
  }

  & .ant-modal-title {
    font-size: 23px;
    font-family: "Gilroy SemiBold";
    color: #35364f;
  }

  & .ant-modal-body {
    padding: 0 30px;
  }

  & .ant-modal-footer {
    display: ${(props) => (props.hideFooter ? "none" : "flex")};
    justify-content: space-between;
    align-items: flex-start;
    border-top: none;
    padding: 30px;
  }

  & .ant-radio-group {
    width: 100%;
  }
`;

export const SuccessMessage = styled.h6`
  width: 100%;
  padding: 33px 70px 20px 70px;
  padding-top: ${(props) => (props.type2 ? "0" : "33px")};
  margin-bottom: 0;
  text-align: center;
  font-family: ${(props) =>
    props.type2 ? "'Urbanist', sans-serif" : "Gilroy Bold"};
  font-weight: 900;
  font-size: 20px;
  color: #35364f;
`;

export const Confirmtext = styled.h6`
  width: 100%;
  padding: 33px 70px 20px 70px;
  padding-top: ${(props) => (props.type2 ? "0" : "33px")};
  margin-bottom: 0;
  text-align: center;
  font-family: ${(props) =>
    props.type2 ? "'Urbanist', sans-serif" : "Gilroy Bold"};
  font-weight: 900;
  font-size: 14px;
  color: #35364f;
`;

export const DoctorName = styled.h6`
  width: 100%;
  padding: 33px 70px 20px 70px;
  padding-top: ${(props) => (props.type2 ? "0" : "33px")};
  margin-bottom: 0;
  text-align: center;
  font-family: ${(props) =>
    props.type2 ? "'Urbanist', sans-serif" : "Gilroy Bold"};
  font-weight: 900;
  font-size: 18px;
  color: #35364f;
`;

export const BookingValue = styled.span`
  font-family: Metropolis Regular;
  font-size: 18px;
  font-weight: 500;
  color: #565656;
  position: relative;
  top: 6px;
  left: 15px;
`
export const CancellationPolicyContainer = styled.div`
  margin-top: 30px;

  & span:first-child {
    font-family: 'Metropolis Regular';
    font-size: 16px;
    font-weight: 700;
    color: #000000;
  }

  & span:last-child {
    font-family: 'Metropolis Regular';
    font-size: 14px;
    font-weight: 400;
    color: #565656;
    display: block;
    margin-top: 10px;
  }

  @media only screen and (max-width: 600px) {
    margin-top: 20px;

    & span:first-child {
      font-size: 14px;
    }

    & span:last-child {
      font-size: 12px;
      margin-top: 8px;
    }
  }
`;

export const ConfirmationSection = styled.div`
  padding: 20px 140px;

  @media only screen and (max-width: 600px){
    padding: 0px;
  }
`