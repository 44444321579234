const defaultOnlineReducer = {
    onlineDoctors:[],
    onlinePatients:[]
}
const onlineReducer = (state = defaultOnlineReducer, action) => {
    switch (action.type) {
        case "SET_ONLINE_DOCTORS":
            return ({ ...state, onlineDoctors: action.onlineDoctors });
        case "CLEAR_ONLINE_DOCTORS":
            return ({ ...state, onlineDoctors: [] });
        case "SET_ONLINE_PATIENTS":
            return ({ ...state, onlinePatients: action.onlinePatients });
        case "CLEAR_ONLINE_PATIENTS":
            return ({ ...state, onlinePatients: [] })
        default:
            return(state)
    }
}
export default onlineReducer