import React, { useState, useEffect } from "react";
import { AutoComplete, message } from "antd";
import { useHistory } from "react-router";
import moment from "moment";
import {
  BannerContainer,
  WelcomeTextContainer,
  WelcomeText,
  SearchIconContainer,
  SearchInputContainer,
} from "./styledComponents";
import SearchIcon from "../../../assets/icons/DashBoardIcons/SearchIcon";
import ApiCalls from "../../../apis/ApiCalls";
// import DashboardContent from "../CorporateDashboard/DashboardContent";
import storage from "../../../storage";
import WelcomeSubscription from "../CorporateDashboard/welcomeSubscription";

const WelcomeBanner = ({ user }, { mysub }) => {
  const [searchValue, setSearchValue] = useState("");
  const [doctorSuggestion, setDoctorSuggestion] = useState([]);

  const history = useHistory();

  const getDoctorSuggestions = () => {
    ApiCalls.getDoctorsList({ page: 0, limit: 10, text: searchValue.trim() })
      .then((response) => {
        if (response.data.status) {
          let doctors = response.data.data.doctors;
          let options = doctors.map((item) => {
            return { value: item.name };
          });
          setDoctorSuggestion(options);
        }
      })
      .catch((error) => {
        message.error(
          error.response
            ? error?.response?.data?.data?.message
            : "No Response From Server",
          5
        );
      });
  };

  useEffect(() => {
    let searchTimeout = null;
    if (searchValue.length > 0) {
      searchTimeout = setTimeout(() => {
        getDoctorSuggestions();
      }, 500);
    } else {
      setDoctorSuggestion([]);
    }
    return () => {
      clearTimeout(searchTimeout);
    };
    // eslint-disable-next-line
  }, [searchValue]);

  return (
    <BannerContainer>
      <div style={{ display: "flex", flexDirection: "column" }}>
        <div style={{ display: "flex", gap: "8px", alignItems: "center" }}>
          <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g clip-path="url(#clip0_1266_21995)">
              <path
                d="M10 14.1667C12.3012 14.1667 14.1667 12.3012 14.1667 10C14.1667 7.69882 12.3012 5.83334 10 5.83334C7.69881 5.83334 5.83333 7.69882 5.83333 10C5.83333 12.3012 7.69881 14.1667 10 14.1667Z"
                stroke="#565656"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M10 0.833344V2.50001"
                stroke="#565656"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M10 17.5V19.1667"
                stroke="#565656"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M3.51666 3.51666L4.7 4.7"
                stroke="#565656"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M15.3 15.3L16.4833 16.4833"
                stroke="#565656"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M0.833328 10H2.49999"
                stroke="#565656"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M17.5 10H19.1667"
                stroke="#565656"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M3.51666 16.4833L4.7 15.3"
                stroke="#565656"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M15.3 4.7L16.4833 3.51666"
                stroke="#565656"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </g>
            <defs>
              <clipPath id="clip0_1266_21995">
                <rect width="20" height="20" fill="white" />
              </clipPath>
            </defs>
          </svg>
          <span
            style={{ color: "#565656", fontSize: "20px", fontWeight: "700" }}
          >
            Good Morning!
          </span>
        </div>
        <div>
          <span
            style={{ color: "#262626", fontWeight: "400", fontSize: "36px" }}
          >
            Welcome John 🎉
          </span>
        </div>
      </div>
      {/* <WelcomeTextContainer>
        <WelcomeText sub={true}>
          {moment(new Date()).format("dddd, DD MMMM")}
        </WelcomeText>
        <WelcomeText>Hi, {user.firstName}</WelcomeText>
      </WelcomeTextContainer>
      {localStorage.getItem("userType") !== "company" ? (
        <SearchInputContainer>
          <SearchIconContainer>
            <SearchIcon />
          </SearchIconContainer>
          <AutoComplete
            placeholder="Search"
            options={doctorSuggestion}
            onSearch={(val) => setSearchValue(val)}
            onSelect={(val) => setSearchValue(val)}
            value={searchValue}
            onKeyDown={(e) => {
              if (e.keyCode === 13) {
                e.preventDefault();
                history.push({
                  pathname: "/user/base/home/view-doctors",
                  state: {
                    searchValue: searchValue,
                    selectedCity: null,
                  },
                });
              }
            }}
          />
        </SearchInputContainer>
      ) : null}

      {storage.user.getItem()?.isCorporatePatient ? (
        <WelcomeSubscription />
      ) : null} */}
    </BannerContainer>
  );
};

export default WelcomeBanner;
