import { Button } from "antd";
import styled from "styled-components";

export const ListCardContainer = styled.div`
  position: relative;
  height: auto;
  width: 100%;
  & > :first-of-type {
    margin-top: ${(props) => (props.margin ? "15px" : "0 !important")};
  }

  & .upload-file-custom {
    margin-top: 15px;
  }
`;

export const StyledCard = styled.div`
  position: relative;
  display: ${(props) => (props.image ? "flex" : "")};
  height: auto;
  padding: 22px 0 17px;
  width: 100%;
  margin-top: 15px;
  border: 1px solid #dde5ed;
  border-color: ${(props) => (props.selected ? "#4385C6" : "#dde5ed")};
  border-radius: 10px;
`;

export const CollegeName = styled.p`
  padding-left: 27px;
  margin-bottom: 0;
  font-size: 16px;
  font-family: "Gilroy SemiBold";
  color: #35364f;
  line-height: 1;
`;

export const Qualification = styled.p`
  padding: 9px 0 0 27px;
  margin-bottom: 0;
  font-size: 14px;
  font-family: 'Urbanist', sans-serif;
  color: #4385C6;
  line-height: 1;
  text-transform: capitalize;
`;

export const Address = styled.p`
  padding: 9px 27px 0 27px;
  margin-bottom: 0;
  font-size: 14px;
  font-family: 'Urbanist', sans-serif;
  color: #65667d;
  line-height: ${(props) => (props.type2 ? "1.4" : "1")};
  word-break: break-all;
`;

export const AddEducation = styled.button`
  border: none;
  background: none;
  width: auto;
  font-size: 18px;
  font-family: "Gilroy SemiBold";
  color: #4385C6;
  margin-top: 15px;
  margin-bottom: 30px;
  cursor: pointer;
`;

export const FormContainer = styled.div`
  height: auto;
  width: 100%;
  padding: 15px 0;
`;

export const ButtonContainer = styled.div`
  float: right;
  height: 35px;
  width: 150px;
  display: flex;
  justify-content: space-between;
`;

export const StyledButton = styled(Button)`
  border-radius: 5px;
`;

export const SpinContainer = styled.div`
  height: 209px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const EditOption = styled.button`
  border: none;
  background: none;
  position: absolute;
  top: 20px;
  right: ${(props) => (props.delete ? "25px" : "125px")};
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: auto;
  width: ${(props) => (props.delete ? "75px" : "60px")};
  font-size: 15px;
  font-family: 'Urbanist', sans-serif;
  color: #35364f;
  cursor: pointer;
  & svg {
    height: 13px;
    width: 13px;
  }
`;
