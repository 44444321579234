import React, { useEffect, useState } from "react";
import { message } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import DashboardContent from "./DashboardContent";
import WelcomeBanner from "../PatientDashboard/WelcomeBanner";
import { CorporateDashboardContainer } from "./styledComponent";
import ApiCalls from "../../../apis/ApiCalls";
import storage from "../../../storage";

const CorporateDashboard = ({ user }) => {
  const [corporateData, setDashboardData] = useState(null);
  const [loading, setLoading] = useState(false);

  const getCompanyprofile = () => {
    setLoading(true);
    ApiCalls.getCompanyProfile()
      .then((response) => {
        if (response.data.status) {
          setDashboardData(response.data.data);
          localStorage.setItem("companyId", response.data.data._id);
          storage.user.setItem(response.data.data);
        }
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        message.error(
          error.response
            ? error?.response?.data?.data?.message
            : "No Response From Server",
          5
        );
      });
  };

  useEffect(() => {
    getCompanyprofile();
  }, []);

  return loading ? (
    <LoadingOutlined />
  ) : (
    <CorporateDashboardContainer>
      <WelcomeBanner user={user} />
      <DashboardContent companyProfile={corporateData} />
    </CorporateDashboardContainer>
  );
};

export default CorporateDashboard;
