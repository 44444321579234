const setCallData = (call = {}) => ({
  type: "SET_CALL",
  call,
});

const removeCallData = () => ({
  type: "CLEAR_CALL",
});

const setExtendedAppointmentInfo = (extendedCallInfo = {}) => ({
  type: "SET_EXTENDED_APPOINTMENT_INFO",
  extendedCallInfo,
});

export default {
  setCallData,
  removeCallData,
  setExtendedAppointmentInfo,
};
