import React, { useEffect, useRef, useState } from "react";
import NotificationIcon from "../../assets/images/DashboardHeaderIcon/notifications.svg";
import {
  NotificationsContainer,
  StyledPopover,
  NotificationItemContainer,
  NotificationIconContainer,
  NotificationInfo,
  NotificationTitle,
  NotificationTime,
  NotificationMessage,
  MarkAsRead,
  ClearAll,
  EmptyMessage,
  NotificationBellIconWrapper,
  NotificationUnreadReminder,
} from "./styledComponent";
import InfiniteScroll from "react-infinite-scroller";
import SocketActions from "../../sockets/SocketActions";
import ApiCalls from "../../apis/ApiCalls";
import { message } from "antd";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";
import { LoadingOutlined } from "@ant-design/icons";
import { useHistory } from "react-router";
import storage from "../../storage";
import reduxActions from "../../redux-store/actions";

const Notifications = () => {
  const [notifications, setNotifications] = useState([]);
  const [showNotifications, setShowNotifications] = useState(false);
  const [loading, setLoading] = useState(false);
  const [hasMore, setHasMore] = useState(false);
  const [scrollLoading, setScrollLoading] = useState(false);
  const scrollParent = useRef();
  const history = useHistory();
  const user = useSelector((state) => state.auth.userData);
  const userDataRef = useRef();
  const dispatch = useDispatch();
  const renderTime = (time) => {
    return moment(time).fromNow();
  };
  let userType = localStorage.getItem("userType");

  const getAllNotifications = (page) => {
    if (page === 0) setLoading(true);
    setScrollLoading(true);
    let params = { page: page, limit: 5 };
    (userType === "patient"
      ? ApiCalls.fetchAllNotificationsPatient(params)
      : userType === "provider"
      ? ApiCalls.fetchAllNotificationsProvider(params)
      : ApiCalls.fetchAllNotificationsCompanies(params)
    )
      .then((response) => {
        if (response.data.status) {
          let res = response.data.data;
          if (page === 0) {
            setNotifications(res.notificationData);
            user.unreadNotificationCount = res.unreadNotificationCount;
            storage.user.setItem(user);
            dispatch(reduxActions.authActions.setUserData({ ...user }));
          } else setNotifications(notifications.concat(res.notificationData));
          setHasMore(res.length > 4);
        }
        setScrollLoading(false);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        setScrollLoading(false);
        message.error(
          error.response
            ? error?.response?.data?.data?.message
            : "No Response From Server",
          5
        );
      });
  };

  const markAsRead = (id) => {
    let data = { _id: id };
    (userType === "patient"
      ? ApiCalls.markAsReadNotificationPatient(data)
      : userType === "provider"
      ? ApiCalls.markAsReadNotificationProvider(data)
      : ApiCalls.markAsReadNotificationCompanies(data)
    )
      .then((response) => {
        if (response.data.status) {
          let index = notifications.findIndex((item) => item._id === id);
          notifications[index].read = true;
          setNotifications([...notifications]);
          user.unreadNotificationCount =
            response.data.data.unreadNotificationCount;
          storage.user.setItem(user);
          dispatch(reduxActions.authActions.setUserData({ ...user }));
        }
      })
      .catch((error) => {
        message.error(
          error.response
            ? error?.response?.data?.data?.message
            : "No Response From Server",
          5
        );
      });
  };

  const clearAll = () => {
    (userType === "patient"
      ? ApiCalls.clearAllNotificationsPatient()
      : userType === "provider"
      ? ApiCalls.clearAllNotificationsProvider()
      : ApiCalls.clearAllNotificationsCompanies()
    )
      .then((response) => {
        if (response.data.status) {
          setNotifications([]);

          user.unreadNotificationCount = 0;
          storage.user.setItem(user);
          dispatch(reduxActions.authActions.setUserData({ ...user }));
        }
      })
      .catch((error) => {
        message.error(
          error.response
            ? error?.response?.data?.data?.message
            : "No Response From Server",
          5
        );
      });
  };

  const redirect = (title) => {
    return ["Payment Status", "Appointment Reminder"].includes(title)
      ? true
      : false;
  };

  const receiveNotificationCB = (res) => {
    setShowNotifications(true);
    setNotifications((prev) => [res, ...prev]);

    userDataRef.current.unreadNotificationCount =
      userDataRef.current.unreadNotificationCount + 1;
    storage.user.setItem(userDataRef.current);
    dispatch(reduxActions.authActions.setUserData({ ...userDataRef.current }));
  };

  useEffect(() => {
    userDataRef.current = user;
  });

  useEffect(() => {
    if (userType) SocketActions.receiveNotification(receiveNotificationCB);
    return () => {
      if (userType) SocketActions.offReceiveNotification(receiveNotificationCB);
    };
    // eslint-disable-next-line
  }, [userType]);

  useEffect(() => {
    userType && showNotifications && getAllNotifications(0);
    if (!showNotifications) setNotifications([]);
    // eslint-disable-next-line
  }, [userType, showNotifications]);

  const content = (
    <>
      <NotificationsContainer ref={scrollParent}>
        {loading ? (
          <LoadingOutlined />
        ) : notifications.length === 0 ? (
          <EmptyMessage>No new notifications found!</EmptyMessage>
        ) : (
          <InfiniteScroll
            pageStart={0}
            loadMore={getAllNotifications}
            hasMore={!scrollLoading && hasMore}
            initialLoad={false}
            useWindow={false}
            getScrollParent={() => scrollParent.current}
            key={showNotifications}
          >
            {notifications.map((item) => {
              return (
                <NotificationItemContainer
                  read={item.read}
                  key={item._id}
                  title={redirect(item.title) ? "View appointment" : null}
                  pointer={redirect(item.title)}
                  onClick={() => {
                    if (redirect(item.title)) {
                      history.push("/user/base/home/appointments");
                      setShowNotifications(false);
                    }
                  }}
                >
                  <NotificationIconContainer bg={item.logo} />
                  <NotificationInfo>
                    <NotificationTitle>{item.title}</NotificationTitle>
                    <NotificationTime>
                      {renderTime(item.notificationSendDate)}
                    </NotificationTime>
                    <NotificationMessage>{item.msg}</NotificationMessage>
                    {!item.read && (
                      <MarkAsRead
                        onClick={(e) => {
                          e.stopPropagation();
                          markAsRead(item._id);
                        }}
                      >
                        Mark as read
                      </MarkAsRead>
                    )}
                  </NotificationInfo>
                </NotificationItemContainer>
              );
            })}
          </InfiniteScroll>
        )}
      </NotificationsContainer>
      {notifications.length > 1 ? (
        <ClearAll onClick={() => clearAll()}>Clear All</ClearAll>
      ) : null}
    </>
  );

  return (
    <>
      <StyledPopover
        content={content}
        trigger="click"
        visible={showNotifications}
        placement="bottomRight"
        onVisibleChange={() => setShowNotifications(!showNotifications)}
      >
        <NotificationBellIconWrapper>
          <svg
            width="32"
            height="32"
            viewBox="0 0 32 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M24 10.667C24 8.54526 23.1571 6.51043 21.6569 5.01014C20.1566 3.50985 18.1217 2.66699 16 2.66699C13.8783 2.66699 11.8434 3.50985 10.3431 5.01014C8.84286 6.51043 8 8.54526 8 10.667C8 20.0003 4 22.667 4 22.667H28C28 22.667 24 20.0003 24 10.667Z"
              stroke="black"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M18.3067 28C18.0723 28.4041 17.7358 28.7395 17.331 28.9727C16.9262 29.2059 16.4672 29.3286 16 29.3286C15.5329 29.3286 15.0739 29.2059 14.6691 28.9727C14.2642 28.7395 13.9278 28.4041 13.6934 28"
              stroke="black"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>

          {user.unreadNotificationCount > 0 ? (
            <NotificationUnreadReminder />
          ) : null}
        </NotificationBellIconWrapper>
      </StyledPopover>
    </>
  );
};
export default Notifications;
