import React, { useRef, useEffect } from "react";

const OtpInput = ({ length = 6, otp, setOtp }) => {
  const inputRefs = useRef([]);

  useEffect(() => {
    if (inputRefs.current[0]) {
      inputRefs.current[0].focus();
    }
  }, []);

  const handleChange = (element, index) => {
    if (isNaN(element.value)) return false;

    const newOtp = [...otp];
    newOtp[index] = element.value.slice(-1);
    setOtp(newOtp);

    if (element.value && index < length - 1) {
      inputRefs.current[index + 1].focus();
    }
  };

  const handleKeyDown = (e, index) => {
    if (e.key === "Backspace") {
      const newOtp = [...otp];
      newOtp[index] = "";
      setOtp(newOtp);

      if (index > 0) {
        inputRefs.current[index - 1].focus();
      }
    }
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        gap: "12px",
        alignItems: "center",
      }}
    >
      {otp.map((data, index) => {
        return (
          <input
            key={index}
            type="text"
            maxLength="1"
            ref={(el) => (inputRefs.current[index] = el)}
            value={data}
            onChange={(e) => handleChange(e.target, index)}
            onKeyDown={(e) => handleKeyDown(e, index)}
            style={{
              width: "62px",
              height: "62px",
              padding: "12px 16px 12px 20px",
              borderRadius: "12px",
              backgroundColor: "#F8F8FA",
              fontFamily: "Metropolis Regular",
              fontWeight: "600",
              fontSize: "32px",
              color: "#262626",
              border: "none",
            }}
          />
        );
      })}
    </div>
  );
};

export default OtpInput;
