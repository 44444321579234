import React, { useEffect, useState } from "react";
import {
   SurveyFormContainer,
   SurveyFormHeader,
   Heading,
   SubText,
   SurveyFormWrapper,
   Wrapper,
   Image,
   SurveyFormBox,
   NumberText,
   Divider,
   ContentWrapper,
   TextHeading,
   Text,
} from "./styledComponents";
import Checklist from "../../assets/images/patientModuleIcons/checklist_rtl.svg";
import { CustomButton } from "../ViewDoctors/styledComponents";
import { useHistory, useParams } from "react-router-dom";
import { message } from "antd";
import ApiCalls from "../../apis/ApiCalls";
import moment from "moment";

const SurveyForm =()=>{
   const [loading, setLoading] = useState(true);
   const [allForm, setAllForm] = useState([]);
   const [singleForm, setSingleForm] = useState({});
   const [questionnairs, setQuestionnairs] = useState([]);

   const param = useParams();
   const history = useHistory();

   const getSurveyFormList = () => {
      setLoading(true);
      ApiCalls.getAllSurveyFormList()
        .then((response) => {
          if (response.data.status) {
            if (response?.data?.data?.data?.length > 0) {
             setAllForm(response?.data?.data?.data)
             message.success("All survey form list are here")
            }
          } else {
            setAllForm([]);
          }
          setLoading();
        })
        .catch((err) => {
          setLoading();
          message.error("somwthing went wrong")
        });
    };

    const GiveSurvey = (item) => {
      history.push(`/user/base/home/dashboard/survay-form/give-survey/${item._id}`)  
    };

    useEffect(()=>{
      getSurveyFormList();
    },[])

 return(
    <SurveyFormContainer>
      <SurveyFormHeader>
         <Wrapper>

         <Image>
         <img src={Checklist} height="20px" width="20px"/>
         </Image>
            <Heading>
            Survey
            </Heading>
         </Wrapper>
         <SubText>
            Nunc blandit mauris duis massa lacinia feugiat imperdiet quis sed.
         </SubText>
      </SurveyFormHeader>
     
      <SurveyFormWrapper style={{display:"grid", gridTemplateColumns:"1fr 1fr"}}>
      {allForm.map((item, index)=>(
         <SurveyFormBox key={index._id}>
            <NumberText>{index + 1}</NumberText>
            <Divider/>
         <ContentWrapper>
            <TextHeading>Survey form {index + 1} ({item.formName})</TextHeading>
            <SubText>{item.formDescription}(desc)</SubText>
            <Text>Ends on {moment(item.updatedAt).format('Do MMMM YYYY')}</Text>
            <CustomButton onClick={()=>GiveSurvey(item)}>Give Survey</CustomButton>
         </ContentWrapper>
         </SurveyFormBox>
          ))}
      </SurveyFormWrapper>
    
    </SurveyFormContainer>
 )   
}
export default SurveyForm;