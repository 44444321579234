import styled from "styled-components";

export const BlogsPageWrapper = styled.div`
  height: auto;
  width: 100%;
  padding: 35px 35px 35px 40px;

  @media only screen and (max-width: 600px) {
    padding: 35px 20px 35px 20px;
  }
`;

export const ListContainer = styled.div`
  position: relative;
  margin-top: 30px;
  display: grid;
  grid-gap: 20px;
  grid-template-columns: repeat(auto-fill, 270px);
  grid-auto-rows: 120px;

  & .anticon-loading {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    & svg {
      height: 2rem;
      width: 2rem;
    }
  }
`;

export const BlogCard = styled.div`
  position: relative;
  display: -webkit-box;
  height: auto;
  width: 100%;
  padding: 20px;
  border-radius: 10px;
  ${'' /* background: linear-gradient(#E3E3E34D 30%, #E3E3E34D 30%); */}
  cursor: pointer;

  @media only screen and (max-width: 600px) {
    margin-top: 20px;
  }
`;

export const InfoContainer = styled.div`
  height: 100%;
  width: calc(100% - 70px);
  padding-left: 10px;
`;

export const Name = styled.p`
  font-family: "Gilroy Bold";
  font-size: 13px;
  color: #35364f;
  text-transform: capitalize;
  max-height: 40px;
  margin-bottom: 0px;
`;

export const MoreInfo = styled.p`
  font-family: 'Urbanist', sans-serif;
  font-size: 14px;
  color: #65667d;
  text-transform: capitalize;
  margin-top: 20px
`;

export const PageTitle = styled.p`
    font-size: 20px;
    font-weight: 800;
`

export const ShowIcon = styled.div`
  cursor: pointer;
  margin-top: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: blue;
`

export const EmptyResultMessageRecord = styled.p`
  position: absolute;
  top: 60%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-family: 'Urbanist', sans-serif;
  font-weight:500;
  font-size: 16px;
  color: #13140F;
  text-align: center;
`;

export const ArticleFiltersContainer = styled.div`
    display: flex;
    gap: 8px;
    scroll-behavior: smooth;
    overflow: auto;
    cursor: pointer;

    .filterItemContainer {
        font-size: 16px;
        font-weight: 500;
        color: #262626;
        font-family: Metropolis Bold;
        display: flex;
        justify-content: center;
        align-items: center;
        border: 1px solid #262626;
        border-radius: 30px;
        padding: 6px 12px;
        flex-basis: auto;
        white-space: nowrap;
        transition: background-color 0.3s, border-color 0.3s;
        cursor: pointer;
    }

    .filterItemContainer.selected {
        font-size: 16px;
        font-weight: 500;
        font-family: Metropolis Bold;
        background-color: #007bff;
        border-color: #007bff;
        color: #ffffff;
    }
`;