import React, { useState, useEffect } from "react";
import {
  CardContainer,
  Heading,
  Amount,
  WalletContainer,
  WalletWrapper,
  WalletHeader,
  WalletImage,
  WalletText,
  AddWalletContainer,
  AddCreditButton,
  CustomButton,
  Price,
  StripeText
} from "./styledComponent";
import { useHistory } from 'react-router-dom';
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import Balance from "../../../assets/images/CorporateModule/balance.png";
import { Input, Row, message, Divider } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { InputWrapper } from "../../signup/styledComponents";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import StripePaymentGateway from './Payment/Stripe';
import rafiki from "../../../assets/images/CorporateModule/rafiki.png";
import ApiCalls from "../../../apis/ApiCalls";
import storage from "../../../storage";
import ConfirmationModal from "./Payment";

const WalletCard = () => {
  const history = useHistory();
  const [dashboardData, setDashboardData] = useState({});
  const [paymentSatus, setPaymentStatus] = useState({});
  const [amount, setAmount] = useState("");
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const [amountDetails, setAmountDetails] = useState({});
  const [paymentStatusModel, setPaymentStatusModel] = useState(false);
  const [selectedGateway, setSelectedGateway] = useState(null);
  const stripePromise = loadStripe(
    process.env.REACT_APP_STRIPE_KEY
  );
  const [stripeModal, setStripeModal] = useState({
    show: false,
    clientsecret: null,
    transactionId: null
  });

  const inputChange = (e) => {
    setAmount(e.target.value);
  };

  // Get company profile details
  const getCompanyprofile = () => {
    ApiCalls.getCompanyProfile()
      .then((response) => {
        if (response.data.status) {
          setDashboardData(response.data.data);
          storage.user.setItem(response.data.data);
        }
        setLoading(false);
      })
      .catch((error) => {
        message.error(
          error.response
            ? error?.response?.data?.data?.message
            : "No Response From Server",
          5
        );
        setLoading(false);
      });
  };

  const handleOpen = (gateway) => {
    if (amount?.length > 0) {
      setSelectedGateway(gateway);
      let companyId = localStorage.getItem("companyId");
      let data = {
        amount,
        gateway
      };
      ApiCalls.getRechargeCredit(companyId, data)
        .then((response) => {
          if (response.data.status) {
            setAmountDetails(response.data.data);
            setOpen(true);
          } else {
            message.error("Something went wrong");
          }
        })
        .catch((err) => {
          message.error("Something went wrong");
        });
    }
  };

  useEffect(() => {
    getCompanyprofile();
  }, []);

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 450,
    height: 651,
    bgcolor: "background.paper",
    border: "2px solid #CCF9FF",
    borderRadius: "8px",
    boxShadow: 24,
    padding: 9,
  };

  const onCancel = () => {
    setOpen(false);
    setSelectedGateway(null);
  };

  // Checking payment status
  const getPaymentStatus = (id) => {
    (selectedGateway === 'STRIPE' ? ApiCalls.verifyStripePayment(id) : ApiCalls.verifyRazorPayPayment(id))
      .then((response) => {
        if (response.data.status) {
          getCompanyprofile();
          setStripeModal({
            show: false,
            clientsecret: null,
            transactionId: null
          });
          setAmount("");
          setPaymentStatus("Success");
          setPaymentStatusModel(true);
          setSelectedGateway(null);
        } else {
          setStripeModal({
            show: false,
            clientsecret: null,
            transactionId: null
          });
          setSelectedGateway(null);
          setPaymentStatus("Failed");
          setPaymentStatusModel(true);
          message.error(response.data.data.message);
        }
      })
      .catch((error) =>
        message.error(
          error.response
            ? error?.response?.data?.data?.message
            : "No Response From Server",
          5
        )
      );
  };

  function loadScript(src) {
    return new Promise((resolve) => {
      const script = document.createElement("script");
      script.src = src;
      script.onload = () => {
        resolve(true);
      };
      script.onerror = () => {
        resolve(false);
      };
      document.body.appendChild(script);
    });
  }

  // Display Stripe
  const displayStripe = () => {
    setOpen(false);
    setStripeModal({
      show: true,
      clientsecret: amountDetails?.clientSecret,
      transactionId: amountDetails?.transactionId
    });
  };

  // Display razorpay
  async function displayRazorpay() {
    const res = await loadScript(
      "https://checkout.razorpay.com/v1/checkout.js"
    );

    if (!res) {
      alert("Razorpay SDK failed to load. Are you online?");
      return;
    }
    setOpen(false);

    const options = {
      key: process.env.REACT_APP_RAZORPAY_KEY,
      amount: Number(
        (amountDetails.amount + amountDetails.serviceTaxValue) * 100
      ).toString(),
      currency: amountDetails.currency,
      name: "TeleHealings",
      description: "",
      order_id: amountDetails.orderId,
      handler: async function (response) {
        getPaymentStatus(amountDetails.transactionId);
      },
      prefill: {
        name: dashboardData.companyName,
        email: dashboardData.companyEmail,
      },
      notes: {
        address: dashboardData.companyAddress,
      },
      theme: {
        color: "#4385C6",
      },
    };

    const paymentObject = new window.Razorpay(options);
    paymentObject.open();
  }

  const onOk = () => {
    if(selectedGateway === 'STRIPE') {
      displayStripe();
    } else if(selectedGateway === 'RAZOR_PAY') {
      displayRazorpay();
    }
  };

  const donePayment = () => {
    setPaymentStatusModel(false);
    history.push('/user/base/home/corporate/dashboard');
  };

  return loading ? (
    <LoadingOutlined />
  ) : (
    <CardContainer>
      <WalletContainer>
        <Heading>Wallet balance :</Heading>
        <Amount amount={true}>
          ₹{dashboardData?.subscriptionCreditDetails?.creaditsLeft}
        </Amount>
        <WalletWrapper>
          <WalletImage>
            <img src={Balance} alt="No img found" />
          </WalletImage>
          <AddWalletContainer>
            <WalletHeader>Add credits to wallet</WalletHeader>
            <WalletText>
              Please enter the amount of credits you would like to add to your
              wallet below
            </WalletText>
            <Row style={{ marginBottom: "23px" }}>
              <InputWrapper>
                <Input
                  placeholder="Amount"
                  value={amount}
                  onChange={inputChange}
                />
              </InputWrapper>
            </Row>
            <Row>
              {/* <AddCreditButton
                onClick={() => handleOpen('STRIPE')}
                disabled={amount?.length === 0}
                style={{ marginRight: '20px' }}
              >
                Buy using stripe
              </AddCreditButton> */}
              <AddCreditButton
                onClick={() => handleOpen('RAZOR_PAY')}
                disabled={amount?.length === 0}
              >
                Buy using razorpay
              </AddCreditButton>
            </Row>
            {/* <StripeText>Use stripe if outside India</StripeText> */}
          </AddWalletContainer>
        </WalletWrapper>
        {open && (
          <Modal
            hideBackdrop
            open={open}
            aria-labelledby="child-modal-title"
            aria-describedby="child-modal-description"
          >
            <Box sx={{ ...style, width: 390 }}>
              <img
                src={rafiki}
                alt="No img found"
                height="270px"
                width="246px"
              />
              <h2 id="child-modal-title" style={{ color: "#4385C6" }}>
                Price Details
              </h2>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <Price id="child-modal-description">
                  Sub Total(inclusive of taxes):
                </Price>
                <Price>
                  {amountDetails.creditPurchaseAmount}
                  {amountDetails.currency}
                </Price>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <Price id="child-modal-description">
                  Payment Integrator Charge:
                </Price>
                <Price id="child-modal-description">
                  {amountDetails.integratorprocessingFeeValue}
                  {amountDetails.currency}
                </Price>
              </div>
              <Divider />
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <Price id="child-modal-description">Total:</Price>
                <Price id="child-modal-description">
                  {amountDetails.amount}
                  {amountDetails.currency}
                </Price>
              </div>
              <div style={{ display: "flex", flexDirection: "row" }}>
                <CustomButton
                  cancel2={true}
                  onClick={onCancel}
                  style={{ marginRight: "10px" }}
                >
                  Cancel
                </CustomButton>
                <CustomButton onClick={onOk} loading={loading}>
                  Proceed
                </CustomButton>
              </div>
            </Box>
          </Modal>
        )}
        {paymentStatusModel ? (
          <ConfirmationModal
            paymentStatusModel={paymentStatusModel}
            onOk={donePayment}
            onCancel={() => setPaymentStatusModel(false)}
            paymentStatus={paymentSatus}
          />
        ) : null}

        {/* Stripe payment modal */}
        {/* {stripeModal.show ? (
          <Modal open={stripeModal.show}>
            <Elements stripe={stripePromise} options={{ clientSecret: stripeModal.clientsecret }}>
              <StripePaymentGateway
                transactionId={stripeModal.transactionId}
                verifyPayment={getPaymentStatus}
                onCancel={() => setStripeModal({ show: false, clientsecret: null, transactionId: null })}
              />
            </Elements>
          </Modal>
        ) : null} */}

      </WalletContainer>
    </CardContainer>
  );
};

export default WalletCard;
