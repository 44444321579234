import React from "react";

const SignUp = ({ onFinish }) => {
  return (
    <button
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        padding: "14px 10px",
        border: "none",
        borderRadius: "8px",
        gap: "16px",
        backgroundColor: "#2C509F",
      }}
      onMouseOver={(e) => (e.target.style.cursor = "pointer")}
      onClick={onFinish}
    >
      <span
        style={{
          fontSize: "16px",
          fontWeight: "700",
          color: "#FFFFFF",
        }}
      >
        Sign Up
      </span>
    </button>
  );
};

export default SignUp;
