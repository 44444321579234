import React from "react";
import {
  FailedMessage,
  DoneButtonContainer,
  Note,
  Wrapper,
} from "../../../BookAppointment/styledComponents";
import PrimaryButton from "../../../../components/PrimaryButton";
import PaymentFailed from "../../../../assets/images/CoporatePatientModule/PaymentFailed.png";

const FailedPayment = ({ onOk }) => {
  return (
    <>
      <Wrapper>
        <FailedMessage>
          <span style={{ color: " #FF5353" }}>Payment Failed!</span>
        </FailedMessage>
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            margin: "20px",
          }}
        >
          <img
            src={PaymentFailed}
            height="200px"
            weight="200px"
            alt=""
          />
        </div>
        <FailedMessage type2={true}>
          Your Payment has failed! Please try again. <br />
        </FailedMessage>
        <Note>
          Hac venenatis etiam lectus at cursus. Viverra dui lectus suspendisse
          sem et.
        </Note>
        <DoneButtonContainer>
          <PrimaryButton label="Pay Now" onClick={onOk} />
        </DoneButtonContainer>
      </Wrapper>
    </>
  );
};

export default FailedPayment;
