import React from "react";

const Name = ({ name, setName }) => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        maxWidth: "815px",
        width: "100%",
        padding: "5% 0",
        gap: "clamp(24px, 4vw, 36px)",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "12px",
        }}
      >
        <span
          style={{
            fontSize: "clamp(24px, 5vw, 32px)",
            fontWeight: "700",
            color: "#262626",
            fontFamily: "Metropolis Bold, sans-serif",
          }}
        >
          What should we call you?
        </span>
        <span
          style={{
            fontSize: "clamp(16px, 3vw, 20px)",
            fontWeight: "400",
            color: "#262626",
            fontFamily: "Metropolis Regular, sans-serif",
          }}
        >
          Let's have a brief introduction
        </span>
      </div>
      <input
        style={{
          width: "100%",
          maxWidth: "386px",
          height: "clamp(60px, 10vw, 90px)",
          borderRadius: "8px",
          border: "1px solid #ABABAB",
          padding: "16px",
          backgroundColor: "#FBFBFB",
          fontSize: "clamp(20px, 4vw, 28px)",
          fontWeight: "700",
          color: "#000000",
          fontFamily: "Metropolis Bold, sans-serif",
          textAlign: "center",
        }}
        value={name}
        onChange={(e) => {
          setName(e.target.value);
        }}
      />
    </div>
  );
};

export default Name;
